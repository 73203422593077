import React from 'react';

export const LogoPlaceholderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 55.6893V23.3003C8 20.2701 9.71202 17.5 12.4223 16.1449L33.4741 5.61895C35.2472 4.73242 37.3333 6.02174 37.3333 8.00409V17.0227V30.356V58.356H10.6667C9.19391 58.356 8 57.1621 8 55.6893ZM53.3333 58.356H40V38.356V18.356L53.354 26.1458C54.9925 27.1016 56 28.8558 56 30.7526V55.6893C56 57.1621 54.8061 58.356 53.3333 58.356ZM18.6667 21.0227C17.1939 21.0227 16 22.2166 16 23.6894C16 25.1621 17.1939 26.356 18.6667 26.356H26.6667C28.1394 26.356 29.3333 25.1621 29.3333 23.6894C29.3333 22.2166 28.1394 21.0227 26.6667 21.0227H18.6667ZM16 34.356C16 32.8833 17.1939 31.6894 18.6667 31.6894H26.6667C28.1394 31.6894 29.3333 32.8833 29.3333 34.356C29.3333 35.8288 28.1394 37.0227 26.6667 37.0227H18.6667C17.1939 37.0227 16 35.8288 16 34.356ZM18.6667 42.356C17.1939 42.356 16 43.5499 16 45.0227C16 46.4955 17.1939 47.6894 18.6667 47.6894H26.6667C28.1394 47.6894 29.3333 46.4955 29.3333 45.0227C29.3333 43.5499 28.1394 42.356 26.6667 42.356H18.6667Z"
      fill="#646F87"
    />
  </svg>
);
