import React from 'react';

export const OptionsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.75 5.5C13.75 6.46649 12.9666 7.25 12 7.25C11.0334 7.25 10.25 6.46649 10.25 5.5C10.25 4.53351 11.0334 3.75 12 3.75C12.9666 3.75 13.75 4.53351 13.75 5.5Z"
      fill="white"
    />
    <path
      d="M13.75 12C13.75 12.9665 12.9666 13.75 12 13.75C11.0334 13.75 10.25 12.9665 10.25 12C10.25 11.0335 11.0334 10.25 12 10.25C12.9666 10.25 13.75 11.0335 13.75 12Z"
      fill="white"
    />
    <path
      d="M12 20.25C12.9666 20.25 13.75 19.4665 13.75 18.5C13.75 17.5335 12.9666 16.75 12 16.75C11.0334 16.75 10.25 17.5335 10.25 18.5C10.25 19.4665 11.0334 20.25 12 20.25Z"
      fill="white"
    />
  </svg>
);
