import { useCallback, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormProps } from '../../SoundWave/components/Form/Form';
import { ModalProps } from '../../SoundWave/components/Modal/Modal';
import {
  FormFieldProps,
  FormFieldType,
} from '../../SoundWave/components/FormField/FormField';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useId, useMixpanel } from '../../../hooks';
import { AddNoteFormValues } from '../../../store/relationshipFlows/relationshipFlows.types';
import {
  closeAddNoteModal,
  resetAddNoteFlow,
} from '../../../store/relationshipFlows/relationshipFlows.slice';
import {
  createAccountNote,
  updateAccountNote,
} from '../../../store/relationshipDetails/relationshipDetails.thunks';

const MAX_NOTE_LENGTH = 5000;

const validationSchema = Yup.object().shape({
  note: Yup.string()
    .required('This field is required')
    .max(
      MAX_NOTE_LENGTH,
      ({ value }) => `${value.length} of ${MAX_NOTE_LENGTH} characters`
    ),
});

export const useAddNoteModal = (): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<keyof AddNoteFormValues, FormFieldProps>;
  values: AddNoteFormValues;
  maxNoteLength: number;
  hasError: boolean;
} => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(
    (state) => state.relatioshipFlows.addNote.isModalOpened
  );

  const initialValues = useAppSelector(
    (state) => state.relatioshipFlows.addNote.initialValues
  );

  const noteId = useAppSelector(
    (state) => state.relatioshipFlows.addNote.noteId
  );

  const isUpdateNotesLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.updateNotes
  );

  const accountId = useAppSelector(
    (state) => state.relatioshipFlows.addNote.accountId
  );

  const formId = useId();

  const { trackEvent } = useMixpanel();

  const {
    dirty,
    errors,
    touched,
    values,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik<AddNoteFormValues>({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (formValues) => {
      try {
        trackEvent(noteId ? 'updateAccountNote' : 'createAccountNote');

        if (noteId) {
          await dispatch(
            updateAccountNote({
              ...formValues,
              accountId: accountId || '',
              noteId: noteId || '',
            })
          ).unwrap();
        } else {
          await dispatch(
            createAccountNote({ ...formValues, accountId: accountId || '' })
          ).unwrap();
        }

        dispatch(closeAddNoteModal());
      } catch (error) {
        // TODO: Add error handling
        console.log(error);
      }
    },
  });

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  const modalProps = useMemo(
    () => ({
      isOpened,
      closeOnClickOutside: true,
      width: 560,
      className: 'add-note-modal',
      onClose: () => {
        dispatch(closeAddNoteModal());
      },
      onAnimationEnd: () => {
        dispatch(resetAddNoteFlow());
      },
      headerProps: {
        title: noteId ? 'Edit Note' : 'Add Note',
      },
      footerProps: {
        formId,
        isLoading: isUpdateNotesLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: noteId ? 'Save' : 'Add',
        isConfirmButtonDisabled: !isValid || !dirty,
      },
    }),
    [dirty, dispatch, formId, isOpened, isUpdateNotesLoading, isValid, noteId]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      note: {
        type: FormFieldType.TEXTAREA,
        name: 'note',
        placeholder: 'Enter text',
        label: 'Note',
        value: values.note,
        error: errors.note,
        touched: touched.note,
        className: 'note-field',
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [errors, handleBlur, handleChange, handleFocus, touched, values]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    values,
    maxNoteLength: MAX_NOTE_LENGTH,
    hasError: !!errors.note && !!touched.note,
  };
};
