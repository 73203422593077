import { useState } from 'react';
import Resizer from 'react-image-file-resizer';

export type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

type ImageCropperProps = {
  getCroppedImg: (imageSrc: string, pixelCrop: Area) => Promise<string>;
  crop: {
    x: number;
    y: number;
  };
  onCropChange: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
  zoom: number;
  onZoomChange: React.Dispatch<React.SetStateAction<number>>;
  onCropComplete: (_: Area, croppedPixels: Area) => void;
  croppedAreaPixels: Area | null;
};

export const useImageCropper = (): ImageCropperProps => {
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = (_: Area, croppedPixels: Area) => {
    setCroppedAreaPixels(croppedPixels);
  };

  const createImage = async (url: string) =>
    new Promise<HTMLImageElement>((resolve) => {
      const image = new Image();
      image.setAttribute('crossorigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        resolve(image);
      };
    });

  const resizeImage = (image: string): Promise<string> => {
    return new Promise((resolve) => {
      fetch(image)
        .then((res) => res.blob())
        .then((blob) => {
          Resizer.imageFileResizer(
            blob,
            300,
            300,
            'PNG',
            100,
            0,
            (uri) => {
              resolve(uri as string);
            },
            'base64',
            250,
            250
          );
        });
    });
  };

  async function getCroppedImg(
    imageSrc: string,
    pixelCrop: { x: number; y: number; width: number; height: number }
  ): Promise<string> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx?.putImageData(
      data || new ImageData(0, 0),
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    return resizeImage(canvas.toDataURL('image/png'));
  }

  return {
    getCroppedImg,
    crop,
    onCropChange,
    zoom,
    onZoomChange,
    onCropComplete,
    croppedAreaPixels,
  };
};
