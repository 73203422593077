import React from 'react';

export const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4712 4.8619C12.7315 5.12233 12.7314 5.54444 12.4709 5.80471L7.13447 11.138C7.00938 11.2631 6.83975 11.3332 6.66291 11.3332C6.48606 11.3331 6.3165 11.2628 6.19152 11.1376L3.52799 8.47096C3.2678 8.21046 3.26804 7.78835 3.52855 7.52816C3.78905 7.26796 4.21116 7.26821 4.47136 7.52871L6.66362 9.72356L11.5284 4.86163C11.7888 4.60135 12.2109 4.60148 12.4712 4.8619Z"
      fill="#537BFF"
    />
  </svg>
);
