import React from 'react';
import { IconProps } from './components';

export enum Features {
  WEBHOOKS = 'webhooks',
  EVENTT_NOTOFICATIONS = 'eventNotifications',
  MY_ANALYTICS = 'myAnalytics',
  MY_INSIGHTS = 'myInsights',
  TEAMS = 'teams',
  TEAM_ANALYTICS = 'teamAnalytics',
  EMAIL_ANALYZER = 'emailAnalyzer',
  EMAIL_SIMULATOR = 'emailSimulator',
  X_RAY_ANALYSIS = 'xRayAnalysis',
  X_RAY_SIMULATION = 'xRaySimulation',
  PERSON_ANALYSIS = 'personAnalysis',
  OFFLINE_ANALYSIS = 'offlineAnalysis',
  WATCH_THREADS = 'watchThreads',
  WATCH_CONTACTS = 'watchContacts',
  MY_ENGAGEMENTS = 'myEngagements',
  GROUP_THREADS_BY_CONTACTS = 'groupThreadsByContacts',
  EXTENDED_CONTACTS_METADATA = 'extendedContactsMetadata',
  MESSAGE_WIZARD = 'messageWizard',
  INLINE_MENU = 'inlineMenu',
  PIXEL_TRACKING = 'pixelTracking',
  SOUNDWAVE = 'soundwave',
  CALENDAR_AGENDA = 'calendarAgenda',
  REPLY_FROM_DASHBOARD = 'replyFromDashboard',
  CONNECT_MY_ZOOM = 'connectMyZoom',
}

export type App = {
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  stats: {
    emailsAnalyzed?: number;
    updatedAt?: string;
    installHistory?: {
      action: string;
      date: string;
      version: string;
    };
  };
};

export type InviteStatus = 'sent' | 'revoked' | 'accepted' | 'expired';

export type User = {
  name: string;
  firstName: string;
  lastName: string;
  avatar: string;
  phoneNumber: string;
  timezone: string;
  title: string;
  signupProviders: SignInProvider[];
  userId: string;
  orgId: string;
  email: string;
  apps: App[];
  role: Role;
  lastSignIn: string;
  lastActivity: string;
  createdAt: string;
  updatedAt: string;
  stats: {
    emailsAnalyzed: number;
    updatedAt: string;
  };
  status?: string;
  deleted: boolean;
  active: boolean;
  isEmailVerified: boolean;
  inviteStatus: InviteStatus;
  inviteExpires: string;
  jwt?: string;
  updatedAvatar?: string;
  userHash: string;
  showDashboardWelcomePopup: boolean;
  showInstallExtensionPopup: boolean;
  isChromeExtensionInstalled: boolean;
  metadata?: {
    features?: Array<{
      enabled: boolean;
      isInPackage: boolean;
      name: Features;
    }>;
  };
};

export type UserActivity = {
  totalEmailsAnalyzed: number | null;
  totalEmailsSimulated: number | null;
  lastEmailAnalyzedDate: string | null;
  lastSimulationDate: string | null;
};

export type UserValuesToUpdate = {
  name?: string;
  newAvatar?: string;
  lastName?: string;
  firstName?: string;
  timezone?: string;
  title?: string;
  phoneNumber?: string;
};

interface SignInProvider {
  name: string;
  metadata?: {
    default: boolean;
  };
}

export type OrgSubscriptionStatus = 'active' | 'inactive' | 'trial';

export interface OrgSubscription {
  planName: string;
  status: OrgSubscriptionStatus;
  trialEnd: string;
  endedAt: string;
}

export type Organization = {
  name: string;
  nameType: string;
  url: string;
  primaryEmail: string;
  avatar: string;
  orgId: string;
  createdAt: string;
  updatedAt: string;
  deleted: boolean;
  inviteStatus: 'sent' | 'expired';
  inviteExpires: string;
  location: string | null;
  usersCount: number | string;
  maxUsersCount: number | string;
  ownerName: string;
  adminNames: string[];
  acquisitionSource: string | null;
  timezone: string | null;
  activeUsersCount: number | null;
  inactiveUsersCount: number | null;
  teamsCount: number | null;
  enabledFeatures: string[];
  isFunctionalityEnabled?: boolean;
  subscription: OrgSubscription | null;
  signupType: string;
};

export type BaseRouteI = {
  path: string;
  Component: React.FC;
  label: string;
  tooltip: string;
  Icon?: React.FC<IconProps>;
  roles: string[];
  disabled?: boolean;
  subroutes?: SubRoutes[];
  isIframe?: boolean;
  isAppSumo?: boolean;
};

export interface RouteI extends BaseRouteI {
  Icon?: React.FC<IconProps>;
}

export type SubRoutes = {
  path: string;
  Component: React.FC;
  label: string;
  Icon: React.FC<IconProps>;
  disabled?: boolean;
  roles: string[];
};

export type AddedUser = {
  email: string;
  inviteType: 'userInvite' | 'ownerInvite';
  orgId: string;
  name: string;
  avatar?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  userId: string;
};

export type ResendInviteUser = {
  orgId: string;
  userId: string;
  inviteType: 'ownerInvite' | 'userInvite';
  requesterId: string;
};

export type Role = 'admin' | 'dealmaker' | 'owner' | 'superAdmin';

export type RowUserStatus = 'active' | 'inactive' | 'sent' | 'expired';

export type Row = {
  key: string;
  name: string;
  email: string;
  status?: RowUserStatus;
  active?: boolean;
  lastActivity?: string;
  emailsAnalyzed?: number;
  invitationSent?: InviteStatus;
  inviteStatus?: InviteStatus;
  options?: User;
  userId: string;
  avatar?: string;
  role?: Role;
  orgId?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  teamMemberRole?: 'teamLeader' | 'member';
};

export type AuthCredentials = {
  email: string;
  password: string;
};

export type TeamMember = {
  userId: string;
  teamMemberRole: string;
};

export type AddedTeam = {
  name: string;
  avatar?: string;
  members?: TeamMember[];
};

export enum UserConnection {
  Zoom = 'zoom',
  GmailRead = 'gmailRead',
  GmailWrite = 'gmailWrite',
  Calendar = 'calendar',
}

export interface UserConnections {
  [UserConnection.Zoom]: boolean | undefined;
  [UserConnection.GmailRead]: boolean | undefined;
  [UserConnection.GmailWrite]: boolean | undefined;
  [UserConnection.Calendar]: boolean | undefined;
  zoomEmail: string | null;
}
