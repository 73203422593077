import React, { useState } from 'react';

type CloseIconProps = {
  onClick: () => void;
};

export const CloseIcon: React.FC<CloseIconProps> = ({
  onClick,
}: CloseIconProps): JSX.Element => {
  const [hover, setIsHover] = useState(false);
  const onEnter = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };
  const circleFill = hover ? '#646c83' : '#4d4558';
  return (
    <div onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <svg
        width="25"
        height="28"
        viewBox="-2 -2 27 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="close-icon"
        onClick={onClick}
      >
        <circle cx="11.5" cy="12" r="13.5" fill={circleFill} />
        <rect
          x="17.6775"
          y="17.7634"
          width="1.8"
          height="14"
          rx="1"
          transform="rotate(135 17.6775 16.7634)"
          fill="#FFF"
        />
        <rect
          x="6.36401"
          y="19.1775"
          width="1.8"
          height="14"
          rx="1"
          transform="rotate(-135 6.36401 18.1775)"
          fill="#FFF"
        />
      </svg>
    </div>
  );
};
