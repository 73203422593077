import React from 'react';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { getInitials } from './Avatar.utils';

export enum AvatarSize {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export interface AvatarProps {
  src?: string;
  name?: string;
  text?: string;
  side?: RelationshipMemberSide;
  className?: string;
  size?: AvatarSize;
  hasBorder?: boolean;
  placeholder?: React.FC<React.SVGProps<SVGSVGElement>>;
  shape?: 'circle' | 'square';
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  name,
  text,
  side,
  className,
  placeholder,
  size = AvatarSize.M,
  hasBorder = true,
  shape = 'circle',
}) => {
  const attrs = {
    container: {
      className: `sound-wave-avatar sound-wave-avatar--${size}${
        hasBorder ? '' : ' sound-wave-avatar--no-border'
      }${side ? ` sound-wave-avatar--${side}` : ''}${
        className ? ` ${className}` : ''
      }${shape === 'square' ? ' sound-wave-avatar--square' : ''}`,
    },
    image: {
      src: src || '',
      className: 'sound-wave-avatar__image',
    },
    text: {
      className: 'sound-wave-avatar__text',
    },
    placeholderIcon: {
      className: 'sound-wave-avatar__placeholder-icon',
    },
  };

  if (src) {
    return (
      <div {...attrs.container}>
        <img {...attrs.image} alt={name} />
      </div>
    );
  }

  if (placeholder) {
    const PlaceholderIcon = placeholder;

    return (
      <div {...attrs.container}>
        <PlaceholderIcon {...attrs.placeholderIcon} />
      </div>
    );
  }

  if (name) {
    return (
      <div {...attrs.container}>
        <span {...attrs.text}>{getInitials(name)}</span>
      </div>
    );
  }

  if (text) {
    return (
      <div {...attrs.container}>
        <span {...attrs.text}>{text}</span>
      </div>
    );
  }

  return <div {...attrs.container} />;
};
