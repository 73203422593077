import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import axios from '../../axios';
import {
  AddErrorVerifyPassword,
  AddUserAction,
  AddUserFailureAction,
  ClearErrorsAction,
  ClearUsersAction,
  CloseDeleteModalAction,
  DeleteUsersAction,
  GetUsersAction,
  InvokeAddUserModalAction,
  InvokeAssignRoleModalAction,
  InvokeDeleteModalAction,
  SetCurrentUserAction,
  UpdateUserAction,
  VerifyPasswordFailure,
} from './users.types';
import {
  AddedUser,
  ResendInviteUser,
  User,
  UserValuesToUpdate,
} from '../../types';

export const GET_USERS = 'GET_USERS';
export const DELETE_USERS = 'DELETE_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const INVOKE_DELETE_MODAL = 'INVOKE_DELETE_MODAL';
export const INVOKE_ADD_USER_MODAL = 'INVOKE_ADD_USER_MODAL';
export const INVOKE_ASSIGN_ROLE_MODAL = 'INVOKE_ASSIGN_ROLE_MODAL';
export const CLOSE_DELETE_MODAL = 'CLOSE_DELETE_MODAL';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const RESEND_INVITE = 'RESEND_INVITE';
export const VERIFY_PASSWORD_STATUS = 'VERIFY_PASSWORD_STATUS';
export const VERIFY_PASSWORD_FAILURE = 'VERIFY_PASSWORD_FAILURE';
export const ADD_ERROR_INCORRECT_PASSWORD = 'ADD_ERROR_VERIFY_PASSWORD';
export const CHANGE_PASSWORD_STATUS = 'CHANGE_PASSWORD_STATUS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_USERS = 'CLEAR_USERS';

export const getUsers = (orgId: string, signal?: AbortSignal) => async (
  dispatch: Dispatch<GetUsersAction>
): Promise<void> => {
  try {
    const users = await axios.get(
      `/services/orgs/${orgId}/users?analyzedEmails=true`,
      { signal }
    );
    dispatch({
      type: GET_USERS,
      payload: users.data.payload,
    });
  } catch (e) {
    // err
  }
};

export const deleteUsers = (userIds: string[], orgId: string) => async (
  dispatch: Dispatch<DeleteUsersAction | GetUsersAction>
): Promise<void> => {
  try {
    const removed: User[] = [];
    userIds.forEach(async (userId) => {
      const removedUser: AxiosResponse<User> = await axios.delete(
        `/services/orgs/${orgId}/users/${userId}`
      );
      removed.push(removedUser.data);
    });
    dispatch({
      type: DELETE_USERS,
      payload: userIds,
    });
  } catch (e) {
    // Handle error
  }
};

export const updateUser = (
  userToUpdate: UserValuesToUpdate,
  orgId: string,
  userId: string
) => async (dispatch: Dispatch<UpdateUserAction>): Promise<void> => {
  try {
    // eslint-disable-next-line
    const newObj: any = {}; // eslint-disable-next-line
    for (const [key, value] of Object.entries(userToUpdate)) {
      if (value?.length) {
        newObj[key] = value.trim();
      }
    }
    const valuesToUpdate = {
      ...newObj,
      newAvatar: userToUpdate.newAvatar
        ? {
            contentType: 'image/png',
            b64content: userToUpdate.newAvatar.split(',')[1],
          }
        : null,
    };
    const updatedUser = await axios.patch(
      `/services/orgs/${orgId}/users/${userId}`,
      valuesToUpdate
    );
    dispatch({
      type: UPDATE_USER,
      payload: updatedUser.data.payload,
    });
  } catch (e) {
    // Handle error
  }
};
export const updateUserRole = (
  role: string,
  orgId: string,
  userId: string
) => async (dispatch: Dispatch<UpdateUserAction>): Promise<void> => {
  try {
    const roleToUpdate = { newRole: role };
    const updatedUser = await axios.patch(
      `services/orgs/${orgId}/users/${userId}/role`,
      roleToUpdate
    );
    dispatch({
      type: UPDATE_USER,
      payload: updatedUser.data.payload,
    });
  } catch (e) {
    // Handle error
  }
};

export const setCurrentUser = (user: User): SetCurrentUserAction => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const invokeAddUserModal = (orgId: string) => async (
  dispatch: Dispatch<InvokeAddUserModalAction>
): Promise<void> => {
  try {
    const users = await axios.get(
      `/services/orgs/${orgId}/users?analyzedEmails=true`
    );
    dispatch({
      type: INVOKE_ADD_USER_MODAL,
      payload: users.data.payload,
    });
  } catch (e) {
    // err
  }
};

export const invokeDeleteModal = (users: User[]): InvokeDeleteModalAction => ({
  type: INVOKE_DELETE_MODAL,
  payload: users,
});

export const closeDeleteUserModal = (): CloseDeleteModalAction => ({
  type: CLOSE_DELETE_MODAL,
});

export const addUser = (user: AddedUser) => async (
  dispatch: Dispatch<AddUserAction | AddUserFailureAction>
): Promise<void> => {
  try {
    const userToAdd = {
      ...user,
      newAvatar: user.avatar
        ? { contentType: 'image/png', b64content: user.avatar.split(',')[1] }
        : null,
    };
    await axios.post(
      `/services/orgs/${user.orgId}/invites?requesterId=${user.userId}`,
      userToAdd
    );
    dispatch({
      type: ADD_USER,
    });
  } catch (e) {
    dispatch({
      type: ADD_USER_FAILURE,
      payload: 'A user with this email address already exists',
    });
  }
};

export const invokeAssignRoleModal = (
  user: User
): InvokeAssignRoleModalAction => ({
  type: INVOKE_ASSIGN_ROLE_MODAL,
  payload: user,
});

export const resendInvite = (user: ResendInviteUser) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    await axios.post(
      `/services/orgs/${user.orgId}/invites/resend?requesterId=${user.requesterId}`,
      user
    );
    dispatch({
      type: RESEND_INVITE,
    });
  } catch (e) {
    // err
  }
};

export const verifyPassword = (payload: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    const response = await axios.post(`/verify-password/`, {
      password: payload,
    });
    if (response.status === 200)
      dispatch({
        type: VERIFY_PASSWORD_STATUS,
        flag: true,
      });
    dispatch({ type: CLEAR_ERRORS });
  } catch (e) {
    dispatch({ type: CLEAR_ERRORS });
    dispatch({
      type: VERIFY_PASSWORD_STATUS,
      flag: false,
    });
    dispatch({
      type: ADD_ERROR_INCORRECT_PASSWORD,
      message: 'Incorrect password',
    });
  }
};

export const verifyPasswordFailure = (): VerifyPasswordFailure => ({
  type: VERIFY_PASSWORD_FAILURE,
});

export const addErrorVerifyPasswordFail = (
  message: string
): AddErrorVerifyPassword => ({
  type: ADD_ERROR_INCORRECT_PASSWORD,
  message,
});

export const changePasswordCode = (cred: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    const response = await axios.post(`/update-password/`, { password: cred });
    if (response.status === 200)
      dispatch({
        type: CHANGE_PASSWORD_STATUS,
        payload: true,
      });
  } catch (e) {
    dispatch({
      type: CHANGE_PASSWORD_STATUS,
      payload: false,
    });
  }
};

export const clearErrors = (): ClearErrorsAction => ({
  type: CLEAR_ERRORS,
});

export const clearUsers = (): ClearUsersAction => ({
  type: CLEAR_USERS,
});
