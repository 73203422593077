export const getUserLocalTimezone = (isForQuery?: boolean): string => {
  let gmtTimezone: string | number = new Date().getTimezoneOffset();

  if (+gmtTimezone < 0) {
    gmtTimezone = `GMT${isForQuery ? '%2b' : '+'}${
      -new Date().getTimezoneOffset() / 60
    }`;
  } else if (+gmtTimezone > 0) {
    gmtTimezone = `GMT-${new Date().getTimezoneOffset() / 60}`;
  } else {
    gmtTimezone = `GMT${isForQuery ? '%2b' : '+'}${
      new Date().getTimezoneOffset() / 60
    }`;
  }

  return gmtTimezone;
};
