import React from 'react';
import { useNavigationTabs } from './hooks/useNavigationTabs';

export const NavigationTabs: React.FC = () => {
  const navigationTabs = useNavigationTabs();

  const attrs = {
    container: {
      className: 'navigation-tabs',
    },
  };

  const renderNavigationTabs = () =>
    navigationTabs.map(({ label, path, isActive, onClick }) => {
      const props = {
        key: path,
        type: 'button' as const,
        className: 'navigation-tabs__item',
        disabled: isActive,
        onClick,
      };

      return <button {...props}>{label}</button>;
    });

  if (!navigationTabs.length) {
    return null;
  }

  return <div {...attrs.container}>{renderNavigationTabs()}</div>;
};
