import { Account } from '../../types/account.types';
import { Contact } from '../../types/contact.types';
import { Relationship } from '../../types/relationship.types';
import { Filter, FilterValue } from '../../types/relationshipFilters.types';

export enum RSSortBy {
  ACCOUNT_NAME = 'name',
  CREATED_AT = 'createdAt',
  LAST_ACTIVITY = 'lastActivity',
  EVENTS_NUMBER = 'touchPointsCount',
}

export enum RSSortOrder {
  FROM_HIGH = 'desc',
  FLOM_LOW = 'asc',
}

export enum RelatioshipCheckboxFilters {
  HIDE_RELATIONSHIPS_WITH_EVENTS = 'hideNonEmptyRelationships',
  HIDE_RELATIONSHIPS_WITHOUT_EVENTS = 'hideEmptyRelationships',
  SHOW_MY_RELATIONSHIPS = 'showMyRelationships',
}

export interface RelationshipsResponse {
  count: number;
  p: number;
  ipp: number;
  sortBy: RSSortBy | null;
  sortOrder: RSSortOrder | null;
  nextPage: string | null;
  results: Relationship[];
}

export interface AccountsResponse {
  count: number;
  p: number;
  ipp: number;
  sortBy: string;
  sortOrder: string;
  nextPage: string | null;
  results: Account[];
}

export interface ContactsResponse {
  count: number;
  p: number;
  ipp: number;
  sortBy: string;
  sortOrder: string;
  nextPage: string | null;
  results: Contact[];
}

export interface RelationshipsFiltersQueryParams {
  lastInteractionDateMin?: string;
  lastInteractionDateMax?: string;
  relationshipAgeMonthsMin?: string;
  relationshipAgeMonthsMax?: string;
}
export interface RelationshipsQueryParams
  extends RelationshipsFiltersQueryParams {
  ipp: number;
  p: number;
  sortBy: string;
  sortOrder: string;
}

export interface RelationshipsState {
  relationships: RelationshipsResponse;
  relationshipsForTable: Relationship[];
  search: {
    accountsData: {
      accounts: Account[];
      isLoading: boolean;
      isError: boolean;
      noResults: boolean;
    };
    contactsData: {
      contacts: Contact[];
      isLoading: boolean;
      isError: boolean;
      noResults: boolean;
    };
  };
  relationshipsDataStatus: {
    isLoading: boolean;
    isError: boolean;
  };
  filters: Filter<FilterValue>[];
  filtersQueryParams: RelationshipsFiltersQueryParams;
}
