import React from 'react';

export const ConnectIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2413 12.6067C14.1787 13.6693 12.4559 13.6693 11.3933 12.6067C11.0008 12.2143 10.3646 12.2143 9.97213 12.6067C9.57969 12.9992 9.57969 13.6354 9.97213 14.0279C11.8196 15.8754 14.815 15.8754 16.6625 14.0279L20.6144 10.076C22.4619 8.22848 22.4619 5.23311 20.6144 3.38562C18.7669 1.53812 15.7715 1.53813 13.924 3.38563L12.6067 4.70293C12.2143 5.09536 12.2143 5.73163 12.6067 6.12406C12.9992 6.51649 13.6354 6.51649 14.0279 6.12405L15.3451 4.80676C16.4078 3.74414 18.1306 3.74413 19.1932 4.80675C20.2559 5.86937 20.2559 7.59221 19.1932 8.65483L15.2413 12.6067ZM8.75866 11.3933C9.82127 10.3306 11.5441 10.3306 12.6067 11.3933C12.9992 11.7857 13.6354 11.7857 14.0279 11.3933C14.4203 11.0008 14.4203 10.3646 14.0279 9.97213C12.1804 8.12464 9.18501 8.12464 7.33752 9.97213L3.38562 13.924C1.53813 15.7715 1.53813 18.7669 3.38562 20.6144C5.23311 22.4619 8.2285 22.4619 10.076 20.6144L11.3933 19.2971C11.7857 18.9046 11.7857 18.2684 11.3933 17.8759C11.0008 17.4835 10.3646 17.4835 9.97212 17.8759L8.65484 19.1932C7.59223 20.2559 5.86937 20.2559 4.80675 19.1932C3.74414 18.1306 3.74413 16.4078 4.80675 15.3452L8.75866 11.3933Z"
      fill="white"
    />
  </svg>
);
