import { Reducer } from 'redux';
import {
  CLEAR_ANALYTICS,
  GET_ANALYTICS_DATA,
  GET_CHARTS_DATA,
  GET_DATA_FIRST_TIME,
  GET_QDATA_FOR_IFRAME,
  SET_ERROR,
  SET_LOADING,
  SET_NOT_ENOUGH_DATA,
} from './analytics.actions';
import {
  AnalyticsActions,
  ChartsData,
  IframeQData,
  Measurements,
  Stats,
} from './analytics.types';

export interface AnalyticsState {
  iframeData: IframeQData | null;
  stats: Stats | null;
  measurements: Measurements | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  isGetDataCalledFirstTime: boolean;
  chartsData: ChartsData | null;
}

export const initialState: AnalyticsState = {
  iframeData: null,
  stats: null,
  measurements: null,
  isLoading: false,
  noData: false,
  error: false,
  isGetDataCalledFirstTime: false,
  chartsData: null,
};

export const analyticsReducer: Reducer<AnalyticsState, AnalyticsActions> = (
  state: AnalyticsState = initialState,
  action: AnalyticsActions
): AnalyticsState => {
  switch (action.type) {
    case GET_ANALYTICS_DATA:
      return {
        ...state,
        stats: action.payload.stats,
        measurements: action.payload.measurements,
      };
    case GET_QDATA_FOR_IFRAME:
      return {
        ...state,
        iframeData: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.flag,
      };
    case SET_NOT_ENOUGH_DATA:
      return {
        ...state,
        noData: action.flag,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.flag,
      };
    case GET_DATA_FIRST_TIME:
      return {
        ...state,
        isGetDataCalledFirstTime: true,
      };
    case CLEAR_ANALYTICS:
      return {
        ...initialState,
      };
    case GET_CHARTS_DATA:
      return {
        ...state,
        chartsData: action.payload,
      };
    default:
      return state;
  }
};
