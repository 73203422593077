import React from 'react';

export const ZoomIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_10729_8743)">
      <path
        d="M12.6277 17.2677C11.9209 17.9726 10.7825 17.9726 10.0853 17.2677C9.37851 16.5628 9.37851 15.4274 10.0853 14.7319C10.792 14.027 11.9304 14.027 12.6277 14.7319C13.325 15.4274 13.325 16.5628 12.6277 17.2677Z"
        fill="#537BFF"
      />
      <path
        d="M16.3797 17.2677C17.077 17.9726 18.2154 17.9726 18.9221 17.2677C19.6194 16.5628 19.6194 15.4274 18.9221 14.7319C18.2248 14.027 17.0864 14.027 16.3797 14.7319C15.6729 15.4274 15.6729 16.5628 16.3797 17.2677Z"
        fill="#537BFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16ZM13.472 13.8803C12.8886 13.2984 12.1249 13.0051 11.3517 13.0051C10.5881 13.0051 9.8149 13.2984 9.23146 13.8803C8.05986 15.0489 8.05986 16.9365 9.23146 18.1098C10.4031 19.2783 12.2957 19.2783 13.472 18.1098C14.6436 16.946 14.6436 15.0441 13.472 13.8803ZM17.6509 13.0051C18.4241 13.0051 19.1878 13.2985 19.7712 13.8804C20.938 15.0442 20.938 16.946 19.7712 18.1098C18.5948 19.2784 16.7022 19.2784 15.5306 18.1098C14.359 16.9366 14.359 15.0489 15.5306 13.8804C16.1141 13.2985 16.8872 13.0051 17.6509 13.0051ZM4.15608 18.985L3.85725 18.9709C3.36394 18.9425 3.00345 18.5829 2.97024 18.0862L2.95601 17.7881L6.56096 14.2021H3.86199L3.56316 14.1879C3.07934 14.1595 2.71884 13.7952 2.6809 13.3079L2.66667 13.0099H7.16336L7.46219 13.0241C7.9555 13.043 8.32074 13.412 8.34446 13.904L8.35869 14.2021L4.75848 17.7929H7.45745L7.75628 17.807C8.24959 17.8354 8.6006 18.195 8.63854 18.687L8.65277 18.985H4.15608ZM24.5335 15.3943C24.5145 15.1719 24.4861 14.9779 24.3817 14.7982C24.1778 14.4386 23.7888 14.2021 23.3382 14.1926C22.897 14.1926 22.5128 14.4292 22.3041 14.7887C22.195 14.959 22.1666 15.1625 22.1523 15.3848L22.1381 15.6829V17.7739L22.1239 18.072C22.1049 18.564 21.7444 18.9235 21.2416 18.9519L20.9428 18.9661V12.9862C21.2416 12.9862 21.7681 13.1376 22.0006 13.3978C22.3848 13.1376 22.8496 12.9862 23.3429 12.9862C24.0592 12.9862 24.6995 13.3032 25.1407 13.7952C25.5818 13.2985 26.2174 12.9862 26.9384 12.9862C28.2665 12.9862 29.3385 14.0554 29.3385 15.3801V18.9661L29.0397 18.9519C28.5464 18.9235 28.1859 18.564 28.1574 18.072L28.1432 17.7739V15.6829L28.129 15.3848C28.1195 15.1719 28.091 14.9685 27.9867 14.7887C27.7732 14.4339 27.3843 14.1926 26.9431 14.1926C26.502 14.1926 26.1178 14.4244 25.9091 14.784C25.8 14.9638 25.7715 15.1672 25.7573 15.3895L25.7431 15.6876V18.9756L25.4442 18.9614C24.9509 18.933 24.5904 18.5687 24.562 18.0814L24.5477 17.7834V15.6923L24.5335 15.3943Z"
        fill="#537BFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_10729_8743">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
