import { InviteStatus, Row, RowUserStatus, User } from '../../types';

const defineUserStatus = (
  activeStatus: boolean,
  inviteStatus: InviteStatus
): RowUserStatus | undefined => {
  if (inviteStatus === 'sent' || inviteStatus === 'expired') {
    return inviteStatus;
  }

  if (inviteStatus === 'accepted') {
    return activeStatus ? 'active' : 'inactive';
  }

  return undefined;
};

export const getUserRow = (user: User): Row => ({
  ...user,
  name: `${user.firstName} ${user.lastName}`,
  userId: user.userId,
  key: user.userId,
  status: defineUserStatus(user.active, user.inviteStatus),
  options: user,
  emailsAnalyzed:
    user.apps.find((app) => app.name === 'email-analyzer')?.stats
      ?.emailsAnalyzed || 0,
  invitationSent: user.inviteStatus,
});

export const sortUsersArray = (usersArray: User[]): Row[] => {
  const result: User[] = [];
  const superAdmins = usersArray.filter((user) => user?.role === 'superAdmin');
  const owner = usersArray.filter((user) => user?.role === 'owner');
  const admins = usersArray.filter((user) => user?.role === 'admin');
  const dealmakers = usersArray
    .filter((user) => user?.role === 'dealmaker')
    .sort((a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1));
  return result
    .concat(superAdmins, owner, admins, dealmakers)
    .map((user) => getUserRow(user));
};
