import React from 'react';
import { useMixpanel } from '../../hooks';
import { showBottomNotification } from '../../store/bottomNotification/bottomNotification.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  closeUnassignAccountModal,
  resetUnassignAccountFlow,
} from '../../store/relationshipFlows/relationshipFlows.slice';
import { assignEventToAccount } from '../../store/relationshipFlows/relationshipFlows.thunks';
import { ConfirmationModal } from '../SoundWave';
import { DangerIcon } from './icons/DangerIcon';

export const UnassignEventModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(
    (state) => state.relatioshipFlows.unassignAccount.isModalOpened
  );

  const isLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.assignEventToAccount
  );

  const eventId = useAppSelector(
    (state) => state.relatioshipFlows.unassignAccount.eventId
  );

  const { trackEvent } = useMixpanel();

  const attrs = {
    modal: {
      isOpened,
      title: 'Unassign Event',
      description: 'Do you want to unassigne this Event?',
      icon: DangerIcon,
      footerProps: {
        isLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes, Unassign',
        isConfirmButtonDanger: true,
        onConfirm: async () => {
          try {
            if (eventId) {
              trackEvent('unassignEvent');

              await dispatch(
                assignEventToAccount({ eventId, accountId: null })
              ).unwrap();

              dispatch(closeUnassignAccountModal());

              dispatch(
                showBottomNotification({ text: 'Event was unassigned' })
              );
            }
          } catch (error) {
            // TODO: Add error handling
            console.log(error);
          }
        },
      },
      onClose: () => {
        dispatch(closeUnassignAccountModal());
      },
      onAnimationEnd: () => {
        dispatch(resetUnassignAccountFlow());
      },
    },
  };

  return <ConfirmationModal {...attrs.modal} />;
};
