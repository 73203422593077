import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';

export const ProfileRedirect: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const history = useHistory();

  const { search } = useLocation();

  const zoomAuthCode = useMemo(() => new URLSearchParams(search).get('code'), [
    search,
  ]);

  useEffect(() => {
    if (user) {
      history.replace(
        `/orgs/${user.orgId}/users/${user.userId}${
          zoomAuthCode ? `/?zoomAuthCode=${zoomAuthCode}` : ''
        }`
      );
    }
  }, [history, user, zoomAuthCode]);

  return null;
};
