import React from 'react';

export const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M6.77575 20H2L8.3978 4H13.1736L6.77575 20Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09583 19.0111L11.7028 4.98889H9.07772L3.47076 19.0111H6.09583ZM6.77575 20L13.1736 4H8.3978L2 20H6.77575Z"
      fill="white"
    />
    <path d="M15.6039 20H10.8281L17.2259 4H22.0017L15.6039 20Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.924 19.0111L20.5309 4.98889H17.9058L12.2989 19.0111H14.924ZM15.6039 20L22.0017 4H17.2259L10.8281 20H15.6039Z"
      fill="white"
    />
  </svg>
);
