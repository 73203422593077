import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';

export const AccountSettings: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const attrs = {
    redirect: {
      to: `/orgs/${user?.orgId}/admin-settings/users`,
    },
  };

  if (!user) {
    return null;
  }

  return <Redirect {...attrs.redirect} />;
};
