import React from 'react';

export interface ExistingContactDisclaimerProps {
  children?: React.ReactNode;
  text?: string;
}

export const ExistingContactDisclaimer: React.FC<ExistingContactDisclaimerProps> = ({
  children,
  text,
}) => {
  const attrs = {
    container: {
      className: 'existing-contact-disclaimer',
    },
  };

  return (
    <div {...attrs.container}>
      {text ? <span>{text}</span> : null}
      {children}
    </div>
  );
};
