import { configureStore, Reducer } from '@reduxjs/toolkit';
import { MODE } from '../constants/config';
import {
  analyticsReducer,
  AnalyticsState,
} from './analytics/analytics.reducer';
import { appSumoReducer, AppSumoState } from './appSumo/appSumo.reducer';
import { chartsReducer, ChartsState } from './charts/charts.reducer';
import { dealsReducer } from './deals/deals.reducer';
import { orgReducer, OrgState } from './org/org.reducer';
import { teamQReducer, TeamQState } from './teamQ/teamQ.reducer';
import { teamsReducer, TeamsState } from './teams/teams.reducer';
import { usersReducer, UsersState } from './users/users.reducer';
import {
  MyMeetingsState,
  myMeetingsReducer,
} from './myMeetings/myMeetings.reducer';
import meetingDetailsReducer from './meetingDetails/meetingDetails.slice';
import relationshipsReducer from './relationships/relationships.slice';
import relationshipDetailsReducer from './relationshipDetails/relationshipDetails.slice';
import relatioshipFlowsReducer from './relationshipFlows/relationshipFlows.slice';
import meetingRecordsReducer from './meetingRecords/meetingRecords.slice';
import bottomNotificationReducer from './bottomNotification/bottomNotification.slice';
import threadDetailsReducer from './threadDetails/threadDetails.slice';
import soundWaveTeamQReducer from './soundWaveTeamQ/soundWaveTeamQ.slice';
import globalReducer from './global/global.slice';
import billingReducer from './billing/billing.slice';
import authReducer from './auth/auth.slice';

export const store = configureStore({
  reducer: {
    users: usersReducer as Reducer<UsersState>,
    auth: authReducer,
    org: orgReducer as Reducer<OrgState>,
    teams: teamsReducer as Reducer<TeamsState>,
    analytics: analyticsReducer as Reducer<AnalyticsState>,
    charts: chartsReducer as Reducer<ChartsState>,
    deals: dealsReducer,
    appSumo: appSumoReducer as Reducer<AppSumoState>,
    teamQ: teamQReducer as Reducer<TeamQState>,
    soundWaveTeamQ: soundWaveTeamQReducer,
    myMeetings: myMeetingsReducer as Reducer<MyMeetingsState>,
    meetingDetails: meetingDetailsReducer,
    relationships: relationshipsReducer,
    relationshipDetails: relationshipDetailsReducer,
    relatioshipFlows: relatioshipFlowsReducer,
    meetingRecords: meetingRecordsReducer,
    bottomNotification: bottomNotificationReducer,
    threadDetails: threadDetailsReducer,
    global: globalReducer,
    billing: billingReducer,
  },
  devTools: MODE === 'local' || MODE === 'stage',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
