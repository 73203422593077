export enum PaginationSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Pagination {
  count: number;
  p: number;
  ipp: number;
  nextPage: number | null;
  sortOrder?: PaginationSortOrder | null;
  sortBy?: string | null;
}
