import { User } from '../types';

const getDealId = (): string => {
  const { pathname } = window.location;

  let dealId = '';

  if (pathname) {
    const isDealsDetailCheck = pathname.includes('deals/');
    const pathLength = pathname.split('/').length;
    const slicedDealId = isDealsDetailCheck
      ? pathname.split('/')[pathLength - 1]
      : '';

    dealId = slicedDealId;
  }

  return dealId;
};

const getIdsForEngagement = () => {
  const { pathname } = window.location;

  let engagementId = '';

  let branchId = '';

  if (pathname) {
    const isEngagements =
      pathname.includes('engagements') && pathname.includes('threads');

    const pathLength = pathname.split('/').length;

    engagementId = isEngagements ? pathname.split('/')[pathLength - 3] : '';

    branchId = isEngagements ? pathname.split('/')[pathLength - 1] : '';
  }

  return { engagementId, branchId };
};

const getMeetingId = () => {
  const { pathname } = window.location;

  if (pathname) {
    const isMeetings = pathname.includes('my-meetings');
    const pathLength = pathname.split('/').length;
    return isMeetings ? pathname.split('/')[pathLength - 1] || '' : '';
  }

  return '';
};

export const getCurrentPath = (path: string, user: User | null): string => {
  const dealId = getDealId();

  const { engagementId, branchId } = getIdsForEngagement();

  const meetingId = getMeetingId();

  return path
    .replace(':orgId', user?.orgId || '')
    .replace(':userId', user?.userId || '')
    .replace(':engagementId', engagementId)
    .replace(':branchId', branchId)
    .replace(':dealId', dealId)
    .replace(':meetingId', meetingId);
};

export const getStringTokens = (
  text: string,
  search = ''
): Array<{ value: string; isHighlighted: boolean }> => {
  const parts = text.split(
    new RegExp(`(${search.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')})`, 'gi')
  );

  return parts.map((part) => ({
    value: part,
    isHighlighted: part.toLowerCase() === search.toLowerCase(),
  }));
};
