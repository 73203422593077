import { createSlice } from '@reduxjs/toolkit';
import {
  getDetailedMeetingCountStats,
  getDetailedMeetingDurationStats,
  getDetailedTalkTimeRatioStats,
  getMeetingCountChartData,
  getMeetingDurationChartData,
  getTalkTimeRatioChartData,
} from './soundWaveTeamQ.thunks';
import { TeamQState } from './soundWaveTeamQ.types';

export const initialState: TeamQState = {
  isLoading: {
    meetingCountStats: false,
    meetingDurationStats: false,
    talkTimeRatioStats: false,
    meetingCountChartData: false,
    meetingDurationChartData: false,
    talkTimeRatioChartData: false,
  },
  isError: {
    meetingCountStats: false,
    meetingDurationStats: false,
    talkTimeRatioStats: false,
    meetingCountChartData: false,
    meetingDurationChartData: false,
    talkTimeRatioChartData: false,
  },
  meetingCountStats: null,
  meetingDurationStats: null,
  talkTimeRatioStats: null,
  meetingCountChartData: null,
  meetingDurationChartData: null,
  talkTimeRatioChartData: null,
};

const teamQSlice = createSlice({
  name: 'soundWaveTeamQ',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDetailedMeetingCountStats.pending, (state) => {
      state.isLoading.meetingCountStats = true;
      state.isError.meetingCountStats = false;
    });
    builder.addCase(getDetailedMeetingCountStats.fulfilled, (state, action) => {
      state.isLoading.meetingCountStats = false;
      state.meetingCountStats = action.payload;
    });
    builder.addCase(getDetailedMeetingCountStats.rejected, (state, action) => {
      state.isLoading.meetingCountStats = false;
      state.isError.meetingCountStats = !action.meta.aborted;
    });
    builder.addCase(getDetailedMeetingDurationStats.pending, (state) => {
      state.isLoading.meetingDurationStats = true;
      state.isError.meetingDurationStats = false;
    });
    builder.addCase(
      getDetailedMeetingDurationStats.fulfilled,
      (state, action) => {
        state.isLoading.meetingDurationStats = false;
        state.meetingDurationStats = action.payload;
      }
    );
    builder.addCase(
      getDetailedMeetingDurationStats.rejected,
      (state, action) => {
        state.isLoading.meetingDurationStats = false;
        state.isError.meetingDurationStats = !action.meta.aborted;
      }
    );
    builder.addCase(getDetailedTalkTimeRatioStats.pending, (state) => {
      state.isLoading.talkTimeRatioStats = true;
      state.isError.talkTimeRatioStats = false;
    });
    builder.addCase(
      getDetailedTalkTimeRatioStats.fulfilled,
      (state, action) => {
        state.isLoading.talkTimeRatioStats = false;
        state.talkTimeRatioStats = action.payload;
      }
    );
    builder.addCase(getDetailedTalkTimeRatioStats.rejected, (state, action) => {
      state.isLoading.talkTimeRatioStats = false;
      state.isError.talkTimeRatioStats = !action.meta.aborted;
    });
    builder.addCase(getMeetingCountChartData.pending, (state) => {
      state.isLoading.meetingCountChartData = true;
      state.isError.meetingCountChartData = false;
    });
    builder.addCase(getMeetingCountChartData.fulfilled, (state, action) => {
      state.isLoading.meetingCountChartData = false;
      state.meetingCountChartData = action.payload;
    });
    builder.addCase(getMeetingCountChartData.rejected, (state, action) => {
      state.isLoading.meetingCountChartData = false;
      state.isError.meetingCountChartData = !action.meta.aborted;
    });
    builder.addCase(getMeetingDurationChartData.pending, (state) => {
      state.isLoading.meetingDurationChartData = true;
      state.isError.meetingDurationChartData = false;
    });
    builder.addCase(getMeetingDurationChartData.fulfilled, (state, action) => {
      state.isLoading.meetingDurationChartData = false;
      state.meetingDurationChartData = action.payload;
    });
    builder.addCase(getMeetingDurationChartData.rejected, (state, action) => {
      state.isLoading.meetingDurationChartData = false;
      state.isError.meetingDurationChartData = !action.meta.aborted;
    });
    builder.addCase(getTalkTimeRatioChartData.pending, (state) => {
      state.isLoading.talkTimeRatioChartData = true;
      state.isError.talkTimeRatioChartData = false;
    });
    builder.addCase(getTalkTimeRatioChartData.fulfilled, (state, action) => {
      state.isLoading.talkTimeRatioChartData = false;
      state.talkTimeRatioChartData = action.payload;
    });
    builder.addCase(getTalkTimeRatioChartData.rejected, (state, action) => {
      state.isLoading.talkTimeRatioChartData = false;
      state.isError.talkTimeRatioChartData = !action.meta.aborted;
    });
  },
});

export default teamQSlice.reducer;
