import React from 'react';

export const CrmConnectionIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.334 8.00016C13.334 10.9457 10.9462 13.3335 8.00065 13.3335C5.05513 13.3335 2.66732 10.9457 2.66732 8.00016C2.66732 5.05464 5.05513 2.66683 8.00065 2.66683C10.9462 2.66683 13.334 5.05464 13.334 8.00016ZM14.6673 8.00016C14.6673 11.6821 11.6825 14.6668 8.00065 14.6668C4.31875 14.6668 1.33398 11.6821 1.33398 8.00016C1.33398 4.31826 4.31875 1.3335 8.00065 1.3335C11.6825 1.3335 14.6673 4.31826 14.6673 8.00016ZM9.26722 8.69345H10.6859C11.1405 8.69345 11.3838 8.15627 11.0854 7.81192L7.60978 3.86344C7.28839 3.49614 6.68234 3.7257 6.68234 4.21238V7.51808H5.26364C4.80452 7.51808 4.56118 8.05526 4.8642 8.39961L8.33979 12.3481C8.66118 12.7154 9.26722 12.4858 9.26722 11.9992V8.69345Z"
      fill="#95A1B6"
    />
  </svg>
);
