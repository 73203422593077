import { useEffect } from 'react';
import { useMixpanel } from './useMixpanel';

export const useTrackPageView = (deps?: any[]): void => {
  const { trackEvent } = useMixpanel();

  const depsStr = deps ? JSON.stringify(deps) : '';

  useEffect(() => {
    trackEvent('pageView');
  }, [trackEvent, depsStr]);
};
