import { Dispatch } from 'redux';
import { GetAppSumoAction } from './appSumo.types';
import axios from '../../axios';

export const GET_APP_SUMO = 'GET_APP_SUMO';

export const getAppSumo = (orgId: string) => async (
  dispatch: Dispatch<GetAppSumoAction>
): Promise<void> => {
  try {
    const getAppSumoResponse = await axios.get(
      `services/orgs/${orgId}?planInfo=true`
    );

    dispatch({
      type: GET_APP_SUMO,
      payload: getAppSumoResponse.data.payload.customerTierInfo,
    });
  } catch (e) {
    // err
  }
};
