import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toggleAccountTeamsSection } from '../../store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const TeamsForRedirect: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const isAccountTeamTabActive = useAppSelector(
    (state) => state.auth.isAccountTeamTabActive
  );

  const history = useHistory();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user) {
      if (!isAccountTeamTabActive) {
        dispatch(toggleAccountTeamsSection(true));
      }

      history.push(`/orgs/${user.orgId}/users/${user.userId}`);
    }
  }, [user, history, isAccountTeamTabActive, dispatch]);

  return null;
};
