import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LogoIcon } from './icons/LogoIcon';

export const Maintenance: React.FC = () => {
  const { pathname, search } = useLocation();

  const history = useHistory();

  const attrs = {
    container: {
      className: 'maintenance',
    },
    content: {
      className: 'maintenance__content',
    },
    logo: {
      className: 'maintenance__logo',
    },
    image: {
      src: '/assets/maintenance.png',
      className: 'maintenance__image',
    },
    textWrapper: {
      className: 'maintenance__text-wrapper',
    },
    title: {
      className: 'maintenance__title',
    },
    description: {
      className: 'maintenance__description',
    },
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const authCode = params.get('authCode');

    if (authCode) {
      history.replace(pathname);
    }
  }, [history, pathname, search]);

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>
        <LogoIcon {...attrs.logo} />
        <img {...attrs.image} alt="Maintenance" />
        <div {...attrs.textWrapper}>
          <div {...attrs.title}>Maintenance Time</div>
          <div {...attrs.description}>
            We&apos;re currently performing scheduled maintenance to improve our
            services. We apologize for any inconvenience and expect to be back
            online shortly.
          </div>
        </div>
      </div>
    </div>
  );
};
