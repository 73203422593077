import React from 'react';

type ActiveStatusProps = {
  status: 'active' | 'inactive' | 'sent' | 'expired';
  className?: string;
  centered?: boolean;
};

export const ActiveStatus: React.FC<ActiveStatusProps> = ({
  status,
  className = '',
  centered = false,
}: ActiveStatusProps): JSX.Element => {
  const defineStatusToShow = () => {
    let statusToShow: string = status;

    if (status === 'sent') {
      statusToShow = 'Invite Sent';
    } else if (status === 'expired') {
      statusToShow = 'Invite Expired';
    }

    return statusToShow;
  };

  const currentStatus = defineStatusToShow();

  return (
    <div
      className={`active-status active-status__${status} ${className} ${
        centered ? 'centered' : ''
      }`}
    >
      {currentStatus}
    </div>
  );
};
