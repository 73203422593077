import React from 'react';

export const LoaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM2.5223 8C2.5223 11.3014 5.19861 13.9777 8.5 13.9777C11.8014 13.9777 14.4777 11.3014 14.4777 8C14.4777 4.69861 11.8014 2.0223 8.5 2.0223C5.19861 2.0223 2.5223 4.69861 2.5223 8Z"
      fill="white"
    />
    <path
      d="M15.4896 8C16.0476 8 16.5066 7.54581 16.4363 6.99221C16.2141 5.24214 15.4176 3.6039 14.1569 2.34315C12.6566 0.842855 10.6217 1.60186e-07 8.5 0C6.37827 -1.60186e-07 4.34344 0.842854 2.84315 2.34315C1.58239 3.6039 0.785897 5.24214 0.563701 6.99221C0.493413 7.54581 0.952387 8 1.51043 8C2.06848 8 2.51216 7.54442 2.60602 6.99433C2.81293 5.78174 3.39072 4.65351 4.27212 3.77211C5.39342 2.65081 6.91424 2.02087 8.5 2.02087C10.0858 2.02087 11.6066 2.65081 12.7279 3.77212C13.6093 4.65351 14.1871 5.78174 14.394 6.99433C14.4878 7.54443 14.9315 8 15.4896 8Z"
      fill="#646F87"
    />
  </svg>
);
