import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useFirebase } from '../../../../context';
import { useClickOutside } from '../../../../hooks';
import { toggleOnboardingModal } from '../../../../store/auth/auth.slice';
import { sendSupportRequest } from '../../../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  Avatar,
  AvatarSize,
} from '../../../SoundWave/components/Avatar/Avatar';

export const HeaderAccount: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const history = useHistory();

  const { signOut } = useFirebase();

  const [isOpened, setIsOpened] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const { shutdown } = useIntercom();

  const attrs = {
    container: {
      ref: containerRef,
      className: `header-account${isOpened ? ' header-account--opened' : ''}`,
    },
    avatarWrapper: {
      type: 'button' as const,
      className: 'header-account__avatar-wrapper',
      onClick: () => {
        setIsOpened(!isOpened);
      },
    },
    avatar: {
      src: user?.avatar,
      name: user?.name,
      size: AvatarSize.L,
      hasBorder: false,
      className: 'header-account__avatar',
    },
    popup: {
      className: 'header-account__popup',
    },
    popupHeader: {
      className: 'header-account__popup-header',
    },
    popupHeaderAvatar: {
      src: user?.avatar,
      name: user?.name,
      size: AvatarSize.L,
      hasBorder: false,
    },
    name: {
      className: 'header-account__popup-header-name',
    },
    title: {
      className: 'header-account__popup-header-title',
    },
    optionsGroup: {
      className: 'header-account__options-group',
    },
    optionsGroupBordered: {
      className:
        'header-account__options-group header-account__options-group--bordered',
    },
  };

  const optionsGroups = [
    {
      bordered: false,
      options: [
        {
          children: 'My Account',
          onClick: () => {
            history.push(`/orgs/${user?.orgId}/users/${user?.userId}`);
            setIsOpened(false);
          },
        },
        ...(user?.role !== 'dealmaker'
          ? [
              {
                children: 'Admin Settings',
                onClick: () => {
                  history.push(`/orgs/${user?.orgId}/admin-settings`);
                  setIsOpened(false);
                },
              },
            ]
          : []),
      ],
    },
    {
      bordered: false,
      options: [
        {
          children: 'Help Center',
          onClick: () => {
            if (user) {
              dispatch(sendSupportRequest());
              window.open('https://help.substrata.me/en/', '_blank');
              setIsOpened(false);
            }
          },
        },
        {
          children: 'Tutorials',
          onClick: () => {
            window.open(
              'https://help.substrata.me/en/collections/3602298-tutorials',
              '_blank'
            );
            setIsOpened(false);
          },
        },
        {
          children: 'Get Started',
          onClick: () => {
            dispatch(toggleOnboardingModal(true));
            setIsOpened(false);
          },
        },
      ],
    },
    {
      bordered: true,
      options: [
        {
          children: 'Request Features',
          onClick: () => {
            window.open('https://feedback.substrata.me', '_blank');
            setIsOpened(false);
          },
        },
        {
          children: 'Contact Us',
          onClick: () => {
            window.open('https://www.substrata.me/contact/', '_blank');
            setIsOpened(false);
          },
        },
      ],
    },
    {
      bordered: false,
      options: [
        {
          children: 'Service Status',
          onClick: () => {
            window.open('https://substrata.statuspage.io/', '_blank');
            setIsOpened(false);
          },
        },
        {
          children: 'Sign Out',
          onClick: () => {
            shutdown();
            setIsOpened(false);
            signOut();
          },
        },
      ],
    },
  ];

  const renderOptions = (
    options: Array<{ children: React.ReactNode; onClick: () => void }>
  ) =>
    options.map((option, index) => {
      const props = {
        ...option,
        key: index,
        className: 'header-account__option',
        type: 'button' as const,
      };

      return <button {...props} />;
    });

  const renderOptionsGroups = () =>
    optionsGroups.map((group, index) => {
      const props = {
        key: index,
        className: group.bordered
          ? attrs.optionsGroupBordered.className
          : attrs.optionsGroup.className,
      };

      return <div {...props}>{renderOptions(group.options)}</div>;
    });

  useClickOutside(containerRef, () => {
    setIsOpened(false);
  });

  return (
    <div {...attrs.container}>
      <button {...attrs.avatarWrapper}>
        <Avatar {...attrs.avatar} />
      </button>
      <div {...attrs.popup}>
        <div {...attrs.popupHeader}>
          <Avatar {...attrs.popupHeaderAvatar} />
          <div {...attrs.name}>{user?.name}</div>
          <div {...attrs.title}>{user?.title}</div>
        </div>
        {renderOptionsGroups()}
      </div>
    </div>
  );
};
