import React from 'react';

export const SvgIcons: React.FC = (): JSX.Element => {
  return (
    <svg className="d-none" height="0" width="0">
      <symbol
        id="thumb-down-icon"
        viewBox="0 0 12 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0933 3.62015C10.7153 1.60422 8.69273 0.343888 6.71649 0.892842L1.76642 2.26786C1.03132 2.47206 0.476421 3.07669 0.335917 3.82657L0.111941 5.02196C-0.0528275 5.90135 0.572662 6.6696 1.26925 6.95846C1.52415 7.06416 1.81563 7.21142 2.12256 7.41604C3.80151 8.53534 4.60295 10.0526 5.13791 11.3416C5.23414 11.5734 5.32642 11.8109 5.4155 12.0402C5.5617 12.4165 5.6993 12.7706 5.83163 13.0413C5.94272 13.2686 6.07621 13.4967 6.25086 13.6719C6.43709 13.8588 6.68612 13.9998 6.99952 13.9998C7.35671 13.9998 7.66515 13.8766 7.91095 13.6605C8.14667 13.4533 8.30449 13.1793 8.41301 12.8992C8.62753 12.3456 8.69458 11.6483 8.70261 11.0061C8.71078 10.3527 8.65783 9.7053 8.60381 9.22517C8.59484 9.14546 8.58582 9.0701 8.57699 8.99982H9.08967C10.655 8.99982 11.8353 7.57763 11.5469 6.0391L11.0933 3.62015Z"
          fill="currentColor"
        />
      </symbol>

      <symbol
        id="thumb-up-icon"
        viewBox="0 0 12 14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5806 0.0519184C6.83006 -0.15668 6.24411 0.402802 6.03494 0.923642C5.79411 1.52335 5.58243 1.94427 5.32941 2.44739C5.17287 2.75865 5.00052 3.10138 4.79565 3.53736C4.32141 4.54658 3.84755 5.19379 3.5035 5.58186C3.33128 5.77611 3.19093 5.90617 3.09835 5.98467C3.05204 6.02394 3.01761 6.05037 2.99704 6.06552L2.9809 6.07716L1.109 7.18151C0.272443 7.67505 -0.0885815 8.69829 0.253045 9.60752L0.773038 10.9915C0.989444 11.5674 1.45891 12.0117 2.04591 12.1961L7.40179 13.8784C8.73654 14.2977 10.1555 13.54 10.5497 12.1977L11.9139 7.55159C12.29 6.27082 11.3298 4.98812 9.99492 4.98812H8.60995C8.67586 4.76149 8.74339 4.50938 8.80466 4.24783C8.93612 3.68673 9.04781 3.04516 9.03753 2.5104C9.02797 2.01325 8.97781 1.49158 8.77353 1.04839C8.55437 0.572904 8.1709 0.215981 7.5806 0.0519184ZM2.9768 6.08001L2.97492 6.08129L2.9768 6.08001Z"
          fill="currentColor"
        />
      </symbol>

      <symbol
        id="close-icon"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.397052 0.553788L0.46967 0.46967C0.735936 0.203403 1.1526 0.179197 1.44621 0.397052L1.53033 0.46967L8 6.939L14.4697 0.46967C14.7626 0.176777 15.2374 0.176777 15.5303 0.46967C15.8232 0.762563 15.8232 1.23744 15.5303 1.53033L9.061 8L15.5303 14.4697C15.7966 14.7359 15.8208 15.1526 15.6029 15.4462L15.5303 15.5303C15.2641 15.7966 14.8474 15.8208 14.5538 15.6029L14.4697 15.5303L8 9.061L1.53033 15.5303C1.23744 15.8232 0.762563 15.8232 0.46967 15.5303C0.176777 15.2374 0.176777 14.7626 0.46967 14.4697L6.939 8L0.46967 1.53033C0.203403 1.26406 0.179197 0.8474 0.397052 0.553788L0.46967 0.46967L0.397052 0.553788Z"
          fill="currentColor"
        />
      </symbol>
    </svg>
  );
};
