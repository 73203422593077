import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { RootState } from '../store';
import {
  DetailedAnalysis,
  DetailedAnalysisEmail,
  ReplyEmailInfo,
  SubmitFeedbackRequest,
  TableRowAccount,
  UserFeedbackType,
} from './deals.types';
import { convertEngagementsForList, mockDataForTable } from './deals.utils';
import demoDataDetailedView from './mock-data-detailed-view.json';
import { UpperHandFeedbackEnum } from '../../enums/upper-hand-feedback.enum';

export const getAccountInfo = createAsyncThunk(
  'deals/getAccountInfo',
  async (
    accountId: string,
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get(
        `services/orgs/${orgId}/users/${userId}/accounts/${accountId}`
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const getDeals = createAsyncThunk(
  'deals/getDeals',
  async (
    {
      sortBy,
      sortOrder,
      page = 1,
    }: {
      sortBy?: 'accountName' | 'lastInteractionDate';
      sortOrder?: 1 | -1;
      page?: number;
    },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
        deals: { requestedEngsQty },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const requestConfig = {
        params: {
          page,
          includeInternal: true,
          ipp: requestedEngsQty,
          sortBy: sortBy || 'lastInteractionDate',
          sortOrder: sortOrder || -1,
        },
      };

      const response = await axios.get(
        `services/orgs/${orgId}/users/${userId}/engagements`,
        requestConfig
      );

      const accountIds: string[] = Array.from(
        new Set(
          response.data?.payload?.results?.map(
            (element: any) => element?.account?.accountId
          )
        )
      );

      const engagements = convertEngagementsForList(
        response.data?.payload?.results?.length
          ? response.data.payload
          : mockDataForTable
      );

      const pageNumber = response.data?.payload?.results?.length ? page : 1;

      if (accountIds.length) {
        accountIds.forEach((accountId) => {
          dispatch(getAccountInfo(accountId));
        });
      }

      return fulfillWithValue({ engagements, pageNumber });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const removeThreads = createAsyncThunk(
  'deals/removeThreads',
  async (
    {
      threadIds,
      sortBy,
      sortOrder,
    }: {
      threadIds: string[];
      sortBy?: 'accountName' | 'lastInteractionDate';
      sortOrder?: 1 | -1;
    },
    { dispatch, getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
        deals: { engagements },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const { page = 0 } = engagements || {};
      const requestBody = {
        op: 'delete',
        engagementIds: threadIds,
      };

      const response = await axios.post(
        `services/orgs/${orgId}/users/${userId}/engagements`,
        requestBody
      );

      if (response.status === 200) {
        await dispatch(getDeals({ sortBy, sortOrder, page }));
      }

      return fulfillWithValue(null);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const renameCurrentDealAccount = createAsyncThunk(
  'deals/renameCurrentDealAccount',
  async (name: string, { getState, fulfillWithValue, rejectWithValue }) => {
    try {
      const {
        auth: { user },
        deals: { currentDeal },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const { accountId = '' } = currentDeal?.account || {};
      const requestBody = {
        name,
      };

      const response = await axios.patch(
        `services/orgs/${orgId}/users/${userId}/accounts/${accountId}`,
        requestBody
      );

      return fulfillWithValue(response.data.payload as TableRowAccount);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
        deals: { currentDeal },
      } = getState() as RootState;

      return !!(user && currentDeal);
    },
  }
);

export const getDetailedAnalysis = createAsyncThunk<
  DetailedAnalysis,
  {
    engagementId: string;
    branchId: string;
    isMock?: boolean;
  }
>(
  'deals/getDetailedAnalysis',
  async (
    { engagementId, branchId, isMock },
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      if (isMock) {
        return fulfillWithValue(demoDataDetailedView.payload);
      }

      const response = await axios.get(
        `services/orgs/${orgId}/users/${userId}/engagements/${engagementId}/threads/${branchId}`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const sendViewEmail = createAsyncThunk<
  null,
  { engagementId: string; emailId: string }
>(
  'deals/sendViewEmail',
  async (
    { engagementId, emailId },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const requestBody = {
        platformMessageIds: [emailId],
      };

      await axios.post(
        `services/orgs/${orgId}/users/${userId}/threads/${engagementId}/viewed-emails`,
        requestBody
      );

      return fulfillWithValue(null);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const updateCrmData = createAsyncThunk<
  {
    dealName?: string;
    crmStage?: string;
    dealValue?: string;
  },
  {
    platformThreadId: string;
    dealName?: string;
    crmStage?: string;
    dealValue?: string;
  }
>(
  'deals/updateCrmData',
  async (
    { platformThreadId, dealName, crmStage, dealValue },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const updateThreadProps = {
        ...(dealName && { dealName: dealName.trim() }),
        ...(crmStage && { crmStage: crmStage.trim() }),
        ...(dealValue && { dealValue: dealValue.trim() }),
      };

      const requestBody = {
        operations: [
          {
            updateThreadProps,
            platformThreadIds: [platformThreadId],
          },
        ],
      };

      await axios.patch(
        `services/orgs/${orgId}/users/${userId}/deals`,
        requestBody
      );

      return fulfillWithValue(updateThreadProps);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const updateThreadComplete = createAsyncThunk<
  DetailedAnalysis,
  { platformThreadId: string; flag: boolean }
>(
  'deals/updateThreadComplete',
  async (
    { platformThreadId, flag },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const requestBody = {
        operations: [
          {
            platformThreadIds: [platformThreadId],
            updateThreadProps: {
              isCompleted: flag,
            },
          },
        ],
      };

      await axios.patch(
        `services/orgs/${orgId}/users/${userId}/deals`,
        requestBody
      );

      const response = await axios.post(
        `services/orgs/${orgId}/users/${userId}/deals/${platformThreadId}/detailed-analysis`
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

export const submitFeedback = createAsyncThunk<
  DetailedAnalysisEmail,
  { platformThreadId: string; feedback: UpperHandFeedbackEnum }
>(
  'deals/submitFeedback',
  async (
    { platformThreadId, feedback },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
        deals: { selectedEmail },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const { messageId = '', platformMessageId = '' } = selectedEmail || {};
      const requestBody: SubmitFeedbackRequest = {
        orgId,
        userId,
        platformThreadId,
        platformMessageId,
        emailId: messageId,
        type: UserFeedbackType.UPPER_HAND,
        feedback: feedback === UpperHandFeedbackEnum.CORRECT,
      };

      await axios.post(
        `services/orgs/${orgId}/users/${userId}/feedback`,
        requestBody
      );

      return fulfillWithValue({
        ...selectedEmail,
        upperHandFeedback: feedback,
      } as DetailedAnalysisEmail);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
        deals: { selectedEmail },
      } = getState() as RootState;

      return !!user && !!selectedEmail;
    },
  }
);

export const sendReplyEmail = createAsyncThunk<
  null,
  { platformThreadId: string; body: ReplyEmailInfo }
>(
  'deals/sendReplyEmail',
  async (
    { platformThreadId, body },
    { getState, fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      await axios.post(
        `api/v1/relationship-svc/orgs/${orgId}/users/${userId}/threads/${platformThreadId}/reply`,
        // `http://localhost:8009/orgs/${orgId}/users/${userId}/threads/${platformThreadId}/reply`,
        body
      );

      return fulfillWithValue(null);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);

// TODO: Move to meetings slice
export const getMyMeetings = createAsyncThunk(
  'deals/getMyMeetings',
  async (_, { getState, fulfillWithValue, rejectWithValue }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      await axios.get(
        `api/v1/relationship-svc/orgs/${orgId}/users/${userId}/calendar/events`
      );

      return fulfillWithValue(null);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;

      return !!user;
    },
  }
);
