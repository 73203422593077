import { Slider } from 'antd';
import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button } from '..';
import { useImageCropper, Area } from '../hooks/useImageCropper';

type ImageCropperProps = {
  image: string;
  onCancel: () => void;
  onSave: (image: string) => void;
  isFromTeams?: boolean;
};

export const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  onCancel,
  onSave,
  isFromTeams,
}: ImageCropperProps): JSX.Element => {
  const {
    getCroppedImg,
    croppedAreaPixels,
    crop,
    zoom,
    onCropChange,
    onZoomChange,
    onCropComplete,
  } = useImageCropper();

  const [isLoading, setLoading] = useState(false);
  const [currentZoom, setcurrentZoom] = useState(100);

  const getCroppedImage = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels as Area);
    onSave(croppedImage);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onCancel();
    }, 1500);
  };

  const handleIncreaseZoom = () => {
    if (!isLoading) {
      if (zoom < 2.9) {
        onZoomChange(zoom + 0.1);
      } else if (zoom >= 2.9) {
        onZoomChange(3);
      }
    }
  };
  const handleDecreaseZoom = () => {
    if (!isLoading) {
      if (zoom > 1.1) {
        onZoomChange(zoom - 0.1);
      } else if (zoom <= 1.1) {
        onZoomChange(1);
      }
    }
  };

  useEffect(() => {
    setcurrentZoom((zoom - 1) * 100);
  }, [zoom]);

  return (
    <div className="image-cropper">
      <div className="image-cropper__cropper">
        <Cropper
          aspect={isFromTeams ? 4 / 3 : 1}
          image={image}
          crop={crop}
          zoom={zoom}
          zoomSpeed={0.1}
          onCropChange={onCropChange}
          onZoomChange={!isLoading ? onZoomChange : () => {}}
          cropShape={isFromTeams ? 'rect' : 'round'}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className="image-cropper__slider">
        <Slider
          defaultValue={1}
          value={zoom}
          tooltipVisible={false}
          min={1}
          max={3}
          step={0.005}
          onChange={onZoomChange}
          disabled={isLoading}
        />
        <div className="image-cropper__slider-value">
          {Math.round(currentZoom)}%
        </div>
        <div
          className="image-cropper__slider-plus"
          onClick={handleIncreaseZoom}
        >
          +
        </div>
        <div
          className="image-cropper__slider-minus"
          onClick={handleDecreaseZoom}
        >
          -
        </div>
      </div>
      <div className="image-cropper__btn-block">
        <Button
          className="image-cropper__btn-block__btn"
          variant="common"
          onClick={getCroppedImage}
          loading={isLoading}
        >
          Save
        </Button>
      </div>

      <Button onClick={onCancel} className="image-cropper__btn" variant="text">
        Cancel
      </Button>
    </div>
  );
};
