import React from 'react';

export const RemoveIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67893 3.74394C4.8081 2.39162 5.94724 1.33398 7.3334 1.33398H8.66674C10.0529 1.33398 11.192 2.39162 11.3212 3.74394L13.4161 4.0058C13.7814 4.05147 14.0406 4.38466 13.9949 4.75001C13.9493 5.11535 13.6161 5.37451 13.2507 5.32884L9.24042 4.82755C8.41672 4.72459 7.58342 4.72459 6.75972 4.82755L2.74943 5.32884C2.38408 5.37451 2.05089 5.11535 2.00522 4.75001C1.95955 4.38466 2.2187 4.05147 2.58405 4.0058L4.67893 3.74394ZM6.07118 3.56991C6.2503 3.0449 6.74776 2.66732 7.3334 2.66732H8.66674C9.25239 2.66732 9.74985 3.0449 9.92896 3.56991L9.4058 3.50451C8.47228 3.38782 7.52786 3.38782 6.59434 3.50451L6.07118 3.56991Z"
      fill="#070B12"
    />
    <path
      d="M3.27305 6.00339C3.63972 5.97005 3.964 6.24028 3.99733 6.60696L4.49864 12.1214C4.56108 12.8081 5.1369 13.334 5.8265 13.334H10.1736C10.8632 13.334 11.4391 12.8081 11.5015 12.1214L12.0028 6.60696C12.0361 6.24028 12.3604 5.97005 12.7271 6.00339C13.0938 6.03672 13.364 6.361 13.3307 6.72768L12.8294 12.2421C12.7045 13.6156 11.5528 14.6673 10.1736 14.6673H5.8265C4.44729 14.6673 3.29565 13.6156 3.17078 12.2421L2.66947 6.72768C2.63614 6.361 2.90637 6.03672 3.27305 6.00339Z"
      fill="#070B12"
    />
    <path
      d="M6.00007 11.334C6.00007 10.9658 6.29855 10.6673 6.66674 10.6673H9.3334C9.70159 10.6673 10.0001 10.9658 10.0001 11.334C10.0001 11.7022 9.70159 12.0007 9.3334 12.0007H6.66674C6.29855 12.0007 6.00007 11.7022 6.00007 11.334Z"
      fill="#070B12"
    />
    <path
      d="M6.00007 8.00065C5.63188 8.00065 5.3334 8.29913 5.3334 8.66732C5.3334 9.03551 5.63188 9.33398 6.00007 9.33398H10.0001C10.3683 9.33398 10.6667 9.03551 10.6667 8.66732C10.6667 8.29913 10.3683 8.00065 10.0001 8.00065H6.00007Z"
      fill="#070B12"
    />
  </svg>
);
