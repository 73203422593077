import React from 'react';

export const RemoveIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67893 3.74321C4.8081 2.39089 5.94724 1.33325 7.3334 1.33325H8.66674C10.0529 1.33325 11.192 2.39089 11.3212 3.74321L13.4161 4.00507C13.7814 4.05074 14.0406 4.38393 13.9949 4.74928C13.9493 5.11462 13.6161 5.37377 13.2507 5.3281L9.24042 4.82682C8.41672 4.72386 7.58342 4.72386 6.75972 4.82682L2.74943 5.3281C2.38408 5.37377 2.05089 5.11462 2.00522 4.74928C1.95955 4.38393 2.2187 4.05074 2.58405 4.00507L4.67893 3.74321ZM6.07118 3.56918C6.2503 3.04417 6.74776 2.66659 7.3334 2.66659H8.66674C9.25239 2.66659 9.74985 3.04417 9.92896 3.56918L9.4058 3.50378C8.47228 3.38709 7.52786 3.38709 6.59434 3.50378L6.07118 3.56918Z"
      fill="#070B12"
    />
    <path
      d="M3.27305 6.00266C3.63972 5.96932 3.964 6.23955 3.99733 6.60623L4.49864 12.1206C4.56108 12.8074 5.1369 13.3333 5.8265 13.3333H10.1736C10.8632 13.3333 11.4391 12.8074 11.5015 12.1206L12.0028 6.60623C12.0361 6.23955 12.3604 5.96932 12.7271 6.00266C13.0938 6.03599 13.364 6.36026 13.3307 6.72694L12.8294 12.2413C12.7045 13.6149 11.5528 14.6666 10.1736 14.6666H5.8265C4.44729 14.6666 3.29565 13.6149 3.17078 12.2413L2.66947 6.72694C2.63614 6.36026 2.90637 6.03599 3.27305 6.00266Z"
      fill="#070B12"
    />
    <path
      d="M6.00007 11.3333C6.00007 10.9651 6.29855 10.6666 6.66674 10.6666H9.3334C9.70159 10.6666 10.0001 10.9651 10.0001 11.3333C10.0001 11.7014 9.70159 11.9999 9.3334 11.9999H6.66674C6.29855 11.9999 6.00007 11.7014 6.00007 11.3333Z"
      fill="#070B12"
    />
    <path
      d="M6.00007 7.99992C5.63188 7.99992 5.3334 8.2984 5.3334 8.66659C5.3334 9.03478 5.63188 9.33325 6.00007 9.33325H10.0001C10.3683 9.33325 10.6667 9.03478 10.6667 8.66659C10.6667 8.2984 10.3683 7.99992 10.0001 7.99992H6.00007Z"
      fill="#070B12"
    />
  </svg>
);
