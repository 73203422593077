import { DateTime } from 'luxon';
import { MeetingRecordsFiltersQueryParams } from './meetingRecords.type';
import {
  DateRangeFilterValue,
  Filter,
  FilterType,
  FilterValue,
  MeetingRecordsDateFilterValue,
  RangeFilterValue,
} from '../../types/meetingRecordsFilters.types';

export const isRangeFilterValue = (
  value?: FilterValue
): value is RangeFilterValue =>
  !!value &&
  typeof value === 'object' &&
  'min' in (value as RangeFilterValue) &&
  'max' in (value as RangeFilterValue);

export const isDateRangeFilterValue = (
  value?: FilterValue
): value is DateRangeFilterValue<MeetingRecordsDateFilterValue> =>
  !!value &&
  typeof value === 'object' &&
  'selectValue' in
    (value as DateRangeFilterValue<MeetingRecordsDateFilterValue>);

export const isCheckboxFilterValue = (value?: FilterValue): value is boolean =>
  typeof value === 'boolean';

export const getIsFilterChecked = (
  value: FilterValue,
  defaultValue: FilterValue,
  isActive: boolean
): boolean => {
  if (isDateRangeFilterValue(value) && isDateRangeFilterValue(defaultValue)) {
    return isActive && value.selectValue !== defaultValue.selectValue;
  }

  if (isRangeFilterValue(value) && isRangeFilterValue(defaultValue)) {
    return (
      isActive &&
      (value.min !== defaultValue.min || value.max !== defaultValue.max)
    );
  }

  return isActive && value !== defaultValue;
};

const getMeetingRecordsDateQueryParams = (
  value: DateRangeFilterValue<MeetingRecordsDateFilterValue>
): Pick<
  MeetingRecordsFiltersQueryParams,
  'meetingDateMin' | 'meetingDateMax'
> => {
  switch (value.selectValue) {
    case MeetingRecordsDateFilterValue.TODAY: {
      return {
        meetingDateMax: DateTime.now().toISODate(),
        meetingDateMin: DateTime.now().minus({ days: 1 }).toISODate(),
      };
    }
    case MeetingRecordsDateFilterValue.WEEK: {
      return {
        meetingDateMax: DateTime.now().toISODate(),
        meetingDateMin: DateTime.now().minus({ weeks: 1 }).toISODate(),
      };
    }
    case MeetingRecordsDateFilterValue.MONTH: {
      return {
        meetingDateMax: DateTime.now().toISODate(),
        meetingDateMin: DateTime.now().minus({ months: 1 }).toISODate(),
      };
    }
    case MeetingRecordsDateFilterValue.QUARTER: {
      return {
        meetingDateMax: DateTime.now().toISODate(),
        meetingDateMin: DateTime.now().minus({ quarters: 1 }).toISODate(),
      };
    }
    case MeetingRecordsDateFilterValue.YEAR: {
      return {
        meetingDateMax: DateTime.now().toISODate(),
        meetingDateMin: DateTime.now().minus({ years: 1 }).toISODate(),
      };
    }
    case MeetingRecordsDateFilterValue.CUSTOM: {
      return {
        ...(value.startDate
          ? {
              meetingDateMin:
                DateTime.fromISO(value.startDate).toISODate() || '',
            }
          : {}),
        ...(value.endDate
          ? {
              meetingDateMax: DateTime.fromISO(value.endDate).toISODate() || '',
            }
          : {}),
      };
    }
    default: {
      return {};
    }
  }
};

const getMeetingRecordsAgeQueryParams = (
  value: FilterValue
): Pick<MeetingRecordsFiltersQueryParams, 'meetingType'> => {
  if (
    isRangeFilterValue(value) ||
    isDateRangeFilterValue(value) ||
    isCheckboxFilterValue(value)
  ) {
    return {};
  }

  return {
    ...(value === 'internal' ? { meetingType: 'internal' } : {}),
    ...(value === 'external' ? { meetingType: 'external' } : {}),
  };
};

const getCheckboxFiltersQueryParams = (filter: Filter<FilterValue>) => {
  if (
    filter.type !== FilterType.CHECKBOX ||
    !isCheckboxFilterValue(filter.value) ||
    !filter.value ||
    !filter.name
  ) {
    return {};
  }

  return { [filter.name]: filter.value };
};

export const getRelationshipsFiltersQueryParams = (
  appliedFilters: Filter<FilterValue>[]
): MeetingRecordsFiltersQueryParams =>
  appliedFilters.reduce<MeetingRecordsFiltersQueryParams>((acc, filter) => {
    if (isDateRangeFilterValue(filter.value)) {
      return { ...acc, ...getMeetingRecordsDateQueryParams(filter.value) };
    }

    return {
      ...acc,
      ...getMeetingRecordsAgeQueryParams(filter.value),
      ...getCheckboxFiltersQueryParams(filter),
    };
  }, {});
