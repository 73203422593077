import { useMemo } from 'react';
import { match as RouterMatch, matchPath, useLocation } from 'react-router-dom';
import { PATHS } from '../constants/paths';

const getMatches = (pathname: string) =>
  Object.values(PATHS).map((path) =>
    matchPath(pathname, { path, exact: true })
  );

export const useCurrentPathMatch = (): RouterMatch | null => {
  const { pathname } = useLocation();

  const matches = useMemo(() => getMatches(pathname), [pathname]);

  const currentPathMatch = useMemo(
    () => matches.find((matchItem) => !!matchItem) || null,
    [matches]
  );

  return currentPathMatch;
};
