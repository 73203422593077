import React from 'react';

export const GoogleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M16.2646 13.4983V18.6252H23.3893C23.0765 20.274 22.1376 21.6701 20.7296 22.6088L25.026 25.9425C27.5293 23.6319 28.9735 20.238 28.9735 16.2063C28.9735 15.2676 28.8893 14.3649 28.7328 13.4984L16.2646 13.4983Z"
      fill="#4285F4"
    />
    <path
      d="M8.84557 18.425L7.87655 19.1668L4.44653 21.8385C6.62486 26.159 11.0895 29.1438 16.2645 29.1438C19.8388 29.1438 22.8354 27.9643 25.0259 25.9425L20.7294 22.6088C19.55 23.4031 18.0456 23.8845 16.2645 23.8845C12.8225 23.8845 9.8981 21.5618 8.85098 18.4327L8.84557 18.425Z"
      fill="#34A853"
    />
    <path
      d="M4.44639 9.97192C3.54382 11.753 3.02637 13.7629 3.02637 15.9051C3.02637 18.0473 3.54382 20.0571 4.44639 21.8382C4.44639 21.8502 8.85126 18.4203 8.85126 18.4203C8.58649 17.626 8.43 16.7836 8.43 15.9049C8.43 15.0263 8.58649 14.1839 8.85126 13.3896L4.44639 9.97192Z"
      fill="#FBBC05"
    />
    <path
      d="M16.2648 7.93806C18.2145 7.93806 19.9475 8.612 21.3315 9.91179L25.1224 6.12083C22.8238 3.97864 19.8392 2.66675 16.2648 2.66675C11.0898 2.66675 6.62486 5.63938 4.44653 9.97198L8.85127 13.3899C9.89825 10.2608 12.8228 7.93806 16.2648 7.93806Z"
      fill="#EA4335"
    />
  </svg>
);
