import React from 'react';

interface Props {
  active: boolean;
}

export const BillingIcon: React.FC<Props> = ({
  active,
}: Props): JSX.Element => {
  return (
    <div className="submenu__body__link-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {active ? (
          <path
            d="M1.33325 5.16634C1.33325 4.15382 2.15406 3.33301 3.16659 3.33301H12.8333C13.8458 3.33301 14.6666 4.15382 14.6666 5.16634V6.33301H1.33325V5.16634ZM1.33325 7.33301H14.6666V10.833C14.6666 11.8455 13.8458 12.6663 12.8333 12.6663H3.16659C2.15406 12.6663 1.33325 11.8455 1.33325 10.833V7.33301ZM10.4999 9.66634C10.2238 9.66634 9.99992 9.8902 9.99992 10.1663C9.99992 10.4425 10.2238 10.6663 10.4999 10.6663H12.1666C12.4427 10.6663 12.6666 10.4425 12.6666 10.1663C12.6666 9.8902 12.4427 9.66634 12.1666 9.66634H10.4999Z"
            fill={active ? 'white' : '#44536A'}
            style={{ transition: 'all 0.2s linear' }}
          />
        ) : (
          <path
            d="M10.4999 9.66668C10.2238 9.66668 9.99992 9.89053 9.99992 10.1667C9.99992 10.4428 10.2238 10.6667 10.4999 10.6667H12.1666C12.4427 10.6667 12.6666 10.4428 12.6666 10.1667C12.6666 9.89053 12.4427 9.66668 12.1666 9.66668H10.4999ZM3.16659 3.33334C2.15406 3.33334 1.33325 4.15416 1.33325 5.16668V10.8333C1.33325 11.8459 2.15406 12.6667 3.16659 12.6667H12.8333C13.8458 12.6667 14.6666 11.8459 14.6666 10.8333V5.16668C14.6666 4.15415 13.8458 3.33334 12.8333 3.33334H3.16659ZM2.33325 10.8333V7.33334H13.6666V10.8333C13.6666 11.2936 13.2935 11.6667 12.8333 11.6667H3.16659C2.70635 11.6667 2.33325 11.2936 2.33325 10.8333ZM2.33325 6.33334V5.16668C2.33325 4.70644 2.70635 4.33334 3.16659 4.33334H12.8333C13.2935 4.33334 13.6666 4.70644 13.6666 5.16668V6.33334H2.33325Z"
            fill={active ? '#646c8391' : '#44536A'}
            style={{ transition: 'all 0.2s linear' }}
          />
        )}
      </svg>
    </div>
  );
};
