import React from 'react';

export const RelationshipsIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    isActive?: boolean;
  }
> = ({ isActive = false, ...rest }) =>
  isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2ZM8.99997 7C8.99997 7.82843 8.3284 8.5 7.49997 8.5C6.67155 8.5 5.99997 7.82843 5.99997 7C5.99997 6.17157 6.67155 5.5 7.49997 5.5C8.3284 5.5 8.99997 6.17157 8.99997 7ZM9.99997 7C9.99997 6.44772 10.4477 6 11 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H11C10.4477 8 9.99997 7.55228 9.99997 7ZM9.99997 12C9.99997 11.4477 10.4477 11 11 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 9.99997 12.5523 9.99997 12ZM11 16C10.4477 16 9.99997 16.4477 9.99997 17C9.99997 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16H11ZM7.49997 18.5C8.3284 18.5 8.99997 17.8284 8.99997 17C8.99997 16.1716 8.3284 15.5 7.49997 15.5C6.67155 15.5 5.99997 16.1716 5.99997 17C5.99997 17.8284 6.67155 18.5 7.49997 18.5ZM8.99994 12C8.99994 12.8284 8.32837 13.5 7.49994 13.5C6.67151 13.5 5.99994 12.8284 5.99994 12C5.99994 11.1716 6.67151 10.5 7.49994 10.5C8.32837 10.5 8.99994 11.1716 8.99994 12Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2ZM6 4C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6ZM10 7C10 6.44772 10.4477 6 11 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8H11C10.4477 8 10 7.55228 10 7ZM10 12C10 11.4477 10.4477 11 11 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H11C10.4477 13 10 12.5523 10 12ZM11 16C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16H11ZM7.5 8.5C8.32843 8.5 9 7.82843 9 7C9 6.17157 8.32843 5.5 7.5 5.5C6.67157 5.5 6 6.17157 6 7C6 7.82843 6.67157 8.5 7.5 8.5ZM9 17C9 17.8284 8.32843 18.5 7.5 18.5C6.67157 18.5 6 17.8284 6 17C6 16.1716 6.67157 15.5 7.5 15.5C8.32843 15.5 9 16.1716 9 17ZM7.5 13.5C8.32843 13.5 9 12.8284 9 12C9 11.1716 8.32843 10.5 7.5 10.5C6.67157 10.5 6 11.1716 6 12C6 12.8284 6.67157 13.5 7.5 13.5Z"
        fill="white"
      />
    </svg>
  );
