import { Reducer } from 'redux';
import {
  CLEAR_CHARTS_ERRORS,
  CLEAR_CHARTS_STATE,
  SET_AVG_EMAIL_LENGTH,
  SET_AVG_EMAIL_LENGTH_ERR,
  SET_AVG_EMAIL_LENGTH_LOADING,
  SET_AVG_EMAIL_LENGTH_NO_DATA,
  SET_AVG_PARTICIPANTS,
  SET_AVG_PARTICIPANTS_ERR,
  SET_AVG_PARTICIPANTS_LOADING,
  SET_AVG_PARTICIPANTS_NO_DATA,
  SET_ESTIMATED_TEND,
  SET_ESTIMATED_TEND_ERR,
  SET_ESTIMATED_TEND_LOADING,
  SET_ESTIMATED_TEND_NO_DATA,
  SET_INCOMING_ACTIVITY,
  SET_INCOMING_ACTIVITY_ERR,
  SET_INCOMING_ACTIVITY_LOADING,
  SET_INCOMING_ACTIVITY_NO_DATA,
  SET_LEAST_COMPETENTE,
  SET_LEAST_COMPETENTE_ERR,
  SET_LEAST_COMPETENTE_LOADING,
  SET_LEAST_COMPETENTE_NO_DATA,
  SET_MEETING_COUNT,
  SET_MEETING_COUNT_ERR,
  SET_MEETING_COUNT_LOADING,
  SET_MEETING_COUNT_NO_DATA,
  SET_MEETING_DURATION,
  SET_MEETING_DURATION_ERR,
  SET_MEETING_DURATION_LOADING,
  SET_MEETING_DURATION_NO_DATA,
  SET_MOST_COMPETENTE,
  SET_MOST_COMPETENTE_ERR,
  SET_MOST_COMPETENTE_LOADING,
  SET_MOST_COMPETENTE_NO_DATA,
  SET_OUTGOING_ACTIVITY,
  SET_OUTGOING_ACTIVITY_ERR,
  SET_OUTGOING_ACTIVITY_LOADING,
  SET_OUTGOING_ACTIVITY_NO_DATA,
  SET_Q_ACTIVITY,
  SET_Q_ACTIVITY_ERR,
  SET_Q_ACTIVITY_LOADING,
  SET_Q_ACTIVITY_NO_DATA,
  SET_RESPONSE_TIME,
  SET_RESPONSE_TIME_ERR,
  SET_RESPONSE_TIME_LOADING,
  SET_RESPONSE_TIME_NO_DATA,
  SET_SELLING_ZONE,
  SET_SELLING_ZONE_ERR,
  SET_SELLING_ZONE_LOADING,
  SET_SELLING_ZONE_NO_DATA,
  SET_SENTIMENT,
  SET_SENTIMENT_ERR,
  SET_SENTIMENT_LOADING,
  SET_SENTIMENT_NO_DATA,
  SET_TALK_TIME_RATIO,
  SET_TALK_TIME_RATIO_ERR,
  SET_TALK_TIME_RATIO_LOADING,
  SET_TALK_TIME_RATIO_NO_DATA,
  SET_TOP_DOMAINS,
  SET_TOP_DOMAINS_ERR,
  SET_TOP_DOMAINS_LOADING,
  SET_TOP_DOMAINS_NO_DATA,
  SET_UPPER_HAND_POSS,
  SET_UPPER_HAND_POSS_ERR,
  SET_UPPER_HAND_POSS_LOADING,
  SET_UPPER_HAND_POSS_NO_DATA,
} from './charts.actions';
import {
  AvgEmailsLength,
  AvgParticipants,
  ChartsActions,
  CompetentWith,
  EstimatedTendencies,
  IncomingEmailActivity,
  MeetingCountData,
  MeetingDurationData,
  OutgoingEmailActivity,
  QActivity,
  ReceivedSentiment,
  ResponseTime,
  SellingZone,
  TalkTimeRatioData,
  TopTenDomains,
  UpperHandPossessions,
} from './charts.types';

interface ChartErr {
  isLoading: boolean;
  error: boolean;
  noData: boolean;
}
export interface ChartsState {
  uhPossessions: UpperHandPossessions | null;
  uhPossessionsStatus: ChartErr;
  estTendencies: EstimatedTendencies | null;
  estTendenciesStatus: ChartErr;
  qActivity: QActivity | null;
  qActivityStatus: ChartErr;
  sentiment: ReceivedSentiment | null;
  sentimentStatus: ChartErr;
  responseTime: ResponseTime | null;
  responseTimeStatus: ChartErr;
  participans: AvgParticipants | null;
  participansStatus: ChartErr;
  emailLength: AvgEmailsLength | null;
  emailLengthStatus: ChartErr;
  topDomains: TopTenDomains | null;
  topDomainsStatus: ChartErr;
  sellingZone: SellingZone | null;
  sellingZoneStatus: ChartErr;
  mostCompetent: CompetentWith[] | null;
  mostCompetentStatus: ChartErr;
  leastCompetent: CompetentWith[] | null;
  leastCompetentStatus: ChartErr;
  incomingEmailActivity: IncomingEmailActivity | null;
  incomingEmailActivityStatus: ChartErr;
  outgoingEmailActivity: OutgoingEmailActivity | null;
  outgoingEmailActivityStatus: ChartErr;
  talkTimeRatio: TalkTimeRatioData | null;
  talkTimeRatioStatus: ChartErr;
  meetingCount: MeetingCountData | null;
  meetingCountStatus: ChartErr;
  meetingDuration: MeetingDurationData | null;
  meetingDurationStatus: ChartErr;
}

export const initialState: ChartsState = {
  uhPossessions: null,
  uhPossessionsStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  estTendencies: null,
  estTendenciesStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  qActivity: null,
  qActivityStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  sentiment: null,
  sentimentStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  responseTime: null,
  responseTimeStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  participans: null,
  participansStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  emailLength: null,
  emailLengthStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  topDomains: null,
  topDomainsStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  sellingZone: null,
  sellingZoneStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  mostCompetent: null,
  mostCompetentStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  leastCompetent: null,
  leastCompetentStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  incomingEmailActivity: null,
  incomingEmailActivityStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  outgoingEmailActivity: null,
  outgoingEmailActivityStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  talkTimeRatio: null,
  talkTimeRatioStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  meetingCount: null,
  meetingCountStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
  meetingDuration: null,
  meetingDurationStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
};

export const chartsReducer: Reducer<ChartsState, ChartsActions> = (
  state: ChartsState = initialState,
  action: ChartsActions
): ChartsState => {
  switch (action.type) {
    case SET_UPPER_HAND_POSS:
      return {
        ...state,
        uhPossessions: action.payload,
      };
    case SET_UPPER_HAND_POSS_LOADING:
      return {
        ...state,
        uhPossessionsStatus: {
          ...state.uhPossessionsStatus,
          isLoading: action.payload,
        },
      };
    case SET_UPPER_HAND_POSS_NO_DATA:
      return {
        ...state,
        uhPossessionsStatus: {
          ...state.uhPossessionsStatus,
          noData: true,
        },
      };
    case SET_UPPER_HAND_POSS_ERR:
      return {
        ...state,
        uhPossessionsStatus: {
          ...state.uhPossessionsStatus,
          error: true,
        },
      };

    case SET_ESTIMATED_TEND:
      return {
        ...state,
        estTendencies: action.payload,
      };
    case SET_ESTIMATED_TEND_LOADING:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          isLoading: action.payload,
        },
      };
    case SET_ESTIMATED_TEND_NO_DATA:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          noData: true,
        },
      };
    case SET_ESTIMATED_TEND_ERR:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          error: true,
        },
      };

    case SET_Q_ACTIVITY:
      return {
        ...state,
        qActivity: action.payload,
      };
    case SET_Q_ACTIVITY_LOADING:
      return {
        ...state,
        qActivityStatus: {
          ...state.qActivityStatus,
          isLoading: action.payload,
        },
      };
    case SET_Q_ACTIVITY_NO_DATA:
      return {
        ...state,
        qActivityStatus: {
          ...state.qActivityStatus,
          noData: true,
        },
      };
    case SET_Q_ACTIVITY_ERR:
      return {
        ...state,
        qActivityStatus: {
          ...state.qActivityStatus,
          error: true,
        },
      };

    case SET_SENTIMENT:
      return {
        ...state,
        sentiment: action.payload,
      };
    case SET_SENTIMENT_LOADING:
      return {
        ...state,
        sentimentStatus: {
          ...state.sentimentStatus,
          isLoading: action.payload,
        },
      };
    case SET_SENTIMENT_NO_DATA:
      return {
        ...state,
        sentimentStatus: {
          ...state.sentimentStatus,
          noData: true,
        },
      };
    case SET_SENTIMENT_ERR:
      return {
        ...state,
        sentimentStatus: {
          ...state.sentimentStatus,
          error: true,
        },
      };

    case SET_RESPONSE_TIME:
      return {
        ...state,
        responseTime: action.payload,
      };
    case SET_RESPONSE_TIME_LOADING:
      return {
        ...state,
        responseTimeStatus: {
          ...state.responseTimeStatus,
          isLoading: action.payload,
        },
      };
    case SET_RESPONSE_TIME_NO_DATA:
      return {
        ...state,
        responseTimeStatus: {
          ...state.responseTimeStatus,
          noData: true,
        },
      };
    case SET_RESPONSE_TIME_ERR:
      return {
        ...state,
        responseTimeStatus: {
          ...state.responseTimeStatus,
          error: true,
        },
      };

    case SET_AVG_PARTICIPANTS:
      return {
        ...state,
        participans: action.payload,
      };
    case SET_AVG_PARTICIPANTS_LOADING:
      return {
        ...state,
        participansStatus: {
          ...state.participansStatus,
          isLoading: action.payload,
        },
      };
    case SET_AVG_PARTICIPANTS_NO_DATA:
      return {
        ...state,
        participansStatus: {
          ...state.participansStatus,
          noData: true,
        },
      };
    case SET_AVG_PARTICIPANTS_ERR:
      return {
        ...state,
        participansStatus: {
          ...state.participansStatus,
          error: true,
        },
      };

    case SET_AVG_EMAIL_LENGTH:
      return {
        ...state,
        emailLength: action.payload,
      };
    case SET_AVG_EMAIL_LENGTH_LOADING:
      return {
        ...state,
        emailLengthStatus: {
          ...state.emailLengthStatus,
          isLoading: action.payload,
        },
      };
    case SET_AVG_EMAIL_LENGTH_NO_DATA:
      return {
        ...state,
        emailLengthStatus: {
          ...state.emailLengthStatus,
          noData: true,
        },
      };
    case SET_AVG_EMAIL_LENGTH_ERR:
      return {
        ...state,
        emailLengthStatus: {
          ...state.emailLengthStatus,
          error: true,
        },
      };

    case SET_TOP_DOMAINS:
      return {
        ...state,
        topDomains: action.payload,
      };
    case SET_TOP_DOMAINS_LOADING:
      return {
        ...state,
        topDomainsStatus: {
          ...state.topDomainsStatus,
          isLoading: action.payload,
        },
      };
    case SET_TOP_DOMAINS_NO_DATA:
      return {
        ...state,
        topDomainsStatus: {
          ...state.topDomainsStatus,
          noData: true,
        },
      };
    case SET_TOP_DOMAINS_ERR:
      return {
        ...state,
        topDomainsStatus: {
          ...state.topDomainsStatus,
          error: true,
        },
      };

    case SET_SELLING_ZONE:
      return {
        ...state,
        sellingZone: action.payload,
      };
    case SET_SELLING_ZONE_LOADING:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          isLoading: action.payload,
        },
      };
    case SET_SELLING_ZONE_NO_DATA:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          noData: true,
        },
      };
    case SET_SELLING_ZONE_ERR:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          error: true,
        },
      };

    case SET_MOST_COMPETENTE:
      return {
        ...state,
        mostCompetent: action.payload,
      };
    case SET_MOST_COMPETENTE_LOADING:
      return {
        ...state,
        mostCompetentStatus: {
          ...state.mostCompetentStatus,
          isLoading: action.payload,
        },
      };
    case SET_MOST_COMPETENTE_NO_DATA:
      return {
        ...state,
        mostCompetentStatus: {
          ...state.mostCompetentStatus,
          noData: true,
        },
      };
    case SET_MOST_COMPETENTE_ERR:
      return {
        ...state,
        mostCompetentStatus: {
          ...state.mostCompetentStatus,
          error: true,
        },
      };

    case SET_LEAST_COMPETENTE:
      return {
        ...state,
        leastCompetent: action.payload,
      };
    case SET_LEAST_COMPETENTE_LOADING:
      return {
        ...state,
        leastCompetentStatus: {
          ...state.leastCompetentStatus,
          isLoading: action.payload,
        },
      };
    case SET_LEAST_COMPETENTE_NO_DATA:
      return {
        ...state,
        leastCompetentStatus: {
          ...state.leastCompetentStatus,
          noData: true,
        },
      };
    case SET_LEAST_COMPETENTE_ERR:
      return {
        ...state,
        leastCompetentStatus: {
          ...state.leastCompetentStatus,
          error: true,
        },
      };

    case SET_INCOMING_ACTIVITY:
      return {
        ...state,
        incomingEmailActivity: action.payload,
      };
    case SET_INCOMING_ACTIVITY_LOADING:
      return {
        ...state,
        incomingEmailActivityStatus: {
          ...state.incomingEmailActivityStatus,
          isLoading: action.payload,
        },
      };
    case SET_INCOMING_ACTIVITY_NO_DATA:
      return {
        ...state,
        incomingEmailActivityStatus: {
          ...state.incomingEmailActivityStatus,
          noData: true,
        },
      };
    case SET_INCOMING_ACTIVITY_ERR:
      return {
        ...state,
        incomingEmailActivityStatus: {
          ...state.incomingEmailActivityStatus,
          error: true,
        },
      };

    case SET_OUTGOING_ACTIVITY:
      return {
        ...state,
        outgoingEmailActivity: action.payload,
      };
    case SET_OUTGOING_ACTIVITY_LOADING:
      return {
        ...state,
        outgoingEmailActivityStatus: {
          ...state.outgoingEmailActivityStatus,
          isLoading: action.payload,
        },
      };
    case SET_OUTGOING_ACTIVITY_NO_DATA:
      return {
        ...state,
        outgoingEmailActivityStatus: {
          ...state.outgoingEmailActivityStatus,
          noData: true,
        },
      };
    case SET_OUTGOING_ACTIVITY_ERR:
      return {
        ...state,
        outgoingEmailActivityStatus: {
          ...state.outgoingEmailActivityStatus,
          error: true,
        },
      };

    case SET_TALK_TIME_RATIO:
      return {
        ...state,
        talkTimeRatio: action.payload,
      };
    case SET_TALK_TIME_RATIO_LOADING:
      return {
        ...state,
        talkTimeRatioStatus: {
          ...state.talkTimeRatioStatus,
          isLoading: action.payload,
        },
      };
    case SET_TALK_TIME_RATIO_NO_DATA:
      return {
        ...state,
        talkTimeRatioStatus: {
          ...state.talkTimeRatioStatus,
          noData: true,
        },
      };
    case SET_TALK_TIME_RATIO_ERR:
      return {
        ...state,
        talkTimeRatioStatus: {
          ...state.talkTimeRatioStatus,
          error: true,
        },
      };

    case SET_MEETING_COUNT:
      return {
        ...state,
        meetingCount: action.payload,
      };
    case SET_MEETING_COUNT_LOADING:
      return {
        ...state,
        meetingCountStatus: {
          ...state.meetingCountStatus,
          isLoading: action.payload,
        },
      };
    case SET_MEETING_COUNT_NO_DATA:
      return {
        ...state,
        meetingCountStatus: {
          ...state.meetingCountStatus,
          noData: true,
        },
      };
    case SET_MEETING_COUNT_ERR:
      return {
        ...state,
        meetingCountStatus: {
          ...state.meetingCountStatus,
          error: true,
        },
      };

    case SET_MEETING_DURATION:
      return {
        ...state,
        meetingDuration: action.payload,
      };
    case SET_MEETING_DURATION_LOADING:
      return {
        ...state,
        meetingDurationStatus: {
          ...state.meetingDurationStatus,
          isLoading: action.payload,
        },
      };
    case SET_MEETING_DURATION_NO_DATA:
      return {
        ...state,
        meetingDuration: null,
        meetingDurationStatus: {
          ...state.meetingDurationStatus,
          noData: true,
        },
      };
    case SET_MEETING_DURATION_ERR:
      return {
        ...state,
        meetingDurationStatus: {
          ...state.meetingDurationStatus,
          error: true,
        },
      };

    case CLEAR_CHARTS_ERRORS:
      return {
        ...state,
        uhPossessionsStatus: initialState.uhPossessionsStatus,
        estTendenciesStatus: initialState.estTendenciesStatus,
        qActivityStatus: initialState.qActivityStatus,
        sentimentStatus: initialState.sentimentStatus,
        responseTimeStatus: initialState.responseTimeStatus,
        participansStatus: initialState.participansStatus,
        emailLengthStatus: initialState.emailLengthStatus,
        topDomainsStatus: initialState.topDomainsStatus,
        sellingZoneStatus: initialState.sellingZoneStatus,
        mostCompetentStatus: initialState.mostCompetentStatus,
        leastCompetentStatus: initialState.leastCompetentStatus,
        incomingEmailActivityStatus: initialState.incomingEmailActivityStatus,
        outgoingEmailActivityStatus: initialState.outgoingEmailActivityStatus,
        talkTimeRatioStatus: initialState.talkTimeRatioStatus,
        meetingCountStatus: initialState.meetingCountStatus,
        meetingDurationStatus: initialState.meetingDurationStatus,
      };

    case CLEAR_CHARTS_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
