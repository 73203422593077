import React from 'react';
import { Form, FormField, Modal } from '../SoundWave';
import { useAssignAccountForm } from './hooks/useAssignAccountForm';

export const AssignAccountModal: React.FC = () => {
  const { modalProps, formProps, fieldProps } = useAssignAccountForm();

  const attrs = {
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
  };

  return (
    <Modal {...modalProps}>
      <Form {...formProps}>
        <fieldset {...attrs.fieldset}>
          <FormField {...fieldProps.accountId} />
        </fieldset>
      </Form>
    </Modal>
  );
};
