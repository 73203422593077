import React from 'react';

export const MeetingsIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    isActive?: boolean;
  }
> = ({ isActive = false, ...rest }) =>
  isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        d="M2 8C2 6.34315 3.34315 5 5 5H14C15.6565 5 16.9995 6.34261 17 7.99901C17 7.99868 17 7.99934 17 7.99901V16C17 16.0007 17 16.0015 17 16.0022C16.9988 17.658 15.6561 19 14 19H5C3.34315 19 2 17.6569 2 16V8Z"
        fill="white"
      />
      <path
        d="M18 16.1935L19.6426 17.5003C20.9354 18.5353 22 17.91 22 16.1095V7.89384C22 6.0933 20.9354 5.46797 19.6426 6.49222L18 7.79905L18 16.1935Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C3.79086 5 2 6.79086 2 9V15C2 17.2091 3.79086 19 6 19H13C14.6782 19 16.115 17.9665 16.7087 16.5013L18.0876 17.4154L18.0889 17.4163C18.8832 17.9459 19.8667 18.2199 20.7468 17.7878C21.651 17.3439 22 16.3796 22 15.4245V8.5782C22 7.62233 21.6506 6.65992 20.7487 6.21473C19.8705 5.78127 18.8872 6.05035 18.0916 6.57567L16.7067 7.49383C16.1118 6.03118 14.6763 5 13 5H6ZM17 9.69903V14.2947L19.1952 15.7501L19.1976 15.7517C19.4335 15.9091 19.6135 15.9723 19.7277 15.9922C19.7833 16.0018 19.8197 16.0007 19.8395 15.9984C19.8495 15.9972 19.856 15.9957 19.8596 15.9946C19.861 15.9942 19.8622 15.9938 19.863 15.9935C19.8643 15.993 19.8651 15.9927 19.8654 15.9925L19.8671 15.9915C19.8679 15.9909 19.8701 15.9892 19.8736 15.9855C19.8804 15.9784 19.8959 15.96 19.9144 15.922C19.9529 15.8431 20 15.6866 20 15.4245V8.5782C20 8.31616 19.9529 8.15962 19.9143 8.08037C19.8958 8.04221 19.8801 8.0235 19.873 8.01615C19.8668 8.00969 19.864 8.00838 19.8635 8.00818C19.8628 8.00783 19.8553 8.00407 19.8361 8.00175C19.8158 7.99929 19.7791 7.99809 19.7234 8.00753C19.609 8.02692 19.4295 8.08904 19.1942 8.24426L17 9.69903ZM15 9C15 7.89543 14.1046 7 13 7H6C4.89543 7 4 7.89543 4 9V15C4 16.1046 4.89543 17 6 17H13C14.1046 17 15 16.1046 15 15V9Z"
        fill="white"
      />
    </svg>
  );
