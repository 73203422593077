import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';
import { AxiosError } from 'axios';
import { useId, useMixpanel } from '../../../hooks';
import {
  FormFieldProps,
  FormFieldType,
} from '../../SoundWave/components/FormField/FormField';
import { FormProps } from '../../SoundWave/components/Form/Form';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AddMemberFormValues } from '../../../store/relationshipFlows/relationshipFlows.types';
import {
  createContact,
  getExistingContactSuggestions,
  linkContactToAccount,
  updateContact,
} from '../../../store/relationshipFlows/relationshipFlows.thunks';
import {
  closeAddMemberModal,
  resetAddMemberFlow,
  updateExistingContact,
} from '../../../store/relationshipFlows/relationshipFlows.slice';
import { ContactItem } from '../components/ContactItem/ContactItem';
import { ConfirmationIcon } from '../../Icons/ConfirmationIcon';
import { ModalProps } from '../../SoundWave/components/Modal/Modal';
import { ConfirmationModalProps } from '../../SoundWave/components/ConfirmationModal/ConfirmationModal';
import { GlobeIcon } from '../../Icons/GlobeIcon';
import { PersonPlaceholderIcon } from '../../Icons/PersonPlaceholderIcon';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../../types/relationshipMember.types';
import { WarningIcon } from '../../Icons/WarningIcon';
import { showBottomNotification } from '../../../store/bottomNotification/bottomNotification.slice';
import { StatusCode } from '../../../types/statusCode.types';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('This field is required'),
  firstName: Yup.string()
    .required('This field is required')
    .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter'),
  lastName: Yup.string()
    .required('This field is required')
    .typeError('This is a required field')
    .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter'),
  title: Yup.string(),
  countryCode: Yup.string(),
  phone: Yup.string()
    .min(6, 'Please enter a valid phone number')
    .max(14, 'Too long phone number'),
  assignedTo: Yup.string().required('This field is required'),
  contactType: Yup.string().required('This field is required'),
  avatar: Yup.string(),
  note: Yup.string(),
});

export const useAddMemberForm = (): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<
    keyof Omit<AddMemberFormValues, 'countryCode'>,
    FormFieldProps
  >;
  cancelModalProps: ConfirmationModalProps;
  confirmationModalProps: ConfirmationModalProps;
  values: AddMemberFormValues;
  resetContactFields: () => void;
} => {
  const dispatch = useAppDispatch();

  const userOrganization = useAppSelector((state) => state.auth.org);

  const accountId = useAppSelector(
    (state) => state.relatioshipFlows.addMember.accountId
  );

  const propectAccountName = useAppSelector(
    (state) => state.relatioshipFlows.addMember.accountName
  );

  const isOpened = useAppSelector(
    (state) => state.relatioshipFlows.addMember.isModalOpened
  );

  const isCreateContactLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.createContact
  );

  const isLinkContactToAccountLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.linkContactToAccount
  );

  const isEditFlow = useAppSelector(
    (state) => state.relatioshipFlows.addMember.isEditFlow
  );

  const isEditingExistingContact = useAppSelector(
    (state) => state.relatioshipFlows.addMember.isEditingExistingContact
  );

  const existingContact = useAppSelector(
    (state) => state.relatioshipFlows.addMember.existingContact
  );

  const initialValues = useAppSelector(
    (state) => state.relatioshipFlows.addMember.initialValues
  );

  const existingContactSuggestions = useAppSelector(
    (state) => state.relatioshipFlows.addMember.existingContactSuggestions
  );

  const { trackEvent } = useMixpanel();

  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(
    false
  );

  const exisitingContactSuggestionsRequest = useRef<
    (Promise<unknown> & { abort: () => void }) | null
  >(null);

  const updateExisitingContactSuggestions = useCallback(
    debounce((email: string) => {
      exisitingContactSuggestionsRequest.current = dispatch(
        getExistingContactSuggestions(email)
      );
    }, 300),
    [dispatch]
  );

  const {
    errors,
    touched,
    values,
    isValid,
    dirty,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setValues,
    resetForm,
  } = useFormik<AddMemberFormValues>({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (formValues, helpers) => {
      try {
        trackEvent(existingContact ? 'updateContact' : 'createContact');

        const { contactId } = existingContact
          ? await dispatch(updateContact(formValues)).unwrap()
          : await dispatch(createContact(formValues)).unwrap();

        await dispatch(
          linkContactToAccount({
            ...formValues,
            contactId,
            accountId: accountId || '',
          })
        ).unwrap();

        dispatch(closeAddMemberModal());

        if (isEditFlow) {
          dispatch(
            showBottomNotification({ text: 'Changes applied successfully' })
          );
        } else {
          setIsConfirmationModalOpened(true);
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.status === StatusCode.Conflict
        ) {
          helpers.setFieldError(
            'email',
            'Contact with this email already exists'
          );
        }
      }
    },
  });

  const { email: emailValue } = values;

  const formId = useId();

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleEmailAutocomplete = useCallback(
    (_, value: string) => {
      const contact = existingContactSuggestions.find(
        ({ email }) => email === value
      );

      if (contact) {
        const [firstName = '', lastName = ''] =
          contact.metadata?.name?.split(' ') || [];
        const phoneNumber = contact?.metadata?.phoneNumbers?.[0];

        setValues({
          ...values,
          email: value,
          firstName,
          lastName,
          title: contact.metadata?.title || '',
          phone: phoneNumber?.number || '',
          countryCode:
            phoneNumber?.countryCode && phoneNumber?.countryCallingCode
              ? `${phoneNumber.countryCode}|${phoneNumber.countryCallingCode}`
              : '',
          avatar: contact.metadata?.avatarSrc || '',
        });

        dispatch(updateExistingContact(contact || null));
      } else {
        setValues({
          ...values,
          email: value,
        });
      }
    },
    [dispatch, existingContactSuggestions, setValues, values]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  const handleEmailChange = useCallback(
    (_, value: string) => {
      exisitingContactSuggestionsRequest.current?.abort();
      handleChange('email', value);
      updateExisitingContactSuggestions(value);
    },
    [handleChange, updateExisitingContactSuggestions]
  );

  const resetContactFields = useCallback(() => {
    if (existingContact) {
      const [firstName = '', lastName = ''] =
        existingContact.metadata?.name?.split(' ') || [];
      const phoneNumber = existingContact?.metadata?.phoneNumbers?.[0];

      setValues({
        ...values,
        firstName,
        lastName,
        title: existingContact.metadata?.title || '',
        phone: phoneNumber?.number || '',
        countryCode:
          phoneNumber?.countryCode && phoneNumber?.countryCallingCode
            ? `${phoneNumber.countryCode}|${phoneNumber.countryCallingCode}`
            : '',
        avatar: existingContact.metadata?.avatarSrc || '',
      });
    }
  }, [existingContact, setValues, values]);

  const emailAutocompleteOptions = useMemo(() => {
    const isEmailValid =
      !!emailValue && Yup.string().email().isValidSync(emailValue);

    const newContactItemProps = {
      text: emailValue,
      secondaryText: 'New Contact',
      showSecondaryText: true,
      isNewContact: true,
    };

    const defaultSuggestions = isEmailValid
      ? [
          {
            value: emailValue,
            label: <ContactItem {...newContactItemProps} />,
          },
        ]
      : [];

    return existingContactSuggestions.length
      ? existingContactSuggestions.map(({ email, metadata, extra }, index) => {
          const props = {
            text: email || '',
            key: index,
            secondaryText: metadata?.name || '',
            imageSrc: metadata?.avatarSrc || '',
            title: metadata?.title || '',
            showSecondaryText: true,
            showDetails: true,
            search: emailValue,
            associatedRelationships: extra?.linkedAccounts || [],
          };

          return {
            value: email || '',
            label: <ContactItem {...props} />,
          };
        })
      : defaultSuggestions;
  }, [emailValue, existingContactSuggestions]);

  const modalProps = useMemo(
    () => ({
      isOpened,
      closeOnClickOutside: true,
      width: 848,
      onClose: () => {
        if (dirty) {
          setIsCancelModalOpened(true);
        } else {
          dispatch(closeAddMemberModal());
        }
      },
      onAnimationEnd: () => {
        dispatch(resetAddMemberFlow());
      },
      headerProps: {
        title: isEditFlow ? 'Edit Member Details' : 'Add Member',
      },
      footerProps: {
        formId,
        isLoading: isCreateContactLoading || isLinkContactToAccountLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: isEditFlow ? 'Apply' : 'Add to Team',
        isConfirmButtonDisabled: !isValid || !dirty,
      },
    }),
    [
      dirty,
      dispatch,
      formId,
      isEditFlow,
      isCreateContactLoading,
      isLinkContactToAccountLoading,
      isOpened,
      isValid,
    ]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      email: {
        type: FormFieldType.EMAIL,
        name: 'email',
        placeholder: 'example@domain.com',
        label: 'Enter email',
        value: values.email,
        required: true,
        error: errors.email,
        touched: touched.email,
        disabled:
          !!existingContact?.email ||
          (!!existingContact && !isEditingExistingContact),
        withAutocomplete: true,
        options: emailAutocompleteOptions,
        tooltip: existingContact?.email
          ? 'This email address is linked to previous events and cannot be changed.'
          : '',
        onAutocomplete: handleEmailAutocomplete,
        onChange: handleEmailChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      firstName: {
        type: FormFieldType.TEXT,
        name: 'firstName',
        placeholder: 'Enter name',
        label: 'First Name',
        value: values.firstName,
        required: true,
        error: errors.firstName,
        touched: touched.firstName,
        disabled: !!existingContact && !isEditingExistingContact,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      lastName: {
        type: FormFieldType.TEXT,
        name: 'lastName',
        placeholder: 'Enter last name',
        label: 'Last Name',
        value: values.lastName,
        required: true,
        error: errors.lastName,
        touched: touched.lastName,
        disabled: !!existingContact && !isEditingExistingContact,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      avatar: {
        type: FormFieldType.IMAGE,
        name: 'avatar',
        rounded: true,
        value: values.avatar,
        placeholderIcon: PersonPlaceholderIcon,
        disabled: !!existingContact && !isEditingExistingContact,
        onChange: handleChange,
      },
      title: {
        type: FormFieldType.TEXT,
        name: 'title',
        placeholder: 'Enter position and company',
        label: 'Title',
        value: values.title,
        error: errors.title,
        touched: touched.title,
        disabled: !!existingContact && !isEditingExistingContact,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      phone: {
        type: FormFieldType.PHONE,
        name: 'phone',
        selectName: 'countryCode',
        placeholder: 'Phone',
        selectPlaceholder: <GlobeIcon />,
        label: 'Phone Number',
        touched: touched.phone,
        value: values.phone,
        selectValue: values.countryCode,
        error: errors.phone,
        disabled: !!existingContact && !isEditingExistingContact,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      assignedTo: {
        type: FormFieldType.SELECT,
        name: 'assignedTo',
        placeholder: 'Select',
        label: 'Assignment',
        value: values.assignedTo,
        error: errors.assignedTo,
        touched: touched.assignedTo,
        required: true,
        options: [
          {
            value: RelationshipMemberSide.PROSPECT_SIDE,
            label: propectAccountName
              ? `${propectAccountName} team`
              : 'Prospect team',
          },
          {
            value: RelationshipMemberSide.USER_SIDE,
            label: userOrganization?.name
              ? `${userOrganization.name} team`
              : 'User team',
          },
        ],
        onChange: handleChange,
      },
      contactType: {
        type: FormFieldType.SELECT,
        name: 'contactType',
        placeholder: 'Select',
        label: 'Member Type',
        value: values.contactType,
        error: errors.contactType,
        touched: touched.contactType,
        required: true,
        options: [
          {
            value: RelationshipMemberAssociationType.TEAM_MEMBER,
            label: 'Team Member',
          },
          {
            value: RelationshipMemberAssociationType.THIRD_PARTY,
            label: 'Associated 3rd party',
          },
        ],
        onChange: handleChange,
      },
      note: {
        type: FormFieldType.TEXTAREA,
        name: 'note',
        placeholder: 'Describe contact or role',
        label: 'Notes',
        value: values.note,
        error: errors.note,
        touched: touched.note,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [
      emailAutocompleteOptions,
      errors,
      existingContact,
      handleBlur,
      handleChange,
      handleEmailAutocomplete,
      handleEmailChange,
      handleFocus,
      isEditingExistingContact,
      propectAccountName,
      touched,
      userOrganization,
      values,
    ]
  );

  const cancelModalProps = useMemo(
    () => ({
      icon: WarningIcon,
      isOpened: isCancelModalOpened,
      title: isEditFlow
        ? 'Cancel editing member details?'
        : 'Cancel adding new member?',
      description: isEditFlow
        ? 'All changes will be discarded.'
        : 'All entered information will be discarded.',
      footerProps: {
        cancelButtonText: 'Back',
        confirmButtonText: 'Yes, Cancel',
        isConfirmButtonSecondary: true,
        onConfirm: () => {
          setIsCancelModalOpened(false);
          dispatch(closeAddMemberModal());
        },
      },
      onClose: () => {
        setIsCancelModalOpened(false);
      },
    }),
    [dispatch, isCancelModalOpened, isEditFlow]
  );

  const confirmationModalProps = useMemo(
    () => ({
      icon: ConfirmationIcon,
      isOpened: isConfirmationModalOpened,
      title: 'Member added successfully',
      buttonProps: {
        text: 'Close',
        isCloseButton: true,
      },
      onClose: () => {
        setIsConfirmationModalOpened(false);
      },
    }),
    [isConfirmationModalOpened]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    cancelModalProps,
    confirmationModalProps,
    values,
    resetContactFields,
  };
};
