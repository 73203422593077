import { DateTime } from 'luxon';

export const getRelationshipAgeString = (dateString?: string): string => {
  if (!dateString) {
    return '-';
  }

  const startDate = DateTime.fromISO(dateString);
  const currentDate = DateTime.now();

  const duration = currentDate.diff(startDate, [
    'years',
    'months',
    'days',
    'hours',
  ]);

  const isLessThanOneDay = duration.shiftTo('days').days < 1;
  const isLessThanOneMonth = duration.shiftTo('months').months < 1;
  const isLessThanOneYear = duration.shiftTo('years').years < 1;

  if (isLessThanOneDay) {
    return '< 1 day';
  }

  if (isLessThanOneMonth) {
    const shiftedDuration = duration.shiftTo('days');
    const days = Math.floor(shiftedDuration.days);

    return `${days} day${days === 1 ? '' : 's'}`;
  }

  if (isLessThanOneYear) {
    const shiftedDuration = duration.shiftTo('months', 'days');
    const months = Math.floor(shiftedDuration.months);
    const days = Math.floor(shiftedDuration.days);

    return `${months} month${months === 1 ? '' : 's'}${
      days ? `, ${days} day${days === 1 ? '' : 's'}` : ''
    }`;
  }

  const shiftedDuration = duration.shiftTo('years', 'months', 'days');
  const years = Math.floor(shiftedDuration.years);
  const months = Math.floor(shiftedDuration.months);
  const days = Math.floor(shiftedDuration.days);

  return `${years} year${years === 1 ? '' : 's'}${
    months ? `, ${months} month${months === 1 ? '' : 's'}` : ''
  }${days ? `, ${days} day${days === 1 ? '' : 's'}` : ''}`;
};
