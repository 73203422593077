import React from 'react';

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.1565 2.67178C11.6358 2.15108 10.7915 2.15108 10.2708 2.67178L9.87666 3.06596C9.87798 3.06833 9.87929 3.07071 9.88059 3.0731L10.2062 3.67224C10.6364 4.46385 11.2866 5.114 12.0782 5.5442L12.6773 5.86981C12.7923 5.93228 12.8815 6.02366 12.9408 6.13002L13.335 5.73591C13.8557 5.21521 13.8557 4.37099 13.335 3.85029L12.1565 2.67178Z"
      fill="#646F87"
    />
    <path
      d="M12.0335 7.03742L11.4415 6.71571C10.4237 6.16259 9.58781 5.32668 9.03469 4.30891L8.8935 4.04911L3.51781 9.42481C3.33056 9.61206 3.2033 9.85083 3.15227 10.1107L2.673 12.5511C2.58171 13.016 2.99079 13.425 3.45565 13.3337L5.89607 12.8545C6.15592 12.8034 6.39468 12.6762 6.58193 12.4889L12.0335 7.03742Z"
      fill="#646F87"
    />
  </svg>
);
