import React from 'react';

export const RemoveIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_3765_150374)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2426 3.75827C12.503 4.01862 12.503 4.44072 12.2426 4.70107L8.94281 8.00091L12.2426 11.3007C12.503 11.5611 12.503 11.9832 12.2426 12.2435C11.9823 12.5039 11.5602 12.5039 11.2998 12.2435L8 8.94371L4.70017 12.2435C4.43982 12.5039 4.01771 12.5039 3.75736 12.2435C3.49701 11.9832 3.49701 11.5611 3.75736 11.3007L7.05719 8.00091L3.75736 4.70107C3.49701 4.44072 3.49701 4.01862 3.75736 3.75827C4.01771 3.49792 4.43982 3.49792 4.70017 3.75827L8 7.0581L11.2998 3.75827C11.5602 3.49792 11.9823 3.49792 12.2426 3.75827Z"
        fill="#646F87"
      />
    </g>
    <defs>
      <clipPath id="clip0_3765_150374">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
