import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import chevron from './icons/chevron.svg';
import { RootState } from '../../store/store';
import { getCurrentPath } from '../utils';
import { getAdminSettingsLinks } from './utils/getLinks';

export const Submenu: React.FC = () => {
  const { user, org } = useSelector((state: RootState) => state.auth);

  const { settings, status } = useSelector(
    (state: RootState) => state.org.webhook
  );

  const { pathname } = useLocation();

  const history = useHistory();

  const adminSettingsLinks = useMemo(
    () => (user ? getAdminSettingsLinks(user) : []),
    [user]
  );

  const isNavigationDisabled =
    typeof org?.isFunctionalityEnabled === 'boolean' &&
    !org.isFunctionalityEnabled;

  const attrs = {
    wrapper: {
      className: 'submenu',
    },

    header: {
      wrapper: {
        className: 'submenu__header',
      },

      title: {
        className: 'submenu__header-title',
      },
    },

    body: {
      wrapper: {
        className: 'submenu__body',
      },

      link: {
        wrapper: {
          className: `${
            isNavigationDisabled ? 'disabled ' : ''
          }submenu__body__link`,
        },

        icon: {
          className: 'submenu__body__link-icon',
        },

        label: {
          className: 'submenu__body__link-label',
        },

        chevron: {
          className: 'submenu__body__link-chevron',
          src: chevron,
        },
      },
    },
  };

  const goToPage = (path: string) => {
    if (pathname !== path) {
      history.push(path);
    }
  };

  const defineLabelClassName = (path: string) => {
    if (
      path.includes('/apps') &&
      (status.connectionLost ||
        status.updateFailed ||
        settings.isDisabledByError)
    ) {
      return 'error submenu__body__link-label';
    }

    return 'submenu__body__link-label';
  };

  const renderAdminSettingsLinks = () =>
    adminSettingsLinks.map(({ label, path, Icon }) => (
      <div
        key={`${label}${path}`}
        className={`${
          isNavigationDisabled && !path.includes('admin-settings/billing')
            ? 'disabled '
            : ''
        }submenu__body__link`}
        onClick={() => goToPage(path)}
      >
        <div {...attrs.body.link.icon}>
          <Icon active={pathname === getCurrentPath(path, user)} />
        </div>
        <div className={defineLabelClassName(path)}>{label}</div>
        <img
          {...attrs.body.link.chevron}
          style={{ opacity: pathname === getCurrentPath(path, user) ? 1 : 0 }}
          alt=""
        />
      </div>
    ));

  if (
    user &&
    user.role !== 'dealmaker' &&
    pathname.includes('/admin-settings')
  ) {
    return (
      <div {...attrs.wrapper}>
        <div {...attrs.header.wrapper}>
          <div {...attrs.header.title}>Admin Settings</div>
        </div>
        <div {...attrs.body.wrapper}>{renderAdminSettingsLinks()}</div>
      </div>
    );
  }

  return null;
};
