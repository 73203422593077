import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '../Modal/Modal';
import { SIGNUP_LINK } from '../../constants/config';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { UserSignUpFlow } from '../../store/auth/auth.types';

export const NonPaidUserModal: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const userSignUpFlow = useAppSelector((state) => state.auth.userSignUpFlow);

  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userSignUpFlow === UserSignUpFlow.SUBSCRIPTION_EXPIRED) {
      setShowModal(true);
    }
  }, [dispatch, userSignUpFlow]);

  useEffect(() => {
    if (user && showModal) {
      history.replace(`/orgs/${user.orgId}/users/${user.orgId}/relationships`);
    }
  }, [history, showModal, user]);

  const attrs = {
    Modal: {
      open: showModal,
      title: '',
      onClose: () => {},
      empty: true,
      className: 'non-paid-modal',
      zIndex: 1000,
    },

    body: {
      wrapper: {
        className: 'non-paid-modal__body',
      },

      title: {
        className: 'non-paid-modal__body-title',
      },

      message: {
        className: 'non-paid-modal__body-message',
      },

      button: {
        className: 'non-paid-modal__body-button',
        onClick: () => {
          if (user?.role === 'owner') {
            window.location.href = `${SIGNUP_LINK}/signup/pricing`;
          } else {
            window.location.href = 'https://www.substrata.me/';
          }
        },
      },
    },
  };

  const getTitle = () => {
    return 'No active subscription';
  };

  const getMessage = () => {
    if (user?.role === 'owner') {
      return 'You don’t seem to have an active subscription. Hit the Continue button to view plans and pricing';
    }

    return 'You don’t seem to have an active subscription. Contact your organization owner to activate your subscription.';
  };

  const getButtonLabel = () => {
    if (user?.role === 'owner') {
      return 'Continue';
    }

    return 'Go to homepage';
  };

  return (
    <Modal {...attrs.Modal}>
      <div {...attrs.body.wrapper}>
        <div {...attrs.body.title}>{getTitle()}</div>
        <div {...attrs.body.message}>{getMessage()}</div>
        <div {...attrs.body.button}>{getButtonLabel()}</div>
      </div>
    </Modal>
  );
};
