import React from 'react';

export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M16.0006 8.0013C16.737 8.0013 17.334 8.59826 17.334 9.33464V18.668C17.334 19.4043 16.737 20.0013 16.0006 20.0013C15.2643 20.0013 14.6673 19.4043 14.6673 18.668V9.33464C14.6673 8.59826 15.2643 8.0013 16.0006 8.0013Z"
      fill="#FFB200"
    />
    <path
      d="M16.0006 24.668C16.9211 24.668 17.6673 23.9218 17.6673 23.0013C17.6673 22.0808 16.9211 21.3346 16.0006 21.3346C15.0802 21.3346 14.334 22.0808 14.334 23.0013C14.334 23.9218 15.0802 24.668 16.0006 24.668Z"
      fill="#FFB200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.138 6.37259C11.2135 4.1098 13.4953 2.66797 16.0006 2.66797C18.506 2.66797 20.7878 4.1098 21.8633 6.37259L29.1541 21.7118C30.8362 25.2507 28.2556 29.3346 24.3372 29.3346H7.66406C3.74571 29.3346 1.16507 25.2507 2.84715 21.7118L10.138 6.37259ZM16.0006 5.33464C14.5245 5.33464 13.1801 6.18414 12.5465 7.51735L5.2556 22.8565C4.41456 24.626 5.70488 26.668 7.66406 26.668H24.3372C26.2964 26.668 27.5867 24.626 26.7457 22.8565L19.4548 7.51734C18.8211 6.18414 17.4768 5.33464 16.0006 5.33464Z"
      fill="#FFB200"
    />
  </svg>
);
