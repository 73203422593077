import React from 'react';

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.09119 2.14637C7.67698 1.56058 8.62673 1.56059 9.21251 2.14637L9.91962 2.85348C10.5054 3.43926 10.5054 4.38901 9.91962 4.9748L5.31541 9.579C5.10476 9.78966 4.83614 9.93282 4.54381 9.99023L2.93347 10.3065C2.23619 10.4434 1.62257 9.8298 1.75951 9.13252L2.07576 7.52218C2.13317 7.22985 2.27633 6.96123 2.48699 6.75058L7.09119 2.14637ZM8.50541 2.85348C8.31014 2.65822 7.99356 2.65822 7.7983 2.85348L7.38784 3.26394L7.39144 3.27058C7.7141 3.86428 8.20171 4.3519 8.79541 4.67455L8.80205 4.67815L9.21251 4.26769C9.40777 4.07243 9.40777 3.75585 9.21251 3.56058L8.50541 2.85348ZM8.07134 5.40886C7.49985 5.04953 7.01646 4.56614 6.65713 3.99465L3.19409 7.45768C3.12387 7.5279 3.07616 7.61744 3.05702 7.71488L2.74077 9.32522L4.35111 9.00897C4.44855 8.98984 4.53809 8.94212 4.60831 8.8719L8.07134 5.40886Z"
      fill="white"
    />
  </svg>
);
