import React from 'react';

type Props = {
  changeValue: (flag: boolean, index?: number) => void;
  defaultActive: boolean;
  className?: string;
  index?: number;
  disabled?: boolean;
};

export const Checkbox: React.FC<Props> = ({
  changeValue,
  defaultActive = false,
  className = '',
  index,
  disabled = false,
}): JSX.Element => {
  const toggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e?.target?.checked === 'boolean') {
      changeValue(e.target.checked, index);
    }
  };

  return (
    <label className={`${className} ${disabled ? 'disabled' : ''} checkbox`}>
      <input
        type="checkbox"
        onChange={toggleCheckbox}
        className="checkbox__control"
        checked={defaultActive}
        disabled={disabled}
      />
      <div className="checkbox__overlay" />
    </label>
  );
};
