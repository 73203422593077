import React, { CSSProperties } from 'react';

type PopupProps = {
  className?: string;
  open?: boolean;
  options?: JSX.Element[];
  text?: string;
  style?: CSSProperties;
  onMouseOut?: () => void;
};

export const Popup: React.FC<PopupProps> = ({
  className = '',
  open,
  options = [],
  text,
  style,
  onMouseOut = () => {},
}: PopupProps): JSX.Element => {
  const conditionalClassName = `popup ${className} ${open ? 'open' : 'closed'}`;

  return (
    <div
      className={conditionalClassName}
      style={style}
      onMouseLeave={onMouseOut}
    >
      {options.map((option, index) => (
        <React.Fragment key={index}>{option}</React.Fragment>
      ))}
      {text}
    </div>
  );
};
