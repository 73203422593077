import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  loading: boolean;
  children?: JSX.Element | string;
}

export const PersonalQSkeleton: React.FC<Props> = (props: Props) => {
  const { loading, children } = props;
  return (
    <>
      {loading ? (
        <div className="personalq-iframe__skeleton">
          <div className="personalq-iframe__skeleton__title">
            <Skeleton
              loading
              active
              avatar={false}
              paragraph={false}
              title={{ style: { width: 200, height: 16, borderRadius: 30 } }}
            />
          </div>
          <div className="personalq-iframe__skeleton__circle">
            <Skeleton
              loading
              active
              avatar={{ size: 100, style: { margin: '18px 0px 15px' } }}
              paragraph={false}
              title={false}
            />
          </div>

          <div className="personalq-iframe__skeleton__message">
            <Skeleton
              loading
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 170,
                  height: 11,
                  borderRadius: 30,
                  marginTop: 13,
                },
              }}
            />
            <Skeleton
              loading
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 170,
                  height: 11,
                  borderRadius: 30,
                  marginTop: 2,
                },
              }}
            />
            <Skeleton
              loading
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 170,
                  height: 11,
                  borderRadius: 30,
                  marginTop: 2,
                },
              }}
            />
            <Skeleton
              loading
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 170,
                  height: 11,
                  borderRadius: 30,
                  marginTop: 2,
                },
              }}
            />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
