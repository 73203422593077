import React from 'react';
import { useAddNoteModal } from './hooks/useAddNoteModal';
import { Form, FormField, Modal } from '../SoundWave';

export const AddNoteModal: React.FC = () => {
  const {
    modalProps,
    formProps,
    fieldProps,
    values,
    maxNoteLength,
    hasError,
  } = useAddNoteModal();

  const attrs = {
    content: {
      className: 'add-note-modal__content',
    },
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
    characterCounter: {
      className: `add-note-modal__character-counter${
        values.note.length > maxNoteLength
          ? ' add-note-modal__character-counter--error'
          : ''
      }`,
    },
  };

  return (
    <Modal {...modalProps}>
      <div {...attrs.content}>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.note} />
          </fieldset>
        </Form>
        {!hasError ? (
          <span {...attrs.characterCounter}>
            {values.note.length} of {maxNoteLength} characters
          </span>
        ) : null}
      </div>
    </Modal>
  );
};
