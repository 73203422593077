import React, { useState } from 'react';
import { ChevronDownIcon } from '../../../../icons/ChevronDownIcon';

export interface DropdownOptionProps {
  label?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  className?: string;
  suboptions?: DropdownOptionProps[];
  onClick?: () => void;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  label,
  disabled = false,
  icon: iconProp,
  className,
  suboptions,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const attrs = {
    dropdownOption: {
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();

        if (suboptions?.length) {
          setIsExpanded(!isExpanded);
        }

        onClick?.();
      },
      disabled,
      className: `sound-wave-dropdown__option${
        isExpanded ? ' sound-wave-dropdown__option--expanded' : ''
      }${className ? ` ${className}` : ''}`,
      ...(!suboptions && { type: 'button' as const }),
    },
    dropdownOptionLabel: {
      className: 'sound-wave-dropdown__option-label',
    },
    dropdownIcon: {
      className: 'sound-wave-dropdown__dropdown-icon',
    },
    suboptionsWrapper: {
      className: 'sound-wave-dropdown__suboptions-wrapper',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
      ...(isExpanded && {
        style: { maxHeight: `${((suboptions?.length || 0) + 1) * 36}px` },
      }),
    },
  };

  const DropdownOptionComponent = suboptions ? 'div' : 'button';
  const icon =
    suboptions && !iconProp ? (
      <ChevronDownIcon {...attrs.dropdownIcon} />
    ) : (
      iconProp
    );

  const renderSuboptions = () =>
    suboptions?.map(
      (
        {
          label: suboptionLabel,
          disabled: suboptionDisabled,
          icon: suboptionIcon,
          className: suboptionClassName,
          onClick: onSuboptionClick,
        },
        index
      ) => {
        const props = {
          suboption: {
            key: index,
            disabled: suboptionDisabled,
            type: 'button' as const,
            className: `sound-wave-dropdown__option${
              suboptionClassName ? ` ${suboptionClassName}` : ''
            }`,
            onClick: () => {
              onSuboptionClick?.();
            },
          },
        };

        return (
          <button {...props.suboption}>
            <span {...attrs.dropdownOptionLabel}>
              <span>{suboptionLabel}</span>
              {suboptionIcon}
            </span>
          </button>
        );
      }
    );

  return (
    <DropdownOptionComponent {...attrs.dropdownOption}>
      <span {...attrs.dropdownOptionLabel}>
        <span>{label}</span>
        {icon}
      </span>
      {suboptions?.length ? (
        <span {...attrs.suboptionsWrapper}>{renderSuboptions()}</span>
      ) : null}
    </DropdownOptionComponent>
  );
};
