import { ChangeEvent, useState } from 'react';

type FileUpload = {
  handleUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  file: string | null;
  removeFile: () => void;
  croppedImage: string;
  saveCroppedImage: (image: string) => void;
};

export const useFileUpload = (): FileUpload => {
  const [file, setFile] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<string>('');

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0];
    const preparedFile = new FileReader();
    preparedFile.onload = function onload() {
      setFile(preparedFile.result && preparedFile.result.toString());
    };
    if (selectedFile) {
      preparedFile.readAsDataURL(selectedFile as File);
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  const saveCroppedImage = (image: string) => {
    setCroppedImage(image);
  };

  return { handleUpload, file, removeFile, croppedImage, saveCroppedImage };
};
