import React from 'react';
import { useAddAccountForm } from './hooks/useAddAccountForm';
import { ConfirmationModal, FormField, Modal } from '../SoundWave';
import { Form } from '../SoundWave/components/Form/Form';

export const AddAccountModal: React.FC = () => {
  const {
    modalProps,
    formProps,
    fieldProps,
    cancelModalProps,
    confirmationModalProps,
  } = useAddAccountForm();

  const attrs = {
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
  };

  return (
    <>
      <Modal {...modalProps}>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.logo} />
            <FormField {...fieldProps.name} />
            <FormField {...fieldProps.website} />
          </fieldset>
        </Form>
      </Modal>
      <ConfirmationModal {...cancelModalProps} />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
};
