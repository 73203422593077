import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useClickOutside } from '../../../../../../hooks';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { CheckedIcon } from '../../icons/CheckedIcon';
import { Scrollbar } from '../../../../../Scrollbar/Scrollbar';

export interface SelectOption {
  value: string;
  label: React.ReactNode;
  dropdownLabel?: React.ReactNode;
  isHidden?: boolean;
}

export interface SelectProps {
  options?: SelectOption[];
  disabled?: boolean;
  autocomplete?: boolean;
  placeholder?: React.ReactNode;
  value?: string;
  width?: number;
  dropdownWidth?: number;
  className?: string;
  onChange?: (value: string) => void;
  onInputChange?: (value: string) => void;
  renderDropdownContent?: () => React.ReactNode;
}

export const Select: React.FC<SelectProps> = ({
  value,
  options,
  disabled = false,
  autocomplete = false,
  placeholder,
  width,
  dropdownWidth,
  className,
  onChange,
  onInputChange,
  renderDropdownContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedOption = useMemo(
    () => options?.find((option) => option.value === value),
    [value, options]
  );

  const attrs = {
    container: {
      className: `sound-wave-select${
        isExpanded ? ' sound-wave-select--expanded' : ''
      }${value ? ' sound-wave-select--selected' : ''}${
        className ? ` ${className}` : ''
      }${autocomplete ? ' sound-wave-select--autocomplete' : ''}`,
      ref: containerRef,
      ...(typeof width === 'number'
        ? { style: { width, minWidth: width } }
        : {}),
    },
    field: {
      disabled,
      type: 'button' as const,
      className: 'form-field__input sound-wave-select__field',
      onClick: () => {
        setIsExpanded(!isExpanded);
      },
    },
    input: {
      disabled,
      placeholder: typeof placeholder === 'string' ? placeholder : '',
      ref: inputRef,
      defaultValue:
        typeof selectedOption?.label === 'string' ? selectedOption.label : '',
      type: 'text' as const,
      className: 'form-field__input sound-wave-select__input',
      onFocus: () => {
        setIsExpanded(true);
      },
    },
    text: {
      className: 'sound-wave-select__text',
    },
    fieldIcon: {
      className: 'sound-wave-select__field-icon',
    },
    dropdown: {
      className: 'sound-wave-select__dropdown',
      ...(typeof dropdownWidth === 'number'
        ? { style: { width: dropdownWidth, minWidth: dropdownWidth } }
        : {}),
    },
    scrollbar: {
      className: 'sound-wave-select__scrollable',
    },
    checkedIcon: {
      className: 'sound-wave-select__checked-icon',
    },
  };

  useClickOutside(containerRef, () => {
    const inputRefCurrent = inputRef.current;

    setIsExpanded(false);

    if (
      inputRefCurrent &&
      typeof selectedOption?.label === 'string' &&
      inputRefCurrent.value !== selectedOption.label
    ) {
      inputRefCurrent.value = selectedOption.label;
      onInputChange?.(selectedOption.label);
    }
  });

  useEffect(() => {
    const inputRefCurrent = inputRef.current;
    const listener = ({ target }: Event) => {
      if (target instanceof HTMLInputElement) {
        onInputChange?.(target.value);

        if (!target.value) {
          onChange?.('');
        }
      }
    };

    inputRefCurrent?.addEventListener('input', listener);

    return () => {
      inputRefCurrent?.removeEventListener('input', listener);
    };
  }, [onChange, onInputChange]);

  useEffect(() => {
    const inputRefCurrent = inputRef.current;

    if (
      inputRefCurrent &&
      typeof selectedOption?.label === 'string' &&
      inputRefCurrent.value !== selectedOption.label
    ) {
      inputRefCurrent.value = selectedOption.label;
    }
  }, [selectedOption?.label]);

  const renderOptions = () =>
    options?.map((option, index) => {
      const isSelected = option.value === selectedOption?.value;

      const props = {
        key: index,
        type: 'button' as const,
        disabled: isSelected || disabled,
        className: `sound-wave-select__option${
          option.isHidden ? ' sound-wave-select__option--hidden' : ''
        }`,
        'aria-selected': isSelected,
        onClick: () => {
          onChange?.(option.value);
          setIsExpanded(false);

          if (inputRef.current && typeof option.label === 'string') {
            onInputChange?.(option.label);
            inputRef.current.value = option.label;
          }
        },
      };

      return (
        <button {...props}>
          <span {...attrs.text}>{option.dropdownLabel || option.label}</span>
          {isSelected ? <CheckedIcon {...attrs.checkedIcon} /> : null}
        </button>
      );
    });

  return (
    <div {...attrs.container}>
      {autocomplete ? (
        <>
          <input {...attrs.input} />
          <ChevronDownIcon {...attrs.fieldIcon} />
        </>
      ) : (
        <button {...attrs.field}>
          <span {...attrs.text}>
            {selectedOption?.label || placeholder || ''}
          </span>
          <ChevronDownIcon {...attrs.fieldIcon} />
        </button>
      )}
      <div {...attrs.dropdown}>
        <Scrollbar {...attrs.scrollbar}>
          {renderDropdownContent?.()}
          {renderOptions()}
        </Scrollbar>
      </div>
    </div>
  );
};
