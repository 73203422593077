import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import {
  Account,
  AccountCompanyDetails,
  AccountNote,
} from '../../types/account.types';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import { Contact } from '../../types/contact.types';
import { RelationshipMemberLink } from '../../types/relationshipMember.types';
import { AddNoteFormValues } from '../relationshipFlows/relationshipFlows.types';
import {
  ApplyEditModeChangesRequestBody,
  CreateAccountNoteRequestBody,
} from './relationshipDetails.types';
import { RootState } from '../store';
import { Pagination } from '../../types/pagination.types';
import { RelationshipEvent } from '../../types/relationshipEvent.types';

export const getRelationshipAccountDetails = createAsyncThunk<Account, string>(
  'relationshipDetails/getRelationshipAccountDetails',
  async (
    accountId,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<Account>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getRelationshipAccountCompanyDetails = createAsyncThunk<
  AccountCompanyDetails,
  string
>(
  'relationshipDetails/getRelationshipAccountCompanyDetails',
  async (
    accountId,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<AccountCompanyDetails>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/company-details`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getAccountNotes = createAsyncThunk<AccountNote[], string>(
  'relationshipDetails/getAccountNotes',
  async (
    accountId,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<AccountNote[]>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/notes`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const createAccountNote = createAsyncThunk<
  AccountNote,
  AddNoteFormValues & {
    accountId: string;
  }
>(
  'relationshipDetails/createAccountNote',
  async (
    { accountId, note },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<AccountNote>,
        ApiResponse<AccountNote>,
        CreateAccountNoteRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/notes`,
        {
          name: '',
          note,
        },
        {
          signal,
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const updateAccountNote = createAsyncThunk<
  AccountNote,
  AddNoteFormValues & {
    accountId: string;
    noteId: string;
  }
>(
  'relationshipDetails/updateAccountNote',
  async (
    { accountId, noteId, note },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.put<
        ApiResponseData<AccountNote>,
        ApiResponse<AccountNote>,
        CreateAccountNoteRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/notes/${noteId}`,
        {
          name: '',
          note,
        },
        {
          signal,
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const deleteAccountNote = createAsyncThunk<
  string,
  {
    accountId: string;
    noteId: string;
  }
>(
  'relationshipDetails/deleteAccountNote',
  async (
    { accountId, noteId },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      await axios.delete<ApiResponseData<void>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/notes/${noteId}`,
        {
          signal,
        }
      );

      return fulfillWithValue(noteId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getRelationshipContacts = createAsyncThunk<Contact[], string>(
  'relationshipDetails/getRelationshipContacts',
  async (
    accountId,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<{ accountId: string; contacts: Contact[] }>
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/contacts`,
        { signal }
      );

      return fulfillWithValue(response.data.payload.contacts);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getRelationshipEvents = createAsyncThunk<
  Pagination & {
    results: RelationshipEvent[];
  },
  string
>(
  'relationshipDetails/getRelationshipEvents',
  async (
    accountId,
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
        relationshipDetails: {
          eventsPagination: { ipp, p },
        },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<
          Pagination & {
            results: RelationshipEvent[];
          }
        >
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/touch-points`,
        { signal, params: { ipp, p: p + 1 } }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
        relationshipDetails: { isLoading },
      } = getState() as RootState;
      return !!user && !isLoading.events;
    },
  }
);

export const applyEditModeChanges = createAsyncThunk<
  {
    accountId: string;
    contacts: Array<
      RelationshipMemberLink & {
        contactId: string;
      }
    >;
  },
  {
    accountId: string;
    editModeChanges: Array<{
      contact: Contact;
      changes: RelationshipMemberLink;
    }>;
  }
>(
  'relationshipDetails/applyEditModeChanges',
  async (
    { accountId, editModeChanges },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const contacts = editModeChanges.map(
        ({
          contact: { contactId },
          changes: { associationType, contactSide, isActive },
        }) => ({
          contactId,
          ...(associationType && { associationType }),
          ...(contactSide && { contactSide }),
          ...(typeof isActive === 'boolean' && { isActive }),
        })
      );

      await axios.post<
        ApiResponseData<void>,
        ApiResponse<void>,
        ApplyEditModeChangesRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}/edit-mode`,
        { contacts },
        { signal }
      );

      return fulfillWithValue({ accountId, contacts });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
