import { createSlice } from '@reduxjs/toolkit';
import { sendContactRequest } from './global.thunks';
import { GlobalState } from './global.types';

export const initialState: GlobalState = {
  isLoading: {
    contactRequest: false,
  },
  isError: {
    contactRequest: false,
  },
  isMaintenanceMode: false,
  isMixpanelInitialized: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    switchToMaintenanceMode: (state) => {
      state.isMaintenanceMode = true;
    },
    initMixpanel: (state) => {
      state.isMixpanelInitialized = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendContactRequest.pending, (state) => {
      state.isLoading.contactRequest = true;
      state.isError.contactRequest = false;
    });
    builder.addCase(sendContactRequest.fulfilled, (state) => {
      state.isLoading.contactRequest = false;
    });
    builder.addCase(sendContactRequest.rejected, (state, action) => {
      state.isLoading.contactRequest = false;
      state.isError.contactRequest = !action.meta.aborted;
    });
  },
});

export const { switchToMaintenanceMode, initMixpanel } = globalSlice.actions;

export default globalSlice.reducer;
