const OFFSET = 8;

export const getDropdownPosition = (
  dropdown: HTMLDivElement,
  container: HTMLDivElement
): string => {
  const dropdownRect = dropdown.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  const topPosition = containerRect.top - dropdownRect.height - OFFSET;
  const bottomPositon = containerRect.top + containerRect.height + OFFSET;

  const top =
    bottomPositon + dropdownRect.height > window.innerHeight
      ? topPosition
      : bottomPositon;

  const left = containerRect.left + containerRect.width - dropdownRect.width;

  return `translate(${left}px, ${top}px)`;
};
