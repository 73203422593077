import React from 'react';

export const ImagePlaceholderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8.5C6 7.11929 7.11929 6 8.5 6C9.88071 6 11 7.11929 11 8.5C11 9.88071 9.88071 11 8.5 11C7.11929 11 6 9.88071 6 8.5ZM8.5 8C8.22386 8 8 8.22386 8 8.5C8 8.77614 8.22386 9 8.5 9C8.77614 9 9 8.77614 9 8.5C9 8.22386 8.77614 8 8.5 8Z"
      fill="#95A1B6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V13.9773C22.0004 13.9926 22.0004 14.0079 22 14.0233V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16V8ZM20 12.523V8C20 5.79086 18.2091 4 16 4H8C5.79086 4 4 5.79086 4 8V16C4 16.2401 4.02115 16.4753 4.0617 16.7037L6.18104 15.1142C7.26345 14.3024 8.75798 14.3258 9.81451 15.171C10.1855 15.4678 10.7173 15.451 11.0688 15.1314L13.0121 13.3648C14.7116 11.8198 17.144 11.3806 19.2765 12.2336L20 12.523ZM4.92392 18.5571L7.38104 16.7142C7.73378 16.4497 8.22081 16.4573 8.56512 16.7327C9.70367 17.6436 11.3353 17.5921 12.4142 16.6113L14.3575 14.8447C15.4905 13.8147 17.112 13.5218 18.5337 14.0905L20 14.677V16C20 18.2091 18.2091 20 16 20H8C6.76328 20 5.65765 19.4387 4.92392 18.5571Z"
      fill="#95A1B6"
    />
  </svg>
);
