import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BottomNotificationState } from './bottomNotification.types';

const initialState: BottomNotificationState = {
  isOpened: false,
  text: null,
  position: null,
};

const bottomNotificationSlice = createSlice({
  name: 'bottomNotification',
  initialState,
  reducers: {
    showBottomNotification: (
      state,
      action: PayloadAction<{ text: string; position?: number }>
    ) => {
      state.isOpened = true;
      state.text = action.payload.text;
      state.position = action.payload.position || null;
    },
    hideBottomNotification: (state) => {
      state.isOpened = false;
    },
    resetBottomNotification: (state) => {
      state.text = null;
      state.position = null;
    },
  },
});

export const {
  showBottomNotification,
  hideBottomNotification,
  resetBottomNotification,
} = bottomNotificationSlice.actions;

export default bottomNotificationSlice.reducer;
