import React from 'react';

export const GmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M23.3363 7.7915L16.0792 13.2885L8.65625 7.7915V7.79299L8.66522 7.80042V15.4976L15.9955 21.1135L23.3363 15.7146V7.7915Z"
      fill="#EA4335"
    />
    <path
      d="M25.2408 6.45457L23.335 7.79167V15.7147L29.3319 11.2458V8.55381C29.3319 8.55381 28.604 4.70891 25.2408 6.45457Z"
      fill="#FBBC05"
    />
    <path
      d="M23.335 15.7147V25.9911H27.9313C27.9313 25.9911 29.2393 25.8603 29.3334 24.4133V11.2458L23.335 15.7147Z"
      fill="#34A853"
    />
    <path
      d="M8.66522 25.9998V15.4977L8.65625 15.4902L8.66522 25.9998Z"
      fill="#C5221F"
    />
    <path
      d="M8.65475 7.79316L6.7594 6.46349C3.39619 4.71784 2.66675 8.56125 2.66675 8.56125V11.2533L8.65475 15.4904V7.79316Z"
      fill="#C5221F"
    />
    <path
      d="M8.65625 7.79321V15.4904L8.66522 15.4979V7.80064L8.65625 7.79321Z"
      fill="#C5221F"
    />
    <path
      d="M2.66675 11.2549V24.4223C2.75942 25.8708 4.06883 26.0001 4.06883 26.0001H8.66521L8.65475 15.4905L2.66675 11.2549Z"
      fill="#4285F4"
    />
  </svg>
);
