import React from 'react';

export const ChevronRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25258 6.65848C8.58247 6.28145 8.58247 5.71849 8.25257 5.34147L5.04067 1.67074C4.85883 1.46292 4.54295 1.44187 4.33513 1.62371C4.12731 1.80555 4.10625 2.12143 4.2881 2.32925L7.5 5.99997L4.28809 9.67074C4.10625 9.87856 4.12731 10.1944 4.33513 10.3763C4.54295 10.5581 4.85883 10.5371 5.04067 10.3292L8.25258 6.65848Z"
      fill="#95A1B6"
    />
  </svg>
);
