import { Reducer } from 'redux';
import { Organization } from '../../types';
import {
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SET_LOADING,
  UPDATE_ORG_NAME_SUCCESS,
  UPDATE_ORG_NAME_ERROR,
  CLEAR_UPDATE_ORG_STATUS,
  GET_WEBHOOK_SETTINGS,
  GET_WEBHOOK_EVENTS,
  GET_WEBHOOK_SETTINGS_KEY,
  UPDATE_WEBHOOK_SETTINGS,
  RESET_WEBHOOK_STATUS,
  UPDATE_WEBHOOK_LOADING,
  UPDATE_WEBHOOK_SUCCESS,
  UPDATE_WEBHOOK_FAILED,
  TOGGLE_WEBHOOK_STATUS_MODAL,
  UPDATE_WEBHOOK_EVENTS,
  CLEAR_WEBHOOK_DATA,
  SET_WEBHOOK_KEY_LOADING,
} from './org.actions';
import {
  OrgsActions,
  WebhookEventsResponse,
  WebhookSettings,
} from './org.types';

export type OrgState = {
  myOrg: Organization | null;
  isLoading: boolean;
  updateSuccess: boolean;
  updateError: boolean;
  currentOrg: Organization | null;

  webhook: {
    isInitialized: boolean;
    eventsInfo: WebhookEventsResponse;
    settings: WebhookSettings;
    status: {
      isLoading: boolean;
      encryptKeyLoading: boolean;
      updateSuccess: boolean;
      updateFailed: boolean;
      errorMessage: string | null;
      connectionLost: boolean;
      statusModalOpen: boolean;
      isDataReseted: boolean;
    };
  };
};

const initialState: OrgState = {
  myOrg: null,
  isLoading: false,
  updateSuccess: false,
  updateError: false,
  currentOrg: null,
  webhook: {
    isInitialized: false,
    eventsInfo: {
      events: [],
      enabled: false,
      healthy: false,
    },
    settings: {
      url: '',
      hasEncryption: false,
      keyId: '',
      key: '',
      oldKey: '',
      hasAuth: false,
      isDisabledByError: false,
      errorMessage: '',
      auth: [],
    },
    status: {
      isLoading: false,
      encryptKeyLoading: false,
      updateSuccess: false,
      updateFailed: false,
      errorMessage: null,
      connectionLost: false,
      statusModalOpen: false,
      isDataReseted: false,
    },
  },
};

export const orgReducer: Reducer<OrgState, OrgsActions> = (
  state: OrgState = initialState,
  action: OrgsActions
): OrgState => {
  switch (action.type) {
    case GET_ORGANIZATION:
      return {
        ...state,
        myOrg: action.payload,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        myOrg: action.payload.org,
      };

    case UPDATE_ORG_NAME_SUCCESS:
      return {
        ...state,
        updateSuccess: action.payload,
      };

    case UPDATE_ORG_NAME_ERROR:
      return {
        ...state,
        updateError: action.payload,
      };

    case CLEAR_UPDATE_ORG_STATUS:
      return {
        ...state,
        updateError: initialState.updateError,
        updateSuccess: initialState.updateError,
        isLoading: initialState.isLoading,
      };

    case GET_WEBHOOK_EVENTS:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          isInitialized: true,
          eventsInfo: action.payload,
          status: {
            ...state.webhook.status,
            isDataReseted: false,
          },
        },
      };

    case UPDATE_WEBHOOK_EVENTS:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          eventsInfo: action.payload,
        },
      };

    case UPDATE_WEBHOOK_SETTINGS:
    case GET_WEBHOOK_SETTINGS:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          settings: {
            ...action.payload,
            key: action.payload.key || '',
            keyId: action.payload.keyId || '',
            oldKey: action.payload.key || '',
          },
          status: {
            ...state.webhook.status,
            isDataReseted: false,
          },
        },
      };

    case UPDATE_WEBHOOK_LOADING:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: {
            ...state.webhook.status,
            isLoading: action.payload,
          },
        },
      };

    case UPDATE_WEBHOOK_SUCCESS:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: {
            ...state.webhook.status,
            updateSuccess: true,
            statusModalOpen: true,
          },
        },
      };

    case UPDATE_WEBHOOK_FAILED:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: {
            ...state.webhook.status,
            updateFailed: true,
            errorMessage:
              typeof action.payload === 'string' ? action.payload : null,
            statusModalOpen: true,
          },
        },
      };

    case GET_WEBHOOK_SETTINGS_KEY:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          settings: {
            ...state.webhook.settings,
            keyId: action.payload?.keyId,
            key: action.payload?.key,
            oldKey: action.payload?.oldKey || '',
            hasEncryption: true,
          },
        },
      };

    case SET_WEBHOOK_KEY_LOADING:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: {
            ...state.webhook.status,
            encryptKeyLoading: action.payload,
          },
        },
      };

    case TOGGLE_WEBHOOK_STATUS_MODAL:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: {
            ...state.webhook.status,
            statusModalOpen: action.payload,
          },
        },
      };

    case RESET_WEBHOOK_STATUS:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          status: initialState.webhook.status,
        },
      };

    case CLEAR_WEBHOOK_DATA:
      return {
        ...state,
        webhook: {
          ...state.webhook,
          eventsInfo: initialState.webhook.eventsInfo,
          settings: initialState.webhook.settings,
          status: {
            ...initialState.webhook.status,
            isDataReseted: true,
          },
        },
      };

    default:
      return state;
  }
};
