import { Reducer } from 'redux';
import { Row, User } from '../../types';
import {
  ADD_ERROR_INCORRECT_PASSWORD,
  ADD_USER,
  ADD_USER_FAILURE,
  CHANGE_PASSWORD_STATUS,
  CLEAR_ERRORS,
  CLEAR_USERS,
  CLOSE_DELETE_MODAL,
  DELETE_USERS,
  GET_USERS,
  INVOKE_ADD_USER_MODAL,
  INVOKE_ASSIGN_ROLE_MODAL,
  INVOKE_DELETE_MODAL,
  SET_CURRENT_USER,
  UPDATE_USER,
  VERIFY_PASSWORD_FAILURE,
  VERIFY_PASSWORD_STATUS,
} from './users.actions';
import { UsersActions } from './users.types';
import { getUserRow, sortUsersArray } from './users.utils';

type ModalsState = {
  deleteModalOpen: boolean;
  addUserModalOpen: boolean;
  assignRoleModalOpen: boolean;
};

export type UsersState = {
  usersCount: number;
  maxUsersCount: number;
  users: Row[];
  modals: ModalsState;
  currentUser: User | null;
  errors: {
    addUser: string;
    verifyPassword: string;
  };
  password: {
    isPasswordVerified: boolean;
    isPasswordSuccessfullyChanged: boolean;
  };
};

export const initialState: UsersState = {
  usersCount: 0,
  maxUsersCount: 0,
  users: [],
  modals: {
    deleteModalOpen: false,
    addUserModalOpen: false,
    assignRoleModalOpen: false,
  },
  currentUser: null,
  errors: {
    addUser: '',
    verifyPassword: '',
  },
  password: {
    isPasswordVerified: false,
    isPasswordSuccessfullyChanged: false,
  },
};

export const usersReducer: Reducer<UsersState, UsersActions> = (
  state: UsersState = initialState,
  action: UsersActions
): UsersState => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: sortUsersArray(action.payload.users),
        usersCount: action.payload.usersCount,
        maxUsersCount: action.payload.maxUsersCount,
      };
    case DELETE_USERS:
      return {
        ...state,
        users: state.users.filter(
          (user) => !action.payload.includes(user.userId)
        ),
      };
    case INVOKE_DELETE_MODAL:
      return {
        ...state,
        currentUser: null,
        modals: {
          ...state.modals,
          deleteModalOpen: !state.modals.deleteModalOpen,
        },
      };
    case CLOSE_DELETE_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          deleteModalOpen: false,
        },
        maxUsersCount:
          state.maxUsersCount === -1
            ? state.maxUsersCount
            : state.maxUsersCount + 1,
      };
    }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        modals: {
          ...state.modals,
          addUserModalOpen: !state.modals.addUserModalOpen,
        },
        maxUsersCount:
          state.maxUsersCount === -1
            ? state.maxUsersCount
            : state.maxUsersCount - 1,
      };
    case ADD_USER_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          addUser: action.payload,
        },
      };
    case INVOKE_ADD_USER_MODAL:
      return {
        ...state,
        users: action.payload.users.map((user) => getUserRow(user)),
        modals: {
          ...state.modals,
          addUserModalOpen: !state.modals.addUserModalOpen,
        },
      };
    case INVOKE_ASSIGN_ROLE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          assignRoleModalOpen: !state.modals.assignRoleModalOpen,
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.userId !== action.payload.userId
            ? user
            : getUserRow(action.payload)
        ),
      };
    case VERIFY_PASSWORD_STATUS:
      return {
        ...state,
        password: {
          ...state.password,
          isPasswordVerified: action.flag,
        },
      };
    case VERIFY_PASSWORD_FAILURE:
      return {
        ...state,
        password: {
          ...state.password,
          isPasswordVerified: false,
        },
      };
    case ADD_ERROR_INCORRECT_PASSWORD:
      return {
        ...state,
        errors: {
          ...state.errors,
          verifyPassword: action.message,
        },
      };
    case CHANGE_PASSWORD_STATUS:
      return {
        ...state,
        password: {
          ...state.password,
          isPasswordSuccessfullyChanged: action.payload,
        },
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {
          ...initialState.errors,
        },
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
      };
    default:
      return state;
  }
};
