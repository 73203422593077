import React from 'react';

export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.93202 3.03553L2.98043 2.97945C3.15794 2.80194 3.43572 2.78581 3.63146 2.93104L3.68754 2.97945L8.00065 7.29234L12.3138 2.97945C12.509 2.78419 12.8256 2.78419 13.0209 2.97945C13.2161 3.17472 13.2161 3.4913 13.0209 3.68656L8.70798 7.99967L13.0209 12.3128C13.1984 12.4903 13.2145 12.7681 13.0693 12.9638L13.0209 13.0199C12.8434 13.1974 12.5656 13.2135 12.3698 13.0683L12.3138 13.0199L8.00065 8.70701L3.68754 13.0199C3.49228 13.2152 3.17569 13.2152 2.98043 13.0199C2.78517 12.8246 2.78517 12.508 2.98043 12.3128L7.29332 7.99967L2.98043 3.68656C2.80292 3.50905 2.78678 3.23127 2.93202 3.03553L2.98043 2.97945L2.93202 3.03553Z"
      fill="#9AABCB"
    />
  </svg>
);
