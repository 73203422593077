import React, { useState, useEffect, CSSProperties, useRef } from 'react';

import { ReactComponent as Chevron } from './assets/chevron-icon.svg';
import { ReactComponent as PerformanceChevron } from './assets/performance-chevron.svg';
import { ReactComponent as WebhookChevron } from './assets/webhook-chevron-icon.svg';
import { ReactComponent as SubscriptionChevron } from './assets/subscription-chevron-icon.svg';
import { ReactComponent as AddInfoPopupChevron } from './assets/add-info-popup-chevron.svg';
import { ReactComponent as AddBotModalChevron } from './assets/add-bot-chevron.svg';
import { defaultAvatar } from './assets';

interface RequiredItemProperties<T> {
  value: T;
  label: string;
  avatar?: string;
}

interface CustomSelectProps<A, T extends RequiredItemProperties<A>> {
  label?: string;
  isRequired?: boolean;
  selectedItem?: T;
  items: T[];
  onChange?: (newValue: T) => void;
  resetValue?: boolean;
  placeholder?: string;
  icon?: string | null;
  style?: CSSProperties;
  className?: string;
  margin?: string;
  padding?: string;
  justifyContentItems?: string;
  selectedItemJustify?: string;
  selectedItemFontSize?: string;
  selectedItemFontFamily?: string;
  selectedItemFontWeight?: string;
  itemPadding?: string;
  disabled?: boolean;
  isData?: boolean;
  isError?: boolean;
  medium?: boolean;
  isPerformance?: boolean;
  isTeamQ?: boolean;
  isInCrmWindow?: boolean;
  isInWebhook?: boolean;
  isInSubscription?: boolean;
  isInAddInfoPopup?: boolean;
  isInAddBotModal?: boolean;
}

function CustomSelect<A, T extends RequiredItemProperties<A>>(
  props: CustomSelectProps<A, T>
): JSX.Element {
  const {
    label,
    isRequired,
    selectedItem,
    items,
    onChange,
    resetValue,
    placeholder,
    icon,
    medium,
    style,
    className,
    margin,
    padding,
    justifyContentItems,
    selectedItemJustify,
    selectedItemFontSize,
    selectedItemFontFamily,
    selectedItemFontWeight,
    itemPadding,
    disabled,
    isData,
    isError,
    isPerformance,
    isTeamQ,
    isInCrmWindow,
    isInWebhook,
    isInSubscription,
    isInAddInfoPopup,
    isInAddBotModal,
  } = props;
  const [currentValue, setCurrentValue] = useState<T | null>(
    selectedItem || null
  );
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const refToCatchClickOutside = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (open && !refToCatchClickOutside.current?.contains(target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    if (selectedItem) {
      setCurrentValue(selectedItem || null);
    }
  }, [selectedItem]);

  const updateValue = (newValue: T) => () => {
    setCurrentValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
    toggleOpen();
  };

  useEffect(() => {
    if (resetValue && currentValue) {
      setCurrentValue(null);
    }
  }, [resetValue]);

  const wrapperClassName = `${isPerformance ? 'performance' : ''} ${
    isTeamQ ? 'teamq-page' : ''
  } ${isInWebhook ? 'in-webhook' : ''} ${
    isInSubscription ? 'in-subscription' : ''
  } ${open ? 'up-index' : ''} ${className} mainBlock`;

  const optionsTopContainerClassName = !open
    ? 'closed mainBlock-dropped-overflow-container'
    : `${isPerformance || isTeamQ ? 'performance' : ''} ${
        isInSubscription ? 'in-subscription' : ''
      } ${
        isInAddInfoPopup ? 'in-add-info-popup' : ''
      } mainBlock-dropped-overflow-container ${
        isInAddBotModal ? 'in-add-bot-modal' : ''
      } mainBlock-dropped-overflow-container`;

  const optionsWrapperClassName = `mainBlock-dropped-block ${
    !open ? 'closed' : ''
  } ${isPerformance || isTeamQ ? 'performance' : ''} ${
    isInCrmWindow ? 'in-crm' : ''
  } ${isInWebhook ? 'in-webhook' : ''} ${
    isInSubscription ? 'in-subscription' : ''
  } ${isInAddInfoPopup ? 'in-add-info-popup' : ''} ${
    isInAddBotModal ? 'in-add-bot-modal' : ''
  }`;

  const defineSelectsBorderColor = () => {
    let resultClassName = 'mainBlock-current-selected-item-container';

    if (isError) {
      resultClassName = 'error mainBlock-current-selected-item-container';
    } else if (
      open &&
      !isPerformance &&
      !isTeamQ &&
      !isInSubscription &&
      !isInAddInfoPopup &&
      !isInAddBotModal
    ) {
      resultClassName = 'open mainBlock-current-selected-item-container';
    } else if (open && (isPerformance || isTeamQ)) {
      resultClassName = `${
        isPerformance ? 'performance' : 'teamq-page'
      } mainBlock-current-selected-item-container`;
    } else if (isInSubscription) {
      resultClassName = `${
        open ? 'open' : ''
      } in-subscription mainBlock-current-selected-item-container`;
    } else if (isData && !open) {
      resultClassName = 'warn mainBlock-current-selected-item-container';
    } else if (isInAddInfoPopup) {
      resultClassName = `${
        open ? 'open' : ''
      } in-add-info-popup mainBlock-current-selected-item-container`;
    } else if (isInAddBotModal) {
      resultClassName = `${
        open ? 'open' : ''
      } in-add-bot-modal mainBlock-current-selected-item-container`;
    }

    return resultClassName;
  };

  const selectClassName = defineSelectsBorderColor();

  const droppedItemClassName = (el: T) => {
    let resultClassName = 'drop-item';

    if (el.label === 'Custom' || el.label === currentValue?.label) {
      resultClassName = `${isInAddInfoPopup ? 'in-add-info-popup' : ''} ${
        isInAddBotModal ? 'add-bot ' : ''
      } disabled drop-item`;
    } else if (el.label === 'All Users') {
      resultClassName = 'all-users drop-item';
    } else if (isInSubscription) {
      resultClassName = 'in-subscription drop-item';
    } else if (isInAddInfoPopup) {
      resultClassName = 'in-add-info-popup drop-item';
    } else if (isInAddBotModal) {
      resultClassName = 'new-acc drop-item';
      if (el.label === 'Create a new Account') {
        resultClassName = 'new-acc drop-item';
      } else {
        resultClassName = 'add-bot drop-item';
      }
    }

    return resultClassName;
  };

  const title = label?.length ? (
    <span className={`${isRequired ? 'required' : ''} mainBlock__label`}>
      {label}
    </span>
  ) : null;

  const selectedItemIcon = icon ? (
    <img
      className={`${
        medium ? 'mainBlock-current-selected-item-container-optionalIcon' : ''
      } ${
        isInAddInfoPopup
          ? 'mainBlock-current-selected-item-container-icon-in-add-info-popup'
          : ''
      }`}
      src={icon}
      alt="icon"
    />
  ) : null;

  const selectedItemValue = (
    <div
      className={`${isTeamQ ? 'teamq-page' : ''} ${
        isInSubscription ? 'in-subscription' : ''
      } ${
        !currentValue?.label ? 'placeholder' : ''
      } mainBlock-current-selected-item-text`}
      style={{
        padding,
        justifyContent: selectedItemJustify,
        fontSize: selectedItemFontSize,
        fontFamily: selectedItemFontFamily,
        fontWeight: selectedItemFontWeight,
      }}
    >
      {currentValue?.label || placeholder || null}
    </div>
  );

  const chevron = (
    <div
      className={`${isPerformance || isTeamQ ? 'performance' : ''} ${
        isInSubscription ? 'in-subscription' : ''
      } ${isInWebhook ? 'in-webhook' : ''} ${
        isInAddInfoPopup ? 'in-add-info-popup' : ''
      } ${
        isInAddBotModal ? 'in-add-bot-modal' : ''
      } mainBlock-chevron-container  ${!open ? 'revert' : ''}`}
    >
      {isInWebhook ? <WebhookChevron /> : null}
      {isPerformance || isTeamQ ? <PerformanceChevron /> : null}
      {isInSubscription ? <SubscriptionChevron /> : null}
      {isInAddInfoPopup ? <AddInfoPopupChevron /> : null}
      {isInAddBotModal ? <AddBotModalChevron /> : null}
      {!isInAddBotModal &&
      !isInWebhook &&
      !isPerformance &&
      !isTeamQ &&
      !isInSubscription &&
      !isInAddInfoPopup ? (
        <Chevron />
      ) : null}
    </div>
  );

  const mappedOptions = items.map((el) => (
    <div
      key={JSON.stringify(el)}
      className={droppedItemClassName(el)}
      onClick={updateValue(el)}
      style={{
        justifyContent: justifyContentItems,
        padding: itemPadding,
      }}
    >
      {typeof el.avatar === 'string' ? (
        <img
          className="drop-item-avatar"
          src={el.avatar || defaultAvatar}
          alt=""
        />
      ) : null}
      {el.label}
    </div>
  ));

  return (
    <div className={wrapperClassName} ref={refToCatchClickOutside}>
      {title}
      <div
        className={selectClassName}
        style={style}
        onClick={!disabled ? toggleOpen : undefined}
      >
        {selectedItemIcon}
        {selectedItemValue}
        {chevron}
      </div>
      <div className={optionsTopContainerClassName} style={{ margin }}>
        <div className={optionsWrapperClassName}>{mappedOptions}</div>
      </div>
    </div>
  );
}

export default CustomSelect;
