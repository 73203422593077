import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import axios from '../../axios';
import { RootState } from '../store';
import {
  TeamQDateRangeParams,
  TeamQMeetingCountChartData,
  TeamQMeetingCountUserStats,
  TeamQMeetingDurationChartData,
  TeamQMeetingDurationUserStats,
  TeamQStats,
  TeamQStatsRequestBody,
  TeamQTalkTimeRatioChartData,
  TeamQTalkTimeRatioUserStats,
} from './soundWaveTeamQ.types';

export const getDetailedMeetingCountStats = createAsyncThunk<
  TeamQStats<TeamQMeetingCountUserStats>,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getDetailedMeetingCountStats',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQStats<TeamQMeetingCountUserStats> }>,
        ApiResponse<{ data: TeamQStats<TeamQMeetingCountUserStats> }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/detailed-meeting-count`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getMeetingCountChartData = createAsyncThunk<
  TeamQMeetingCountChartData,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getMeetingCountChartData',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQMeetingCountChartData }>,
        ApiResponse<{ data: TeamQMeetingCountChartData }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/meeting-count`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getDetailedMeetingDurationStats = createAsyncThunk<
  TeamQStats<TeamQMeetingDurationUserStats>,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getDetailedMeetingDurationStats',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQStats<TeamQMeetingDurationUserStats> }>,
        ApiResponse<{ data: TeamQStats<TeamQMeetingDurationUserStats> }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/detailed-meeting-duration`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getMeetingDurationChartData = createAsyncThunk<
  TeamQMeetingDurationChartData,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getMeetingDurationChartData',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQMeetingDurationChartData }>,
        ApiResponse<{ data: TeamQMeetingDurationChartData }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/meeting-duration`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getDetailedTalkTimeRatioStats = createAsyncThunk<
  TeamQStats<TeamQTalkTimeRatioUserStats>,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getDetailedTalkTimeRatioStats',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQStats<TeamQTalkTimeRatioUserStats> }>,
        ApiResponse<{ data: TeamQStats<TeamQTalkTimeRatioUserStats> }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/detailed-talk-time-ratio`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getTalkTimeRatioChartData = createAsyncThunk<
  TeamQTalkTimeRatioChartData,
  TeamQDateRangeParams & {
    teamId?: string;
  }
>(
  'soundWaveTeamQ/getTalkTimeRatioChartData',
  async (
    { teamId, ...dateRangeParams },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ data: TeamQTalkTimeRatioChartData }>,
        ApiResponse<{ data: TeamQTalkTimeRatioChartData }>,
        TeamQStatsRequestBody
      >(
        `/services/orgs/${orgId}/users/${userId}/stats/unified/talk-time-ratio`,
        {
          type: teamId ? 'team' : 'allUsers',
          ...(teamId ? { teamId } : {}),
        },
        {
          signal,
          params: {
            tz: DateTime.now().toFormat('ZZZZ'),
            ...dateRangeParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
