import React from 'react';

export const PersonPlaceholderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3ZM9 8C9 6.34315 10.3431 5 12 5C13.6569 5 15 6.34315 15 8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8Z"
      fill="#95A1B6"
    />
    <path
      d="M6 21C6 18.2386 8.23858 16 11 16H13C15.7614 16 18 18.2386 18 21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21C20 17.134 16.866 14 13 14H11C7.13401 14 4 17.134 4 21C4 21.5523 4.44772 22 5 22C5.55228 22 6 21.5523 6 21Z"
      fill="#95A1B6"
    />
  </svg>
);
