import { UsersIcon } from '../icons/account/UsersIcon';
import { TeamsIcon } from '../icons/account/TeamsIcon';
import { OrganizationIcon } from '../icons/account/OrganizationIcon';
import { AppsIcon } from '../icons/account/AppsIcon';
import { BillingIcon } from '../icons/account/BillingIcon';
import { User } from '../../../types';

interface IconProps {
  active: boolean;
}

export interface SubmenuLink {
  label: string;
  Icon: React.FC<IconProps>;
  path: string;
}

export const getAdminSettingsLinks = (user: User): SubmenuLink[] => [
  {
    label: 'Users',
    Icon: UsersIcon,
    path: `/orgs/${user?.orgId}/admin-settings/users`,
  },
  {
    label: 'Teams',
    Icon: TeamsIcon,
    path: `/orgs/${user?.orgId}/admin-settings/teams`,
  },
  {
    label: 'Organization',
    Icon: OrganizationIcon,
    path: `/orgs/${user?.orgId}/admin-settings/organization`,
  },
  {
    label: 'Apps',
    Icon: AppsIcon,
    path: `/orgs/${user?.orgId}/admin-settings/apps`,
  },
  {
    label: 'Billing',
    Icon: BillingIcon,
    path: `/orgs/${user?.orgId}/admin-settings/billing`,
  },
];
