import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { RootState } from '../store';
import {
  AccountsResponse,
  ContactsResponse,
  RelationshipsFiltersQueryParams,
  RelationshipsResponse,
  RSSortBy,
  RSSortOrder,
} from './relationships.types';

export const getRelationships = createAsyncThunk<
  RelationshipsResponse,
  {
    filters: RelationshipsFiltersQueryParams | undefined;
    sortBy: RSSortBy | null;
    sortOrder: RSSortOrder | null;
  }
>(
  'relationships/getRelationships',
  async (
    { filters, sortBy, sortOrder },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
        relationships: { relationships },
      } = getState() as RootState;

      const { ipp, count, p, results } = relationships;

      const { orgId = '', userId = '' } = user || {};

      const pageToRequest = results?.length && p * ipp < count ? p + 1 : 1;

      const response = await axios.get(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/relationships/filter`,
        {
          signal,
          params: {
            ipp,
            p: pageToRequest,
            ...(sortBy && { sortBy }),
            ...(sortOrder && { sortOrder }),
            ...filters,
          },
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getAccountsForSearch = createAsyncThunk<AccountsResponse, string>(
  'relationships/getAccountsForSearch',
  async (value, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const body = {
        search: {
          partialMatch: true,
          fields: ['name'],
          value,
          pagination: {
            p: 1,
            ipp: 100,
            sortOrder: 'asc',
            sortBy: 'string',
          },
        },
      };

      const response = await axios.post(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/search`,
        body,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getContactsForSearch = createAsyncThunk<ContactsResponse, string>(
  'relationships/getContactsForSearch',
  async (value, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const body = {
        search: {
          partialMatch: true,
          fields: ['email'],
          value,
          pagination: {
            p: 1,
            ipp: 50,
            sortOrder: 'asc',
            sortBy: 'string',
          },
        },
        includeAssociatedAccounts: true,
      };

      const response = await axios.post(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts/search`,
        body,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
