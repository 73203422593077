import {
  LEFT_RIGHT_OFFSET,
  TOP_BOTTOM_OFFSET,
} from '../constants/tooltipConstants';
import { TooltipPosition } from '../Tooltip';

export const getTooltipContentPosition = (
  content: HTMLDivElement,
  parent: HTMLDivElement,
  position: TooltipPosition,
  simplified: boolean,
  cursorPosition?: { x: number; y: number }
): string => {
  const contentRect = content.getBoundingClientRect();
  const parentRect = cursorPosition
    ? {
        top: cursorPosition.y,
        left: cursorPosition.x,
        width: 0,
        height: 0,
      }
    : parent.getBoundingClientRect();

  switch (position) {
    case TooltipPosition.TOP: {
      const top = parentRect.top - contentRect.height;
      const left =
        parentRect.left + parentRect.width / 2 - contentRect.width / 2;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.TOP_START: {
      const top = parentRect.top - contentRect.height;
      const left = simplified
        ? parentRect.left
        : parentRect.left - TOP_BOTTOM_OFFSET;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.TOP_END: {
      const top = parentRect.top - contentRect.height;
      const left = simplified
        ? parentRect.left + parentRect.width - contentRect.width
        : parentRect.left +
          parentRect.width -
          contentRect.width +
          TOP_BOTTOM_OFFSET;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.RIGHT: {
      const top =
        parentRect.top + parentRect.height / 2 - contentRect.height / 2;
      const left = parentRect.left + parentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.RIGHT_START: {
      const top = simplified
        ? parentRect.top
        : parentRect.top - LEFT_RIGHT_OFFSET;
      const left = parentRect.left + parentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.RIGHT_END: {
      const top = simplified
        ? parentRect.top + parentRect.height - contentRect.height
        : parentRect.top +
          parentRect.height -
          contentRect.height +
          LEFT_RIGHT_OFFSET;
      const left = parentRect.left + parentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.BOTTOM: {
      const top = parentRect.top + parentRect.height;
      const left =
        parentRect.left + parentRect.width / 2 - contentRect.width / 2;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.BOTTOM_START: {
      const top = parentRect.top + parentRect.height;
      const left = simplified
        ? parentRect.left
        : parentRect.left - TOP_BOTTOM_OFFSET;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.BOTTOM_END: {
      const top = parentRect.top + parentRect.height;
      const left = simplified
        ? parentRect.left + parentRect.width - contentRect.width
        : parentRect.left +
          parentRect.width -
          contentRect.width +
          TOP_BOTTOM_OFFSET;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.LEFT: {
      const top =
        parentRect.top + parentRect.height / 2 - contentRect.height / 2;
      const left = parentRect.left - contentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.LEFT_START: {
      const top = simplified
        ? parentRect.top
        : parentRect.top - LEFT_RIGHT_OFFSET;
      const left = parentRect.left - contentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    case TooltipPosition.LEFT_END: {
      const top = simplified
        ? parentRect.top + parentRect.height - contentRect.height
        : parentRect.top +
          parentRect.height -
          contentRect.height +
          LEFT_RIGHT_OFFSET;
      const left = parentRect.left - contentRect.width;

      return `translate(${left}px, ${top}px)`;
    }
    default: {
      return '';
    }
  }
};
