import React, { createContext, useEffect, useMemo, useRef } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MIX_PANEL_TOKEN, MODE } from '../../constants/config';
import { initMixpanel } from '../../store/global/global.slice';

export const MixpanelContext = createContext({
  mixpanelInstance: mixpanel,
});

export const MixpanelProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const isInitialized = useAppSelector(
    (state) => state.global.isMixpanelInitialized
  );

  const isUserSet = useRef(false);

  const contextValue = useMemo(
    () => ({
      mixpanelInstance: mixpanel,
    }),
    []
  );

  useEffect(() => {
    if (!isInitialized) {
      mixpanel.init(MIX_PANEL_TOKEN, {
        debug: MODE === 'local',
      });

      dispatch(initMixpanel());
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (user && isInitialized && !isUserSet.current) {
      mixpanel.identify(`${user.orgId}|${user.userId}`);

      mixpanel.people.set_once({
        name: user.name,
        firstName: user.firstName,
        lastName: user.lastName,
        orgId: user.orgId,
        userId: user.userId,
      });

      isUserSet.current = true;
    }
  }, [isInitialized, user]);

  return (
    <MixpanelContext.Provider value={contextValue}>
      {children}
    </MixpanelContext.Provider>
  );
};
