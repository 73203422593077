import { v4 as uuidv4 } from 'uuid';
import {
  DealStageFilterValue,
  LastInteractionDateFilterValue,
  RelationshipAgeFilterValue,
  RelationshipOwnerFilterValue,
} from '../../types/relationshipFilters.types';

export const RELATIONSHIP_EVENTS_FILTERS_GROUP_ID = uuidv4();

export const ADMIN_FILTERS_GROUP_ID = uuidv4();

export const RELATIONSHIP_OWNER_FILTER_DEFAULT_VALUE =
  RelationshipOwnerFilterValue.DEFAULT;

export const LAST_INTERACTION_DATE_FILTER_DEFAULT_VALUE = {
  selectValue: LastInteractionDateFilterValue.ALL,
  startDate: null,
  endDate: null,
};

export const RELATIONSHIP_AGE_FILTER_DEFAULT_VALUE =
  RelationshipAgeFilterValue.ALL;

export const DEAL_STAGE_FILTER_DEFAULT_VALUE = DealStageFilterValue.ALL;

export const DEAL_SIZE_FILTER_DEFAULT_VALUE = {
  min: 0,
  max: 0,
};

export const RELATIONSHIP_OWNER_FILTER_OPTIONS = [
  {
    value: RelationshipOwnerFilterValue.DEFAULT,
    label: 'All',
  },
];

export const LAST_INTERACTION_DATE_FILTER_OPTIONS = [
  {
    value: {
      selectValue: LastInteractionDateFilterValue.ALL,
      startDate: null,
      endDate: null,
    },
    label: 'All',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.TODAY,
      startDate: null,
      endDate: null,
    },
    label: 'Today',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.WEEK,
      startDate: null,
      endDate: null,
    },
    label: 'This Week',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.MONTH,
      startDate: null,
      endDate: null,
    },
    label: 'This Month',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.QUARTER,
      startDate: null,
      endDate: null,
    },
    label: 'This Quarter',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.YEAR,
      startDate: null,
      endDate: null,
    },
    label: 'This Year',
  },
  {
    value: {
      selectValue: LastInteractionDateFilterValue.CUSTOM,
      startDate: null,
      endDate: null,
    },
    label: 'Custom',
  },
];

export const RELATIONSHIP_AGE_FILTER_OPTIONS = [
  {
    value: RelationshipAgeFilterValue.ALL,
    label: 'All',
  },
  {
    value: RelationshipAgeFilterValue.LESS_THAN_1_MONTH,
    label: '< 1',
  },
  {
    value: RelationshipAgeFilterValue.BETWEEN_1_3_MONTHS,
    label: '1-3 months',
  },
  {
    value: RelationshipAgeFilterValue.BETWEEN_3_6_MONTHS,
    label: '3-6 months',
  },
  {
    value: RelationshipAgeFilterValue.BETWEEN_6_12_MONTHS,
    label: '6-12 months',
  },
  {
    value: RelationshipAgeFilterValue.BETWEEN_12_24_MONTHS,
    label: '12-24 months',
  },
  {
    value: RelationshipAgeFilterValue.MORE_THAN_24_MONTHS,
    label: '> 24',
  },
];

export const DEAL_STAGE_FILTER_OPTIONS = [
  {
    value: DealStageFilterValue.ALL,
    label: 'All',
  },
  {
    value: DealStageFilterValue.LEAD_GENERATION,
    label: 'Lead Generation',
  },
  {
    value: DealStageFilterValue.INTIAL_CONTACT,
    label: 'Initial Contact',
  },
  {
    value: DealStageFilterValue.QUALIFICATION,
    label: 'Qualification',
  },
  {
    value: DealStageFilterValue.PROPOSAL_SENT,
    label: 'Proposal Sent',
  },
  {
    value: DealStageFilterValue.OPPORTUNITY,
    label: 'Opportunity',
  },
  {
    value: DealStageFilterValue.NEGOTIATIONS,
    label: 'Negotiations',
  },
  {
    value: DealStageFilterValue.VERBAL_COMMITMENT,
    label: 'Verbal Commitment',
  },
  {
    value: DealStageFilterValue.CLOSED_WON,
    label: 'Closed Won',
  },
  {
    value: DealStageFilterValue.CLOSED_LOST,
    label: 'Closed Lost',
  },
];
