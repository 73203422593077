import React from 'react';

export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M15.9974 23.9987C16.7338 23.9987 17.3307 23.4017 17.3307 22.6654V13.332C17.3307 12.5957 16.7338 11.9987 15.9974 11.9987C15.261 11.9987 14.6641 12.5957 14.6641 13.332V22.6654C14.6641 23.4017 15.261 23.9987 15.9974 23.9987Z"
      fill="#FF6325"
    />
    <path
      d="M15.9974 7.33203C16.9179 7.33203 17.6641 8.07822 17.6641 8.9987C17.6641 9.91917 16.9179 10.6654 15.9974 10.6654C15.0769 10.6654 14.3307 9.91917 14.3307 8.9987C14.3307 8.07822 15.0769 7.33203 15.9974 7.33203Z"
      fill="#FF6325"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66406 15.9987C2.66406 23.3625 8.6336 29.332 15.9974 29.332C23.3612 29.332 29.3307 23.3625 29.3307 15.9987C29.3307 8.6349 23.3612 2.66536 15.9974 2.66536C8.6336 2.66536 2.66406 8.6349 2.66406 15.9987ZM15.9974 26.6654C10.1064 26.6654 5.33073 21.8897 5.33073 15.9987C5.33073 10.1077 10.1064 5.33203 15.9974 5.33203C21.8884 5.33203 26.6641 10.1077 26.6641 15.9987C26.6641 21.8897 21.8884 26.6654 15.9974 26.6654Z"
      fill="#FF6325"
    />
  </svg>
);
