import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../../store/store';

export const DealsForRedirect: React.FC = () => {
  const { user, isAccountTeamTabActive } = useSelector(
    (state: RootState) => state.auth
  );

  const history = useHistory();

  useEffect(() => {
    if (user) {
      history.push(`/orgs/${user.orgId}/users/${user.userId}/engagements`);
    }
  }, [user, history, isAccountTeamTabActive]);

  return null;
};
