import { FormFieldType } from '../FormField';

export const getInputMaskOptions = (type: FormFieldType, selectValue = '') => {
  switch (type) {
    case FormFieldType.PHONE: {
      const countryCallingCode = selectValue.split('|')[1] || '';

      return {
        mask: `${
          countryCallingCode
            ? `${countryCallingCode.replace(/0/g, '\\0')} `
            : ''
        }phone`,
        lazy: false,
        blocks: {
          phone: {
            mask: /^\d{0,}$/,
          },
        },
      };
    }
    default: {
      return {};
    }
  }
};
