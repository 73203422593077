import { SellingZoneGraphItem } from '@substratahq/selling-zone/typing/base';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DealsForTable,
  DealsTableRow,
  DetailedAnalysis,
  DetailedAnalysisEmail,
  EmailBranch,
  EngagementsForTable,
  EngagementTableRow,
  RemoveThreadStatus,
  TableDataSortCase,
  TableRowAccount,
} from './deals.types';
import {
  getAccountInfo,
  getDeals,
  getDetailedAnalysis,
  removeThreads,
  renameCurrentDealAccount,
  submitFeedback,
  updateCrmData,
  updateThreadComplete,
} from './deals.thunks';

export interface DealsState {
  deals: DealsForTable | null;
  engagements: EngagementsForTable | null;
  dealsOpenedFirts: boolean;
  dealsNoData: boolean;
  neverAnalyzed: boolean;
  currentDeal: EngagementTableRow | null;
  detailedAnalysis: DetailedAnalysis | null;
  isLoading: {
    deals: boolean;
    deatiledAnalysis: boolean;
  };
  selectedThread?: DealsTableRow | null;
  selectedBranch: EmailBranch | null;
  selectedEmail?: DetailedAnalysisEmail | null;
  graphItems: SellingZoneGraphItem[];
  selectedGraphItem?: SellingZoneGraphItem | null;
  removeThreadStatus?: RemoveThreadStatus;
  requestedPageNumber: number;
  dataSortCase: TableDataSortCase;
  requestedEngsQty: number;
}

export const initialState: DealsState = {
  deals: null,
  engagements: null,
  dealsOpenedFirts: false,
  dealsNoData: false,
  neverAnalyzed: false,
  currentDeal: null,
  detailedAnalysis: null,
  isLoading: {
    deals: false,
    deatiledAnalysis: false,
  },
  selectedThread: null,
  selectedBranch: null,
  selectedEmail: null,
  graphItems: [],
  selectedGraphItem: null,
  removeThreadStatus: null,
  requestedPageNumber: 1,
  dataSortCase: '1-9',
  requestedEngsQty: 10,
};

const updateEngagementsAccountInfo = (
  engagements: EngagementsForTable,
  payload: TableRowAccount
) => {
  let result = engagements;

  if (engagements && engagements?.results?.length > 0 && payload) {
    const updatedEngagements: EngagementsForTable = {
      ...engagements,
      results: engagements.results.map((el) => {
        let elementToReturn = el;

        if (el.account.accountId === payload.accountId) {
          elementToReturn = {
            ...el,
            account: {
              ...payload,
              isUpdated: 'UPDATED',
              accountId: payload.accountId,
              subrowQty: el.subrows?.length,
            },
          };
        }

        return elementToReturn;
      }),
    };

    result = updatedEngagements;
  }

  return result;
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    resetEngagementPageNumber: (state) => {
      state.requestedPageNumber = 1;
    },
    setCurrentDeal: (
      state,
      action: PayloadAction<EngagementTableRow | null>
    ) => {
      state.currentDeal =
        state.engagements?.results.find(
          (engagement) =>
            engagement.engagementId === action.payload?.engagementId
        ) || null;
    },
    resetRemovedThreadStatus: (state) => {
      state.removeThreadStatus = null;
    },
    clearDetailedAnalysis: (state) => {
      state.detailedAnalysis = initialState.detailedAnalysis;
      state.selectedBranch = initialState.selectedBranch;
      state.selectedGraphItem = initialState.selectedGraphItem;
      state.selectedEmail = initialState.selectedEmail;
      state.graphItems = initialState.graphItems;
    },
    setCurrentThread: (state, action: PayloadAction<DealsTableRow>) => {
      state.selectedThread = action.payload;
    },
    setCurrentBranch: (state, action: PayloadAction<EmailBranch>) => {
      state.selectedBranch = action.payload;
    },
    setCurrentEmail: (state, action: PayloadAction<string>) => {
      state.selectedEmail = state.selectedBranch?.emails
        ? state.selectedBranch.emails.find(
            (email) => email?.platformMessageId === action.payload
          )
        : null;
    },
    updateCurrentEmail: (
      state,
      action: PayloadAction<DetailedAnalysisEmail>
    ) => {
      state.selectedEmail = action.payload;
    },
    setGraphItems: (state, action: PayloadAction<SellingZoneGraphItem[]>) => {
      state.graphItems = action.payload;
    },
    setSelectedGraphItem: (state, action: PayloadAction<number>) => {
      state.selectedGraphItem = state?.graphItems?.length
        ? state.graphItems.find((item) => item.id === action.payload)
        : null;
    },
    setDataSortCase: (state, action: PayloadAction<TableDataSortCase>) => {
      state.dataSortCase = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(renameCurrentDealAccount.fulfilled, (state, action) => {
      if (state.engagements?.results?.length) {
        state.engagements.results = state.engagements.results.map(
          (engagement) =>
            action.payload.accountId === engagement.account.accountId
              ? {
                  ...engagement,
                  account: {
                    ...engagement.account,
                    name: action.payload.name,
                  },
                }
              : engagement
        );
      }
    });
    builder.addCase(getAccountInfo.fulfilled, (state, action) => {
      state.engagements = state.engagements
        ? updateEngagementsAccountInfo(state.engagements, action.payload)
        : state.engagements;
    });
    builder.addCase(getDeals.pending, (state) => {
      state.isLoading.deals = true;
    });
    builder.addCase(getDeals.fulfilled, (state, action) => {
      const { engagements, pageNumber } = action.payload;
      state.isLoading.deals = false;
      state.engagements = engagements;
      state.requestedPageNumber = pageNumber;
      state.dealsNoData = !engagements.results.length;
      state.dealsOpenedFirts =
        state.dealsOpenedFirts || state.engagements?.results.length
          ? initialState.dealsOpenedFirts
          : true;
      state.neverAnalyzed =
        typeof engagements.analyzerUsedOnce === 'boolean'
          ? !engagements.analyzerUsedOnce
          : initialState.neverAnalyzed;
    });
    builder.addCase(getDeals.rejected, (state) => {
      state.isLoading.deals = false;
    });
    builder.addCase(removeThreads.pending, (state) => {
      state.isLoading.deals = true;
    });
    builder.addCase(removeThreads.fulfilled, (state) => {
      state.isLoading.deals = false;
      state.removeThreadStatus = 'success';
    });
    builder.addCase(removeThreads.rejected, (state) => {
      state.isLoading.deals = false;
      state.removeThreadStatus = 'error';
    });
    builder.addCase(getDetailedAnalysis.pending, (state) => {
      state.isLoading.deatiledAnalysis = true;
    });
    builder.addCase(getDetailedAnalysis.fulfilled, (state, action) => {
      state.isLoading.deatiledAnalysis = false;
      state.detailedAnalysis = action.payload;
    });
    builder.addCase(getDetailedAnalysis.rejected, (state) => {
      state.isLoading.deatiledAnalysis = false;
    });
    builder.addCase(updateCrmData.fulfilled, (state, action) => {
      if (state.detailedAnalysis) {
        const { dealName, crmStage, dealValue } = state.detailedAnalysis.deal;
        const {
          dealName: newDealName,
          crmStage: newCrmStage,
          dealValue: newDealValue,
        } = action.payload;

        state.detailedAnalysis.deal.dealName = newDealName || dealName;
        state.detailedAnalysis.deal.crmStage = newCrmStage || crmStage;
        state.detailedAnalysis.deal.dealValue = newDealValue || dealValue;
      }
    });
    builder.addCase(updateThreadComplete.fulfilled, (state, action) => {
      state.detailedAnalysis = action.payload;
    });
    builder.addCase(submitFeedback.fulfilled, (state, action) => {
      state.selectedEmail = action.payload;
    });
  },
});

export const { actions: dealsActions, reducer: dealsReducer } = dealsSlice;
