import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { ReactComponent as Triangle } from './icons/triangle_icon.svg';
import { ReactComponent as CircleLines } from './icons/circleLines_icon.svg';
import GetColorAndMessage from '../../pages/Analytics/components/infoBar/getColorAndMessage';
import { PersonalQSkeleton } from './PersonalQSkeleton';
import { ACCOUNT_URL } from '../../constants/config';
import { getQDataForIframe } from '../../store/analytics/analytics.actions';

const PersonalQForIframe: React.FC = (): JSX.Element => {
  const { iframeData, isLoading } = useSelector(
    (state: RootState) => state.analytics
  );

  const dispatch = useDispatch();

  const { orgId, userId } = useParams<{ orgId: string; userId: string }>();

  const [color, setColor] = useState('#F26065');
  const [measure, setMeasure] = useState(NaN);
  const [animationDuration, setAnimationDuration] = useState(NaN);
  const [resultMessage, setResultMessage] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [userName, setUserName] = useState('');

  const accountUrl = ACCOUNT_URL;

  useEffect(() => {
    const fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const toDate = moment().format('YYYY-MM-DD');

    dispatch(getQDataForIframe(orgId, userId, fromDate, toDate));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (iframeData) {
      const photo = iframeData?.userMetadata.avatar;
      const name =
        iframeData?.userMetadata?.name || iframeData?.userMetadata?.email || '';

      setTimeout(() => {
        if (iframeData.value) {
          if (photo?.includes('http')) {
            setUserAvatar(photo);
          } else {
            setUserName(name);
          }
          setMeasure(iframeData.value);
          setAnimationDuration(iframeData.value * 2.5);
        } else {
          if (photo?.includes('http')) {
            setUserAvatar(photo);
          } else {
            setUserName(name);
          }
          setResultMessage(
            'Insufficient data for your \nPersonal Q. \nKeep analyzing emails.'
          );
        }
      }, 100);
    }
  }, [iframeData]);

  const rotateCirkle = !measure ? NaN : measure;

  useEffect(() => {
    if (
      !Number.isNaN(rotateCirkle) &&
      rotateCirkle !== undefined &&
      typeof rotateCirkle === 'number'
    ) {
      const { triangeColor, message } = GetColorAndMessage(
        rotateCirkle,
        'overallCompetence'
      );
      setResultMessage(`${message?.substring(0, 80)} ...`);
      setColor(triangeColor);
    } else {
      setColor('#C4C4C4');
      setAnimationDuration(0.1); // eslint-disable-line
    }
  }, [rotateCirkle]);

  const title = (
    <div className="personalq-iframe__title">Current Overall Competence</div>
  );

  const circle = (
    <div className={!measure ? 'iframe-circle' : 'loaded iframe-circle'}>
      {measure ? (
        <div className="iframe-circle__border" />
      ) : (
        <>
          <div className="iframe-circle__border__no-data" />
          <div className="iframe-circle__border__no-data__white-border" />
        </>
      )}
      <div
        className="iframe-circle__conicGradMeasure"
        style={{
          background: `conic-gradient(transparent ${
            typeof measure === 'number' ? measure * 360 : 0
          }deg, white 0)`,
        }}
      />
      <CircleLines className="iframe-circle__lines" fill="red" />
      <div
        className="iframe-circle__triangleToRotate"
        style={{
          transform: `rotate(${
            typeof measure === 'number' ? measure * 360 : 0
          }deg)`,
          transitionDuration: `${animationDuration}s`,
        }}
      >
        <Triangle
          className="iframe-circle__triangleToRotate-triangle"
          fill={color}
          style={{
            transitionDuration: `${animationDuration}s`,
          }}
        />
      </div>
      <div className="iframe-circle__toRotate" />
      <div className="iframe-circle__photoPlace">
        {userAvatar ? (
          <img
            className="iframe-circle__photoPlace__photo"
            src={userAvatar}
            alt="user"
          />
        ) : (
          <div>
            {userName
              .split(' ')
              .map((el) => el[0])
              .join('')}
          </div>
        )}
      </div>
    </div>
  );

  const personalQMessage = (
    <div
      className="personalq-iframe__message"
      style={!measure ? { whiteSpace: 'pre' } : {}}
    >
      {resultMessage}
      {measure ? (
        <a
          href={`${accountUrl}/orgs/${orgId}/users/${userId}/personalq`}
          className="personalq-iframe__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          read more
        </a>
      ) : null}
    </div>
  );

  return (
    <div className="personalq-iframe">
      <PersonalQSkeleton loading={isLoading}>
        <>
          {title}
          {circle}
          {personalQMessage}
        </>
      </PersonalQSkeleton>
    </div>
  );
};

export default PersonalQForIframe;
