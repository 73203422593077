import React from 'react';
import {
  EventHeader,
  EventHeaderProps,
} from './components/EventHeader/EventHeader';
import { Scrollbar } from '../Scrollbar/Scrollbar';

export interface EventFrameProps {
  headerProps: EventHeaderProps;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

export const EventFrame: React.FC<EventFrameProps> = ({
  headerProps,
  leftContent,
  rightContent,
}) => {
  const attrs = {
    container: {
      className: 'event-frame',
    },
    eventHeader: headerProps,
    contentWrapper: {
      className: 'event-frame__content-wrapper',
    },
    left: {
      className: 'event-frame__left',
    },
    right: {
      className: 'event-frame__right',
    },
    rightScrollbar: {
      className: 'event-frame__right-scrollbar',
    },
  };

  return (
    <div {...attrs.container}>
      <EventHeader {...attrs.eventHeader} />
      <div {...attrs.contentWrapper}>
        <div {...attrs.left}>{leftContent}</div>
        <Scrollbar {...attrs.rightScrollbar}>
          <div {...attrs.right}>{rightContent}</div>
        </Scrollbar>
      </div>
    </div>
  );
};
