import { createSlice } from '@reduxjs/toolkit';
import { getInvoices, getSubscriptionInfo } from './billing.thunks';
import { BillingState } from './billing.types';

export const initialState: BillingState = {
  isLoading: {
    subscription: false,
    invoices: false,
  },
  isError: {
    subscription: false,
    invoices: false,
  },
  subscription: null,
  invoices: [],
  hasInvoicesNextPage: false,
};

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    resetBillingPage: (state) => {
      state.subscription = null;
      state.invoices = [];
      state.hasInvoicesNextPage = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionInfo.pending, (state) => {
      state.isLoading.subscription = true;
      state.isError.subscription = false;
    });
    builder.addCase(getSubscriptionInfo.fulfilled, (state, action) => {
      state.isLoading.subscription = false;
      state.subscription = action.payload;
    });
    builder.addCase(getSubscriptionInfo.rejected, (state, action) => {
      state.isLoading.subscription = false;
      state.isError.subscription = !action.meta.aborted;
    });
    builder.addCase(getInvoices.pending, (state) => {
      state.isLoading.invoices = true;
      state.isError.invoices = false;
    });
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.isLoading.invoices = false;
      state.hasInvoicesNextPage = !!action.payload.length;
      state.invoices = [
        ...state.invoices,
        ...(Array.isArray(action.payload) ? action.payload : []),
      ];
    });
    builder.addCase(getInvoices.rejected, (state, action) => {
      state.isLoading.invoices = false;
      state.isError.invoices = !action.meta.aborted;
    });
  },
});

export const { resetBillingPage } = billingSlice.actions;

export default billingSlice.reducer;
