import React from 'react';

export const ConfirmationIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M22.608 13.1696C23.1292 12.6493 23.1299 11.8051 22.6097 11.2839C22.0894 10.7628 21.2452 10.762 20.724 11.2823L14.1068 17.8878L11.2755 15.0564C10.7548 14.5357 9.91057 14.5357 9.38987 15.0564C8.86917 15.5771 8.86917 16.4214 9.38987 16.9421L13.1632 20.7154C13.6836 21.2358 14.5272 21.2361 15.048 20.7162L22.608 13.1696Z"
      fill="#10B981"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9993 2.66602C8.63555 2.66602 2.66602 8.63555 2.66602 15.9993C2.66602 23.3631 8.63555 29.3327 15.9993 29.3327C23.3631 29.3327 29.3327 23.3631 29.3327 15.9993C29.3327 8.63555 23.3631 2.66602 15.9993 2.66602ZM5.33268 15.9993C5.33268 10.1083 10.1083 5.33268 15.9993 5.33268C21.8904 5.33268 26.666 10.1083 26.666 15.9993C26.666 21.8904 21.8904 26.666 15.9993 26.666C10.1083 26.666 5.33268 21.8904 5.33268 15.9993Z"
      fill="#10B981"
    />
  </svg>
);
