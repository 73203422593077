import React from 'react';

export const SupportIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM9.00006 10.0289C9.00006 8.35225 10.3521 7 12.0289 7C13.7057 7 15.0578 8.35225 15.0578 10.0289C15.0578 11.3594 14.1804 12.0603 13.6219 12.442C13.386 12.605 13.2422 12.7279 13.1515 12.8496C13.079 12.9467 13.0289 13.0573 13.0289 13.2434V13.5C13.0289 14.0523 12.5812 14.5 12.0289 14.5C11.4766 14.5 11.0289 14.0523 11.0289 13.5V13.2434C11.0289 11.8831 11.9272 11.1818 12.4874 10.7949L12.4916 10.792L12.4917 10.792C12.7158 10.6389 12.8545 10.5191 12.9411 10.4022C13.0108 10.3081 13.0578 10.2026 13.0578 10.0289C13.0578 9.45672 12.601 9 12.0289 9C11.4568 9 11.0001 9.45672 11.0001 10.0289C11.0001 10.5812 10.5523 11.0289 10.0001 11.0289C9.44778 11.0289 9.00006 10.5812 9.00006 10.0289ZM11 16.5C11 15.9477 11.4477 15.5 12 15.5H12.009C12.5613 15.5 13.009 15.9477 13.009 16.5C13.009 17.0523 12.5613 17.5 12.009 17.5H12C11.4477 17.5 11 17.0523 11 16.5Z"
      fill="white"
    />
  </svg>
);
