import React, { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Maintenance } from '../Maintenance/Maintenance';
import { getCustomToken } from '../../store/auth/auth.thunks';
import { useFirebase } from '../../context';
import { BROADCAST_CHANNEL_NAME, SIGNUP_LINK } from '../../constants/config';
import { BroadcastChannelMessageType } from '../../types/broadcastChannel.types';
import { UserSignUpFlow } from '../../store/auth/auth.types';

export const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isSignInError, isInitialized, isLoggedIn, signIn } = useFirebase();

  const user = useAppSelector((state) => state.auth.user);
  const org = useAppSelector((state) => state.auth.org);
  const userSignUpFlow = useAppSelector((state) => state.auth.userSignUpFlow);
  const customToken = useAppSelector((state) => state.auth.customToken);
  const isCustomTokenError = useAppSelector(
    (state) => state.auth.isError.customToken
  );
  const isMaintenanceMode = useAppSelector(
    (state) => state.global.isMaintenanceMode
  );

  const history = useHistory();

  const { search } = useLocation();

  const { authCode, showWarningPopup } = useMemo(() => {
    const queryParams = new URLSearchParams(search);

    return {
      authCode: queryParams.get('authCode'),
      showWarningPopup: queryParams.get('show-warning-popup'),
    };
  }, [search]);

  const shouldRedirectToLogout =
    isSignInError ||
    isCustomTokenError ||
    (!authCode && isInitialized && !isLoggedIn);

  // Exchange auth code for custom token
  useEffect(() => {
    if (authCode) {
      dispatch(getCustomToken(authCode));
    }
  }, [authCode, dispatch, search]);

  // Sign in with custom token
  useEffect(() => {
    if (customToken) {
      signIn(customToken);
    }
  }, [customToken, signIn]);

  // Notify other tabs about logout
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

    if (shouldRedirectToLogout) {
      broadcastChannel.postMessage({
        type: BroadcastChannelMessageType.LOGOUT,
      });
    }

    return () => {
      broadcastChannel.close();
    };
  }, [shouldRedirectToLogout]);

  // Handle redirects
  useEffect(() => {
    const hasIdentity = !!user && !!org;

    const isFunctionalityDisabled =
      hasIdentity &&
      user.role !== 'dealmaker' &&
      typeof org?.isFunctionalityEnabled === 'boolean' &&
      !org.isFunctionalityEnabled;

    const isOpenedWithNIExtensionWarn =
      hasIdentity && showWarningPopup === 'true';

    if (shouldRedirectToLogout) {
      window.location.href = `${SIGNUP_LINK}/logout`;
      return;
    }

    if (hasIdentity && userSignUpFlow !== UserSignUpFlow.COMPLETED) {
      window.location.href = `${SIGNUP_LINK}/signup/pricing`;
      return;
    }

    if (isFunctionalityDisabled) {
      history.replace(`/orgs/${user.orgId}/admin-settings/billing`);
      return;
    }

    if (isOpenedWithNIExtensionWarn) {
      history.replace(
        `/orgs/${user.orgId}/users/${user.userId}/relationships?show-warning-popup=true`
      );
      return;
    }

    if (hasIdentity) {
      history.replace(`/orgs/${user.orgId}/users/${user.userId}/relationships`);
    }
  }, [
    dispatch,
    history,
    org,
    search,
    shouldRedirectToLogout,
    showWarningPopup,
    user,
    userSignUpFlow,
  ]);

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return null;
};
