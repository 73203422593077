import { useLocation, useRouteMatch } from 'react-router-dom';
import { PATHS } from '../../../../../constants/paths';
import { useAppSelector } from '../../../../../store/hooks';

export interface Breadcrumb {
  url: string;
  label: string;
  isLoading?: boolean;
}

export const useBreadcrumbs = (): Breadcrumb[] => {
  const selectedBranch = useAppSelector(
    (state) => state.threadDetails.selectedBranch
  );

  const meetingTitle = useAppSelector(
    (state) => state.meetingDetails.data?.title
  );

  const relationshipAccountName = useAppSelector(
    (state) => state.relationshipDetails.account?.name
  );

  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );

  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );

  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const isDetailedAnalysisError = useAppSelector(
    (state) => state.threadDetails.isError.threadDetails
  );

  const isRelationshipAccountLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.account
  );

  const isRelationshipAccountError = useAppSelector(
    (state) => state.relationshipDetails.isError.account
  );

  const location = useLocation();

  const relationshipsMatch = useRouteMatch(PATHS.RELATIONSHIPS);
  const relationshipDetailsMatch = useRouteMatch(PATHS.RELATIONSHIP_DETAILS);
  const threadDetailsMatch = useRouteMatch(PATHS.THREAD_DETAILS);
  const meetingDetailsMatch = useRouteMatch(PATHS.MEETING_DETAILS);
  const meetingRecordsMatch = useRouteMatch(PATHS.MEETING_RECORDS);
  const meetingRecordMatch = useRouteMatch(PATHS.MEETING_RECORD);

  const relationshipsUrl = relationshipsMatch?.url;
  const relationshipDetailsUrl = relationshipDetailsMatch?.url;
  const threadDetailsUrl = threadDetailsMatch?.url;
  const meetingDetailsUrl = meetingDetailsMatch?.url;
  const meetingRecordsUrl = meetingRecordsMatch?.url;
  const meetingRecordUrl = meetingRecordMatch?.url;

  if (relationshipsMatch && !relationshipsMatch.isExact) {
    return [
      ...(relationshipsUrl
        ? [{ url: relationshipsUrl, label: 'My Relationships' }]
        : []),
      ...(relationshipDetailsUrl
        ? [
            {
              url: relationshipDetailsUrl,
              label: relationshipAccountName || '',
              isLoading:
                isRelationshipAccountLoading || isRelationshipAccountError,
            },
          ]
        : []),
      ...((relationshipDetailsUrl && location.hash === '#events') ||
      (relationshipDetailsUrl && location.pathname !== relationshipDetailsUrl)
        ? [{ url: `${relationshipDetailsUrl}#events`, label: 'Events' }]
        : []),
      ...(threadDetailsUrl
        ? [
            {
              url: threadDetailsUrl,
              label: selectedBranch?.subject || '',
              isLoading: isDetailedAnalysisLoading || isDetailedAnalysisError,
            },
          ]
        : []),
      ...(meetingDetailsUrl
        ? [
            {
              url: meetingDetailsUrl,
              label: meetingTitle || 'No Meeting Title',
              isLoading: isMeetingDetailsLoading || isMeetingDetailsError,
            },
          ]
        : []),
    ];
  }

  if (meetingRecordsMatch && !meetingRecordsMatch.isExact) {
    return [
      ...(meetingRecordsUrl
        ? [{ url: meetingRecordsUrl, label: 'My Meetings' }]
        : []),
      ...(meetingRecordUrl
        ? [
            {
              url: meetingRecordUrl,
              label: meetingTitle || 'No Meeting Title',
              isLoading: isMeetingDetailsLoading || isMeetingDetailsError,
            },
          ]
        : []),
    ];
  }

  return [];
};
