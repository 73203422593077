import moment from 'moment';
import {
  Engagement,
  EngagementTableRow,
  EngagementsResponse,
  EngagementsForTable,
  EngagementBranch,
  EngagementSubrow,
  TableDataSortCase,
} from './deals.types';

type SortValueToReturnType = {
  sortBy: 'lastInteractionDate' | 'accountName';
  order: 1 | -1;
};

const createEngagementSubrows = (
  branches: EngagementBranch[],
  engagementId: string
): EngagementSubrow[] | null => {
  let result: EngagementSubrow[] | null = null;
  if (branches?.length) {
    result = branches.map((el, i) => ({
      key: el?.branchId,
      platformThreadId: el?.platformThreadId,
      branchId: el?.branchId,
      lastIterationDate: el?.lastIterationDate.length
        ? moment(el.lastIterationDate).fromNow()
        : null,
      contacts: el?.contacts,
      engagementId,
      subject:
        el?.subject?.length && el.subject.includes('Re:')
          ? el.subject.split(':')[1]
          : el.subject,
      isLastSubrow: branches.length === i + 1,
    }));
  }

  return result;
};

const addFieldsToEngagement = (engagement: Engagement): EngagementTableRow => ({
  ...engagement,
  account: {
    ...engagement.account,
    accountId: `${engagement?.account?.accountId}`,
    subrowQty: engagement?.branches?.length,
  },
  key: engagement.engagementId,
  branchId: engagement?.branches[0]?.branchId,
  lastIterationDate:
    moment(engagement?.branches[0]?.lastIterationDate).fromNow() || null,
  subject:
    engagement?.branches[0]?.subject?.length &&
    engagement.branches[0]?.subject.includes('Re:')
      ? engagement.branches[0]?.subject.split(':')[1]
      : engagement.branches[0]?.subject,
  rowToSelect: {
    ...engagement,
    key: engagement.engagementId,
    lastIterationDate: engagement?.branches?.length
      ? moment(engagement.branches[0]?.lastIterationDate).fromNow()
      : null,
    subject:
      engagement?.branches?.length &&
      engagement?.branches[0]?.subject?.length &&
      engagement.branches[0]?.subject.includes('Re:')
        ? engagement.branches[0]?.subject.split(':')[1]
        : engagement.branches[0]?.subject,
  },
  options: engagement,
  subrows: createEngagementSubrows(
    engagement.branches,
    engagement.engagementId
  ),
});

export const convertEngagementsForList = (
  engagements: EngagementsResponse
): EngagementsForTable => ({
  ...engagements,
  results: engagements.results.map((el) => addFieldsToEngagement(el)),
});

export const defineSortValuesForRequest = (
  currentCase: TableDataSortCase
): SortValueToReturnType => {
  let sortBy: 'lastInteractionDate' | 'accountName' = 'lastInteractionDate';

  let order: 1 | -1 = -1;

  if (currentCase === '9-1') {
    order = 1;
  } else if (currentCase === 'a-z') {
    sortBy = 'accountName';
    order = 1;
  } else if (currentCase === 'z-a') {
    sortBy = 'accountName';
    order = -1;
  }

  return { sortBy, order };
};

export const mockDataForTable: EngagementsResponse = {
  count: 2,
  ipp: 10,
  page: 1,
  analyzerUsedOnce: false,
  results: [
    {
      engagementId: 'example-id2',
      domain: null,
      account: {
        accountId: 'drthdrtyh',
        name: 'Acme',
        domain: 'example.com',
        metadata: {
          headquarters: 'California, CA, USA',
          category: 'Computer Equipment …',
          stockSymbol: 'A',
          employees: '154000',
          name: 'Acme',
          domain: 'example.com',
          foundedYear: '1996',
          additionalData: {
            socialMedia: [
              {
                type: 'facebook',
                url: 'google',
              },
              {
                type: 'linkedin',
                url: 'company/google',
              },
              {
                type: 'twitter',
                url: 'Google',
              },
            ],
            description: 'Global technology …',
            estimatedAnnualRevenue: '$378.3B',
            totalFunding: null,
          },
          avatarSrc: 'https://logo.clearbit.com/google.com',
        },
      },
      participants: [
        {
          contactId: 'vhmj56m',
          orgId: 'v645hjm',
          userId: '645vjm',
          isUser: false,
          email: 'arthur@example.com',
          metadata: {
            name: 'Arthur',
            avatarSrc: 'https://lh3.googleusercontent.com/a/default-user=s40-p',
            details: {
              companyName: null,
              phone: null,
            },
          },
        },
        {
          contactId: 'gmfvj',
          orgId: 'mfhjm',
          userId: 'mvhgjm',
          isUser: false,
          email: 'eric@example.com',
          metadata: {
            name: 'Eric',
            avatarSrc: 'https://lh3.googleusercontent.com/a/default-user=s40-p',
            details: {
              companyName: null,
              phone: null,
            },
          },
        },
        {
          contactId: '6574674',
          orgId: '7856876',
          userId: '56876578i7',
          isUser: false,
          email: 'christian@example.com',
          metadata: {
            name: 'Christian',
            avatarSrc: 'https://lh3.googleusercontent.com/a/default-user=s40-p',
            details: {
              companyName: null,
              phone: null,
            },
          },
        },
      ],
      branches: [
        {
          branchId: '12345',
          platformThreadId: '56745674567',
          subject: 'Support Questions',
          lastIterationDate: '2023-11-02T11:35:42+00:00',
          contacts: [
            {
              contactId: '54635567567',
              orgId: '7346567',
              userId: '45674567',
              email: 'susanna@example.com',
              metadata: {
                name: 'Susanna',
                avatarSrc:
                  'https://lh3.googleusercontent.com/a/default-user=s40-p',
                details: {
                  companyName: null,
                  phone: null,
                },
              },
              isUser: false,
            },
            {
              contactId: '7654321465',
              orgId: '643214',
              userId: '7654321',
              email: 'me@example.com',
              metadata: {
                name: 'Me',
                avatarSrc: '',
                details: {
                  companyName: null,
                  phone: null,
                },
              },
              isUser: true,
            },
          ],
        },
      ],
    },
    {
      engagementId: 'example-id',
      domain: null,
      account: {
        accountId: 'bd14df0c507608e0405abe61ecb68b35',
        name: 'susanna@yahoo.com',
        domain: 'susanna@yahoo.com',
        metadata: {
          headquarters: 'United States',
          category: 'Local Services',
          stockSymbol: 'S',
          employees: '10',
          name: 'Susanna@yahoo.com',
          domain: 'exampledomain.com',
          foundedYear: '2022',
          additionalData: {
            socialMedia: [
              {
                type: 'facebook',
                url: 'google',
              },
              {
                type: 'linkedin',
                url: 'company/google',
              },
              {
                type: 'twitter',
                url: 'Google',
              },
            ],
            description: null,
            estimatedAnnualRevenue: '$1000',
            totalFunding: null,
          },
          avatarSrc: '',
        },
      },
      participants: [
        {
          contactId: 'ad1978c0014009b9e254c9ae43d9511d',
          orgId: '6a7be2a80bd64141972f5471e316e94a',
          userId: '4129c44349ef4806af1deb4207adbff1',
          isUser: false,
          email: 'susanna@example.com',
          metadata: {
            name: 'Susanna',
            avatarSrc: 'https://lh3.googleusercontent.com/a/default-user=s40-p',
            details: {
              companyName: null,
              phone: null,
            },
          },
        },
        {
          contactId: 'ad1978c0014009b9e254c9ae43d9511d',
          orgId: '6a7be2a80bd64141972f5471e316e94a',
          userId: '4129c44349ef4806af1deb4207adbff1',
          isUser: true,
          email: 'me@example.com',
          metadata: {
            name: 'Me',
            avatarSrc: '',
            details: {
              companyName: null,
              phone: null,
            },
          },
        },
      ],
      branches: [
        {
          branchId: '678910',
          platformThreadId: '18b80365f8b5cde7',
          subject: 'Lorem ipsum dolor sit amet, consectetur',
          lastIterationDate: '2023-11-02T11:35:42+00:00',
          contacts: [
            {
              contactId: '608c2087b286cc28a00a5e6c18d9f9c6',
              orgId: '6a7be2a80bd64141972f5471e316e94a',
              userId: '4129c44349ef4806af1deb4207adbff1',
              email: 'susanna@example.com',
              metadata: {
                name: 'Susanna',
                avatarSrc:
                  'https://lh3.googleusercontent.com/a/default-user=s40-p',
                details: {
                  companyName: null,
                  phone: null,
                },
              },
              isUser: false,
            },
            {
              contactId: 'ad1978c0014009b9e254c9ae43d9511d',
              orgId: '6a7be2a80bd64141972f5471e316e94a',
              userId: '4129c44349ef4806af1deb4207adbff1',
              email: 'me@example.com',
              metadata: {
                name: 'me',
                avatarSrc: '',
                details: {
                  companyName: null,
                  phone: null,
                },
              },
              isUser: true,
            },
          ],
        },
      ],
    },
  ],
};
