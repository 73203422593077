import React from 'react';

export const PerformanceQIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    isActive?: boolean;
  }
> = ({ isActive = false, ...rest }) =>
  isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.34315 4.34315 2 6 2H8C9.65685 2 11 3.34315 11 5V20H13V10C13 8.34315 14.3431 7 16 7H18C19.6569 7 21 8.34315 21 10V20C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20V5Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5V20H13V10C13 8.34314 14.3431 7 16 7H18C19.6569 7 21 8.34315 21 10V20C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H19H15H13H11H9H5H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20V5C3 3.34315 4.34315 2 6 2H8C9.65685 2 11 3.34315 11 5ZM5 20V18V5C5 4.44772 5.44772 4 6 4H8C8.55228 4 9 4.44772 9 5V18V20H7H5ZM19 20V18V10C19 9.44772 18.5523 9 18 9H16C15.4477 9 15 9.44771 15 10V18V20H17H19Z"
        fill="white"
      />
    </svg>
  );
