import { Dispatch } from 'redux';
import axios from '../../axios';
import {
  SetTeamUh,
  SetTeamUhErr,
  SetTeamUhLoading,
  SetTeamUhNoData,
  SetTeamETendencies,
  SetTeamETendenciesErr,
  SetTeamETendenciesLoading,
  SetTeamETendenciesNoData,
  SetTeamSellingZone,
  SetTeamSellingZoneErr,
  SetTeamSellingZoneLoading,
  SetTeamSellingZoneNoData,
  SetTeamActivity,
  SetTeamActivityLoading,
  SetTeamActivityNoData,
  SetTeamActivityErr,
  SetTeamQThreadDuration,
  SetTeamQThreadDurationLoading,
  SetTeamQThreadDurationNoData,
  SetTeamQThreadDurationErr,
  SetTeamQThreadLength,
  SetTeamQThreadLengthLoading,
  SetTeamQThreadLengthNoData,
  SetTeamQThreadLengthErr,
  SetTeamQAnalyzed,
  SetTeamQAnalyzedLoading,
  SetTeamQAnalyzedNoData,
  SetTeamQAnalyzedErr,
  SetTeamQSimulated,
  SetTeamQSimulatedLoading,
  SetTeamQSimulatedNoData,
  SetTeamQSimulatedErr,
  SetTeamQUpperHand,
  SetTeamQUpperHandLoading,
  SetTeamQUpperHandNoData,
  SetTeamQUpperHandErr,
  SetTeamQAncsSellZone,
  SetTeamQAncsSellZoneLoading,
  SetTeamQAncsSellZoneNoData,
  SetTeamQAncsSellZoneErr,
  SetTeamQCompetence,
  SetTeamQCompetenceLoading,
  SetTeamQCompetenceNoData,
  SetTeamQCompetenceErr,
  SetTeamQEmpathy,
  SetTeamQEmpathyLoading,
  SetTeamQEmpathyNoData,
  SetTeamQEmpathyErr,
  SetTeamQResponsiveness,
  SetTeamQResponsivenessLoading,
  SetTeamQResponsivenessNoData,
  SetTeamQResponsivenessErr,
  TeamQRequestBody,
  TeamQRequestType,
  SetTeamQInitialRequest,
} from './teamQ.types';
import { getUserLocalTimezone } from './teamQ.utils';

export const SET_REQUESTED_TIME_PERIOD_IN_DAYS =
  'SET_REQUESTED_TIME_PERIOD_IN_DAYS';

export const SET_REQUESTED_TEAM_ID = 'SET_REQUESTED_TEAM_ID';

export const SET_TEAM_Q_INIT_REQUEST_SENT = 'SET_TEAM_Q_INIT_REQUEST_SENT';

export const SET_TEAM_Q_ACTIVITY = 'SET_TEAM_Q_ACTIVITY';
export const SET_TEAM_Q_ACTIVITY_LOADING = 'SET_TEAM_Q_ACTIVITY_LOADING';
export const SET_TEAM_Q_ACTIVITY_ERR = 'SET_TEAM_Q_ACTIVITY_ERR';
export const SET_TEAM_Q_ACTIVITY_NO_DATA = 'SET_TEAM_Q_ACTIVITY_NO_DATA';

export const SET_TEAM_Q_UH = 'SET_TEAM_Q_UH';
export const SET_TEAM_Q_UH_LOADING = 'SET_TEAM_Q_UH_LOADING';
export const SET_TEAM_Q_UH_ERR = 'SET_TEAM_Q_UH_ERR';
export const SET_TEAM_Q_UH_NO_DATA = 'SET_TEAM_Q_UH_NO_DATA';

export const SET_TEAM_Q_ESTIMATED_TEND = 'SET_ESTIMATED_TEND';
export const SET_TEAM_Q_ESTIMATED_TEND_LOADING = 'SET_ESTIMATED_TEND_LOADING';
export const SET_TEAM_Q_ESTIMATED_TEND_ERR = 'SET_ESTIMATED_TEND_ERR';
export const SET_TEAM_Q_ESTIMATED_TEND_NO_DATA = 'SET_ESTIMATED_TEND_NO_DATA';

export const SET_TEAM_Q_SELL_ZONE = 'SET_TEAM_Q_SELL_ZONE';
export const SET_TEAM_Q_SELL_ZONE_LOADING = 'SET_TEAM_Q_SELL_ZONE_LOADING';
export const SET_TEAM_Q_SELL_ZONE_ERR = 'SET_TEAM_Q_SELL_ZONE_ERR';
export const SET_TEAM_Q_SELL_ZONE_NO_DATA = 'SET_TEAM_Q_SELL_ZONE_NO_DATA';

export const CLEAR_TEAM_Q_CHARTS_ERRORS = 'CLEAR_TEAM_Q_CHARTS_ERRORS';
export const CLEAR_TEAM_Q_STATE = 'CLEAR_TEAM_Q_STATE';

export const SET_TEAM_Q_THREAD_DURATION = 'SET_TEAM_Q_THREAD_DURATION';
export const SET_TEAM_Q_THREAD_DURATION_LOADING =
  'SET_TEAM_Q_THREAD_DURATION_LOADING';
export const SET_TEAM_Q_THREAD_DURATION_ERR = 'SET_TEAM_Q_THREAD_DURATION_ERR';
export const SET_TEAM_Q_THREAD_DURATION_NO_DATA =
  'SET_TEAM_Q_THREAD_DURATION_NO_DATA';

export const SET_TEAM_Q_THREAD_LENGTH = 'SET_TEAM_Q_THREAD_LENGTH';
export const SET_TEAM_Q_THREAD_LENGTH_LOADING =
  'SET_TEAM_Q_THREAD_LENGTH_LOADING';
export const SET_TEAM_Q_THREAD_LENGTH_ERR = 'SET_TEAM_Q_THREAD_LENGTH_ERR';
export const SET_TEAM_Q_THREAD_LENGTH_NO_DATA =
  'SET_TEAM_Q_THREAD_LENGTH_NO_DATA';

export const SET_TEAM_Q_ANALYZED = 'SET_TEAM_Q_ANALYZED';
export const SET_TEAM_Q_ANALYZED_LOADING = 'SET_TEAM_Q_ANALYZED_LOADING';
export const SET_TEAM_Q_ANALYZED_ERR = 'SET_TEAM_Q_ANALYZED_ERR';
export const SET_TEAM_Q_ANALYZED_NO_DATA = 'SET_TEAM_Q_ANALYZED_NO_DATA';

export const SET_TEAM_Q_SIMULATED = 'SET_TEAM_Q_SIMULATED';
export const SET_TEAM_Q_SIMULATED_LOADING = 'SET_TEAM_Q_SIMULATED_LOADING';
export const SET_TEAM_Q_SIMULATED_ERR = 'SET_TEAM_Q_SIMULATED_ERR';
export const SET_TEAM_Q_SIMULATED_NO_DATA = 'SET_TEAM_Q_SIMULATED_NO_DATA';

export const SET_TEAM_Q_A_UH = 'SET_TEAM_Q_A_UH';
export const SET_TEAM_Q_A_UH_LOADING = 'SET_TEAM_Q_A_UH_LOADING';
export const SET_TEAM_Q_A_UH_ERR = 'SET_TEAM_Q_A_UH_ERR';
export const SET_TEAM_Q_A_UH_NO_DATA = 'SET_TEAM_Q_A_UH_NO_DATA';

export const SET_TEAM_Q_A_SELL_ZONE = 'SET_TEAM_Q_A_SELL_ZONE';
export const SET_TEAM_Q_A_SELL_ZONE_LOADING = 'SET_TEAM_Q_A_SELL_ZONE_LOADING';
export const SET_TEAM_Q_A_SELL_ZONE_ERR = 'SET_TEAM_Q_A_SELL_ZONE_ERR';
export const SET_TEAM_Q_A_SELL_ZONE_NO_DATA = 'SET_TEAM_Q_A_SELL_ZONE_NO_DATA';

export const SET_TEAM_Q_A_COMPETENCE = 'SET_TEAM_Q_A_COMPETENCE';
export const SET_TEAM_Q_A_COMPETENCE_LOADING =
  'SET_TEAM_Q_A_COMPETENCE_LOADING';
export const SET_TEAM_Q_A_COMPETENCE_ERR = 'SET_TEAM_Q_A_COMPETENCE_ERR';
export const SET_TEAM_Q_A_COMPETENCE_NO_DATA =
  'SET_TEAM_Q_A_COMPETENCE_NO_DATA';

export const SET_TEAM_Q_A_EMPATHY = 'SET_TEAM_Q_A_EMPATHY';
export const SET_TEAM_Q_A_EMPATHY_LOADING = 'SET_TEAM_Q_A_EMPATHY_LOADING';
export const SET_TEAM_Q_A_EMPATHY_ERR = 'SET_TEAM_Q_A_EMPATHY_ERR';
export const SET_TEAM_Q_A_EMPATHY_NO_DATA = 'SET_TEAM_Q_A_EMPATHY_NO_DATA';

export const SET_TEAM_Q_A_RESPONSIV = 'SET_TEAM_Q_A_RESPONSIV';
export const SET_TEAM_Q_A_RESPONSIV_LOADING = 'SET_TEAM_Q_A_RESPONSIV_LOADING';
export const SET_TEAM_Q_A_RESPONSIV_ERR = 'SET_TEAM_Q_A_RESPONSIV_ERR';
export const SET_TEAM_Q_A_RESPONSIV_NO_DATA = 'SET_TEAM_Q_A_RESPONSIV_NO_DATA';

export const initialRequestWasSent = (): SetTeamQInitialRequest => ({
  type: SET_TEAM_Q_INIT_REQUEST_SENT,
});

export const getTeamQActivity = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,
  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamActivity
    | SetTeamActivityLoading
    | SetTeamActivityNoData
    | SetTeamActivityErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_ACTIVITY_LOADING, payload: true });

    const activityResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/unified-activity?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (activityResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_ACTIVITY,
        payload: activityResponse.data.payload.data,
      });

      const dataAvailabilityCheck = Object.values(
        activityResponse.data.payload.data
      ).some((el) => (el as number) > 0);

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_ACTIVITY_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_ACTIVITY_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_ACTIVITY_ERR });
    dispatch({ type: SET_TEAM_Q_ACTIVITY_LOADING, payload: false });
  }
};

export const getTeamQUpperHand = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    SetTeamUh | SetTeamUhLoading | SetTeamUhErr | SetTeamUhNoData
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_UH_LOADING, payload: true });

    const uhPossessionsResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/uh-possessions?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (uhPossessionsResponse?.data?.payload?.data?.currentPeriod) {
      dispatch({
        type: SET_TEAM_Q_UH,
        payload: uhPossessionsResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        uhPossessionsResponse.data.payload.data?.currentPeriodDailyStats
          ?.length &&
        uhPossessionsResponse.data.payload.data?.currentPeriodDailyStats.some(
          (el: any) => el?.avgWinLoseScore > 0 // eslint-disable-line
        );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_UH_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_UH_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_UH_ERR });
    dispatch({ type: SET_TEAM_Q_UH_LOADING, payload: false });
  }
};

export const getTeamQETendencies = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamETendencies
    | SetTeamETendenciesLoading
    | SetTeamETendenciesNoData
    | SetTeamETendenciesErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_LOADING, payload: true });

    const tendenciesResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/estimated-tendencies?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (tendenciesResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_ESTIMATED_TEND,
        payload: tendenciesResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        typeof tendenciesResponse.data.payload?.data?.competence === 'number' ||
        typeof tendenciesResponse.data.payload?.data?.empathy === 'number' ||
        typeof tendenciesResponse.data.payload?.data?.responsiveness ===
          'number';

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_ERR });
    dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_LOADING, payload: false });
  }
};

export const getTeamQSellingZone = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamSellingZone
    | SetTeamSellingZoneLoading
    | SetTeamSellingZoneNoData
    | SetTeamSellingZoneErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_SELL_ZONE_LOADING, payload: true });
    const sellZoneResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/selling-zone-stats?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (sellZoneResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_SELL_ZONE,
        payload: sellZoneResponse.data.payload,
      });

      const {
        belowSzEmailsCount,
        inSzEmailsCount,
        aboveSzEmailsCount,
      } = sellZoneResponse.data.payload.data;

      const dataAvailabilityCheck =
        belowSzEmailsCount > 0 || inSzEmailsCount > 0 || aboveSzEmailsCount > 0;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_SELL_ZONE_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_SELL_ZONE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_SELL_ZONE_ERR });
    dispatch({ type: SET_TEAM_Q_SELL_ZONE_LOADING, payload: false });
  }
};

export const getTeamQOverviewData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  teamId: string,
  requestedPeriodInDays: number,
  clearState?: boolean // eslint-disable-next-line
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const timezone = getUserLocalTimezone(true);

  const requestBody =
    teamId === 'allUsers'
      ? {
          type: 'allUsers' as TeamQRequestType,
        }
      : {
          type: 'team' as TeamQRequestType,
          teamId,
        };

  if (clearState) {
    dispatch({ type: CLEAR_TEAM_Q_STATE });
  }

  dispatch({
    type: SET_REQUESTED_TEAM_ID,
    payload: teamId,
  });

  dispatch({
    type: SET_REQUESTED_TIME_PERIOD_IN_DAYS,
    payload: requestedPeriodInDays,
  });

  dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_LOADING, payload: true });
  dispatch({ type: SET_TEAM_Q_UH_LOADING, payload: true });
  dispatch({ type: SET_TEAM_Q_SELL_ZONE_LOADING, payload: true });

  setTimeout(() => {
    dispatch(
      getTeamQActivity(orgId, userId, fromDate, toDate, timezone, requestBody)
    );
    dispatch(
      getTeamQUpperHand(orgId, userId, fromDate, toDate, timezone, requestBody)
    );
    dispatch(
      getTeamQETendencies(
        orgId,
        userId,
        fromDate,
        toDate,
        timezone,
        requestBody
      )
    );
    dispatch(
      getTeamQSellingZone(
        orgId,
        userId,
        fromDate,
        toDate,
        timezone,
        requestBody
      )
    );
  }, 600);
};

export const getTeamQThreadDuration = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQThreadDuration
    | SetTeamQThreadDurationLoading
    | SetTeamQThreadDurationNoData
    | SetTeamQThreadDurationErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_THREAD_DURATION_LOADING, payload: true });

    const threadDurationResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-thread-duration-weeks?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (threadDurationResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_THREAD_DURATION,
        payload: threadDurationResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        threadDurationResponse.data.payload.data.statsPerUser.some(
          (el: any) => el.avgThreadDurationWeeks > 0 // eslint-disable-line
        ) && threadDurationResponse.data.payload.data.mean;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_THREAD_DURATION_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_THREAD_DURATION_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_THREAD_DURATION_ERR });
    dispatch({ type: SET_TEAM_Q_THREAD_DURATION_LOADING, payload: false });
  }
};

export const getTeamQThreadLength = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQThreadLength
    | SetTeamQThreadLengthLoading
    | SetTeamQThreadLengthNoData
    | SetTeamQThreadLengthErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_THREAD_LENGTH_LOADING, payload: true });

    const threadLengthResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-messages-per-thread?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (threadLengthResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_THREAD_LENGTH,
        payload: threadLengthResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        threadLengthResponse.data.payload.data.statsPerUser.some(
          (el: any) => el.avgMessagesPerThread > 0 // eslint-disable-line
        ) && threadLengthResponse.data.payload.data.mean;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_THREAD_LENGTH_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_THREAD_LENGTH_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_THREAD_LENGTH_ERR });
    dispatch({ type: SET_TEAM_Q_THREAD_LENGTH_LOADING, payload: false });
  }
};

export const getTeamQAnalyzed = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQAnalyzed
    | SetTeamQAnalyzedLoading
    | SetTeamQAnalyzedNoData
    | SetTeamQAnalyzedErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_ANALYZED_LOADING, payload: true });

    const analyzedResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-total-analyzed?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (analyzedResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_ANALYZED,
        payload: analyzedResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        analyzedResponse.data.payload.data.statsPerUser.some(
          (el: any) => el.emailsAnalyzed > 0 // eslint-disable-line
        ) && analyzedResponse.data.payload.data.mean;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_ANALYZED_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_ANALYZED_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_ANALYZED_ERR });
    dispatch({ type: SET_TEAM_Q_ANALYZED_LOADING, payload: false });
  }
};

export const getTeamQSimulated = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQSimulated
    | SetTeamQSimulatedLoading
    | SetTeamQSimulatedNoData
    | SetTeamQSimulatedErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_SIMULATED_LOADING, payload: true });

    const simulatedResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-total-simulated?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (simulatedResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_SIMULATED,
        payload: simulatedResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        simulatedResponse.data.payload.data.statsPerUser.some(
          (el: any) => el.emailsSimulated > 0 // eslint-disable-line
        ) && simulatedResponse.data.payload.data.mean;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_SIMULATED_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_SIMULATED_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_SIMULATED_ERR });
    dispatch({ type: SET_TEAM_Q_SIMULATED_LOADING, payload: false });
  }
};

export const getTeamQActivityData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  teamId: string,
  requestedPeriodInDays: number,
  clearState?: boolean // eslint-disable-next-line
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const timezone = getUserLocalTimezone(true);

  const requestBody =
    teamId === 'allUsers'
      ? {
          type: 'allUsers' as TeamQRequestType,
        }
      : {
          type: 'team' as TeamQRequestType,
          teamId,
        };

  if (clearState) {
    dispatch({ type: CLEAR_TEAM_Q_STATE });
  }

  dispatch({
    type: SET_REQUESTED_TEAM_ID,
    payload: teamId,
  });

  dispatch({
    type: SET_REQUESTED_TIME_PERIOD_IN_DAYS,
    payload: requestedPeriodInDays,
  });

  dispatch(
    getTeamQThreadDuration(
      orgId,
      userId,
      fromDate,
      toDate,
      timezone,
      requestBody
    )
  );
  dispatch(
    getTeamQThreadLength(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQAnalyzed(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQSimulated(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
};

export const getTeamQAUpperHand = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQUpperHand
    | SetTeamQUpperHandLoading
    | SetTeamQUpperHandNoData
    | SetTeamQUpperHandErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_A_UH_LOADING, payload: true });

    const uhResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-uh-ratio?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (uhResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_A_UH,
        payload: uhResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        uhResponse.data.payload.data.statsPerUser.some(
          (el: any) => el.uhRatio > 0 // eslint-disable-line
        ) && uhResponse.data.payload.data.mean;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_A_UH_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_A_UH_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_A_UH_ERR });
    dispatch({ type: SET_TEAM_Q_A_UH_LOADING, payload: false });
  }
};

export const getTeamQASellZone = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQAncsSellZone
    | SetTeamQAncsSellZoneLoading
    | SetTeamQAncsSellZoneNoData
    | SetTeamQAncsSellZoneErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_A_SELL_ZONE_LOADING, payload: true });

    const threadLengthResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-selling-zone-position?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (threadLengthResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_A_SELL_ZONE,
        payload: threadLengthResponse.data.payload.data,
      });

      const dataAvailabilityCheck = threadLengthResponse.data.payload.data.statsPerUser.some(
        (
          el: any // eslint-disable-line
        ) =>
          el.aboveSzEmailsRate > 0 ||
          el.belowSzEmailsRate > 0 ||
          el.inSzEmailsRate > 0
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_A_SELL_ZONE_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_A_SELL_ZONE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_A_SELL_ZONE_ERR });
    dispatch({ type: SET_TEAM_Q_A_SELL_ZONE_LOADING, payload: false });
  }
};

export const getTeamQCompetence = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQCompetence
    | SetTeamQCompetenceLoading
    | SetTeamQCompetenceNoData
    | SetTeamQCompetenceErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_A_COMPETENCE_LOADING, payload: true });

    const competenceResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-competence?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (competenceResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_A_COMPETENCE,
        payload: competenceResponse.data.payload.data,
      });

      const dataAvailabilityCheck = competenceResponse.data.payload.data.statsPerUser.some(
        (el: any) => el.competence > 0 // eslint-disable-line
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_A_COMPETENCE_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_A_COMPETENCE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_A_COMPETENCE_ERR });
    dispatch({ type: SET_TEAM_Q_A_COMPETENCE_LOADING, payload: false });
  }
};

export const getTeamQEmpathy = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,
  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQEmpathy
    | SetTeamQEmpathyLoading
    | SetTeamQEmpathyNoData
    | SetTeamQEmpathyErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_A_EMPATHY_LOADING, payload: true });

    const empathyResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-empathy?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (empathyResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_A_EMPATHY,
        payload: empathyResponse.data.payload.data,
      });

      const dataAvailabilityCheck = empathyResponse.data.payload.data.statsPerUser.some(
        (el: any) => el.label > 0 || el.position > 0 // eslint-disable-line
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_A_EMPATHY_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_A_EMPATHY_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_A_EMPATHY_ERR });
    dispatch({ type: SET_TEAM_Q_A_EMPATHY_LOADING, payload: false });
  }
};

export const getTeamQResponsiveness = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string,

  requestBody: Partial<TeamQRequestBody>
) => async (
  dispatch: Dispatch<
    | SetTeamQResponsiveness
    | SetTeamQResponsivenessLoading
    | SetTeamQResponsivenessNoData
    | SetTeamQResponsivenessErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAM_Q_A_RESPONSIV_LOADING, payload: true });

    const responsivenessResponse = await axios.post(
      `services/orgs/${orgId}/users/${userId}/stats/unified/detailed-responsiveness?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`,
      requestBody
    );

    if (responsivenessResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TEAM_Q_A_RESPONSIV,
        payload: responsivenessResponse.data.payload.data,
      });

      const dataAvailabilityCheck = responsivenessResponse.data.payload.data.statsPerUser.some(
        (el: any) => el.label > 0 || el.position > 0 // eslint-disable-line
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TEAM_Q_A_RESPONSIV_NO_DATA });
      }
    }

    dispatch({ type: SET_TEAM_Q_A_RESPONSIV_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TEAM_Q_A_RESPONSIV_ERR });
    dispatch({ type: SET_TEAM_Q_A_RESPONSIV_LOADING, payload: false });
  }
};

export const getTeamQAnalyticsData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  teamId: string,
  requestedPeriodInDays: number,
  clearState?: boolean // eslint-disable-next-line
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const timezone = getUserLocalTimezone(true);

  const requestBody =
    teamId === 'allUsers'
      ? {
          type: 'allUsers' as TeamQRequestType,
        }
      : {
          type: 'team' as TeamQRequestType,
          teamId,
        };

  if (clearState) {
    dispatch({ type: CLEAR_TEAM_Q_STATE });
  }

  dispatch({
    type: SET_REQUESTED_TEAM_ID,
    payload: teamId,
  });

  dispatch({
    type: SET_REQUESTED_TIME_PERIOD_IN_DAYS,
    payload: requestedPeriodInDays,
  });

  dispatch(
    getTeamQAUpperHand(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQASellZone(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQCompetence(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQEmpathy(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQResponsiveness(
      orgId,
      userId,
      fromDate,
      toDate,
      timezone,
      requestBody
    )
  );
};

export const getAllTeamQData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  teamId: string,
  requestedPeriodInDays: number // eslint-disable-next-line
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const timezone = getUserLocalTimezone(true);

  const requestBody =
    teamId === 'allUsers'
      ? {
          type: 'allUsers' as TeamQRequestType,
        }
      : {
          type: 'team' as TeamQRequestType,
          teamId,
        };

  dispatch({ type: CLEAR_TEAM_Q_STATE });

  dispatch({
    type: SET_REQUESTED_TIME_PERIOD_IN_DAYS,
    payload: requestedPeriodInDays,
  });

  dispatch({ type: SET_TEAM_Q_ACTIVITY_LOADING, payload: true });
  dispatch({ type: SET_TEAM_Q_ESTIMATED_TEND_LOADING, payload: true });
  dispatch({ type: SET_TEAM_Q_UH_LOADING, payload: true });
  dispatch({ type: SET_TEAM_Q_SELL_ZONE_LOADING, payload: true });

  setTimeout(() => {
    dispatch(
      getTeamQActivity(orgId, userId, fromDate, toDate, timezone, requestBody)
    );
    dispatch(
      getTeamQUpperHand(orgId, userId, fromDate, toDate, timezone, requestBody)
    );
    dispatch(
      getTeamQETendencies(
        orgId,
        userId,
        fromDate,
        toDate,
        timezone,
        requestBody
      )
    );
    dispatch(
      getTeamQSellingZone(
        orgId,
        userId,
        fromDate,
        toDate,
        timezone,
        requestBody
      )
    );
  }, 600);

  dispatch(
    getTeamQThreadDuration(
      orgId,
      userId,
      fromDate,
      toDate,
      timezone,
      requestBody
    )
  );
  dispatch(
    getTeamQThreadLength(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQAnalyzed(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQSimulated(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQAUpperHand(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQASellZone(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQCompetence(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQEmpathy(orgId, userId, fromDate, toDate, timezone, requestBody)
  );
  dispatch(
    getTeamQResponsiveness(
      orgId,
      userId,
      fromDate,
      toDate,
      timezone,
      requestBody
    )
  );
};
