import { UpperHandFeedbackEnum } from '../../enums/upper-hand-feedback.enum';

type DealRow = {
  platformThreadId: string;
  activeBranchDurationWeeks: number;
  activeBranchUserPerceivedCompetence: number;
  subject: string;
  participants: {
    email: string;
    metadata: {
      avatarSrc: string | null;
      name: string;
    };
  }[];
  lastMessageSentAt: string | null;
  lastActiveParticipant: {
    email: string;
    name: string;
  };
};

export type DealsTableRow = {
  key: string;
  platformThreadId: string;
  activeBranchDurationWeeks: number;
  activeBranchUserPerceivedCompetence: number;
  subject: string;
  participants: {
    email: string;
    metadata: {
      avatarSrc: string | null;
      name: string;
    };
  }[];
  rowToSelect: DealRow;
  lastMessageSentAt: string | null;
  lastActiveParticipant: {
    email: string;
    name: string;
  };
};

export type DealsForTable = {
  analyzerUsedOnce: boolean;
  count: number;
  ipp: number;
  page: number;
  results: DealsTableRow[];
};

export type EngagementParticipant = {
  email: string;
  contactId: string;
  isUser: boolean;
  orgId: string;
  userId: string;
  metadata: {
    avatarSrc: string | null;
    name: string;
    details: {
      companyName: string | null;
      phone: string | null;
    };
  };
};

export type EngagementBranch = {
  branchId: string;
  lastIterationDate: string;
  platformThreadId: string;
  subject: string;
  contacts: EngagementParticipant[];
};

export type TableRowAccount = {
  isUpdated?: string;
  accountId: string;
  name: string;
  domain?: string | null;
  subrowQty?: number;
  metadata: {
    avatarSrc: string | null;
    headquarters: string | null;
    category: string | null;
    domain: string | null;
    employees: string | null;
    foundedYear: string | null;
    stockSymbol: string | null;
    name: string | null;
    additionalData: {
      estimatedAnnualRevenue: string | null;
      totalFunding: string | null;
      description: string | null;
      socialMedia:
        | {
            type: 'linkedin' | 'twitter' | 'facebook';
            url: string;
          }[]
        | [];
    } | null;
  };
};

export type Engagement = {
  engagementId: string;
  domain: string | null;
  account: TableRowAccount;
  branches: EngagementBranch[];
  participants: EngagementParticipant[];
};

export type EngagementSubrow = {
  key: string;
  platformThreadId: string;
  branchId: string;
  engagementId: string;
  subject: string;
  lastIterationDate: string | null;
  contacts: EngagementParticipant[];
  isLastSubrow?: boolean;
};

export type EngagementTableRow = {
  key: string;
  engagementId: string;
  branchId: string;
  account: TableRowAccount;
  subject: string;
  lastIterationDate: string | null;
  participants: EngagementParticipant[];
  options?: Engagement;
  rowToSelect: {
    key: string;
    engagementId: string;
    subject: string;
    participants: EngagementParticipant[];
    lastIterationDate: string | null;
  };
  subrows: EngagementSubrow[] | null;
};

export type EngagementsResponse = {
  analyzerUsedOnce: boolean;
  count: number;
  ipp: number;
  page: number;
  results: Engagement[];
};

export type EngagementsForTable = {
  analyzerUsedOnce: boolean;
  count: number;
  ipp: number;
  page: number;
  results: EngagementTableRow[];
};

export type DetailedAnalysisEmailParticipant = {
  email: string;
  metadata: {
    name: string;
    avatarSrc: string | null;
    linkedin: string | null;
  };
  isSender: boolean;
  isUser: boolean;
  isUserDomain: boolean;
  numberEmailsInBranch: number;
};

export type DetailedAnalysisEmail = {
  assessmentInsights: {
    assessmentInsights: string[];
    lastEmailRelativeMetrics: {
      relativeReplyTime: {
        currentEmailValue: number | null;
        insightText: string | null;
        previousEmailValue: number | null;
        units: 'hrs';
      };
      relativeWordCount: {
        currentEmailValue: number | null;
        insightText: string | null;
        previousEmailValue: number | null;
        units: 'words ';
      };
    };
  };
  dominantParticipant: 'left' | 'right' | 'unresolved' | null;
  index: number | null;
  participants: {
    leftParticipant: DetailedAnalysisEmailParticipant;
    rightParticipant: DetailedAnalysisEmailParticipant;
  };
  platformMessageId: string;
  recipients: {
    type: string;
    email: string;
    metadata: {
      name: string;
      avatarSrc: string | null;
      linkedin: string | null;
    };
    index?: number;
  }[];
  responseTimeHours: number | null;
  scoreInBranch: number;
  sender: {
    email: string;
    metadata: {
      name: string;
      avatarSrc: string | null;
      linkedin: string | null;
    };
  };
  sentAt: string;
  sentiment: {
    mag: number;
  } | null;
  text: string;
  topics: string[] | [];
  wordCount: number;
  communicationType?: 'mix' | 'internal' | 'external';
  messageId?: string;
  subject?: string;

  // UI
  upperHandFeedback?: UpperHandFeedbackEnum;
};

export type ProspectParticipant = {
  contactId: string;
  email: string;
  isUser: boolean;
  metadata: {
    name: string;
    avatarSrc: string | null;
    details: {
      title: string | null;
      phone: string | null;
      timeZone: string | null;
      companyName: string | null;
      socialMedia:
        | {
            type: 'linkedin' | 'twitter' | 'facebook';
            url: string;
          }[]
        | [];
    } | null;
  };
};

export type ProspectDetails = {
  mainParticipant: ProspectParticipant;
  domainParticipants: ProspectParticipant[] | [];
  thirdPartyParticipants: ProspectParticipant[] | [];
};

export type ParticipantGeneralInfo = {
  email: string;
  metadata: {
    name: string;
    avatarSrc: string | null;
    linkedin: string | null;
  };
};

export type EmailBranch = {
  branchId: string;
  allBranchParticipants: DetailedAnalysisEmailParticipant[];
  mainBranchParticipants: DetailedAnalysisEmailParticipant[];
  participantsBySide: {
    userSide: ParticipantGeneralInfo[] | null;
    otherSide: ParticipantGeneralInfo[] | null;
  };
  lastActiveParticipant: DetailedAnalysisEmailParticipant;
  avgOtherSideReplyTimeHours: number | null;
  avgUserReplyTimeHours: number | null;
  avgWordCountOtherSide: number | null;
  avgWordCountUser: number | null;
  branchDurationWeeks: number | null;
  prospectDetails?: ProspectDetails; // account
  subject: string | null;
  userPerceivedCompetence: number | null; // disable
  userSidePerceivedCompetence: number | null; // use this one
  nextMove: {
    type: string | null;
    value: string | null;
  };
  emails: DetailedAnalysisEmail[];
};

export type ProspectDataAccount = {
  name: string | null;
  domain: string | null;
  orgId: string;
  accountId: string;
  metadata: {
    headquarters: string | null;
    category: string | null;
    domain: string | null;
    employees: string | null;
    foundedYear: string | null;
    stockSymbol: string | null;
    name: string | null;
    additionalData: {
      estimatedAnnualRevenue: string | null;
      totalFunding: string | null;
      description: string | null;
      socialMedia:
        | {
            type: 'linkedin' | 'twitter' | 'facebook';
            url: string;
          }[]
        | [];
    } | null;
  };
};

export type Deal = {
  buyingZone: number | string | null;
  crmStage: number | string | null;
  dealId: string;
  dealName: number | string | null;
  dealValue: number | string | null;
  platformThreadId: string;
  userId: string;
  orgId: string;
  isCompleted: boolean;
  deleted: boolean;
};

export type DetailedAnalysis = {
  userId: string;
  orgId: string;
  account: ProspectDataAccount | null;
  deal: Deal;
  branch: EmailBranch;
  engagement: {
    engagementId: string;
    branchIds: string[];
  };
  prospectSideContacts: ProspectParticipant[];
  isThreadInternal: boolean;
  threadHasMessagesInTrash: boolean;
};

export type RemoveThreadStatus = 'success' | 'error' | null;

export type TableDataSortCase = 'a-z' | 'z-a' | '1-9' | '9-1';

export interface ReplyEmailInfo {
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject: string;
  text: string;
  scheduleFor?: string | null;
}

export enum UserFeedbackType {
  UPPER_HAND = 'upperhand',
}

export interface SubmitFeedbackRequest {
  orgId: string;
  userId: string;
  emailId?: string;
  platformMessageId: string;
  platformThreadId: string;
  type: UserFeedbackType;
  feedback: boolean;
}
