import { Role } from '../types';

export enum FilterType {
  SELECT = 'select',
  RANGE = 'range',
  DATE_RANGE = 'dateRange',
  CHECKBOX = 'checkbox',
}

export enum RangeFilterMaskType {
  CURRENCY = 'currency',
}

export type RangeFilterValue = {
  min: number;
  max: number;
};

export type DateRangeFilterValue<T> = {
  selectValue: T;
  startDate: string | null;
  endDate: string | null;
};

export type FilterSelectOption<T> = {
  value: T;
  label: string;
};

export enum MeetingRecordsDateFilterValue {
  ALL = 'all',
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export enum MeetingRecordsTypeFilterValue {
  ALL = 'all',
  INTERNAL_MEETINGS = 'internal',
  ACCOUNT_RELATED_MEETINGS = 'external',
}

export enum MeetingRecordsAgeFilterValue {
  ALL = 'all',
  LESS_THAN_1_MONTH = '|1', // 'eMin|Max' Max=1
  BETWEEN_1_3_MONTHS = '1|3', // Min=1&Max=3
  BETWEEN_3_6_MONTHS = '3|6', // Min=3&Max=6
  BETWEEN_6_12_MONTHS = '6|12', // Min=6&Max=12
  BETWEEN_12_24_MONTHS = '12|24', // Min=12&Max=24
  MORE_THAN_24_MONTHS = '24|', // ?AgeMin=24
}

export type FilterValue =
  | MeetingRecordsDateFilterValue
  | MeetingRecordsTypeFilterValue
  | DateRangeFilterValue<MeetingRecordsDateFilterValue>
  | RangeFilterValue
  | boolean;

export interface Filter<T extends FilterValue> {
  id: string;
  value: T;
  defaultValue: T;
  previousValue: T;
  label: string;
  type: FilterType;
  isActive: boolean;
  groupId?: string;
  restrictedFor?: Role[];
  name?: string;
  selectOptions?: FilterSelectOption<T>[];
  isDisabled?: boolean;
  maskType?: RangeFilterMaskType;
}
