import React, { useLayoutEffect } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { switchToMaintenanceMode } from '../../store/global/global.slice';
import axios from '../../axios';
import { StatusCode } from '../../types/statusCode.types';
import { useFirebase } from '../../context';

export const ClientInterceptors: React.FC = () => {
  const dispatch = useAppDispatch();

  const { firebaseUser } = useFirebase();

  useLayoutEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        try {
          if (firebaseUser && config.headers) {
            const token = await firebaseUser.getIdToken();
            config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
        } catch (e) {
          return Promise.reject(e);
        }
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === StatusCode.ServiceUnavailable) {
          dispatch(switchToMaintenanceMode());
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, firebaseUser]);

  return null;
};
