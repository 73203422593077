import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  MeetingRecordsCheckboxFilters,
  MeetingRecordsFiltersQueryParams,
  MeetingRecordsState,
} from './meetingRecords.type';
import {
  getMeetingRecords,
  getUpcomingMeetings,
} from './meetingRecords.thunks';
import { assignEventToAccount } from '../relationshipFlows/relationshipFlows.thunks';
import { PaginationSortOrder } from '../../types/pagination.types';
import {
  FilterType,
  FilterValue,
} from '../../types/meetingRecordsFilters.types';
import {
  MEETING_RECORDS_DATE_FILTER_DEFAULT_VALUE,
  MEETING_RECORDS_DATE_FILTER_OPTIONS,
  MEETING_RECORDS_TYPE_FILTER_DEFAULT_VALUE,
  MEETING_RECORDS_TYPE_FILTER_OPTIONS,
  MEETING_RECORDS_EVENTS_FILTERS_GROUP_ID,
} from './meetingRecords.constants';
import {
  isDateRangeFilterValue,
  isRangeFilterValue,
} from './meetingRecords.utils';

export const initialState: MeetingRecordsState = {
  isLoading: {
    meetingRecords: false,
  },
  isError: {
    meetingRecords: false,
  },
  pagination: {
    count: 0,
    ipp: 20,
    p: 0,
    nextPage: null,
    sortOrder: null,
    sortBy: null,
  },
  data: [],
  upcoming: {
    data: [],
    nextPage: null,
    status: {
      isError: false,
      isLoading: false,
      noData: false,
    },
  },
  filters: [
    {
      id: uuidv4(),
      value: MEETING_RECORDS_DATE_FILTER_DEFAULT_VALUE,
      previousValue: MEETING_RECORDS_DATE_FILTER_DEFAULT_VALUE,
      defaultValue: MEETING_RECORDS_DATE_FILTER_DEFAULT_VALUE,
      label: 'Meeting Date',
      type: FilterType.DATE_RANGE,
      selectOptions: MEETING_RECORDS_DATE_FILTER_OPTIONS,
      isActive: false,
    },
    {
      id: uuidv4(),
      value: MEETING_RECORDS_TYPE_FILTER_DEFAULT_VALUE,
      previousValue: MEETING_RECORDS_TYPE_FILTER_DEFAULT_VALUE,
      defaultValue: MEETING_RECORDS_TYPE_FILTER_DEFAULT_VALUE,
      label: 'Meeting Types',
      type: FilterType.SELECT,
      selectOptions: MEETING_RECORDS_TYPE_FILTER_OPTIONS,
      isActive: false,
      isDisabled: false,
    },
    {
      id: uuidv4(),
      value: false,
      previousValue: false,
      defaultValue: false,
      label: 'Show Meetings with N/A account only',
      type: FilterType.CHECKBOX,
      isActive: false,
      name: MeetingRecordsCheckboxFilters.SHOW_MEETING_RECORDS_WITH_NA_ACCOUNT,
      groupId: MEETING_RECORDS_EVENTS_FILTERS_GROUP_ID,
    },
  ],
  filtersQueryParams: {},
};

const meetingRecordsSlice = createSlice({
  name: 'meetingRecords',
  initialState,
  reducers: {
    updateSorting: (
      state,
      action: PayloadAction<{
        sortOrder: PaginationSortOrder | null;
        sortBy: string | null;
      }>
    ) => {
      state.data = [];
      state.pagination = {
        ...initialState.pagination,
        sortOrder: action.payload.sortOrder,
        sortBy: action.payload.sortBy,
      };
    },
    resetMeetingRecords: (state) => {
      state.data = [];
      state.pagination = initialState.pagination;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
    applyFilters: (state) => {
      state.filters = state.filters.map((filter) => {
        filter.previousValue = filter.value;
        return filter;
      });
    },
    changeFilterValue: (
      state,
      action: PayloadAction<{ id: string; value: FilterValue }>
    ) => {
      state.filters = state.filters.map((filter) => {
        if (filter.id === action.payload.id) {
          filter.value = action.payload.value;
        }
        return filter;
      });
    },
    toggleFilterActiveState: (state, action: PayloadAction<string>) => {
      state.filters = state.filters.map((filter) => {
        if (filter.id === action.payload) {
          filter.isActive = !filter.isActive;
          filter.value = filter.defaultValue;
        }

        return filter;
      });
    },
    updateFiltersQueryParams: (
      state,
      action: PayloadAction<MeetingRecordsFiltersQueryParams>
    ) => {
      state.filtersQueryParams = action.payload;
    },
    resetUnsavedFilters: (state) => {
      state.filters = state.filters.map((filter) => {
        filter.value = filter.previousValue;

        if (
          isDateRangeFilterValue(filter.value) &&
          isDateRangeFilterValue(filter.defaultValue)
        ) {
          filter.isActive =
            filter.value.selectValue !== filter.defaultValue.selectValue;

          return filter;
        }

        if (
          isRangeFilterValue(filter.value) &&
          isRangeFilterValue(filter.defaultValue)
        ) {
          filter.isActive =
            filter.value.min !== filter.defaultValue.min ||
            filter.value.max !== filter.defaultValue.max;

          return filter;
        }

        filter.isActive = filter.value !== filter.defaultValue;
        return filter;
      });
    },
    clearMeetingRecords: (state) => {
      state.pagination = {
        ...initialState.pagination,
        sortBy: state.pagination.sortBy,
        sortOrder: state.pagination.sortOrder,
      };
      state.data = initialState.data;
      state.isError = initialState.isError;
      state.isLoading = initialState.isLoading;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getMeetingRecords.pending, (state) => {
      state.isLoading.meetingRecords = true;
      state.isError.meetingRecords = false;
    });
    builder.addCase(getMeetingRecords.fulfilled, (state, action) => {
      state.isLoading.meetingRecords = false;
      state.data = [...state.data, ...action.payload.results];
      state.pagination.ipp = action.payload.ipp;
      state.pagination.p = action.payload.p;
      state.pagination.count = action.payload.results.length;
      state.pagination.nextPage = action.payload.nextPage;
    });
    builder.addCase(getMeetingRecords.rejected, (state, action) => {
      state.isLoading.meetingRecords = action.meta.aborted;
      state.isError.meetingRecords = !action.meta.aborted;
    });
    builder.addCase(assignEventToAccount.fulfilled, (state, action) => {
      state.data = state.data.map((record) =>
        record.touchPointId === action.payload.touchPoint?.touchPointId
          ? { ...record, account: action.payload.account || null }
          : record
      );
    });
    builder.addCase(getUpcomingMeetings.pending, (state) => {
      state.upcoming.status.isLoading = true;
      state.upcoming.status.isError = false;
      state.upcoming.status.noData = false;
    });
    builder.addCase(getUpcomingMeetings.fulfilled, (state, action) => {
      state.upcoming.status.isLoading = false;
      state.upcoming.data = action.payload.meetings;
      state.upcoming.nextPage = action.payload.nextPageToken;
    });
    builder.addCase(getUpcomingMeetings.rejected, (state, action) => {
      state.upcoming.status.isLoading = action.meta.aborted;
      state.upcoming.status.isError = !action.meta.aborted;
    });
  },
});

export const {
  updateSorting,
  resetMeetingRecords,
  clearFilters,
  applyFilters,
  changeFilterValue,
  toggleFilterActiveState,
  updateFiltersQueryParams,
  resetUnsavedFilters,
  clearMeetingRecords,
} = meetingRecordsSlice.actions;

export default meetingRecordsSlice.reducer;
