import React from 'react';

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45492 2.86191C10.236 2.08086 11.5023 2.08086 12.2834 2.86191L13.2262 3.80472C14.0072 4.58577 14.0072 5.8521 13.2262 6.63315L7.08722 12.7721C6.80634 13.053 6.44819 13.2438 6.05842 13.3204L3.9113 13.7421C2.98159 13.9246 2.16343 13.1065 2.34602 12.1768L2.76768 10.0297C2.84423 9.63988 3.03511 9.28173 3.31598 9.00085L9.45492 2.86191ZM11.3405 3.80472C11.0802 3.54437 10.6581 3.54437 10.3977 3.80472L9.85045 4.352L9.85526 4.36085C10.2855 5.15246 10.9356 5.80261 11.7272 6.23281L11.7361 6.23762L12.2834 5.69034C12.5437 5.42999 12.5437 5.00788 12.2834 4.74753L11.3405 3.80472ZM10.7618 7.2119C9.9998 6.73279 9.35528 6.08827 8.87617 5.32628L4.25879 9.94366C4.16517 10.0373 4.10154 10.1567 4.07603 10.2866L3.65436 12.4337L5.80148 12.012C5.9314 11.9865 6.05078 11.9229 6.14441 11.8293L10.7618 7.2119Z"
      fill="#070B12"
    />
  </svg>
);
