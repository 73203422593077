import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';

export const EngagementsRedirect: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  const { branchId } = useParams<{
    branchId: string;
  }>();

  const attrs = {
    relationshipsRedirect: {
      to: `/orgs/${user?.orgId}/users/${user?.userId}/relationships`,
    },
    touchPointDetailsRedirect: {
      to: `/orgs/${user?.orgId}/users/${user?.userId}/touch-points/${branchId}`,
    },
  };

  if (branchId) {
    return <Redirect {...attrs.touchPointDetailsRedirect} />;
  }

  return <Redirect {...attrs.relationshipsRedirect} />;
};
