import { TooltipPosition } from '../Tooltip';

export const getCurrentPosition = (
  content: HTMLDivElement,
  position: TooltipPosition,
  autoPosition: boolean
): TooltipPosition => {
  if (!autoPosition) {
    return position;
  }

  const { top, right, bottom, left } = content.getBoundingClientRect();
  const isTopPosition = bottom <= window.innerHeight && top >= 0;
  const isRightPosition = left < 0;
  const isLeftPosition = right > window.innerWidth;

  if (isRightPosition) {
    return isTopPosition
      ? TooltipPosition.TOP_START
      : TooltipPosition.BOTTOM_START;
  }

  if (isLeftPosition) {
    return isTopPosition ? TooltipPosition.TOP_END : TooltipPosition.BOTTOM_END;
  }

  return isTopPosition ? TooltipPosition.TOP : TooltipPosition.BOTTOM;
};
