import { useEffect, useRef } from 'react';

export const useTimeout = (
  callback: () => void,
  delay: number,
  conditional = true
): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (conditional && savedCallback.current) {
      timerId = setTimeout(savedCallback.current, delay);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [conditional, delay]);
};
