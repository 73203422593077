import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CloseIcon } from './CloseIcon';

type ModalProps = {
  title: string;
  titleFontSize?: string;
  centeredTitle?: boolean;
  children: JSX.Element | string;
  onClose: () => void;
  className?: string;
  open: boolean;
  height?: string;
  width?: string;
  upIndex?: boolean;
  opacity?: string;
  empty?: boolean;
  noTitle?: boolean;
  noCloseIcon?: boolean;
  noOverlayClose?: boolean;
  zIndex?: number;
};

export const Modal: React.FC<ModalProps> = ({
  title,
  titleFontSize = '',
  centeredTitle,
  children,
  onClose,
  className = '',
  open,
  height,
  width,
  upIndex,
  opacity,
  empty,
  noTitle,
  noCloseIcon,
  noOverlayClose,
  zIndex,
}: ModalProps): JSX.Element => {
  return (
    <>
      <div className="modal" style={{ opacity }}>
        <CSSTransition unmountOnExit in={open} classNames="modal" timeout={400}>
          <div
            className={`modal__window ${className}`}
            style={
              upIndex ? { height, width, zIndex: 1001 } : { height, width }
            }
          >
            {empty ? null : (
              <>
                <div className="modal__window-bordergradient" />
                {noTitle ? null : (
                  <div
                    className="modal__window-title"
                    style={{
                      fontSize: titleFontSize,
                      justifyContent: centeredTitle ? 'center' : '',
                    }}
                  >
                    {title}
                    {noCloseIcon ? null : <CloseIcon onClick={onClose} />}
                  </div>
                )}
              </>
            )}
            <div className="modal__window-body">{children}</div>
          </div>
        </CSSTransition>
      </div>
      <CSSTransition unmountOnExit in={open} classNames="modal" timeout={400}>
        <div
          className="modal__overlay"
          onClick={noOverlayClose ? () => {} : onClose}
          style={upIndex ? { zIndex: 11 } : { zIndex }}
        />
      </CSSTransition>
    </>
  );
};
