import React, { useMemo } from 'react';
import {
  Avatar,
  AvatarSize,
} from '../../../SoundWave/components/Avatar/Avatar';
import { EditIcon } from './icons/EditIcon';
import { RemoveIcon } from './icons/RemoveIcon';
import { LogoPlaceholderIcon } from '../../../Icons';
import { getStringTokens } from '../../../utils';
import { ContactLinkedAccount } from '../../../../types/contact.types';
import { RelationshipMemberAssociationType } from '../../../../types/relationshipMember.types';

export interface ContactItemProps {
  text: string;
  imageSrc?: string;
  secondaryText?: string;
  isEditing?: boolean;
  showSecondaryText?: boolean;
  isAccount?: boolean;
  isNewContact?: boolean;
  search?: string;
  title?: string;
  showDetails?: boolean;
  associatedRelationships?: ContactLinkedAccount[];
  onEdit?: (isEditing: boolean) => void;
  onRemove?: () => void;
}

export const ContactItem: React.FC<ContactItemProps> = ({
  text,
  imageSrc,
  secondaryText,
  associatedRelationships,
  title,
  isEditing = false,
  showSecondaryText = false,
  isAccount = false,
  isNewContact = false,
  showDetails = false,
  search = '',
  onEdit,
  onRemove,
}) => {
  const tokens = useMemo(() => getStringTokens(text, search), [text, search]);

  const attrs = {
    container: {
      className: 'contact-item',
    },
    avatar: {
      size: isAccount ? AvatarSize.XXS : AvatarSize.XS,
      src: imageSrc,
      name: isNewContact ? text : secondaryText || text,
      shape: isAccount ? ('square' as const) : ('circle' as const),
      className: 'contact-item__avatar',
      ...(isAccount ? { placeholder: LogoPlaceholderIcon } : {}),
    },
    textWrapper: {
      className: `contact-item__text-wrapper${
        onEdit ? ' contact-item__text-wrapper--editable' : ''
      }`,
    },
    text: {
      className: 'contact-item__text',
    },
    textGray: {
      className: 'contact-item__text contact-item__text--gray',
    },
    textHighlighted: {
      className: 'contact-item__text contact-item__text--highlighted',
    },
    separator: {
      className: 'contact-item__separator',
    },
    editButton: {
      type: 'button' as const,
      className: 'contact-item__button',
      'aria-label': isEditing ? 'Discard' : 'Edit selected contact',
      onClick: () => {
        onEdit?.(isEditing);
      },
    },
    removeButton: {
      type: 'button' as const,
      className: 'contact-item__button',
      'aria-label': 'Remove selected contact',
      onClick: onRemove,
    },
    buttonIcon: {
      className: 'contact-item__button-icon',
    },
    infoDropdown: {
      className: 'contact-item__info-dropdown',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
    },
    contactDetails: {
      className: 'contact-item__contact-details',
    },
    avatarWrapper: {
      className: 'contact-item__avatar-wrapper',
    },
    contactDetailsAvatar: {
      src: imageSrc,
      name: secondaryText || text,
    },
    contactDetailsTextWrapper: {
      className: 'contact-item__contact-details-text-wrapper',
    },
    contactDetailsText: {
      className: 'contact-item__contact-details-text',
    },
    contactDetailsTextGray: {
      className:
        'contact-item__contact-details-text contact-item__contact-details-text--gray',
    },
    contactDetailsTextTitle: {
      className:
        'contact-item__contact-details-text contact-item__contact-details-text--title',
    },
    associatedRelationshipsWrapper: {
      className: 'contact-item__associated-relationships-wrapper',
    },
  };

  const renderEmailText = () =>
    tokens.map(({ value, isHighlighted }, index) => {
      const props = {
        key: index,
        className: isHighlighted
          ? attrs.textHighlighted.className
          : attrs.text.className,
      };

      return <span {...props}>{value}</span>;
    });

  const renderAssociatedRelationships = () =>
    associatedRelationships?.map(({ account, link }) => {
      const logoProps = {
        name: account.name,
        size: AvatarSize.XXS,
        src: account.avatarSrc || '',
        shape: 'square' as const,
        placeholder: LogoPlaceholderIcon,
      };

      const linkAssociationType =
        link.associationType === RelationshipMemberAssociationType.TEAM_MEMBER
          ? 'Team member'
          : '3rd Party';

      return (
        <div {...attrs.container}>
          <Avatar {...logoProps} />
          <div {...attrs.textWrapper}>
            <span {...attrs.contactDetailsText}>{account.name}</span>
            {link.associationType ? (
              <>
                <div {...attrs.separator} />
                <span {...attrs.contactDetailsTextGray}>
                  {linkAssociationType}
                </span>
              </>
            ) : null}
          </div>
        </div>
      );
    });

  return (
    <div {...attrs.container}>
      <Avatar {...attrs.avatar} />
      <div {...attrs.textWrapper}>
        <span>{renderEmailText()}</span>
        {secondaryText && showSecondaryText ? (
          <>
            <div {...attrs.separator} />
            <span {...attrs.textGray}>{secondaryText}</span>
          </>
        ) : null}
        {onRemove ? (
          <button {...attrs.removeButton}>
            <RemoveIcon {...attrs.buttonIcon} />
          </button>
        ) : null}
      </div>
      {onEdit ? (
        <button {...attrs.editButton}>
          {isEditing ? 'Discard' : <EditIcon {...attrs.buttonIcon} />}
        </button>
      ) : null}
      {showDetails ? (
        <div {...attrs.infoDropdown}>
          <div {...attrs.contactDetails}>
            <Avatar {...attrs.contactDetailsAvatar} />
            <div {...attrs.contactDetailsTextWrapper}>
              <span {...attrs.contactDetailsTextTitle}>
                {secondaryText || text}
              </span>
              <span {...attrs.contactDetailsTextGray}>{title}</span>
            </div>
          </div>
          {associatedRelationships?.length ? (
            <div {...attrs.associatedRelationshipsWrapper}>
              <span {...attrs.contactDetailsTextGray}>
                Associated to relationships:
              </span>
              <div {...attrs.contactDetailsTextWrapper}>
                {renderAssociatedRelationships()}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
