import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickOutside } from '../../../../../../hooks';

export interface EventHeaderTitleProps {
  value: string;
  onChange?: (value: string) => void;
}

export const EventHeaderTitle: React.FC<EventHeaderTitleProps> = ({
  value: valueProp,
  onChange,
}) => {
  const isEditable = !!onChange;

  const [value, setValue] = useState(valueProp);
  const [isEditing, setIsEditing] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = useCallback(() => {
    setIsEditing(false);
  }, []);

  const updateInputWidth = useCallback(() => {
    const containerRefCurrent = containerRef.current;
    const inputRefCurrent = inputRef.current;
    const offset = 1; // fix for input border

    if (containerRefCurrent && inputRefCurrent) {
      inputRefCurrent.style.width = `${
        containerRefCurrent.clientWidth - offset
      }px`;
    }
  }, []);

  const attrs = {
    container: {
      ref: containerRef,
      className: `event-header-title${
        isEditable ? ' event-header-title--editable' : ''
      }`,
      onClick: () => {
        if (isEditable) {
          setIsEditing(true);
        }
      },
    },
    label: {
      className: `event-header-title__label${
        !isEditing ? ' event-header-title__label--hidden' : ''
      }`,
    },
    content: {
      className: `event-header-title__content${
        isEditing ? ' event-header-title__content--hidden' : ''
      }`,
    },
    input: {
      value,
      ref: inputRef,
      type: 'text',
      className:
        'event-header-title__content event-header-title__content--input',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      },
      onBlur: () => {
        setValue(value || valueProp);

        if (value && value !== valueProp) {
          onChange?.(value || valueProp);
        }
      },
    },
  };

  useEffect(() => {
    setValue(valueProp);
  }, [updateInputWidth, valueProp]);

  useEffect(() => {
    updateInputWidth();
  }, [updateInputWidth, value]);

  useEffect(() => {
    if (inputRef.current) {
      if (isEditing) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  }, [isEditing]);

  useClickOutside(containerRef, handleClickOutside);

  return (
    <div {...attrs.container}>
      <div {...attrs.content}>{value}</div>
      <label {...attrs.label}>
        <input {...attrs.input} />
      </label>
    </div>
  );
};
