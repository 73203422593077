import { DateTime } from 'luxon';
import {
  DateRangeFilterValue,
  Filter,
  FilterType,
  FilterValue,
  LastInteractionDateFilterValue,
  RangeFilterValue,
} from '../../types/relationshipFilters.types';
import { RelationshipsFiltersQueryParams } from './relationships.types';

export const isRangeFilterValue = (
  value?: FilterValue
): value is RangeFilterValue =>
  !!value &&
  typeof value === 'object' &&
  'min' in (value as RangeFilterValue) &&
  'max' in (value as RangeFilterValue);

export const isDateRangeFilterValue = (
  value?: FilterValue
): value is DateRangeFilterValue<LastInteractionDateFilterValue> =>
  !!value &&
  typeof value === 'object' &&
  'selectValue' in
    (value as DateRangeFilterValue<LastInteractionDateFilterValue>);

export const isCheckboxFilterValue = (value?: FilterValue): value is boolean =>
  typeof value === 'boolean';

export const getIsFilterChecked = (
  value: FilterValue,
  defaultValue: FilterValue,
  isActive: boolean
): boolean => {
  if (isDateRangeFilterValue(value) && isDateRangeFilterValue(defaultValue)) {
    return isActive && value.selectValue !== defaultValue.selectValue;
  }

  if (isRangeFilterValue(value) && isRangeFilterValue(defaultValue)) {
    return (
      isActive &&
      (value.min !== defaultValue.min || value.max !== defaultValue.max)
    );
  }

  return isActive && value !== defaultValue;
};

const getLastInteractionDateQueryParams = (
  value: DateRangeFilterValue<LastInteractionDateFilterValue>
): Pick<
  RelationshipsFiltersQueryParams,
  'lastInteractionDateMin' | 'lastInteractionDateMax'
> => {
  switch (value.selectValue) {
    case LastInteractionDateFilterValue.TODAY: {
      return {
        lastInteractionDateMax: DateTime.now().toISODate(),
        lastInteractionDateMin: DateTime.now().minus({ days: 1 }).toISODate(),
      };
    }
    case LastInteractionDateFilterValue.WEEK: {
      return {
        lastInteractionDateMax: DateTime.now().toISODate(),
        lastInteractionDateMin: DateTime.now().minus({ weeks: 1 }).toISODate(),
      };
    }
    case LastInteractionDateFilterValue.MONTH: {
      return {
        lastInteractionDateMax: DateTime.now().toISODate(),
        lastInteractionDateMin: DateTime.now().minus({ months: 1 }).toISODate(),
      };
    }
    case LastInteractionDateFilterValue.QUARTER: {
      return {
        lastInteractionDateMax: DateTime.now().toISODate(),
        lastInteractionDateMin: DateTime.now()
          .minus({ quarters: 1 })
          .toISODate(),
      };
    }
    case LastInteractionDateFilterValue.YEAR: {
      return {
        lastInteractionDateMax: DateTime.now().toISODate(),
        lastInteractionDateMin: DateTime.now().minus({ years: 1 }).toISODate(),
      };
    }
    case LastInteractionDateFilterValue.CUSTOM: {
      return {
        ...(value.startDate
          ? {
              lastInteractionDateMin:
                DateTime.fromISO(value.startDate).toISODate() || '',
            }
          : {}),
        ...(value.endDate
          ? {
              lastInteractionDateMax:
                DateTime.fromISO(value.endDate).toISODate() || '',
            }
          : {}),
      };
    }
    default: {
      return {};
    }
  }
};

const getRelationshipAgeQueryParams = (
  value: FilterValue
): Pick<
  RelationshipsFiltersQueryParams,
  'relationshipAgeMonthsMin' | 'relationshipAgeMonthsMax'
> => {
  if (
    isRangeFilterValue(value) ||
    isDateRangeFilterValue(value) ||
    isCheckboxFilterValue(value)
  ) {
    return {};
  }

  const [relationshipAgeMin, relationshipAgeMax] = value.split('|');

  return {
    ...(relationshipAgeMin
      ? { relationshipAgeMonthsMin: relationshipAgeMin }
      : {}),
    ...(relationshipAgeMax
      ? { relationshipAgeMonthsMax: relationshipAgeMax }
      : {}),
  };
};

const getCheckboxFiltersQueryParams = (filter: Filter<FilterValue>) => {
  if (
    filter.type !== FilterType.CHECKBOX ||
    !isCheckboxFilterValue(filter.value) ||
    !filter.value ||
    !filter.name
  ) {
    return {};
  }

  return { [filter.name]: filter.value };
};

export const getRelationshipsFiltersQueryParams = (
  appliedFilters: Filter<FilterValue>[]
): RelationshipsFiltersQueryParams =>
  appliedFilters.reduce<RelationshipsFiltersQueryParams>((acc, filter) => {
    if (isDateRangeFilterValue(filter.value)) {
      return { ...acc, ...getLastInteractionDateQueryParams(filter.value) };
    }

    return {
      ...acc,
      ...getRelationshipAgeQueryParams(filter.value),
      ...getCheckboxFiltersQueryParams(filter),
    };
  }, {});
