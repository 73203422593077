import React from 'react';

export const DangerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M15.9997 8.00033C16.736 8.00033 17.333 8.59728 17.333 9.33366V18.667C17.333 19.4034 16.736 20.0003 15.9997 20.0003C15.2633 20.0003 14.6663 19.4034 14.6663 18.667V9.33366C14.6663 8.59728 15.2633 8.00033 15.9997 8.00033Z"
      fill="#F0384A"
    />
    <path
      d="M15.9997 24.667C16.9201 24.667 17.6663 23.9208 17.6663 23.0003C17.6663 22.0799 16.9201 21.3337 15.9997 21.3337C15.0792 21.3337 14.333 22.0799 14.333 23.0003C14.333 23.9208 15.0792 24.667 15.9997 24.667Z"
      fill="#F0384A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.137 6.37161C11.2126 4.10882 13.4943 2.66699 15.9997 2.66699C18.5051 2.66699 20.7868 4.10882 21.8623 6.37161L29.1532 21.7108C30.8352 25.2497 28.2546 29.3337 24.3362 29.3337H7.66308C3.74473 29.3337 1.16409 25.2497 2.84617 21.7108L10.137 6.37161ZM15.9997 5.33366C14.5235 5.33366 13.1792 6.18317 12.5455 7.51637L5.25463 22.8556C4.41358 24.625 5.7039 26.667 7.66308 26.667H24.3362C26.2954 26.667 27.5857 24.625 26.7447 22.8556L19.4538 7.51637C18.8202 6.18316 17.4758 5.33366 15.9997 5.33366Z"
      fill="#F0384A"
    />
  </svg>
);
