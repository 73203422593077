import React from 'react';

type InputProps = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  name?: string;
  type?: string;
  icon?: string;
  placeholder?: string;
  label: string;
  className?: string;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  isUpdateOrg?: boolean;
  errorOnTheRight?: boolean;
  isDealsRenameAcc?: boolean;
  isInWebhook?: boolean;
  isInGlobalHeader?: boolean;
  noLabel?: boolean;
  isHiddenValueWithStars?: boolean;
  controlPadding?: string;
  readOnly?: boolean;
  whiteVariant?: boolean;
};

export const Input: React.FC<InputProps> = ({
  value,
  onChange,
  onKeyPress,
  type = 'text',
  icon = '',
  placeholder,
  label,
  className = '',
  name,
  error = '',
  disabled = false,
  required = false,
  isUpdateOrg = false,
  errorOnTheRight = false,
  isDealsRenameAcc = false,
  isInWebhook = false,
  isInGlobalHeader = false,
  noLabel = false,
  isHiddenValueWithStars = false,
  readOnly = false,
  controlPadding = '',
  whiteVariant = false,
}: InputProps): JSX.Element => {
  const isRequired = required ? (
    <span className="input__label-required">*</span>
  ) : null;
  return (
    <label className={`input ${className}`}>
      {noLabel ? null : (
        <div className="input__label">
          {label}
          {isRequired}
        </div>
      )}

      <input
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        name={name}
        placeholder={placeholder}
        className={`${!icon?.length ? 'no-icon' : ''} ${
          isDealsRenameAcc ? 'deals-rename' : ''
        } ${isInWebhook ? 'in-webhook' : ''} 
        ${whiteVariant ? 'white-variant' : ''} ${
          isHiddenValueWithStars ? 'hidden-value' : ''
        } ${error ? 'error' : ''} input__control`}
        type={type}
        disabled={isHiddenValueWithStars || disabled}
        style={{ padding: controlPadding }}
        readOnly={readOnly}
      />
      {icon ? (
        <img
          className={`${
            isInGlobalHeader ? 'in-global-header' : ''
          } input__icon`}
          src={icon}
          alt="Icon"
        />
      ) : null}
      {error ? (
        <div
          className={`${errorOnTheRight ? 'on-right' : ''} input__error`}
          style={isUpdateOrg ? { transform: 'translateY(170%)' } : {}}
        >
          {error}
        </div>
      ) : null}
    </label>
  );
};
