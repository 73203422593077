import React from 'react';

export type ButtonVariantType = 'common' | 'text';
export type ButtonType = 'button' | 'submit' | 'reset';

type ButtonProps = {
  children: string | JSX.Element;
  variant: ButtonVariantType;
  onClick?: () => void;
  className?: string;
  type?: ButtonType;
  disabled?: boolean;
  marginTop?: string;
  padding?: string;
  borderRadius?: string;
  height?: string;
  width?: string;
  fontSize?: string;
  loading?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  onClick,
  className = '',
  type = 'button',
  disabled,
  marginTop,
  padding,
  borderRadius,
  height,
  width,
  fontSize,
  loading,
}: ButtonProps): JSX.Element => {
  const loader = (
    <div className="btn-loader__wrapper">
      <div className="btn-loader__wrapper-dot__one" />
      <div className="btn-loader__wrapper-dot__two" />
      <div className="btn-loader__wrapper-dot__three" />
    </div>
  );

  return (
    <button
      type={type}
      className={`btn btn-${variant} ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={{ marginTop, height, width, padding, borderRadius, fontSize }}
    >
      {loading ? loader : children}
    </button>
  );
};
