import React from 'react';

export interface FormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  id?: string;
  children?: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({ onSubmit, id, children }) => {
  const attrs = {
    form: {
      id,
      onSubmit,
      noValidate: true,
      className: 'sound-wave-form',
    },
  };

  return <form {...attrs.form}>{children}</form>;
};
