import React, { CSSProperties } from 'react';
import { Organization, Row, User } from '../../types';

export type AvatarVariant =
  | 'primaryD'
  | 'primaryL'
  | 'secondaryL'
  | 'secondaryD'
  | 'grey'
  | '';

type AvatarProps = {
  className?: string;
  onClick?: () => void;
  onUpload?: React.ChangeEventHandler<HTMLInputElement>;
  hasUpload?: boolean;
  hasActivityStatus?: boolean;
  image?: string;
  user?: User | Row | null;
  org?: Organization | null;
  style?: CSSProperties;
  inHeader?: boolean;
  inTable?: boolean;
  inModal?: boolean;
  inSidebar?: boolean;
  isTwentyPx?: boolean;
  inMeetingsRow?: boolean;
  engageAva?: boolean;
  userName?: string;
  variant?: AvatarVariant;
  hasBorder?: boolean;
};

export const Avatar: React.FC<AvatarProps> = ({
  className = '',
  onClick,
  hasUpload = false,
  onUpload,
  image = '',
  hasActivityStatus = false,
  user,
  org,
  style,
  inHeader = false,
  inTable = false,
  inModal = false,
  isTwentyPx = false,
  inSidebar = false,
  inMeetingsRow = false,
  engageAva = false,
  hasBorder = false,
  userName = '',
  variant,
}: AvatarProps): JSX.Element => {
  const conditionalClassName = () => {
    let styles = 'avatar__photo';

    if (inHeader) {
      styles = 'header avatar__photo';
    } else if (inTable) {
      styles = 'table avatar__photo';
    } else if (inModal) {
      styles = 'larger avatar__photo';
    } else if (isTwentyPx) {
      styles = 'small avatar__photo';
    } else if (inSidebar) {
      styles = 'in-sidebar avatar__photo';
    } else if (inMeetingsRow) {
      styles = 'in-my-meetings avatar__photo';
    } else if (engageAva) {
      styles = 'engage avatar__photo';
    }

    return styles;
  };

  const defineVariantStyles = () => {
    let fontColor = '#4d4558';
    let background = '#f0f2f9';

    const colors = {
      primary: {
        ligth: {
          font: '#29335C',
          background: '#D3ECFF',
        },
        dark: {
          font: '#FFFF',
          background: '#7AC7FF',
        },
      },
      secondary: {
        ligth: {
          font: '#29335C',
          background: '#CDD5E5',
        },
        dark: {
          font: '#FFFF',
          background: '#6881B2',
        },
      },
      grey: {
        font: '#FFFF',
        background: '#646C83',
      },
    };

    if (variant === 'primaryL') {
      fontColor = colors.primary.ligth.font;
      background = colors.primary.ligth.background;
    } else if (variant === 'primaryD') {
      fontColor = colors.primary.dark.font;
      background = colors.primary.dark.background;
    } else if (variant === 'secondaryL') {
      fontColor = colors.secondary.ligth.font;
      background = colors.secondary.ligth.background;
    } else if (variant === 'secondaryD') {
      fontColor = colors.secondary.dark.font;
      background = colors.secondary.dark.background;
    } else if (variant === 'grey') {
      fontColor = colors.grey.font;
      background = colors.grey.background;
    }

    return { fontColor, background };
  };

  const { fontColor, background } = defineVariantStyles();

  const attrs = {
    wrapper: {
      onClick,
      className: `${hasUpload ? 'active' : ''} ${
        inSidebar ? 'hov-border' : ''
      } ${hasBorder ? 'white-border' : ''} avatar ${className}`,
    },
    avatar: {
      className: conditionalClassName(),
      src: image,
      style,
    },
    avatarLetters: {
      className: conditionalClassName(),
      style: { ...style, backgroundColor: background, color: fontColor },
    },
    label: {
      htmlFor: 'avatar-upload',
      className: inModal ? 'larger avatar__hover' : 'avatar__hover',
    },
    input: {
      onChange: onUpload,
      id: 'avatar-upload',
      type: 'file',
    },
    activityStatus: {
      className: 'avatar__active',
    },
  };

  const defineAvatar = () => {
    let result;
    if (image) {
      result = <img {...attrs.avatar} alt="" />;
    } else if (user || org) {
      result = (
        <div {...attrs.avatarLetters}>
          {user?.firstName?.substring(0, 1) || user?.name?.substring(0, 1)}
          {user?.lastName?.substring(0, 1)}
          {org && org.name?.substring(0, 1)}
        </div>
      );
    } else if (userName) {
      if (userName.split(' ').length > 1) {
        result = (
          <div {...attrs.avatarLetters}>
            {userName.split(' ')[0].substring(0, 1)}
            {userName.split(' ')[1].substring(0, 1)}
          </div>
        );
      } else
        result = (
          <div {...attrs.avatarLetters}>
            {userName.split(' ')[0].substring(0, 1)}
          </div>
        );
    }
    return result;
  };

  const avatarImg = defineAvatar();

  const uploadFunctionality = hasUpload ? (
    <label {...attrs.label}>
      <input {...attrs.input} />
      Upload
    </label>
  ) : null;

  const activityStatus = hasActivityStatus ? (
    <div {...attrs.activityStatus} />
  ) : null;

  return (
    <div {...attrs.wrapper}>
      {avatarImg}
      {uploadFunctionality}
      {activityStatus}
    </div>
  );
};
