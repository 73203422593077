import React, { useEffect, useRef, useState } from 'react';
import { AddIcon } from './icons/AddIcon';
import { ImagePlaceholderIcon } from '../../../../../Icons/ImagePlaceholderIcon';
import { ImageCropper } from './components/ImageCropper/ImageCropper';
import { EditIcon } from './icons/EditIcon';

export interface ImageFileUploadProps {
  id: string;
  name?: string;
  image?: string;
  placeholderIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  rounded?: boolean;
  disabled?: boolean;
  customTitle?: string;
  onChange?: (image: string) => void;
}

export const ImageFileUpload: React.FC<ImageFileUploadProps> = ({
  id,
  name,
  image,
  placeholderIcon,
  rounded = false,
  disabled = false,
  customTitle,
  onChange,
}) => {
  const [currentImage, setCurrentImage] = useState<string | null>(
    image || null
  );

  const [isCropperOpened, setIsCropperOpened] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const PlaceholderIconComponent = placeholderIcon || ImagePlaceholderIcon;

  const attrs = {
    container: {
      disabled,
      type: 'button' as const,
      className: `image-file-upload${
        rounded ? ' image-file-upload--rounded' : ''
      }${disabled ? ' image-file-upload--disabled' : ''}`,
      onClick: () => {
        setIsCropperOpened(true);
      },
    },
    imageWrapper: {
      className: 'image-file-upload__image-wrapper',
    },
    icon: {
      className: 'image-file-upload__icon',
    },
    image: {
      src: image,
      className: 'image-file-upload__image',
    },
    input: {
      id,
      name,
      disabled,
      ref: inputRef,
      type: 'file',
      accept: 'image/*',
      className: 'image-file-upload__input',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const fileReader = new FileReader();

        fileReader.onload = () => {
          setCurrentImage(fileReader.result?.toString() || null);
          inputRef.current!.value = '';
        };

        if (file) {
          fileReader.readAsDataURL(file);
        }
      },
    },
    circle: {
      className: 'image-file-upload__circle',
    },
    circleIcon: {
      className: 'image-file-upload__circle-icon',
    },
    imageCropper: {
      id,
      rounded,
      disabled,
      placeholderIcon: PlaceholderIconComponent,
      image: currentImage || '',
      isOpened: isCropperOpened,
      title: customTitle,
      onCancel: () => {
        setCurrentImage(image || null);
        setIsCropperOpened(false);
      },
      onSave: (croppedImage: string) => {
        onChange?.(croppedImage);
        setIsCropperOpened(false);
      },
      onRemove: () => {
        onChange?.('');
        setCurrentImage(null);
      },
    },
  };

  useEffect(() => {
    setCurrentImage(image || null);
  }, [image]);

  return (
    <>
      <button {...attrs.container}>
        <input {...attrs.input} />
        <div {...attrs.imageWrapper}>
          {image ? (
            <img {...attrs.image} alt="" />
          ) : (
            <PlaceholderIconComponent {...attrs.icon} />
          )}
        </div>
        <div {...attrs.circle}>
          {image ? (
            <EditIcon {...attrs.circleIcon} />
          ) : (
            <AddIcon {...attrs.circleIcon} />
          )}
        </div>
      </button>
      <ImageCropper {...attrs.imageCropper} />
    </>
  );
};
