import { Reducer } from 'redux';
import {
  CLEAR_TEAM_Q_CHARTS_ERRORS,
  CLEAR_TEAM_Q_STATE,
  SET_REQUESTED_TEAM_ID,
  SET_REQUESTED_TIME_PERIOD_IN_DAYS,
  SET_TEAM_Q_ACTIVITY,
  SET_TEAM_Q_ACTIVITY_ERR,
  SET_TEAM_Q_ACTIVITY_LOADING,
  SET_TEAM_Q_ACTIVITY_NO_DATA,
  SET_TEAM_Q_ANALYZED,
  SET_TEAM_Q_ANALYZED_ERR,
  SET_TEAM_Q_ANALYZED_LOADING,
  SET_TEAM_Q_ANALYZED_NO_DATA,
  SET_TEAM_Q_A_COMPETENCE,
  SET_TEAM_Q_A_COMPETENCE_ERR,
  SET_TEAM_Q_A_COMPETENCE_LOADING,
  SET_TEAM_Q_A_COMPETENCE_NO_DATA,
  SET_TEAM_Q_A_EMPATHY,
  SET_TEAM_Q_A_EMPATHY_ERR,
  SET_TEAM_Q_A_EMPATHY_LOADING,
  SET_TEAM_Q_A_EMPATHY_NO_DATA,
  SET_TEAM_Q_A_RESPONSIV,
  SET_TEAM_Q_A_RESPONSIV_ERR,
  SET_TEAM_Q_A_RESPONSIV_LOADING,
  SET_TEAM_Q_A_RESPONSIV_NO_DATA,
  SET_TEAM_Q_A_SELL_ZONE,
  SET_TEAM_Q_A_SELL_ZONE_ERR,
  SET_TEAM_Q_A_SELL_ZONE_LOADING,
  SET_TEAM_Q_A_SELL_ZONE_NO_DATA,
  SET_TEAM_Q_A_UH,
  SET_TEAM_Q_A_UH_ERR,
  SET_TEAM_Q_A_UH_LOADING,
  SET_TEAM_Q_A_UH_NO_DATA,
  SET_TEAM_Q_ESTIMATED_TEND,
  SET_TEAM_Q_ESTIMATED_TEND_ERR,
  SET_TEAM_Q_ESTIMATED_TEND_LOADING,
  SET_TEAM_Q_ESTIMATED_TEND_NO_DATA,
  SET_TEAM_Q_INIT_REQUEST_SENT,
  SET_TEAM_Q_SELL_ZONE,
  SET_TEAM_Q_SELL_ZONE_ERR,
  SET_TEAM_Q_SELL_ZONE_LOADING,
  SET_TEAM_Q_SELL_ZONE_NO_DATA,
  SET_TEAM_Q_SIMULATED,
  SET_TEAM_Q_SIMULATED_ERR,
  SET_TEAM_Q_SIMULATED_LOADING,
  SET_TEAM_Q_SIMULATED_NO_DATA,
  SET_TEAM_Q_THREAD_DURATION,
  SET_TEAM_Q_THREAD_DURATION_ERR,
  SET_TEAM_Q_THREAD_DURATION_LOADING,
  SET_TEAM_Q_THREAD_DURATION_NO_DATA,
  SET_TEAM_Q_THREAD_LENGTH,
  SET_TEAM_Q_THREAD_LENGTH_ERR,
  SET_TEAM_Q_THREAD_LENGTH_LOADING,
  SET_TEAM_Q_THREAD_LENGTH_NO_DATA,
  SET_TEAM_Q_UH,
  SET_TEAM_Q_UH_ERR,
  SET_TEAM_Q_UH_LOADING,
  SET_TEAM_Q_UH_NO_DATA,
} from './teamQ.actions';
import {
  TeamQActions,
  TeamQActivity,
  TeamQAnalyzed,
  TeamQDetailedCompetence,
  TeamQDetailedEmpathy,
  TeamQDetailedResponsiveness,
  TeamQDetailedSellZone,
  TeamQDetailedUH,
  TeamQETendencies,
  TeamQSellingZone,
  TeamQSimulated,
  TeamQThreadDuration,
  TeamQThreadLength,
  TeamQUpperHand,
} from './teamQ.types';

interface Status {
  isLoading: boolean;
  error: boolean;
  noData: boolean;
}
export interface TeamQState {
  requestedTimePeriodDays: number | null;

  requestedTeamId: string | null;

  initialReqestWasSent: boolean;

  activity: TeamQActivity | null;
  activityStatus: Status;

  upperHand: TeamQUpperHand | null;
  upperHandStatus: Status;

  estTendencies: TeamQETendencies | null;
  estTendenciesStatus: Status;

  sellingZone: TeamQSellingZone | null;
  sellingZoneStatus: Status;

  threadDuration: TeamQThreadDuration | null;
  threadDurationStatus: Status;

  threadLength: TeamQThreadLength | null;
  threadLengthStatus: Status;

  analyzed: TeamQAnalyzed | null;
  analyzedStatus: Status;

  simulated: TeamQSimulated | null;
  simulatedStatus: Status;

  analyticsUpperHand: TeamQDetailedUH | null;
  analyticsUpperHandStatus: Status;

  analyticsSellZone: TeamQDetailedSellZone | null;
  analyticsSellZoneStatus: Status;

  competence: TeamQDetailedCompetence | null;
  competenceStatus: Status;

  empathy: TeamQDetailedEmpathy | null;
  empathyStatus: Status;

  responsiveness: TeamQDetailedResponsiveness | null;
  responsivenessStatus: Status;
}

export const initialState: TeamQState = {
  requestedTimePeriodDays: null,

  requestedTeamId: null,

  initialReqestWasSent: false,

  activity: null,
  activityStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  upperHand: null,
  upperHandStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  estTendencies: null,
  estTendenciesStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  sellingZone: null,
  sellingZoneStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  threadDuration: null,
  threadDurationStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  threadLength: null,
  threadLengthStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  analyzed: null,
  analyzedStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  simulated: null,
  simulatedStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  analyticsUpperHand: null,
  analyticsUpperHandStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  analyticsSellZone: null,
  analyticsSellZoneStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  competence: null,
  competenceStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  empathy: null,
  empathyStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },

  responsiveness: null,
  responsivenessStatus: {
    isLoading: false,
    error: false,
    noData: false,
  },
};

export const teamQReducer: Reducer<TeamQState, TeamQActions> = (
  state: TeamQState = initialState,
  action: TeamQActions
): TeamQState => {
  switch (action.type) {
    case SET_TEAM_Q_INIT_REQUEST_SENT:
      return {
        ...state,
        initialReqestWasSent: true,
      };

    case SET_REQUESTED_TIME_PERIOD_IN_DAYS:
      return {
        ...state,
        requestedTimePeriodDays: action.payload,
      };

    case SET_REQUESTED_TEAM_ID:
      return {
        ...state,
        requestedTeamId: action.payload,
      };

    case SET_TEAM_Q_ACTIVITY:
      return {
        ...state,
        activity: action.payload,
      };
    case SET_TEAM_Q_ACTIVITY_LOADING:
      return {
        ...state,
        activityStatus: {
          ...state.activityStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_ACTIVITY_NO_DATA:
      return {
        ...state,
        activityStatus: {
          ...state.activityStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_ACTIVITY_ERR:
      return {
        ...state,
        activityStatus: {
          ...state.activityStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_UH:
      return {
        ...state,
        upperHand: action.payload,
      };
    case SET_TEAM_Q_UH_LOADING:
      return {
        ...state,
        upperHandStatus: {
          ...state.upperHandStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_UH_NO_DATA:
      return {
        ...state,
        upperHandStatus: {
          ...state.upperHandStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_UH_ERR:
      return {
        ...state,
        upperHandStatus: {
          ...state.upperHandStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_ESTIMATED_TEND:
      return {
        ...state,
        estTendencies: action.payload,
      };
    case SET_TEAM_Q_ESTIMATED_TEND_LOADING:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_ESTIMATED_TEND_NO_DATA:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_ESTIMATED_TEND_ERR:
      return {
        ...state,
        estTendenciesStatus: {
          ...state.estTendenciesStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_SELL_ZONE:
      return {
        ...state,
        sellingZone: action.payload,
      };
    case SET_TEAM_Q_SELL_ZONE_LOADING:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_SELL_ZONE_NO_DATA:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_SELL_ZONE_ERR:
      return {
        ...state,
        sellingZoneStatus: {
          ...state.sellingZoneStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_THREAD_DURATION:
      return {
        ...state,
        threadDuration: action.payload,
      };
    case SET_TEAM_Q_THREAD_DURATION_LOADING:
      return {
        ...state,
        threadDurationStatus: {
          ...state.threadDurationStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_THREAD_DURATION_NO_DATA:
      return {
        ...state,
        threadDurationStatus: {
          ...state.threadDurationStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_THREAD_DURATION_ERR:
      return {
        ...state,
        threadDurationStatus: {
          ...state.threadDurationStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_THREAD_LENGTH:
      return {
        ...state,
        threadLength: action.payload,
      };
    case SET_TEAM_Q_THREAD_LENGTH_LOADING:
      return {
        ...state,
        threadLengthStatus: {
          ...state.threadLengthStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_THREAD_LENGTH_NO_DATA:
      return {
        ...state,
        threadLengthStatus: {
          ...state.threadLengthStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_THREAD_LENGTH_ERR:
      return {
        ...state,
        threadLengthStatus: {
          ...state.threadLengthStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_ANALYZED:
      return {
        ...state,
        analyzed: action.payload,
      };
    case SET_TEAM_Q_ANALYZED_LOADING:
      return {
        ...state,
        analyzedStatus: {
          ...state.analyzedStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_ANALYZED_NO_DATA:
      return {
        ...state,
        analyzedStatus: {
          ...state.analyzedStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_ANALYZED_ERR:
      return {
        ...state,
        analyzedStatus: {
          ...state.analyzedStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_SIMULATED:
      return {
        ...state,
        simulated: action.payload,
      };
    case SET_TEAM_Q_SIMULATED_LOADING:
      return {
        ...state,
        simulatedStatus: {
          ...state.simulatedStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_SIMULATED_NO_DATA:
      return {
        ...state,
        simulatedStatus: {
          ...state.simulatedStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_SIMULATED_ERR:
      return {
        ...state,
        simulatedStatus: {
          ...state.simulatedStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_A_UH:
      return {
        ...state,
        analyticsUpperHand: action.payload,
      };
    case SET_TEAM_Q_A_UH_LOADING:
      return {
        ...state,
        analyticsUpperHandStatus: {
          ...state.analyticsUpperHandStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_A_UH_NO_DATA:
      return {
        ...state,
        analyticsUpperHandStatus: {
          ...state.analyticsUpperHandStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_A_UH_ERR:
      return {
        ...state,
        analyticsUpperHandStatus: {
          ...state.analyticsUpperHandStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_A_SELL_ZONE:
      return {
        ...state,
        analyticsSellZone: action.payload,
      };
    case SET_TEAM_Q_A_SELL_ZONE_LOADING:
      return {
        ...state,
        analyticsSellZoneStatus: {
          ...state.analyticsSellZoneStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_A_SELL_ZONE_NO_DATA:
      return {
        ...state,
        analyticsSellZoneStatus: {
          ...state.analyticsSellZoneStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_A_SELL_ZONE_ERR:
      return {
        ...state,
        analyticsSellZoneStatus: {
          ...state.analyticsSellZoneStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_A_COMPETENCE:
      return {
        ...state,
        competence: action.payload,
      };
    case SET_TEAM_Q_A_COMPETENCE_LOADING:
      return {
        ...state,
        competenceStatus: {
          ...state.competenceStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_A_COMPETENCE_NO_DATA:
      return {
        ...state,
        competenceStatus: {
          ...state.competenceStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_A_COMPETENCE_ERR:
      return {
        ...state,
        competenceStatus: {
          ...state.competenceStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_A_EMPATHY:
      return {
        ...state,
        empathy: action.payload,
      };
    case SET_TEAM_Q_A_EMPATHY_LOADING:
      return {
        ...state,
        empathyStatus: {
          ...state.empathyStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_A_EMPATHY_NO_DATA:
      return {
        ...state,
        empathyStatus: {
          ...state.empathyStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_A_EMPATHY_ERR:
      return {
        ...state,
        empathyStatus: {
          ...state.empathyStatus,
          error: true,
        },
      };

    case SET_TEAM_Q_A_RESPONSIV:
      return {
        ...state,
        responsiveness: action.payload,
      };
    case SET_TEAM_Q_A_RESPONSIV_LOADING:
      return {
        ...state,
        responsivenessStatus: {
          ...state.responsivenessStatus,
          isLoading: action.payload,
        },
      };
    case SET_TEAM_Q_A_RESPONSIV_NO_DATA:
      return {
        ...state,
        responsivenessStatus: {
          ...state.responsivenessStatus,
          noData: true,
        },
      };
    case SET_TEAM_Q_A_RESPONSIV_ERR:
      return {
        ...state,
        responsivenessStatus: {
          ...state.responsivenessStatus,
          error: true,
        },
      };

    case CLEAR_TEAM_Q_CHARTS_ERRORS:
      return {
        ...state,
        activityStatus: initialState.activityStatus,
        upperHandStatus: initialState.upperHandStatus,
        estTendenciesStatus: initialState.estTendenciesStatus,
        sellingZoneStatus: initialState.sellingZoneStatus,
        threadLengthStatus: initialState.threadLengthStatus,
        threadDurationStatus: initialState.threadDurationStatus,
        analyzedStatus: initialState.analyzedStatus,
        simulatedStatus: initialState.simulatedStatus,
        analyticsUpperHandStatus: initialState.analyticsUpperHandStatus,
        analyticsSellZoneStatus: initialState.analyticsSellZoneStatus,
        competenceStatus: initialState.competenceStatus,
        empathyStatus: initialState.empathyStatus,
        responsivenessStatus: initialState.responsivenessStatus,
      };

    case CLEAR_TEAM_Q_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
