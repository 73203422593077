import React from 'react';

export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8 11.9998C8.36819 11.9998 8.66667 11.7014 8.66667 11.3332V6.6665C8.66667 6.29831 8.36819 5.99984 8 5.99984C7.63181 5.99984 7.33333 6.29831 7.33333 6.6665V11.3332C7.33333 11.7014 7.63181 11.9998 8 11.9998Z"
      fill="#646F87"
    />
    <path
      d="M8 3.6665C8.46024 3.6665 8.83333 4.0396 8.83333 4.49984C8.83333 4.96007 8.46024 5.33317 8 5.33317C7.53976 5.33317 7.16667 4.96007 7.16667 4.49984C7.16667 4.0396 7.53976 3.6665 8 3.6665Z"
      fill="#646F87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33333 7.99984C1.33333 11.6817 4.3181 14.6665 8 14.6665C11.6819 14.6665 14.6667 11.6817 14.6667 7.99984C14.6667 4.31794 11.6819 1.33317 8 1.33317C4.3181 1.33317 1.33333 4.31794 1.33333 7.99984ZM8 13.3332C5.05448 13.3332 2.66667 10.9454 2.66667 7.99984C2.66667 5.05432 5.05448 2.6665 8 2.6665C10.9455 2.6665 13.3333 5.05432 13.3333 7.99984C13.3333 10.9454 10.9455 13.3332 8 13.3332Z"
      fill="#646F87"
    />
  </svg>
);
