import React from 'react';

export const ChevronDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.12203 10.7843C7.62473 11.2241 8.37534 11.2241 8.87804 10.7843L13.7723 6.50173C14.0494 6.25927 14.0775 5.8381 13.8351 5.56101C13.5926 5.28392 13.1714 5.25584 12.8943 5.4983L8.00004 9.78083L3.10568 5.49829C2.82859 5.25584 2.40741 5.28392 2.16496 5.56101C1.9225 5.8381 1.95058 6.25928 2.22767 6.50173L7.12203 10.7843Z"
      fill="#070B12"
    />
  </svg>
);
