import { Reducer } from 'redux';
import { Row, User } from '../../types';
import {
  CLEAR_TEAMS_ERRORS,
  CREATE_TEAM,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_SUCCESS,
  DELETE_TEAM_FAILURE,
  DELETE_TEAM_SUCCESS,
  GET_TEAMS,
  INVOKE_CREATE_TEAM_MODAL,
  INVOKE_DELETE_MEMBERS_MODAL,
  INVOKE_NEW_LEADER_MODAL,
  SET_CURRENT_MEMBER,
  SET_CURRENT_TEAM,
  SET_TEAMS_LOADING,
  UPDATE_TEAM_DETAILS,
} from './teams.actions';
import { UsersActions } from './teams.types';

export type Team = {
  avatar: string | null;
  createdAt: string;
  createdBy: User;
  members: Row[];
  name: string;
  teamId: string;
  updatedAt: string;
};

export type TeamsState = {
  teams: Team[];
  currentTeam: Team | null;
  currentMember: Row | null;
  success: {
    createTeam: boolean;
    deleteTeam: boolean;
    editTeam: boolean;
  };
  errors: {
    createTeam: boolean;
    deleteTeam: boolean;
    editTeam: boolean;
  };
  modals: {
    createTeamOpen: boolean;
    assignNewLeaderOpen: boolean;
    deleteMembersOpen: boolean;
  };
  isLoading: boolean;
};

export const initialState: TeamsState = {
  teams: [],
  currentTeam: null,
  currentMember: null,
  success: {
    createTeam: false,
    deleteTeam: false,
    editTeam: false,
  },
  errors: {
    createTeam: false,
    deleteTeam: false,
    editTeam: false,
  },
  modals: {
    createTeamOpen: false,
    assignNewLeaderOpen: false,
    deleteMembersOpen: false,
  },
  isLoading: false,
};

export const teamsReducer: Reducer<TeamsState, UsersActions> = (
  state: TeamsState = initialState,
  action: UsersActions
): TeamsState => {
  switch (action.type) {
    case SET_TEAMS_LOADING:
      return {
        ...state,
        isLoading: action.flag,
      };

    case GET_TEAMS:
      return {
        ...state,
        teams: action.payload,
      };

    case CREATE_TEAM:
      return {
        ...state,
        teams: state.teams
          ? [...state.teams, action.payload]
          : [action.payload],
      };

    case SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.payload,
      };

    case SET_CURRENT_MEMBER:
      return {
        ...state,
        currentMember: action.payload,
      };

    case UPDATE_TEAM_DETAILS:
      return {
        ...state,
        teams:
          state &&
          state.teams &&
          state.teams.map((team) =>
            team.teamId === action.payload.teamId ? action.payload : team
          ),
        currentTeam: action.payload,
      };

    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          createTeam: true,
        },
      };

    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          createTeam: true,
        },
      };

    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        success: {
          ...state.success,
          deleteTeam: true,
        },
      };

    case DELETE_TEAM_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          deleteTeam: true,
        },
      };

    case INVOKE_DELETE_MEMBERS_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          deleteMembersOpen: action.flag,
        },
      };

    case INVOKE_NEW_LEADER_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          assignNewLeaderOpen: action.flag,
        },
      };

    case INVOKE_CREATE_TEAM_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          createTeamOpen: action.flag,
        },
      };
    case CLEAR_TEAMS_ERRORS:
      return {
        ...state,
        errors: {
          ...initialState.errors,
        },
        success: {
          ...initialState.success,
        },
      };
    default:
      return state;
  }
};
