import { Dispatch } from 'redux';
import axios from '../../axios';
import {
  AnalyticsActions,
  ClearAnalytics,
  GetDataFirstTime,
  GetQDataForIframe,
  NotEnoughData,
  SetError,
  SetLoading,
} from './analytics.types';

export const GET_QDATA_FOR_IFRAME = 'GET_QDATA_FOR_IFRAME';

export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const SET_SUMMARY = 'SET_SUMMARY';
export const SET_LOADING = 'SET_LOADING';
export const SET_NOT_ENOUGH_DATA = 'SET_NOT_ENOUGH_DATA';
export const SET_ERROR = 'SET_ERROR';
export const GET_DATA_FIRST_TIME = 'GET_DATA_FIRST_TIME';
export const CLEAR_ANALYTICS = 'CLEAR_ANALYTICS';
export const GET_CHARTS_DATA = 'GET_CHARTS_DATA';

export const setLoading = (flag: boolean): SetLoading => ({
  type: SET_LOADING,
  flag,
});
export const setNotEnoughData = (flag: boolean): NotEnoughData => ({
  type: SET_NOT_ENOUGH_DATA,
  flag,
});
export const setError = (flag: boolean): SetError => ({
  type: SET_ERROR,
  flag,
});
export const getDataCalledFirstTime = (): GetDataFirstTime => ({
  type: GET_DATA_FIRST_TIME,
});

export const clearAnalytics = (): ClearAnalytics => ({ type: CLEAR_ANALYTICS });

export const GetAnalyticsData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string
) => async (dispatch: Dispatch<AnalyticsActions>): Promise<void> => {
  dispatch({ type: CLEAR_ANALYTICS });
  dispatch({ type: SET_LOADING, flag: true });
  setTimeout(() => {
    dispatch({ type: SET_LOADING, flag: false });
  }, 100);
  setTimeout(async () => {
    try {
      const response = await axios.get(
        `/services/orgs/${orgId}/users/${userId}/personalq/report?fromDate=${fromDate}&toDate=${toDate}`
      );
      if (response.data.payload.measurements && response.data.payload.stats) {
        dispatch({
          type: GET_ANALYTICS_DATA,
          payload: {
            stats: response.data.payload.stats,
            measurements: {
              empathy: response.data.payload.measurements.empathy,
              responsiveness: response.data.payload.measurements.responsiveness,
              resilience: response.data.payload.measurements.resilience,
              intuition: response.data.payload.measurements.intuition,
              subtleness: response.data.payload.measurements.subtleness,
              overallCompetence:
                response.data.payload.measurements.overallCompetence,
            },
          },
        });
      } else {
        dispatch({
          type: GET_ANALYTICS_DATA,
          payload: {
            stats: response.data.payload.stats,
            measurements: response.data.payload.measurements,
          },
        });
        dispatch({
          type: SET_NOT_ENOUGH_DATA,
          flag: true,
        });
      }
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        flag: true,
      });
    }
  }, 3000);
};

export const getQDataForIframe = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string
) => async (
  dispatch: Dispatch<GetQDataForIframe | SetLoading>
): Promise<void> => {
  dispatch({ type: SET_LOADING, flag: true });
  try {
    const qDataResponse = await axios.get(
      `/services/orgs/${orgId}/users/${userId}/personalq/report?fromDate=${fromDate}&toDate=${toDate}`
    );

    dispatch({
      type: GET_QDATA_FOR_IFRAME,
      payload: {
        value:
          qDataResponse?.data?.payload?.measurements?.overallCompetence || null,
        userMetadata: {
          avatar: qDataResponse?.data?.payload?.userMetadata?.avatar || null,
          email: qDataResponse?.data?.payload?.userMetadata?.email || null,
          name: qDataResponse?.data?.payload?.userMetadata?.name || null,
        },
      },
    });

    dispatch({ type: SET_LOADING, flag: false });
  } catch (e) {
    dispatch({ type: SET_LOADING, flag: false });
  }
};

export const getStatsData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string
) => async (dispatch: Dispatch<AnalyticsActions>): Promise<void> => {
  dispatch({ type: CLEAR_ANALYTICS });
  try {
    const statsResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/general-info?fromDate=${fromDate}&toDate=${toDate}`
    );
    if (statsResponse.data) {
      dispatch({
        type: GET_CHARTS_DATA,
        payload: statsResponse.data.payload,
      });
    }
  } catch (e) {
    dispatch({
      type: SET_ERROR,
      flag: true,
    });
  }
};
