import React from 'react';

export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 14.6668C11.6825 14.6668 14.6673 11.6821 14.6673 8.00016C14.6673 4.31826 11.6825 1.3335 8.00065 1.3335C4.31875 1.3335 1.33398 4.31826 1.33398 8.00016C1.33398 11.6821 4.31875 14.6668 8.00065 14.6668ZM8.66732 5.3335C8.66732 4.96531 8.36884 4.66683 8.00065 4.66683C7.63246 4.66683 7.33398 4.96531 7.33398 5.3335V7.3335H5.33398C4.96579 7.3335 4.66732 7.63197 4.66732 8.00016C4.66732 8.36835 4.96579 8.66683 5.33398 8.66683H7.33398V10.6668C7.33398 11.035 7.63246 11.3335 8.00065 11.3335C8.36884 11.3335 8.66732 11.035 8.66732 10.6668V8.66683H10.6673C11.0355 8.66683 11.334 8.36835 11.334 8.00016C11.334 7.63197 11.0355 7.3335 10.6673 7.3335H8.66732V5.3335Z"
      fill="#646F87"
    />
  </svg>
);
