import { Team } from './teams.reducer';

export const getTeamsUsersRow = (teams: Team[]): Team[] =>
  teams.map((el: Team) => ({
    ...el, // eslint-disable-next-line
    members: el.members.map((member: any) => ({
      ...member,
      userId: member.userId,
      key: member.userId,
      status: member.active ? 'active' : 'inactive',
      options: member,
    })),
  }));
export const getTeamRow = (team: Team): Team => ({
  ...team, // eslint-disable-next-line
  members: team.members.map((member: any) => ({
    ...member,
    userId: member.userId,
    key: member.userId,
    status: member.active ? 'active' : 'inactive',
    options: member,
  })),
});
