import React from 'react';
import { Breadcrumbs } from './components/Breadcrumbs/Breadcrumbs';
import { RelationshipsSearch } from './components/RelationshipsSearch/RelationshipsSearch';
import { HeaderAccount } from './components/HeaderAccount/HeaderAccount';
import { NavigationTabs } from './components/NavigationTabs/NavigationTabs';

export const GlobalHeader: React.FC = () => {
  const attrs = {
    container: {
      className: 'global-header',
    },
    left: {
      className: 'global-header__left',
    },
    right: {
      className: 'global-header__right',
    },
    notifications: {
      className: 'global-header__notifications',
    },
    notificationsButton: {
      type: 'button' as const,
      'aria-label': 'Notifications',
      className: 'global-header__notifications-button',
      onClick: () => {},
    },
  };

  return (
    <header {...attrs.container}>
      <div {...attrs.left}>
        <NavigationTabs />
        <Breadcrumbs />
        <RelationshipsSearch />
      </div>
      <div {...attrs.right}>
        {/* TODO: Add notifications popup */}
        {/* <div {...attrs.notifications}>
          <button {...attrs.notificationsButton}>
            <NotificationIcon />
          </button>
        </div> */}
        <HeaderAccount />
      </div>
    </header>
  );
};
