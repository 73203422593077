import React, { useMemo } from 'react';
import { getStringTokens } from '../../../utils';
import {
  Avatar,
  AvatarSize,
} from '../../../SoundWave/components/Avatar/Avatar';
import { LogoPlaceholderIcon } from '../../../Icons';

export interface AccountOptionProps {
  name: string;
  search?: string;
  logo?: string | null;
}

export const AccountOption: React.FC<AccountOptionProps> = ({
  name,
  search,
  logo,
}) => {
  const tokens = useMemo(() => getStringTokens(name, search), [name, search]);

  const attrs = {
    container: {
      className: 'account-option',
    },
    avatar: {
      src: logo || '',
      size: AvatarSize.XS,
      shape: 'square' as const,
      placeholder: LogoPlaceholderIcon,
      hasBorder: !logo,
    },
    textWrapper: {
      className: 'account-option__text-wrapper',
    },
    text: {
      className: 'account-option__text',
    },
    textHighlighted: {
      className: 'account-option__text account-option__text--highlighted',
    },
  };

  const renderNameText = () =>
    tokens.map(({ value, isHighlighted }, index) => {
      const props = {
        key: index,
        className: isHighlighted
          ? attrs.textHighlighted.className
          : attrs.text.className,
      };

      return <span {...props}>{value}</span>;
    });

  return (
    <span {...attrs.container}>
      <Avatar {...attrs.avatar} />
      <span {...attrs.textWrapper}>{renderNameText()}</span>
    </span>
  );
};
