import { IncomingEmailActivity } from './charts.types';

export const getUserLocalTimezone = (isForQuery?: boolean): string => {
  let gmtTimezone: string | number = new Date().getTimezoneOffset();

  if (+gmtTimezone < 0) {
    gmtTimezone = `GMT${isForQuery ? '%2b' : '+'}${
      -new Date().getTimezoneOffset() / 60
    }`;
  } else if (+gmtTimezone > 0) {
    gmtTimezone = `GMT-${new Date().getTimezoneOffset() / 60}`;
  } else {
    gmtTimezone = `GMT${isForQuery ? '%2b' : '+'}${
      new Date().getTimezoneOffset() / 60
    }`;
  }

  return gmtTimezone;
};

export const renameHeatMapsTimeKeys = (
  object: IncomingEmailActivity
): IncomingEmailActivity => {
  let resultToReturn = object;

  // eslint-disable-next-line
  const dataObject: any = {
    Mon: {},
    Tue: {},
    Wed: {},
    Thu: {},
    Fri: {},
    Sat: {},
    Sun: {},
    highestValue: 0,
    lowestValue: 0,
  };

  const setKeyName = (index: number) => {
    let result = null;
    if (index === 0) {
      result = 'fourAm';
    } else if (index === 1) {
      result = 'eightAm';
    } else if (index === 2) {
      result = 'twelveAm';
    } else if (index === 3) {
      result = 'fourPm';
    } else if (index === 4) {
      result = 'eightPm';
    } else if (index === 5) {
      result = 'twelvePm';
    }

    return result;
  };

  if (Object.values(object).length) {
    const monVals: number[] = Object.values(object.Mon).map((el) => el);
    const tueVals: number[] = Object.values(object.Tue).map((el) => el);
    const wedVals: number[] = Object.values(object.Wed).map((el) => el);
    const thuVals: number[] = Object.values(object.Thu).map((el) => el);
    const friVals: number[] = Object.values(object.Fri).map((el) => el);
    const satVals: number[] = Object.values(object.Sat).map((el) => el);
    const sunVals: number[] = Object.values(object.Sun).map((el) => el);

    monVals.forEach((el, i) => {
      dataObject.Mon[`${setKeyName(i)}`] = el;
    });
    tueVals.forEach((el, i) => {
      dataObject.Tue[`${setKeyName(i)}`] = el;
    });
    wedVals.forEach((el, i) => {
      dataObject.Wed[`${setKeyName(i)}`] = el;
    });
    thuVals.forEach((el, i) => {
      dataObject.Thu[`${setKeyName(i)}`] = el;
    });
    friVals.forEach((el, i) => {
      dataObject.Fri[`${setKeyName(i)}`] = el;
    });
    satVals.forEach((el, i) => {
      dataObject.Sat[`${setKeyName(i)}`] = el;
    });
    sunVals.forEach((el, i) => {
      dataObject.Sun[`${setKeyName(i)}`] = el;
    });

    const arrayOfValues = monVals.concat(
      tueVals,
      wedVals,
      thuVals,
      friVals,
      satVals,
      sunVals
    );

    const maxValue = Math.max(...arrayOfValues);
    const minValue = Math.min(...arrayOfValues);

    resultToReturn = {
      ...dataObject,
      highestValue: maxValue,
      lowestValue: minValue,
    };
  }

  return resultToReturn;
};
