import { Role } from '../types';

export enum FilterType {
  SELECT = 'select',
  RANGE = 'range',
  DATE_RANGE = 'dateRange',
  CHECKBOX = 'checkbox',
}

export enum RangeFilterMaskType {
  CURRENCY = 'currency',
}

export type RangeFilterValue = {
  min: number;
  max: number;
};

export type DateRangeFilterValue<T> = {
  selectValue: T;
  startDate: string | null;
  endDate: string | null;
};

export type FilterSelectOption<T> = {
  value: T;
  label: string;
};

export enum RelationshipOwnerFilterValue {
  DEFAULT = 'default',
}

export enum LastInteractionDateFilterValue {
  ALL = 'all',
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export enum RelationshipAgeFilterValue {
  ALL = 'all',
  LESS_THAN_1_MONTH = '|1', // 'relationshipAgeMin|relationshipAgeMax' ?relationshipAgeMax=1
  BETWEEN_1_3_MONTHS = '1|3', // ?relationshipAgeMin=1&relationshipAgeMax=3
  BETWEEN_3_6_MONTHS = '3|6', // ?relationshipAgeMin=3&relationshipAgeMax=6
  BETWEEN_6_12_MONTHS = '6|12', // ?relationshipAgeMin=6&relationshipAgeMax=12
  BETWEEN_12_24_MONTHS = '12|24', // ?relationshipAgeMin=12&relationshipAgeMax=24
  MORE_THAN_24_MONTHS = '24|', // ?relationshipAgeMin=24
}

export enum DealStageFilterValue {
  ALL = 'all',
  LEAD_GENERATION = 'leadGeneration',
  INTIAL_CONTACT = 'initialContact',
  QUALIFICATION = 'qualification',
  PROPOSAL_SENT = 'proposalSent',
  OPPORTUNITY = 'opportunity',
  NEGOTIATIONS = 'negotiations',
  VERBAL_COMMITMENT = 'verbalCommitment',
  CLOSED_WON = 'closedWon',
  CLOSED_LOST = 'closedLost',
}

export type FilterValue =
  | RelationshipOwnerFilterValue
  | RelationshipAgeFilterValue
  | DealStageFilterValue
  | DateRangeFilterValue<LastInteractionDateFilterValue>
  | RangeFilterValue
  | boolean;

export interface Filter<T extends FilterValue> {
  id: string;
  value: T;
  defaultValue: T;
  previousValue: T;
  label: string;
  type: FilterType;
  isActive: boolean;
  groupId?: string;
  restrictedFor?: Role[];
  name?: string;
  selectOptions?: FilterSelectOption<T>[];
  maskType?: RangeFilterMaskType;
  isDisabled?: boolean;
}
