import React from 'react';

export const TeamQIcon: React.FC<
  React.SVGProps<SVGSVGElement> & {
    isActive?: boolean;
  }
> = ({ isActive = false, ...rest }) =>
  isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10ZM11 21V17C11 15.8954 11.8954 15 13 15H14C15.1046 15 16 15.8954 16 17V21H11ZM17 11V21H22V11C22 9.89543 21.1046 9 20 9H19C17.8954 9 17 9.89543 17 11ZM2 18V20.2063C2 20.6447 2.35533 21 2.79365 21H10V17C10 15.3431 11.3431 14 13 14H14C14.2856 14 14.5618 14.0399 14.8235 14.1144C13.568 12.2366 11.4284 11 9 11C5.13401 11 2 14.134 2 18Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6ZM9 2C6.79086 2 5 3.79086 5 6C5 8.20914 6.79086 10 9 10C11.2091 10 13 8.20914 13 6C13 3.79086 11.2091 2 9 2ZM4 18C4 15.2386 6.23858 13 9 13C10.0237 13 10.9728 13.3066 11.7641 13.8327C12.224 14.1385 12.8447 14.0136 13.1505 13.5537C13.4563 13.0938 13.3314 12.4731 12.8715 12.1673C11.7622 11.4297 10.4298 11 9 11C5.13401 11 2 14.134 2 18V19.2157C2 20.2011 2.79887 21 3.78431 21H9C9.55229 21 10 20.5523 10 20C10 19.4477 9.55229 19 9 19H4V18ZM11 17C11 15.8954 11.8954 15 13 15H14C15.1046 15 16 15.8954 16 17V20V21H15H12H11V20V17ZM14 17H13V19H14V17ZM19 9C17.8954 9 17 9.89543 17 11V20V21H18H21H22V20V11C22 9.89543 21.1046 9 20 9H19ZM19 11H20V19H19V11Z"
        fill="white"
      />
    </svg>
  );
