import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getRelationshipAccountDetails } from '../store/relationshipDetails/relationshipDetails.thunks';

export const useGetRelationshipAccountDetails = (): void => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);

  const { accountId } = useParams<{ accountId: string }>();

  useEffect(() => {
    const request =
      accountId && accountId !== account?.accountId
        ? dispatch(getRelationshipAccountDetails(accountId))
        : null;

    return () => {
      request?.abort();
    };
  }, [accountId, dispatch, account]);
};
