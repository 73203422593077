import { Reducer } from 'redux';
import {
  MeetingInfo,
  MeetingsData,
  MyMeetingsActions,
} from './myMeetings.types';
import { GET_MEETINGS, SET_MEETINGS_LOADING } from './myMeetings.actions';

export interface MyMeetingsState {
  myMeetings: MeetingsData | null;
  futureMeetings: MeetingInfo[] | null;
  pastMeetings: MeetingInfo[] | null;
  isLoading: boolean;
}

export const initialState: MyMeetingsState = {
  myMeetings: null,
  futureMeetings: null,
  pastMeetings: null,
  isLoading: false,
};

const separateMeetings = (meetings: MeetingsData) => {
  const now = new Date().toISOString();

  const futureMeets =
    meetings.data.filter(
      (meeting) =>
        meeting.start >= now || (meeting.start < now && meeting.end >= now)
    ) || null;

  const pastMeets =
    meetings.data.filter((meeting) => meeting.end < now) || null;

  return { futureMeets, pastMeets };
};

export const myMeetingsReducer: Reducer<MyMeetingsState, MyMeetingsActions> = (
  state: MyMeetingsState = initialState,
  action: MyMeetingsActions
): MyMeetingsState => {
  switch (action.type) {
    case GET_MEETINGS:
      return {
        ...state,
        myMeetings: action.payload,
        futureMeetings: separateMeetings(action.payload).futureMeets,
        pastMeetings: separateMeetings(action.payload).pastMeets,
      };

    case SET_MEETINGS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};
