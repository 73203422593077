import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash.debounce';
import { useRouteMatch } from 'react-router-dom';
import { useId, useMixpanel } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  closeAssignAccountModal,
  resetAssignAccountFlow,
  updateSelectedAccount,
} from '../../../store/relationshipFlows/relationshipFlows.slice';
import { AssignAccountFormValues } from '../../../store/relationshipFlows/relationshipFlows.types';
import {
  FormFieldProps,
  FormFieldType,
} from '../../SoundWave/components/FormField/FormField';
import { ModalProps } from '../../SoundWave/components/Modal/Modal';
import { FormProps } from '../../SoundWave/components/Form/Form';
import { AddNewAccountButton } from '../components/AddNewAccountButton/AddNewAccountButton';
import {
  assignEventToAccount,
  getAccounts,
  getExistingAccountSuggestions,
} from '../../../store/relationshipFlows/relationshipFlows.thunks';
import { AccountOption } from '../components/AccountOption/AccountOption';
import { showBottomNotification } from '../../../store/bottomNotification/bottomNotification.slice';
import { PATHS } from '../../../constants/paths';

const validationSchema = Yup.object().shape({
  accountId: Yup.string(),
});

export const useAssignAccountForm = (): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<keyof AssignAccountFormValues, FormFieldProps>;
} => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.isModalOpened
  );

  const isLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.assignEventToAccount
  );

  const isReassignFlow = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.isReassignFlow
  );

  const selectedAccount = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.selectedAccount
  );

  const existingAccountSuggestions = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.existingAccountSuggestions
  );

  const eventId = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.eventId
  );

  const [inputValue, setInputValue] = useState('');

  const accountOptions = useMemo(
    () =>
      [
        ...existingAccountSuggestions,
        ...(selectedAccount ? [{ ...selectedAccount, isHidden: true }] : []),
      ].map((account, index) => {
        const props = {
          key: index,
          name: account.name,
          logo: account.avatarSrc,
          search: inputValue,
        };

        return {
          value: account.accountId,
          label: account.name,
          dropdownLabel: <AccountOption {...props} />,
          isHidden: 'isHidden' in account ? account.isHidden : false,
        };
      }),
    [existingAccountSuggestions, inputValue, selectedAccount]
  );

  const formId = useId();

  const meetingRecordsMatch = useRouteMatch(PATHS.MEETING_RECORDS);

  const { trackEvent } = useMixpanel();

  const exisitingAccountSuggestionsRequest = useRef<
    (Promise<unknown> & { abort: () => void }) | null
  >(null);

  const updateExistingAccountSuggestions = useCallback(
    debounce((name: string) => {
      exisitingAccountSuggestionsRequest.current = dispatch(
        getExistingAccountSuggestions(name)
      );
    }, 300),
    [dispatch]
  );

  const {
    errors,
    isValid,
    touched,
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik<AssignAccountFormValues>({
    initialValues: {
      accountId: selectedAccount?.accountId || '',
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit: async ({ accountId }, helpers) => {
      try {
        trackEvent('assignEvent');

        await dispatch(
          assignEventToAccount({ eventId: eventId || '', accountId })
        ).unwrap();

        dispatch(closeAssignAccountModal());

        if (meetingRecordsMatch) {
          dispatch(
            showBottomNotification({
              text: 'Changes applied successfully',
            })
          );
        } else {
          dispatch(
            showBottomNotification({
              text: isReassignFlow
                ? 'Event was reassigned'
                : 'Account was assigned',
            })
          );
        }
      } catch (error) {
        helpers.setFieldError('accountId', 'Failed to assign account');
      }
    },
  });

  const { accountId } = values;

  const modalTitle = isReassignFlow
    ? 'Reassign event to a different relationship'
    : 'Associate to Account';

  const fieldLabel = isReassignFlow
    ? 'Select Relationship by Account Name'
    : 'Select Account';

  const modalProps = useMemo(
    () => ({
      isOpened,
      closeOnClickOutside: true,
      width: 416,
      onClose: () => {
        dispatch(closeAssignAccountModal());
      },
      onAnimationEnd: () => {
        dispatch(resetAssignAccountFlow());
      },
      headerProps: {
        title: meetingRecordsMatch ? 'Assign to Relationship' : modalTitle,
      },
      footerProps: {
        formId,
        isLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: meetingRecordsMatch ? 'Assign' : 'Apply',
        isConfirmButtonDisabled: !isValid || !accountId,
      },
    }),
    [
      accountId,
      dispatch,
      formId,
      isLoading,
      isOpened,
      isValid,
      meetingRecordsMatch,
      modalTitle,
    ]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      accountId: {
        type: FormFieldType.SELECT,
        withAutocomplete: true,
        name: 'accountId',
        label: meetingRecordsMatch
          ? 'Assign to Relationship by Account Name'
          : fieldLabel,
        value: accountId,
        placeholder: 'Select',
        error: errors.accountId,
        touched: touched.accountId,
        renderDropdownContent: () => <AddNewAccountButton />,
        onChange: (_: string, value: string) => {
          const account = existingAccountSuggestions.find(
            (accounts) => accounts.accountId === value
          );

          dispatch(updateSelectedAccount(account || null));
        },
        onInputChange: (value: string) => {
          exisitingAccountSuggestionsRequest.current?.abort();
          setInputValue(value);
          updateExistingAccountSuggestions(value);
        },
        options: accountOptions,
      },
    }),
    [
      accountId,
      accountOptions,
      dispatch,
      errors,
      existingAccountSuggestions,
      fieldLabel,
      meetingRecordsMatch,
      touched,
      updateExistingAccountSuggestions,
    ]
  );

  useEffect(() => {
    if (isOpened) {
      dispatch(getAccounts());
    }
  }, [dispatch, isOpened]);

  useEffect(() => {
    setFieldValue('accountId', selectedAccount?.accountId || '');
    setInputValue(selectedAccount?.name || '');
  }, [selectedAccount, setFieldValue]);

  return {
    modalProps,
    formProps,
    fieldProps,
  };
};
