import React from 'react';

export const LoaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.03345 12C3.03345 16.9521 7.04791 20.9665 12 20.9665C16.9521 20.9665 20.9665 16.9521 20.9665 12C20.9665 7.04791 16.9521 3.03345 12 3.03345C7.04791 3.03345 3.03345 7.04791 3.03345 12Z"
      fill="#F8FAFC"
    />
    <path
      d="M22.4843 11.9995C23.3214 11.9995 24.0099 11.3182 23.9044 10.4878C23.5712 7.86272 22.3764 5.40536 20.4853 3.51423C18.2348 1.26379 15.1826 -0.000488041 12 -0.000488281C8.8174 -0.000488522 5.76516 1.26379 3.51472 3.51423C1.62358 5.40536 0.428846 7.86272 0.0955515 10.4878C-0.00988021 11.3182 0.67858 11.9995 1.51565 11.9995C2.35272 11.9995 3.01824 11.3161 3.15904 10.491C3.46939 8.67212 4.33609 6.97977 5.65817 5.65768C7.34013 3.97573 9.62135 3.03081 12 3.03081C14.3786 3.03081 16.6599 3.97573 18.3418 5.65768C19.6639 6.97977 20.5306 8.67212 20.841 10.491C20.9818 11.3162 21.6473 11.9995 22.4843 11.9995Z"
      fill="#18222F"
    />
  </svg>
);
