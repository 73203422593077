import React from 'react';

export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M0 24.6154H7.58974V7.58974H24.6154V0H3.79487C1.26496 0 0 1.19658 0 3.58974"
      fill="#4285F4"
    />
    <path
      d="M24.4102 0V7.79487H31.9999V3.58974C31.9999 1.19658 30.8033 0 28.4102 0"
      fill="#1967D2"
    />
    <path d="M24.4102 7.58984H31.9999V24.4104H24.4102" fill="#FBBC04" />
    <path
      d="M0 24.4102V28.4102C0 30.8033 1.19658 31.9999 3.58974 31.9999H7.79487V24.4102"
      fill="#188038"
    />
    <path d="M7.58984 24.4102H24.4104V31.9999H7.58984" fill="#34A853" />
    <path d="M24.4102 31.9999V24.4102H31.9999" fill="#EA4335" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0433 10.7693L20.2052 10.718H20.6155V10.7693H21.3847V21.3334H19.8463V12.6805L17.8787 14.065L16.9933 12.8069L19.7626 10.8582L19.8463 10.8317V10.7693H20.0433ZM13.768 12.3469C13.4359 12.2464 13.0765 12.2294 12.7335 12.2988C12.3906 12.3682 12.0883 12.5189 11.8568 12.7228C11.6261 12.926 11.4772 13.1712 11.4112 13.4246L9.92236 13.0371C10.0705 12.4681 10.3938 11.9613 10.8399 11.5683C11.2852 11.1761 11.837 10.9106 12.4284 10.7909C13.0197 10.6712 13.6373 10.6999 14.214 10.8745C14.7907 11.0492 15.313 11.3661 15.7154 11.8007C16.1186 12.2362 16.3856 12.7737 16.4705 13.3555C16.5555 13.9382 16.452 14.5266 16.1786 15.05C15.9849 15.4209 15.7136 15.7451 15.3869 16.0088C15.711 16.2735 15.9802 16.5974 16.1728 16.9669C16.445 17.4894 16.5495 18.0762 16.466 18.6575C16.3827 19.238 16.1176 19.7742 15.7158 20.2082C15.3148 20.6412 14.7939 20.9559 14.2187 21.1284C13.6435 21.301 13.0276 21.3276 12.4376 21.207C11.8476 21.0865 11.2966 20.8213 10.8506 20.4311C10.4039 20.0402 10.0776 19.5365 9.9243 18.9704L11.4093 18.5682C11.4781 18.8224 11.6301 19.0688 11.8637 19.2732C12.098 19.4782 12.4023 19.6296 12.7456 19.6997C13.0888 19.7698 13.447 19.7537 13.7767 19.6548C14.1062 19.556 14.3845 19.3815 14.5869 19.163C14.7884 18.9454 14.9066 18.6935 14.9432 18.4388C14.9797 18.1848 14.9358 17.9224 14.8084 17.6779C14.6802 17.4318 14.4689 17.2079 14.1853 17.042C13.9041 16.8775 13.5695 16.7807 13.2193 16.7693H12.3078V15.2308H13.2233C13.5757 15.2233 13.9118 15.1293 14.1936 14.9669C14.4767 14.8037 14.6874 14.5821 14.8149 14.3378C14.9417 14.0951 14.9854 13.833 14.9481 13.5776C14.9107 13.3213 14.7908 13.0666 14.5865 12.8459C14.3813 12.6243 14.0999 12.4475 13.768 12.3469Z"
      fill="#4285F4"
    />
  </svg>
);
