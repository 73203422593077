import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Modal } from '../Modal/Modal';

export const ExtensionNIWarnModal: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [isOpen, setOpen] = useState(false);

  const history = useHistory();

  const [checkboxValue, setCheckboxValue] = useState(false);

  const { search } = useLocation();

  const showPopupQuery = new URLSearchParams(search).get('show-warning-popup');

  useEffect(() => {
    if (showPopupQuery && !isOpen) {
      setOpen(true);
    } // eslint-disable-next-line
  }, [search, showPopupQuery]);

  const updateCheckboxValue = (flag: boolean) => {
    setCheckboxValue(flag);
  };

  const goToStore = () => {
    window.location.href =
      'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka';
  };

  const goToEngagements = () => {
    if (isOpen) {
      setOpen(false);
    }

    if (user) {
      const engagementsPath = `/orgs/${user.orgId}/users/${user.userId}/engagements`;

      history.push(engagementsPath);
    }
  };

  const attrs = {
    Modal: {
      open: isOpen,
      title: '',
      onClose: () => setOpen(false),
      empty: true,
      className: 'warn-modal',
    },

    body: {
      wrapper: {
        className: 'warn-modal__body',
      },

      title: {
        className: 'warn-modal__body-title',
      },

      subtitle: {
        className: 'warn-modal__body-subtitle',
      },

      button: {
        className: 'warn-modal__body-button',
        onClick: goToStore,
      },

      noThanks: {
        wrapper: {
          className: 'warn-modal__body__no-thanks',
        },

        link: {
          className: 'warn-modal__body__no-thanks-link',
          onClick: goToEngagements,
        },
      },

      footer: {
        wrapper: {
          className: 'warn-modal__body__footer',
        },

        checkbox: {
          className: 'warn-modal__body__footer-checkbox',
          type: 'checkbox',
          changeValue: updateCheckboxValue,
          defaultActive: checkboxValue,
        },

        text: {
          className: 'warn-modal__body__footer-text',
        },
      },
    },
  };

  return (
    <Modal {...attrs.Modal}>
      <div {...attrs.body.wrapper}>
        <div {...attrs.body.title}>Discover Substrata Q</div>
        <div {...attrs.body.subtitle}>
          Substrata Q for Google Workspace / Gmail requires you to{'\n'} install
          a Chrome Extension.
        </div>
        <div {...attrs.body.button}>Add to Chrome</div>
        <div {...attrs.body.noThanks.wrapper}>
          No thanks, just take me to the{' '}
          <div {...attrs.body.noThanks.link}>dashboard</div>
        </div>
        {/* <div {...attrs.body.footer.wrapper}>
          <Checkbox {...attrs.body.footer.checkbox} />
          <div {...attrs.body.footer.text}>Don&apos;t show again</div>
        </div> */}
      </div>
    </Modal>
  );
};
