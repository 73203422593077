export const getAvatarBase64 = (
  avatar: string
): { contentType: string; b64content: string } | null => {
  if (!avatar) {
    return null;
  }

  const [avatarContentType = '', avatarBase64 = ''] = avatar.split(';');

  return {
    contentType: avatarContentType.replace('data:', ''),
    b64content: avatarBase64.replace('base64,', ''),
  };
};
