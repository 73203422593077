import { Reducer } from 'redux';
import { GET_APP_SUMO } from './appSumo.actions';
import { AppSumo, AppSumoActions } from './appSumo.types';

export type AppSumoState = AppSumo;

const initialState: AppSumoState = {
  planId: null,
  changePlanUrl: null,
};

export const appSumoReducer: Reducer<AppSumoState, AppSumoActions> = (
  state: AppSumoState = initialState,
  action: AppSumoActions
): AppSumoState => {
  switch (action.type) {
    case GET_APP_SUMO:
      return {
        ...state,
        planId: action?.payload?.planId || null,
        changePlanUrl: action?.payload?.changePlanUrl || null,
      };
    default:
      return state;
  }
};
