import React from 'react';
import { PlusIcon } from './icons/PlusIcon';
import { useAppDispatch } from '../../../../store/hooks';
import { openAddAccountModal } from '../../../../store/relationshipFlows/relationshipFlows.slice';

export const AddNewAccountButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const attrs = {
    button: {
      type: 'button' as const,
      className: 'add-new-account-button',
      onClick: () => {
        dispatch(openAddAccountModal({}));
      },
    },
    icon: {
      className: 'add-new-account-button__icon',
    },
  };

  return (
    <button {...attrs.button}>
      <PlusIcon {...attrs.icon} />
      <span>Create a New Account</span>
    </button>
  );
};
