import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getWebhookEvents, getWebhookSettings } from '../store/org/org.actions';

export const useGetAdminSettingsData = (): void => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const isWebhookInitialized = useAppSelector(
    (state) => state.org.webhook.isInitialized
  );

  useEffect(() => {
    if (
      user?.orgId &&
      user?.userId &&
      user?.role !== 'dealmaker' &&
      !isWebhookInitialized
    ) {
      dispatch(getWebhookEvents(user.orgId, user.userId));
      dispatch(getWebhookSettings(user.orgId));
    }
  }, [dispatch, isWebhookInitialized, user?.orgId, user?.userId, user?.role]);
};
