import React from 'react';

export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M7.99967 12.0001C8.36786 12.0001 8.66634 11.7016 8.66634 11.3334V6.66675C8.66634 6.29856 8.36786 6.00008 7.99967 6.00008C7.63148 6.00008 7.33301 6.29856 7.33301 6.66675V11.3334C7.33301 11.7016 7.63148 12.0001 7.99967 12.0001Z"
      fill="#070B12"
    />
    <path
      d="M7.99967 3.66675C8.45991 3.66675 8.83301 4.03984 8.83301 4.50008C8.83301 4.96032 8.45991 5.33341 7.99967 5.33341C7.53944 5.33341 7.16634 4.96032 7.16634 4.50008C7.16634 4.03984 7.53944 3.66675 7.99967 3.66675Z"
      fill="#070B12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33301 8.00008C1.33301 11.682 4.31778 14.6667 7.99967 14.6667C11.6816 14.6667 14.6663 11.682 14.6663 8.00008C14.6663 4.31818 11.6816 1.33341 7.99967 1.33341C4.31778 1.33341 1.33301 4.31818 1.33301 8.00008ZM7.99967 13.3334C5.05416 13.3334 2.66634 10.9456 2.66634 8.00008C2.66634 5.05456 5.05416 2.66675 7.99967 2.66675C10.9452 2.66675 13.333 5.05456 13.333 8.00008C13.333 10.9456 10.9452 13.3334 7.99967 13.3334Z"
      fill="#070B12"
    />
  </svg>
);
