import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { ApiResponseData } from '../../types/apiResponse.types';
import { RootState } from '../store';
import { Invoice, SubscriptionInfo } from './billing.types';

export const getSubscriptionInfo = createAsyncThunk<SubscriptionInfo>(
  'billing/getSubscriptionInfo',
  async (_, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<SubscriptionInfo>>(
        `/api/v1/billing/stripe/orgs/${orgId}/users/${userId}/subscription`,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getInvoices = createAsyncThunk<Invoice[]>(
  'billing/getInvoices',
  async (_, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
        billing: { invoices },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const lastInvoiceId = invoices.length
        ? invoices[invoices.length - 1].invoiceId
        : '';

      const response = await axios.get<ApiResponseData<Invoice[]>>(
        `/api/v1/billing/stripe/orgs/${orgId}/users/${userId}/invoices`,
        { params: lastInvoiceId ? { lastInvoiceId } : {}, signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
