import React from 'react';

export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.42096 4.94493L0.0959667 0H3.25398L5.99997 3.13959L8.74595 0H11.904L7.57897 4.94493L11.9999 9.99958L11.9998 9.99973H8.84205L5.99997 6.75027L3.15789 9.99973H0.00013179L0 9.99958L4.42096 4.94493Z"
      fill="#95A1B6"
    />
  </svg>
);
