import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  ModalHeader,
  ModalHeaderProps,
} from './components/ModalHeader/ModalHeader';
import {
  ModalFooter,
  ModalFooterProps,
} from './components/ModalFooter/ModalFooter';

export interface ModalProps {
  isOpened: boolean;
  children?: React.ReactNode;
  headerProps?: Omit<ModalHeaderProps, 'onClose'>;
  footerProps?: Omit<ModalFooterProps, 'onCancel'>;
  width?: number;
  closeOnClickOutside?: boolean;
  isConfirmation?: boolean;
  className?: string;
  onAnimationEnd?: () => void;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  isOpened: isOpenedProp,
  children,
  headerProps,
  footerProps,
  width,
  className,
  closeOnClickOutside = false,
  isConfirmation = false,
  onAnimationEnd,
  onClose,
}) => {
  const [isOpened, setIsOpened] = useState(isOpenedProp);

  const attrs = {
    container: {
      className: `sound-wave-modal${
        !isOpenedProp ? ' sound-wave-modal--closing' : ''
      }${isConfirmation ? ' sound-wave-modal--confirmation' : ''}${
        className ? ` ${className}` : ''
      }`,
      role: 'presentation',
      onClick: () => {
        if (closeOnClickOutside) {
          onClose();
        }
      },
      onAnimationEnd: () => {
        if (!isOpenedProp) {
          setIsOpened(false);
          onAnimationEnd?.();
        }
      },
    },
    dialog: {
      className: 'sound-wave-modal__dialog',
      role: 'dialog',
      'aria-modal': true,
      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
      },
      ...(width ? { style: { width } } : {}),
    },
    dialogContent: {
      className: 'sound-wave-modal__dialog-content',
    },
    modalHeader: headerProps
      ? {
          ...headerProps,
          onClose,
        }
      : null,
    modalFooter: footerProps
      ? {
          ...footerProps,
          onCancel: onClose,
        }
      : null,
  };

  useEffect(() => {
    if (isOpenedProp) {
      setIsOpened(true);
    }
  }, [isOpenedProp]);

  if (!isOpened) {
    return null;
  }

  return createPortal(
    <div {...attrs.container}>
      <div {...attrs.dialog}>
        {attrs.modalHeader ? <ModalHeader {...attrs.modalHeader} /> : null}
        <div {...attrs.dialogContent}>{children}</div>
        {attrs.modalFooter ? <ModalFooter {...attrs.modalFooter} /> : null}
      </div>
    </div>,
    document.querySelector('#modal-root')!
  );
};
