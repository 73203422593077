import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useClickOutside } from '../../../../hooks';
import {
  DropdownOption,
  DropdownOptionProps,
} from './components/DropdownOption/DropdownOption';
import { getDropdownPosition } from './utils/getDropdownPosition';

export interface DropdownProps {
  isOpened: boolean;
  className?: string;
  contentClassName?: string;
  options?: DropdownOptionProps[];
  closeOnScroll?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  isOpened: isOpenedProp,
  className,
  contentClassName,
  options = [],
  closeOnScroll = false,
  children,
  onClose,
}) => {
  const [isOpened, setIsOpened] = useState(isOpenedProp);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = useRef<HTMLDivElement>(null);

  const attrs = {
    container: {
      ref: containerRef,
      className: `sound-wave-dropdown${className ? ` ${className}` : ''}`,
    },
    dropdownOptions: {
      ref: dropdownOptionsRef,
      className: `sound-wave-dropdown__options${
        !isOpenedProp ? ' sound-wave-dropdown__options--closing' : ''
      }${contentClassName ? ` ${contentClassName}` : ''}`,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
      },
      onAnimationEnd: () => {
        if (!isOpenedProp) {
          setIsOpened(false);
        }
      },
    },
  };

  const handleDropdownPosition = useCallback(() => {
    const dropdownOptionsRefCurrent = dropdownOptionsRef.current;
    const containerRefCurrent = containerRef.current;

    if (isOpened && dropdownOptionsRefCurrent && containerRefCurrent) {
      const transformStyle = getDropdownPosition(
        dropdownOptionsRefCurrent,
        containerRefCurrent
      );

      dropdownOptionsRefCurrent.style.transform = transformStyle;
    }
  }, [containerRef, isOpened]);

  useEffect(() => {
    if (isOpenedProp) {
      setIsOpened(true);
    }
  }, [isOpenedProp]);

  useEffect(() => {
    handleDropdownPosition();
  }, [handleDropdownPosition]);

  useEffect(() => {
    const resizeHandler = () => {
      window.requestAnimationFrame(() => {
        handleDropdownPosition();
      });
    };

    const scrollHandler = () => {
      window.requestAnimationFrame(() => {
        handleDropdownPosition();

        if (closeOnScroll && isOpenedProp) {
          onClose();
        }
      });
    };

    window.addEventListener('resize', resizeHandler);
    document.addEventListener('scroll', scrollHandler, true);

    return () => {
      window.removeEventListener('resize', resizeHandler);
      document.removeEventListener('scroll', scrollHandler, true);
    };
  }, [closeOnScroll, handleDropdownPosition, isOpenedProp, onClose]);

  useClickOutside(dropdownOptionsRef, () => {
    window.requestAnimationFrame(() => {
      if (isOpenedProp) {
        onClose();
      }
    });
  });

  const renderDropdownOptions = () =>
    options.map((option, index) => {
      const props = {
        ...option,
        key: index,
      };

      return <DropdownOption {...props} />;
    });

  const renderDropdown = () => {
    if (!isOpened) {
      return null;
    }

    return createPortal(
      <div {...attrs.dropdownOptions}>{renderDropdownOptions()}</div>,
      document.body
    );
  };

  return (
    <div {...attrs.container}>
      {children}
      {renderDropdown()}
    </div>
  );
};
