import { v4 as uuidv4 } from 'uuid';
import {
  MeetingRecordsTypeFilterValue,
  MeetingRecordsDateFilterValue,
} from '../../types/meetingRecordsFilters.types';

export const MEETING_RECORDS_EVENTS_FILTERS_GROUP_ID = uuidv4();

export const MEETING_RECORDS_DATE_FILTER_DEFAULT_VALUE =
  MeetingRecordsDateFilterValue.ALL;

export const MEETING_RECORDS_TYPE_FILTER_DEFAULT_VALUE =
  MeetingRecordsTypeFilterValue.ALL;

export const MEETING_RECORDS_TYPE_FILTER_OPTIONS = [
  {
    value: MeetingRecordsTypeFilterValue.ALL,
    label: 'All',
  },
  {
    value: MeetingRecordsTypeFilterValue.INTERNAL_MEETINGS,
    label: 'Internal Meetings',
  },
  {
    value: MeetingRecordsTypeFilterValue.ACCOUNT_RELATED_MEETINGS,
    label: 'Account Related Meetings',
  },
];

export const MEETING_RECORDS_DATE_FILTER_OPTIONS = [
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.ALL,
      startDate: null,
      endDate: null,
    },
    label: 'All',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.TODAY,
      startDate: null,
      endDate: null,
    },
    label: 'Today',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.WEEK,
      startDate: null,
      endDate: null,
    },
    label: 'This Week',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.MONTH,
      startDate: null,
      endDate: null,
    },
    label: 'This Month',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.QUARTER,
      startDate: null,
      endDate: null,
    },
    label: 'This Quarter',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.YEAR,
      startDate: null,
      endDate: null,
    },
    label: 'This Year',
  },
  {
    value: {
      selectValue: MeetingRecordsDateFilterValue.CUSTOM,
      startDate: null,
      endDate: null,
    },
    label: 'Custom',
  },
];
