import React from 'react';

export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66732 5.33325C8.66732 4.96506 8.36884 4.66659 8.00065 4.66659C7.63246 4.66659 7.33398 4.96506 7.33398 5.33325V7.33325H5.33398C4.96579 7.33325 4.66732 7.63173 4.66732 7.99992C4.66732 8.36811 4.96579 8.66659 5.33398 8.66659H7.33398V10.6666C7.33398 11.0348 7.63246 11.3333 8.00065 11.3333C8.36884 11.3333 8.66732 11.0348 8.66732 10.6666V8.66659H10.6673C11.0355 8.66659 11.334 8.36811 11.334 7.99992C11.334 7.63173 11.0355 7.33325 10.6673 7.33325H8.66732V5.33325Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 1.33325C4.31875 1.33325 1.33398 4.31802 1.33398 7.99992C1.33398 11.6818 4.31875 14.6666 8.00065 14.6666C11.6825 14.6666 14.6673 11.6818 14.6673 7.99992C14.6673 4.31802 11.6825 1.33325 8.00065 1.33325ZM2.66732 7.99992C2.66732 5.0544 5.05513 2.66659 8.00065 2.66659C10.9462 2.66659 13.334 5.0544 13.334 7.99992C13.334 10.9454 10.9462 13.3333 8.00065 13.3333C5.05513 13.3333 2.66732 10.9454 2.66732 7.99992Z"
      fill="white"
    />
  </svg>
);
