import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { ChevronRightIcon } from '../../icons/ChevronRightIcon';

export const Breadcrumbs: React.FC = () => {
  const breadcrumbs = useBreadcrumbs();

  const attrs = {
    container: {
      className: 'breadcrumbs',
    },
    item: {
      className: 'breadcrumbs__item',
    },
    itemDisabled: {
      className: 'breadcrumbs__item breadcrumbs__item--disabled',
    },
    icon: {
      className: 'breadcrumbs__icon',
    },
    breadcrumbSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 100,
      },
      title: {
        style: {
          width: 100,
          height: 20,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
  };

  const renderBreadcrumbs = () =>
    breadcrumbs.map(({ url, label, isLoading = false }, index) => {
      const isFirstChild = index === 0;
      const props = {
        navLink: {
          to: url,
          exact: true,
          activeClassName: attrs.itemDisabled.className,
          className: attrs.item.className,
          isActive: (
            _: unknown,
            location: { pathname: string; hash: string }
          ) => {
            return url === `${location.pathname}${location.hash}`;
          },
        },
        icon: attrs.icon,
      };

      return (
        <Fragment key={url}>
          {!isFirstChild && <ChevronRightIcon {...props.icon} />}
          {isLoading ? (
            <Skeleton {...attrs.breadcrumbSkeleton} />
          ) : (
            <NavLink key={url} {...props.navLink}>
              {label}
            </NavLink>
          )}
        </Fragment>
      );
    });

  if (!breadcrumbs.length) {
    return null;
  }

  return <div {...attrs.container}>{renderBreadcrumbs()}</div>;
};
