import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { PATHS } from '../../../../constants/paths';
import { Input } from '../../../Input/Input';
import { companyIcon, contactIcon, searchIcon } from './assets';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getAccountsForSearch,
  getContactsForSearch,
} from '../../../../store/relationships/relationships.thunks';
import { clearSearch } from '../../../../store/relationships/relationships.slice';
import { ClearIcon, LoaderIcon } from './icons';
import { getStringTokens } from '../../../utils';

enum RowType {
  Account = 'Account',
  Contact = 'Contact',
}

interface RowProps {
  companyAvatar?: string;
  companyName?: string;
  companyEmail?: string;
  contactAvatar?: string;
  contactName?: string;
  contactEmail?: string;
  accountId?: string;
  rowType?: RowType;
}

export const RelationshipsSearch: React.FC = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);

  const { accountsData, contactsData } = useAppSelector(
    (state) => state.relationships.search
  );

  const history = useHistory();

  const dispatch = useAppDispatch();

  const isRelationshipsPage = useRouteMatch(PATHS.RELATIONSHIPS)?.isExact;

  const [value, setValue] = useState('');

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [inputIsFocused, setInputFocused] = useState(false);

  const isLoading = accountsData.isLoading || contactsData.isLoading;

  const { noResults } = accountsData;

  const withData =
    (accountsData?.accounts?.length || contactsData?.contacts?.length) &&
    value.length >= 3 &&
    !isLoading;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const accountsSearchRequest = useRef<
    (Promise<unknown> & { abort: () => void }) | null
  >(null);
  const contactsSearchRequest = useRef<
    (Promise<unknown> & { abort: () => void }) | null
  >(null);

  const updateSearchRequest = useCallback(
    debounce((currentValue: string) => {
      if (currentValue?.length >= 2) {
        accountsSearchRequest.current = dispatch(
          getAccountsForSearch(currentValue)
        );
        // contactsSearchRequest.current = dispatch(
        //   getContactsForSearch(currentValue)
        // );
      }
    }, 400),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      if (!isRelationshipsPage) {
        setValue('');
      }
    };
  }, [isRelationshipsPage]);

  useEffect(() => {
    // if (withData && !popupIsOpen) {
    //   setPopupIsOpen(true);
    // } else if (!withData && popupIsOpen) {
    //   setPopupIsOpen(false);
    // }
    if (!popupIsOpen && inputIsFocused) {
      setPopupIsOpen(true);
    }

    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (
        popupIsOpen &&
        !wrapperRef.current?.contains(target) &&
        !value.length &&
        !withData
      ) {
        setInputFocused(false);
        setPopupIsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [popupIsOpen, value, withData, inputIsFocused]);

  useEffect(() => {
    if (value?.length <= 2 && accountsSearchRequest?.current) {
      accountsSearchRequest?.current?.abort();
      // contactsSearchRequest?.current?.abort();
    }

    if (
      !value.length &&
      (accountsData?.accounts?.length || contactsData?.contacts?.length)
    ) {
      dispatch(clearSearch());
    } // eslint-disable-next-line
  }, [value, accountsData.accounts.length, contactsData.contacts.length]);

  const updateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      accountsSearchRequest?.current?.abort();
      // contactsSearchRequest?.current?.abort();
      setValue(e.currentTarget.value);
      updateSearchRequest(e.currentTarget.value);
    }, // eslint-disable-next-line
    [updateSearchRequest, value]
  );

  const clearValue = () => {
    if (value) {
      setValue('');
      dispatch(clearSearch());
    }
  };

  const goToRSDetailsPage = (accountId: string) => {
    if (user && accountId) {
      const { orgId, userId } = user;

      history.push(`/orgs/${orgId}/users/${userId}/relationships/${accountId}`);
    }
  };

  const attrs = {
    wrapper: {
      className: 'relationships-search',
      ref: wrapperRef,
      onClick: () => setInputFocused(true),
    },

    input: {
      className: 'relationships-search-input',
      placeholder: 'Search',
      onChange: updateValue,
      value,
      label: '',
      noLabel: true,
      icon: searchIcon,
      isInGlobalHeader: true,
    },

    clearSearchIcon: {
      className: 'relationships-search-clear-icon',
      onClick: clearValue,
    },

    loadingIcon: {
      className: 'relationships-search-loader',
    },

    popup: {
      wrapper: {
        className: `${popupIsOpen ? '' : 'closed '}${
          withData ? '' : 'no-data '
        }relationships-search__popup`,
      },

      body: {
        wrapper: {
          className: 'relationships-search__popup__body',
        },

        row: {
          wrapper: (accountId: string) => ({
            className: 'relationships-search__popup__body__row',
            onClick: () => goToRSDetailsPage(accountId),
          }),

          avatar: {
            wrapper: {
              className: 'relationships-search__popup__body__row__avatar',
            },

            img: (isDefalt: boolean) => ({
              className: `${
                isDefalt ? 'default-icon ' : ''
              }relationships-search__popup__body__row__avatar-img`,
            }),
          },

          companyName: {
            text: {
              className: 'relationships-search__popup__body__row-company-name',
            },

            textHighlighted: {
              className:
                'relationships-search__popup__body__row-company-name--highlighted',
            },
          },

          email: {
            wrapper: {
              className: 'relationships-search__popup__body__row__email',
            },

            text: {
              letter: {
                className:
                  'relationships-search__popup__body__row__email__text-letter',
              },

              letterHighlighted: {
                className:
                  'relationships-search__popup__body__row__email__text-letter--highlighted',
              },
            },
          },
        },
      },

      noResultsBody: {
        wrapper: {
          className: 'relationships-search__popup__no-results-body',
        },
        title: {
          className: 'relationships-search__popup__no-results-body-title',
        },
        text: {
          className: 'relationships-search__popup__no-results-body-text',
        },
      },
    },
  };

  const createRow = ({
    companyAvatar,
    companyName,
    companyEmail,
    contactAvatar,
    contactName,
    contactEmail,
    accountId,
    rowType,
  }: RowProps) => {
    if (rowType === RowType.Account) {
      const avatarSrc = companyAvatar || companyIcon;

      const accountNameTokens = getStringTokens(companyName || '', value);

      const accountEmailTokens = getStringTokens(companyEmail || '', value);

      const defineClassName = (
        isHighlighted: boolean,
        type: 'name' | 'email'
      ) => {
        if (isHighlighted) {
          if (type === 'name') {
            return attrs.popup.body.row.companyName.textHighlighted.className;
          }
          if (type === 'email') {
            return attrs.popup.body.row.email.text.letterHighlighted.className;
          }
        }

        return type === 'name'
          ? attrs.popup.body.row.companyName.text.className
          : attrs.popup.body.row.email.text.letter.className;
      };

      const mapTextWithMarking = (
        tokens: typeof accountNameTokens,
        type: 'name' | 'email'
      ) =>
        tokens.map(({ value: valueToShow, isHighlighted }, index) => {
          const props = {
            key: index,
            className: defineClassName(isHighlighted, type),
          };

          return <span {...props}>{valueToShow}</span>;
        });
      const mappedName = mapTextWithMarking(accountNameTokens, 'name');

      const mappedEmail = mapTextWithMarking(accountEmailTokens, 'email');

      const emailToShow = companyEmail ? (
        <div {...attrs.popup.body.row.email.wrapper}>{mappedEmail}</div>
      ) : null;

      return (
        <div {...attrs.popup.body.row.wrapper(accountId || '')}>
          <div {...attrs.popup.body.row.avatar.wrapper}>
            <img
              {...attrs.popup.body.row.avatar.img(!companyAvatar)}
              src={avatarSrc}
              alt=""
            />
          </div>
          <div>{mappedName || ''}</div>
          {emailToShow}
        </div>
      );
    }

    if (rowType === RowType.Contact) {
      const avatarSection = (
        <div
          {...attrs.popup.body.row.avatar.wrapper}
          style={{ background: 'transparent', border: 'none' }}
        >
          <img
            {...attrs.popup.body.row.avatar.img(!companyAvatar)}
            src={companyAvatar || companyIcon}
            alt=""
          />
          <img
            {...attrs.popup.body.row.avatar.img(!contactAvatar)}
            src={contactAvatar || contactIcon}
            alt=""
          />
        </div>
      );
      return (
        <div {...attrs.popup.body.row.wrapper(accountId || '')}>
          {avatarSection}
          <div {...attrs.popup.body.row.companyName.text}>{companyName}</div>
          <div {...attrs.popup.body.row.email.wrapper}>{contactName}</div>
          <div {...attrs.popup.body.row.email.wrapper}>{contactEmail}</div>
        </div>
      );
    }

    return <></>;
  };

  const mappedAccountRows = accountsData.accounts?.length
    ? accountsData.accounts.map((el, i) => (
        <React.Fragment key={`${el.accountId}|${i}`}>
          {createRow({
            rowType: RowType.Account,
            companyAvatar: el?.avatarSrc || '',
            companyName: el?.name,
            companyEmail: el?.domain || '',
            accountId: el?.accountId || '',
          })}
        </React.Fragment>
      ))
    : null;

  const mappedContactRows = contactsData.contacts?.length
    ? contactsData.contacts.map((el, i) => (
        <React.Fragment key={`${el.contactId}|${i}`}>
          {createRow({
            rowType: RowType.Contact,
            companyAvatar: '',
            contactAvatar: el?.metadata?.avatarSrc || '',
            companyName: el?.metadata?.name,
            companyEmail: el?.email || '',
            accountId: el?.contactId || '',
          })}
        </React.Fragment>
      ))
    : null;

  const searchLoadingBody = isLoading ? (
    <LoaderIcon {...attrs.loadingIcon} />
  ) : null;

  const body = withData ? (
    <div {...attrs.popup.body.wrapper}>
      {mappedAccountRows}
      {mappedContactRows}
    </div>
  ) : (
    <div {...attrs.popup.noResultsBody.wrapper}>
      {value.length > 2 && !isLoading && noResults ? (
        <>
          <div {...attrs.popup.noResultsBody.title}>Nothing Found</div>
          <div {...attrs.popup.noResultsBody.text}>
            There were no results matching the query.
          </div>
        </>
      ) : (
        searchLoadingBody
      )}
      {value.length < 3 && !isLoading ? (
        <>
          <div {...attrs.popup.noResultsBody.title}>Looking for something?</div>
          <div {...attrs.popup.noResultsBody.text}>
            Type account name or email to search
          </div>
        </>
      ) : null}
    </div>
  );

  const popup = (
    <div {...attrs.popup.wrapper}>{popupIsOpen ? body : <></>}</div>
  );

  return isRelationshipsPage ? (
    <div {...attrs.wrapper}>
      {value.length > 0 ? <ClearIcon {...attrs.clearSearchIcon} /> : null}
      <Input {...attrs.input} />
      {popup}
    </div>
  ) : (
    <></>
  );
};
