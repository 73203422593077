import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../axios';
import { MeetingRecord } from '../../types/meetingDetails.types';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import { Pagination } from '../../types/pagination.types';

export const getMeetingRecords = createAsyncThunk<
  Pagination & {
    results: MeetingRecord[];
  }
>(
  'meetingRecords/getMeetingRecords',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
        meetingRecords: {
          pagination: { ipp, p, sortBy, sortOrder },
          filtersQueryParams,
        },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<
        ApiResponseData<
          Pagination & {
            results: MeetingRecord[];
          }
        >
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings`,
        {
          signal,
          params: {
            ipp,
            p: p + 1,
            ...(sortBy && { sortBy }),
            ...(sortOrder && { sortOrder }),
            ...filtersQueryParams,
          },
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

// export const getUpcomingMeetings = createAsyncThunk<{
//   results: {
//     meetings: UpcomingMeeting[];
//     nextPageToken: string | null;
//   };
// }>(
//   'meetingRecords/getUpcomingMeetings',
export const getUpcomingMeetings = createAsyncThunk(
  'meetingRecords/getUpcomingMeetings',
  async (_, { getState, fulfillWithValue, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/calendar/upcoming-meetings?daysLimit=7`,
        // `http://localhost:8009/api/v1/orgs/${orgId}/users/${userId}/calendar/upcoming-meetings?daysLimit=7`,
        {
          signal,
        }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

interface UpdateUpcomingInviteAssistantProps {
  meetingId: string;
  action: string;
}

export const updateUpcomingInviteAssistant = createAsyncThunk<
  any,
  UpdateUpcomingInviteAssistantProps
>(
  'meetingRecords/updateUpcomingIviteAssistant',
  async (
    { meetingId, action },
    { getState, fulfillWithValue, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      interface Body {
        meetingId: string;
        action: string;
      }

      const body: Body = {
        meetingId,
        action,
      };

      const response = await axios.post<
        ApiResponseData<void>,
        ApiResponse<void>
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/calendar/upcoming-meetings/bots`,
        // `http://localhost:8009/api/v1/orgs/${orgId}/users/${userId}/calendar/upcoming-meetings/bots`,
        body,
        { signal }
      );

      return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
