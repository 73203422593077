import React from 'react';
import Simplebar, { Props } from 'simplebar-react';

export const Scrollbar: React.FC<Props> = ({
  children,
  className,
  ...rest
}) => {
  const attrs = {
    container: {
      className: `custom-scrollbar${className ? ` ${className}` : ''}`,
      ...rest,
    },
  };

  return <Simplebar {...attrs.container}>{children}</Simplebar>;
};
