import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { toggleOnboardingModal } from '../../store/auth/auth.slice';
import { arrowIcon } from './assets';
import { viewWelcomeModal } from '../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const OnboardingModal: React.FC = (): JSX.Element => {
  const user = useAppSelector((state) => state.auth.user);
  const org = useAppSelector((state) => state.auth.org);

  const onboardingModalActive = useAppSelector(
    (state) => state.auth.onboardingModalActive
  );

  const isAddInfoModalActive = useAppSelector(
    (state) => state.auth.isAddInfoModalActive
  );

  const { search } = useLocation();

  const isOpenedWithWarnQuery = new URLSearchParams(search).get(
    'show-warning-popup'
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timerToOpen: NodeJS.Timeout;

    if (
      user &&
      user.showDashboardWelcomePopup &&
      org?.isFunctionalityEnabled &&
      !isAddInfoModalActive &&
      !isOpenedWithWarnQuery &&
      !onboardingModalActive
    ) {
      timerToOpen = setTimeout(() => {
        dispatch(toggleOnboardingModal(true));
      }, 500);
    } else if (onboardingModalActive) {
      dispatch(toggleOnboardingModal(false));
    }

    return () => clearTimeout(timerToOpen);
    // eslint-disable-next-line
  }, [user, isAddInfoModalActive]);

  const closeModal = () => {
    dispatch(toggleOnboardingModal(false));

    if (user && user.showDashboardWelcomePopup) {
      dispatch(viewWelcomeModal());
    }
  };

  const attrs = {
    modal: {
      wrapper: {
        className: 'onboarding',
      },

      transition: {
        unmountOnExit: true,
        in: onboardingModalActive,
        classNames: 'onboarding',
        timeout: 400,
      },

      body: {
        wrapper: {
          className: 'onboarding__modal',
        },

        title: {
          className: 'onboarding__modal-title',
        },

        button: {
          className: 'onboarding__modal-button',
          onClick: closeModal,
        },

        divider: {
          className: 'onboarding__modal-divider',
        },

        links: {
          wrapper: {
            className: 'onboarding__modal__links',
          },

          link: {
            className: 'onboarding__modal__links-link',
          },
        },
      },
    },

    overlay: {
      body: {
        className: 'onboarding-overlay',
      },

      transition: {
        unmountOnExit: true,
        in: onboardingModalActive,
        classNames: 'onboarding',
        timeout: 400,
      },
    },
  };

  const openLink = (path: string) => {
    if (path?.length) {
      window.open(encodeURI(path), '_blank');
    }
  };

  const createLink = (label: string, path: string) => {
    return (
      <div {...attrs.modal.body.links.link} onClick={() => openLink(path)}>
        {label}
      </div>
    );
  };

  const titleText = `Welcome to Substrata Q`;

  const links = (
    <div {...attrs.modal.body.links.wrapper}>
      {createLink(
        'Getting Started',
        'https://help.substrata.me/en/collections/3051188-getting-started'
      )}
      {createLink(
        'Tutorials',
        'https://help.substrata.me/en/collections/3602298-tutorials'
      )}
      {createLink(
        'FAQ',
        'https://help.substrata.me/en/collections/3600159-faq'
      )}
      {createLink('Contact Support', 'mailto:support@substrata.me')}
      {createLink('Feedback', 'https://feedback.substrata.me')}
    </div>
  );

  return (
    <>
      <div {...attrs.modal.wrapper}>
        <CSSTransition {...attrs.overlay.transition}>
          <div {...attrs.overlay.body} />
        </CSSTransition>
        <CSSTransition {...attrs.modal.transition}>
          <div {...attrs.modal.body.wrapper}>
            <div {...attrs.modal.body.title}>{titleText}</div>
            <div {...attrs.modal.body.button}>
              Continue to Dashboard <img src={arrowIcon} alt="" />
            </div>
            <div {...attrs.modal.body.divider} />
            {links}
          </div>
        </CSSTransition>
      </div>
    </>
  );
};
