import { Dispatch } from 'redux';
import axios from '../../axios';
import {
  GetMeetingsAction,
  SetLoadingMeetingsAction,
} from './myMeetings.types';

export const GET_MEETINGS = 'GET_MEETINGS';

export const SET_MEETINGS_LOADING = 'SET_MEETINGS_LOADING';

export const getMeetings = (orgId: string, userId: string) => async (
  dispatch: Dispatch<GetMeetingsAction | SetLoadingMeetingsAction>
): Promise<void> => {
  try {
    dispatch({
      type: SET_MEETINGS_LOADING,
      payload: true,
    });

    const response = await axios.get(
      `api/v1/relationship-svc/orgs/${orgId}/users/${userId}/calendar/events`
    );

    dispatch({
      type: GET_MEETINGS,
      payload: { data: response.data.payload },
    });

    dispatch({
      type: SET_MEETINGS_LOADING,
      payload: false,
    });
  } catch (e) {
    dispatch({
      type: SET_MEETINGS_LOADING,
      payload: false,
    });
  }
};
