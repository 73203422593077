import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Contact } from '../../types/contact.types';
import { Account } from '../../types/account.types';
import {
  AddAccountFormValues,
  AddBotFormValues,
  AddMemberFormValues,
  CreateAccountAddBotFormValues,
  CreateAccountRequestBody,
  CreateContactRequestBody,
  GetExisitingAccountSuggestionsRequestBody,
  GetExisitingContactSuggestionsRequestBody,
  LinkContactToAccountRequestBody,
  UpdateAccountRequestBody,
  UpdateContactRequestBody,
} from './relationshipFlows.types';
import { RootState } from '../store';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../types/relationshipMember.types';
import { ApiResponse, ApiResponseData } from '../../types/apiResponse.types';
import { getAvatarBase64 } from './relationshipFlows.utils';
import { RelationshipEvent } from '../../types/relationshipEvent.types';
import { MeetingDetails } from '../../types/meetingDetails.types';
import { EmailAnalysis } from '../../types/emailAnalysis.types';
import { PaginationSortOrder } from '../../types/pagination.types';

export const getExistingContactSuggestions = createAsyncThunk<
  Contact[],
  string
>(
  'relationshipFlows/getExisitingContactSuggestions',
  async (email, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ results: Contact[] }>,
        ApiResponse<{ results: Contact[] }>,
        GetExisitingContactSuggestionsRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts/search`,
        {
          search: {
            fields: ['email'],
            value: email,
          },
          includeAssociatedAccounts: true,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload.results);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const getExistingAccountSuggestions = createAsyncThunk<
  Account[],
  string
>(
  'relationshipFlows/getExistingAccountSuggestions',
  async (name, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<{ results: Account[] }>,
        ApiResponse<{ results: Account[] }>,
        GetExisitingAccountSuggestionsRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/search`,
        {
          search: {
            fields: ['name'],
            value: name,
          },
          pagination: {
            sortBy: 'lastActivity',
            sortOrder: PaginationSortOrder.DESC,
          },
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload.results);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const assignEventToAccount = createAsyncThunk<
  {
    account?: Account | null;
    touchPoint?: RelationshipEvent | null;
    analysis?: MeetingDetails | EmailAnalysis | null;
  },
  { eventId: string; accountId: string | null }
>(
  'relationshipFlows/assignEventToAccount',
  async (
    { eventId, accountId },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.patch<
        ApiResponseData<{
          account?: Account | null;
          touchPoint?: RelationshipEvent | null;
          analysis?: MeetingDetails | EmailAnalysis | null;
        }>,
        ApiResponse<{
          account?: Account | null;
          touchPoint?: RelationshipEvent | null;
          analysis?: MeetingDetails | EmailAnalysis | null;
        }>,
        { newAccountId: string }
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/touch-points/${eventId}`,
        {
          newAccountId: accountId || 'unassigned',
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const createAccount = createAsyncThunk<Account, AddAccountFormValues>(
  'relationshipFlows/createAccount',
  async (
    { logo, name, website },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const logoBase64 = getAvatarBase64(logo);

      const response = await axios.post<
        ApiResponseData<Account>,
        ApiResponse<Account>,
        CreateAccountRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts`,
        {
          name,
          companyWebsite: website,
          avatarB64: logoBase64?.b64content
            ? {
                contentType: logoBase64.contentType.replace('data:', ''),
                b64content: logoBase64.b64content,
              }
            : null,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const updateAccount = createAsyncThunk<
  Account,
  AddAccountFormValues & {
    accountId: string;
  }
>(
  'relationshipFlows/updateAccount',
  async (
    { accountId, logo, name, website },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const logoBase64 = getAvatarBase64(logo);
      const logoProps =
        logoBase64 === null
          ? {
              avatarB64: null,
            }
          : {
              ...(logoBase64.b64content && {
                avatarB64: {
                  contentType: logoBase64.contentType.replace('data:', ''),
                  b64content: logoBase64.b64content,
                },
              }),
            };

      const response = await axios.patch<
        ApiResponseData<Account>,
        ApiResponse<Account>,
        UpdateAccountRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/${accountId}`,
        {
          name,
          companyWebsite: website,
          ...logoProps,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const createContact = createAsyncThunk<Contact, AddMemberFormValues>(
  'relationshipFlows/createContact',
  async (
    { email, firstName, lastName, avatar, title, phone, countryCode },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const [countryCodeValue, countryCallingCode] = countryCode.split('|');
      const avatarBase64 = getAvatarBase64(avatar);

      const response = await axios.post<
        ApiResponseData<Contact>,
        ApiResponse<Contact>,
        CreateContactRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts`,
        {
          contactDetails: {
            email,
            firstName,
            lastName,
            title: title || null,
            phoneNumber: phone
              ? {
                  number: phone,
                  countryCallingCode: countryCallingCode || null,
                  countryCode: countryCodeValue || null,
                }
              : null,
            avatarB64: avatarBase64?.b64content
              ? {
                  contentType: avatarBase64.contentType.replace('data:', ''),
                  b64content: avatarBase64.b64content,
                }
              : null,
          },
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const updateContact = createAsyncThunk<Contact, AddMemberFormValues>(
  'relationshipFlows/updateContact',
  async (
    { email, firstName, lastName, avatar, title, phone, countryCode },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
        relatioshipFlows: {
          addMember: { existingContact },
        },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};
      const contactId = existingContact?.contactId || '';
      const [
        countryCodeValue = '',
        countryCallingCode = '',
      ] = countryCode.split('|');
      const avatarBase64 = getAvatarBase64(avatar);
      const avatarProps =
        avatarBase64 === null
          ? {
              avatarB64: null,
            }
          : {
              ...(avatarBase64.b64content && {
                avatarB64: {
                  contentType: avatarBase64.contentType.replace('data:', ''),
                  b64content: avatarBase64.b64content,
                },
              }),
            };

      const response = await axios.patch<
        ApiResponseData<Contact>,
        ApiResponse<Contact>,
        UpdateContactRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts/${contactId}`,
        {
          email,
          firstName,
          lastName,
          title: title || null,
          phoneNumber: phone
            ? {
                number: phone,
                countryCallingCode: countryCallingCode || null,
                countryCode: countryCodeValue || null,
              }
            : null,
          ...avatarProps,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
        relatioshipFlows: {
          addMember: { existingContact },
        },
      } = getState() as RootState;
      return !!user && !!existingContact;
    },
  }
);

export const linkContactToAccount = createAsyncThunk<
  Contact,
  AddMemberFormValues & {
    accountId: string;
    contactId: string;
  }
>(
  'relationshipFlows/linkContactToAccount',
  async (
    { accountId, contactId, assignedTo, contactType, note },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.post<
        ApiResponseData<Contact>,
        ApiResponse<Contact>,
        LinkContactToAccountRequestBody
      >(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/contacts/${contactId}/links`,
        {
          accountId,
          contactSide: assignedTo as RelationshipMemberSide,
          associationType: contactType as RelationshipMemberAssociationType,
          note: note || null,
        },
        { signal }
      );

      return fulfillWithValue(response.data.payload);
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || error);
    }
  },
  {
    condition: ({ accountId, contactId }, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user && !!accountId && !!contactId;
    },
  }
);

export const getAccounts = createAsyncThunk<Account[]>(
  'relationshipFlows/getAccounts',
  async (_, { fulfillWithValue, getState, rejectWithValue, signal }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const response = await axios.get<ApiResponseData<{ results: Account[] }>>(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts`,
        {
          signal,
          params: {
            p: 1,
            ipp: 100,
            sortBy: 'lastActivity',
            sortOrder: 'desc',
          },
        }
      );

      return fulfillWithValue(response.data.payload.results);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const createAccountAddBotFlow = createAsyncThunk<
  Account,
  CreateAccountAddBotFormValues
>(
  'relationshipFlows/createAccountAddBotFlow',
  async (
    { name, website },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const body = {
        name,
        companyWebsite: website || '',
        avatarB64: null,
      };

      const response = await axios.post(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/accounts/`,
        // `http://localhost:8009/api/v1/orgs/${orgId}/users/${userId}/accounts/`,
        body,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
      // return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);

export const addBot = createAsyncThunk<Account, AddBotFormValues>(
  'relationshipFlows/addBot',
  async (
    { title, joinUrl, accountId },
    { fulfillWithValue, getState, rejectWithValue, signal }
  ) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      const body = {
        meetingTitle: title,
        joinUrl,
        accountId,
      };

      const response = await axios.post(
        `/api/v1/relationship-svc/orgs/${orgId}/users/${userId}/online-meetings/bots`,
        // `http://localhost:8009/api/v1/orgs/${orgId}/users/${userId}/online-meetings/bots`,
        body,
        { signal }
      );

      return fulfillWithValue(response.data.payload);
      // return fulfillWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
