import { Dispatch } from 'redux';
import axios from '../../axios';
import {
  SetAvgEmailsLength,
  SetAvgEmailsLengthErr,
  SetAvgEmailsLengthLoading,
  SetAvgEmailsLengthNoData,
  SetEstimateTendencies,
  SetEstimateTendenciesErr,
  SetEstimateTendenciesLoading,
  SetEstimateTendenciesNoData,
  SetIncomingActivity,
  SetIncomingActivityErr,
  SetIncomingActivityLoading,
  SetIncomingActivityNoData,
  SetLeastCompetent,
  SetLeastCompetentErr,
  SetLeastCompetentLoading,
  SetLeastCompetentNoData,
  SetMeetinDuration,
  SetMeetinDurationErr,
  SetMeetinDurationLoading,
  SetMeetinDurationNoData,
  SetMeetingCount,
  SetMeetingCountErr,
  SetMeetingCountLoading,
  SetMeetingCountNoData,
  SetMostCompetent,
  SetMostCompetentErr,
  SetMostCompetentLoading,
  SetMostCompetentNoData,
  SetOutgoingActivity,
  SetOutgoingActivityErr,
  SetOutgoingActivityLoading,
  SetOutgoingActivityNoData,
  SetParticipants,
  SetParticipantsErr,
  SetParticipantsLoading,
  SetParticipantsNoData,
  SetQActivity,
  SetQActivityErr,
  SetQActivityLoading,
  SetQActivityNoData,
  SetResponseTime,
  SetResponseTimeErr,
  SetResponseTimeLoading,
  SetResponseTimeNoData,
  SetSellingZone,
  SetSellingZoneErr,
  SetSellingZoneLoading,
  SetSellingZoneNoData,
  SetSentiment,
  SetSentimentErr,
  SetSentimentLoading,
  SetSentimentNoData,
  SetTalkTimeRatio,
  SetTalkTimeRatioErr,
  SetTalkTimeRatioLoading,
  SetTalkTimeRatioNoData,
  SetTopDomains,
  SetTopDomainsErr,
  SetTopDomainsLoading,
  SetTopDomainsNoData,
  SetUhPossessions,
  SetUhPossessionsErr,
  SetUhPossessionsLoading,
  SetUhPossessionsNoData,
} from './charts.types';
import { getUserLocalTimezone, renameHeatMapsTimeKeys } from './charts.utils';

export const SET_UPPER_HAND_POSS = 'SET_UPPER_HAND_POSS';
export const SET_UPPER_HAND_POSS_LOADING = 'SET_UPPER_HAND_POSS_LOADING';
export const SET_UPPER_HAND_POSS_ERR = 'SET_UPPER_HAND_POSS_ERR';
export const SET_UPPER_HAND_POSS_NO_DATA = 'SET_UPPER_HAND_POSS_NO_DATA';

export const SET_ESTIMATED_TEND = 'SET_ESTIMATED_TEND';
export const SET_ESTIMATED_TEND_LOADING = 'SET_ESTIMATED_TEND_LOADING';
export const SET_ESTIMATED_TEND_ERR = 'SET_ESTIMATED_TEND_ERR';
export const SET_ESTIMATED_TEND_NO_DATA = 'SET_ESTIMATED_TEND_NO_DATA';

export const SET_MOST_COMPETENTE = 'SET_MOST_COMPETENTE';
export const SET_MOST_COMPETENTE_LOADING = 'SET_MOST_COMPETENTE_LOADING';
export const SET_MOST_COMPETENTE_ERR = 'SET_MOST_COMPETENTE_ERR';
export const SET_MOST_COMPETENTE_NO_DATA = 'SET_MOST_COMPETENTE_NO_DATA';

export const SET_LEAST_COMPETENTE = 'SET_LEAST_COMPETENTE';
export const SET_LEAST_COMPETENTE_LOADING = 'SET_LEAST_COMPETENTE_LOADING';
export const SET_LEAST_COMPETENTE_ERR = 'SET_LEAST_COMPETENTE_ERR';
export const SET_LEAST_COMPETENTE_NO_DATA = 'SET_LEAST_COMPETENTE_NO_DATA';

export const SET_Q_ACTIVITY = 'SET_Q_ACTIVITY';
export const SET_Q_ACTIVITY_LOADING = 'SET_Q_ACTIVITY_LOADING';
export const SET_Q_ACTIVITY_ERR = 'SET_Q_ACTIVITY_ERR';
export const SET_Q_ACTIVITY_NO_DATA = 'SET_Q_ACTIVITY_NO_DATA';

export const SET_SENTIMENT = 'SET_SENTIMENT';
export const SET_SENTIMENT_LOADING = 'SET_SENTIMENT_LOADING';
export const SET_SENTIMENT_ERR = 'SET_SENTIMENT_ERR';
export const SET_SENTIMENT_NO_DATA = 'SET_SENTIMENT_NO_DATA';

export const SET_RESPONSE_TIME = 'SET_RESPONSE_TIME';
export const SET_RESPONSE_TIME_LOADING = 'SET_RESPONSE_TIME_LOADING';
export const SET_RESPONSE_TIME_ERR = 'SET_RESPONSE_TIME_ERR';
export const SET_RESPONSE_TIME_NO_DATA = 'SET_RESPONSE_TIME_NO_DATA';

export const SET_AVG_PARTICIPANTS = 'SET_AVG_PARTICIPANTS';
export const SET_AVG_PARTICIPANTS_LOADING = 'SET_AVG_PARTICIPANTS_LOADING';
export const SET_AVG_PARTICIPANTS_ERR = 'SET_AVG_PARTICIPANTS_ERR';
export const SET_AVG_PARTICIPANTS_NO_DATA = 'SET_AVG_PARTICIPANTS_NO_DATA';

export const SET_AVG_EMAIL_LENGTH = 'SET_AVG_EMAIL_LENGTH';
export const SET_AVG_EMAIL_LENGTH_LOADING = 'SET_AVG_EMAIL_LENGTH_LOADING';
export const SET_AVG_EMAIL_LENGTH_ERR = 'SET_AVG_EMAIL_LENGTH_ERR';
export const SET_AVG_EMAIL_LENGTH_NO_DATA = 'SET_AVG_EMAIL_LENGTH_NO_DATA';

export const SET_TOP_DOMAINS = 'SET_TOP_DOMAINS';
export const SET_TOP_DOMAINS_LOADING = 'SET_TOP_DOMAINS_LOADING';
export const SET_TOP_DOMAINS_ERR = 'SET_TOP_DOMAINS_ERR';
export const SET_TOP_DOMAINS_NO_DATA = 'SET_TOP_DOMAINS_NO_DATA';

export const SET_SELLING_ZONE = 'SET_SELLING_ZONE';
export const SET_SELLING_ZONE_LOADING = 'SET_SELLING_ZONE_LOADING';
export const SET_SELLING_ZONE_ERR = 'SET_SELLING_ZONE_ERR';
export const SET_SELLING_ZONE_NO_DATA = 'SET_SELLING_ZONE_NO_DATA';

export const SET_INCOMING_ACTIVITY = 'SET_INCOMING_ACTIVITY';
export const SET_INCOMING_ACTIVITY_LOADING = 'SET_INCOMING_ACTIVITY_LOADING';
export const SET_INCOMING_ACTIVITY_ERR = 'SET_INCOMING_ACTIVITY_ERR';
export const SET_INCOMING_ACTIVITY_NO_DATA = 'SET_INCOMING_ACTIVITY_DATA';

export const SET_OUTGOING_ACTIVITY = 'SET_OUTGOING_ACTIVITY';
export const SET_OUTGOING_ACTIVITY_LOADING = 'SET_OUTGOING_ACTIVITY_LOADING';
export const SET_OUTGOING_ACTIVITY_ERR = 'SET_OUTGOING_ACTIVITY_ERR';
export const SET_OUTGOING_ACTIVITY_NO_DATA = 'SET_OUTGOING_ACTIVITY_DATA';

export const SET_TALK_TIME_RATIO = 'SET_TALK_TIME_RATIO';
export const SET_TALK_TIME_RATIO_LOADING = 'SET_TALK_TIME_RATIO_LOADING';
export const SET_TALK_TIME_RATIO_ERR = 'SET_TALK_TIME_RATIO_ERR';
export const SET_TALK_TIME_RATIO_NO_DATA = 'SET_TALK_TIME_RATIO_NO_DATA';

export const SET_MEETING_COUNT = 'SET_MEETING_COUNT';
export const SET_MEETING_COUNT_LOADING = 'SET_MEETING_COUNT_LOADING';
export const SET_MEETING_COUNT_ERR = 'SET_MEETING_COUNT_ERR';
export const SET_MEETING_COUNT_NO_DATA = 'SET_MEETING_COUNT_NO_DATA';

export const SET_MEETING_DURATION = 'SET_MEETING_DURATION';
export const SET_MEETING_DURATION_LOADING = 'SET_MEETING_DURATION_LOADING';
export const SET_MEETING_DURATION_ERR = 'SET_MEETING_DURATION_ERR';
export const SET_MEETING_DURATION_NO_DATA = 'SET_MEETING_DURATION_NO_DATA';

export const CLEAR_CHARTS_ERRORS = 'CLEAR_CHARTS_ERRORS';
export const CLEAR_CHARTS_STATE = 'CLEAR_CHARTS_STATE';

export const getUpperHandPossessions = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetUhPossessions
    | SetUhPossessionsLoading
    | SetUhPossessionsErr
    | SetUhPossessionsNoData
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_UPPER_HAND_POSS_LOADING, payload: true });
    const uhPossessionsResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/uh-possessions?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (uhPossessionsResponse?.data?.payload?.data?.currentPeriod) {
      dispatch({
        type: SET_UPPER_HAND_POSS,
        payload: uhPossessionsResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        uhPossessionsResponse.data.payload.data?.currentPeriodDailyStats
          ?.length &&
        uhPossessionsResponse.data.payload.data?.currentPeriodDailyStats.some(
          (el: any) => el?.avgWinLoseScore > 0 // eslint-disable-line
        );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_UPPER_HAND_POSS_NO_DATA });
      }
    }
    dispatch({ type: SET_UPPER_HAND_POSS_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_UPPER_HAND_POSS_ERR });
    dispatch({ type: SET_UPPER_HAND_POSS_LOADING, payload: false });
  }
};

export const getEstimatedTendencies = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetEstimateTendencies
    | SetEstimateTendenciesLoading
    | SetEstimateTendenciesNoData
    | SetEstimateTendenciesErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_ESTIMATED_TEND_LOADING, payload: true });

    const tendenciesResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/estimated-tendencies?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (tendenciesResponse?.data?.payload?.data) {
      dispatch({
        type: SET_ESTIMATED_TEND,
        payload: tendenciesResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        typeof tendenciesResponse.data.payload.data?.competence === 'number' ||
        typeof tendenciesResponse.data.payload.data?.empathy === 'number' ||
        typeof tendenciesResponse.data.payload.data?.responsiveness ===
          'number';

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_ESTIMATED_TEND_NO_DATA });
      }
    }
    dispatch({ type: SET_ESTIMATED_TEND_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_ESTIMATED_TEND_ERR });
    dispatch({ type: SET_ESTIMATED_TEND_LOADING, payload: false });
  }
};

export const getQActivity = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    SetQActivity | SetQActivityLoading | SetQActivityErr | SetQActivityNoData
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_Q_ACTIVITY_LOADING, payload: true });
    const qActivityResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/user-activity?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (qActivityResponse?.data?.payload?.data) {
      dispatch({
        type: SET_Q_ACTIVITY,
        payload: qActivityResponse.data.payload,
      });
      const dataAvailabilityCheck = qActivityResponse.data.payload.data?.some(
        (el: any) => el?.analyzed > 0 || el?.simulated > 0 // eslint-disable-line
      );
      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_Q_ACTIVITY_NO_DATA });
      }
    }

    dispatch({ type: SET_Q_ACTIVITY_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_Q_ACTIVITY_ERR });
    dispatch({ type: SET_Q_ACTIVITY_LOADING, payload: false });
  }
};

export const getSentiment = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    SetSentiment | SetSentimentLoading | SetSentimentErr | SetSentimentNoData
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_SENTIMENT_LOADING, payload: true });
    const sentimentResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/response-sentiments?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (sentimentResponse?.data?.payload?.data) {
      dispatch({
        type: SET_SENTIMENT,
        payload: sentimentResponse.data.payload.data,
      });
      const {
        positive,
        neutral,
        negative,
      } = sentimentResponse.data.payload.data;

      const positiveCheck =
        positive.positiveVs.emailsCount > 0 ||
        positive.neutralVs.emailsCount > 0 ||
        positive.negativeVs.emailsCount > 0;
      const neutralCheck =
        neutral.positiveVs.emailsCount > 0 ||
        neutral.neutralVs.emailsCount > 0 ||
        neutral.negativeVs.emailsCount > 0;
      const negativeCheck =
        negative.positiveVs.emailsCount > 0 ||
        negative.neutralVs.emailsCount > 0 ||
        negative.negativeVs.emailsCount > 0;

      const dataAvailabilityCheck =
        positiveCheck || neutralCheck || negativeCheck;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_SENTIMENT_NO_DATA });
      }
    }

    dispatch({ type: SET_SENTIMENT_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_SENTIMENT_ERR });
    dispatch({ type: SET_SENTIMENT_LOADING, payload: false });
  }
};

export const getResponseTime = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetResponseTime
    | SetResponseTimeLoading
    | SetResponseTimeErr
    | SetResponseTimeNoData
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_RESPONSE_TIME_LOADING, payload: true });
    const avgResTimeResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/avg-response-time?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (avgResTimeResponse?.data?.payload?.data) {
      dispatch({
        type: SET_RESPONSE_TIME,
        payload: avgResTimeResponse.data.payload,
      });

      const dataAvailabilityCheck = avgResTimeResponse.data.payload.data?.some(
        (el: any) => el?.avgRtMin > 0 // eslint-disable-line
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_RESPONSE_TIME_NO_DATA });
      }
    }
    dispatch({ type: SET_RESPONSE_TIME_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_RESPONSE_TIME_ERR });
    dispatch({ type: SET_RESPONSE_TIME_LOADING, payload: false });
  }
};

export const getParticipants = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetParticipants
    | SetParticipantsLoading
    | SetParticipantsNoData
    | SetParticipantsErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_AVG_PARTICIPANTS_LOADING, payload: true });
    const participantsResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/avg-participants?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (participantsResponse?.data?.payload?.data) {
      dispatch({
        type: SET_AVG_PARTICIPANTS,
        payload: participantsResponse.data.payload,
      });

      const dataAvailabilityCheck =
        participantsResponse.data.payload.data?.userSide > 0 ||
        participantsResponse.data.payload.data?.buyerSide > 0 ||
        participantsResponse.data.payload.data?.otherSide > 0;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_AVG_PARTICIPANTS_NO_DATA });
      }
    }
    dispatch({ type: SET_AVG_PARTICIPANTS_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_AVG_PARTICIPANTS_ERR });
    dispatch({ type: SET_AVG_PARTICIPANTS_LOADING, payload: false });
  }
};

export const getEmailsLength = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetAvgEmailsLength
    | SetAvgEmailsLengthLoading
    | SetAvgEmailsLengthNoData
    | SetAvgEmailsLengthErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_AVG_EMAIL_LENGTH_LOADING, payload: true });
    const emailsLengthResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/avg-email-length?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (emailsLengthResponse?.data?.payload?.data) {
      dispatch({
        type: SET_AVG_EMAIL_LENGTH,
        payload: emailsLengthResponse.data.payload,
      });

      const dataAvailabilityCheck = emailsLengthResponse.data.payload.data?.some(
        (
          el: any // eslint-disable-line
        ) => el?.userAvgEmailLengthWords > 0 || el?.buyerAvgEmailLengthWords > 0
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_AVG_EMAIL_LENGTH_NO_DATA });
      }
    }
    dispatch({ type: SET_AVG_EMAIL_LENGTH_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_AVG_EMAIL_LENGTH_ERR });
    dispatch({ type: SET_AVG_EMAIL_LENGTH_LOADING, payload: false });
  }
};

export const getTopDomains = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetTopDomains
    | SetTopDomainsLoading
    | SetTopDomainsNoData
    | SetTopDomainsErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TOP_DOMAINS_LOADING, payload: true });
    const topDomainsResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/most-active-domains?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (topDomainsResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TOP_DOMAINS,
        payload: topDomainsResponse.data.payload,
      });

      const dataAvailabilityCheck = topDomainsResponse.data.payload.data?.some(
        (el: any) => el?.emailsCount > 0 // eslint-disable-line
      );

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TOP_DOMAINS_NO_DATA });
      }
    }
    dispatch({ type: SET_TOP_DOMAINS_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TOP_DOMAINS_ERR });
    dispatch({ type: SET_TOP_DOMAINS_LOADING, payload: false });
  }
};

export const getSellingZone = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetSellingZone
    | SetSellingZoneLoading
    | SetSellingZoneNoData
    | SetSellingZoneErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_SELLING_ZONE_LOADING, payload: true });
    const topDomainsResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/selling-zone-stats?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );
    if (topDomainsResponse?.data?.payload?.data) {
      dispatch({
        type: SET_SELLING_ZONE,
        payload: topDomainsResponse.data.payload,
      });

      const {
        belowSzEmailsCount,
        inSzEmailsCount,
        aboveSzEmailsCount,
      } = topDomainsResponse.data.payload.data;

      const dataAvailabilityCheck =
        belowSzEmailsCount > 0 || inSzEmailsCount > 0 || aboveSzEmailsCount > 0;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_SELLING_ZONE_NO_DATA });
      }
    }
    dispatch({ type: SET_SELLING_ZONE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_SELLING_ZONE_ERR });
    dispatch({ type: SET_SELLING_ZONE_LOADING, payload: false });
  }
};

export const getMostCompetent = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetMostCompetent
    | SetMostCompetentLoading
    | SetMostCompetentNoData
    | SetMostCompetentErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_MOST_COMPETENTE_LOADING, payload: true });

    const mostCompResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/most-competent-vs?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (mostCompResponse?.data?.payload?.data) {
      dispatch({
        type: SET_MOST_COMPETENTE,
        payload: mostCompResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        mostCompResponse.data?.payload?.data?.length;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_MOST_COMPETENTE_NO_DATA });
      }
    }
    dispatch({ type: SET_MOST_COMPETENTE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_MOST_COMPETENTE_ERR });
    dispatch({ type: SET_MOST_COMPETENTE_LOADING, payload: false });
  }
};

export const getLeastCompetent = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetLeastCompetent
    | SetLeastCompetentLoading
    | SetLeastCompetentNoData
    | SetLeastCompetentErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_LEAST_COMPETENTE_LOADING, payload: true });

    const leastCompResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/least-competent-vs?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (leastCompResponse?.data?.payload?.data) {
      dispatch({
        type: SET_LEAST_COMPETENTE,
        payload: leastCompResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        leastCompResponse?.data?.payload?.data?.length;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_LEAST_COMPETENTE_NO_DATA });
      }
    }
    dispatch({ type: SET_LEAST_COMPETENTE_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_LEAST_COMPETENTE_ERR });
    dispatch({ type: SET_LEAST_COMPETENTE_LOADING, payload: false });
  }
};

export const getIncomingEmailActivity = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetIncomingActivity
    | SetIncomingActivityLoading
    | SetIncomingActivityNoData
    | SetIncomingActivityErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_INCOMING_ACTIVITY_LOADING, payload: true });

    const incomingActivityResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/incoming-email-activity?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (incomingActivityResponse?.data?.payload?.data) {
      dispatch({
        type: SET_INCOMING_ACTIVITY,
        payload: renameHeatMapsTimeKeys(
          incomingActivityResponse.data.payload.data
        ),
      });

      const dataAvailabilityCheck = Object.values(
        incomingActivityResponse.data.payload.data
      ) // eslint-disable-next-line
        .map((el: any) => Object.values(el).some((value: any) => value > 0))
        .includes(true);

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_INCOMING_ACTIVITY_NO_DATA });
      }
    }

    dispatch({ type: SET_INCOMING_ACTIVITY_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_INCOMING_ACTIVITY_ERR });
    dispatch({ type: SET_INCOMING_ACTIVITY_LOADING, payload: false });
  }
};

export const getOutgoingEmailActivity = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetOutgoingActivity
    | SetOutgoingActivityLoading
    | SetOutgoingActivityNoData
    | SetOutgoingActivityErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_OUTGOING_ACTIVITY_LOADING, payload: true });

    const outgoingActivityResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/outgoing-email-activity?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (outgoingActivityResponse?.data?.payload?.data) {
      dispatch({
        type: SET_OUTGOING_ACTIVITY,
        payload: renameHeatMapsTimeKeys(
          outgoingActivityResponse.data.payload.data
        ),
      });

      const dataAvailabilityCheck = Object.values(
        outgoingActivityResponse.data.payload.data
      ) // eslint-disable-next-line
        .map((el: any) => Object.values(el).some((value: any) => value > 0))
        .includes(true);

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_OUTGOING_ACTIVITY_NO_DATA });
      }
    }

    dispatch({ type: SET_OUTGOING_ACTIVITY_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_OUTGOING_ACTIVITY_ERR });
    dispatch({ type: SET_OUTGOING_ACTIVITY_LOADING, payload: false });
  }
};

export const getTalkTimeRatio = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetTalkTimeRatio
    | SetTalkTimeRatioLoading
    | SetTalkTimeRatioNoData
    | SetTalkTimeRatioErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TALK_TIME_RATIO_LOADING, payload: true });

    const talkTimeRatioResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/talk-time-ratio?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (talkTimeRatioResponse?.data?.payload?.data) {
      dispatch({
        type: SET_TALK_TIME_RATIO,
        payload: talkTimeRatioResponse.data.payload.data,
      });

      const dataAvailabilityCheck = Object.values(
        talkTimeRatioResponse?.data?.payload?.data
      ) // eslint-disable-next-line
        .some((value: any) => value > 0);

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_TALK_TIME_RATIO_NO_DATA });
      }
    }

    dispatch({ type: SET_TALK_TIME_RATIO_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_TALK_TIME_RATIO_ERR });
    dispatch({ type: SET_TALK_TIME_RATIO_LOADING, payload: false });
  }
};

export const getMeetingCount = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetMeetingCount
    | SetMeetingCountLoading
    | SetMeetingCountNoData
    | SetMeetingCountErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_MEETING_COUNT_LOADING, payload: true });

    const meetingCountResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/meeting-count?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (meetingCountResponse?.data?.payload?.data) {
      dispatch({
        type: SET_MEETING_COUNT,
        payload: meetingCountResponse.data.payload.data,
      });

      const dataAvailabilityCheck =
        meetingCountResponse?.data?.payload?.data?.stats
          // eslint-disable-next-line
          .map((el: any) => el?.count)
          .some((stat: number) => stat > 0) ||
        meetingCountResponse?.data?.payload?.data?.totalCount > 0 ||
        meetingCountResponse?.data?.payload?.data?.teamAverage > 0;

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_MEETING_COUNT_NO_DATA });
      }
    }

    dispatch({ type: SET_MEETING_COUNT_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_MEETING_COUNT_ERR });
    dispatch({ type: SET_MEETING_COUNT_LOADING, payload: false });
  }
};

export const getMeetingDuration = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string,
  timezone: string
) => async (
  dispatch: Dispatch<
    | SetMeetinDuration
    | SetMeetinDurationLoading
    | SetMeetinDurationNoData
    | SetMeetinDurationErr
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_MEETING_DURATION_LOADING, payload: true });

    const meetingDurationResponse = await axios.get(
      `services/orgs/${orgId}/users/${userId}/stats/meeting-duration?fromDate=${fromDate}&toDate=${toDate}&tz=${timezone}`
    );

    if (meetingDurationResponse?.data?.payload?.data) {
      dispatch({
        type: SET_MEETING_DURATION,
        payload: meetingDurationResponse.data.payload.data,
      });

      const dataAvailabilityCheck = Object.values(
        meetingDurationResponse?.data?.payload?.data
      ).some((value: any) => value > 0);

      if (!dataAvailabilityCheck) {
        dispatch({ type: SET_MEETING_DURATION_NO_DATA });
      }
    }

    dispatch({ type: SET_MEETING_DURATION_LOADING, payload: false });
  } catch (e) {
    dispatch({ type: SET_MEETING_DURATION_ERR });
    dispatch({ type: SET_MEETING_DURATION_LOADING, payload: false });
  }
};

export const getChartsData = (
  orgId: string,
  userId: string,
  fromDate: string,
  toDate: string // eslint-disable-next-line
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const timezone = getUserLocalTimezone(true);

  dispatch({ type: CLEAR_CHARTS_STATE });

  dispatch(getQActivity(orgId, userId, fromDate, toDate, timezone));
  dispatch(getUpperHandPossessions(orgId, userId, fromDate, toDate, timezone));
  dispatch(getEstimatedTendencies(orgId, userId, fromDate, toDate, timezone));
  dispatch(getSentiment(orgId, userId, fromDate, toDate, timezone));
  dispatch(getResponseTime(orgId, userId, fromDate, toDate, timezone));
  dispatch(getParticipants(orgId, userId, fromDate, toDate, timezone));
  dispatch(getEmailsLength(orgId, userId, fromDate, toDate, timezone));
  dispatch(getTopDomains(orgId, userId, fromDate, toDate, timezone));
  dispatch(getSellingZone(orgId, userId, fromDate, toDate, timezone));
  dispatch(getMostCompetent(orgId, userId, fromDate, toDate, timezone));
  dispatch(getLeastCompetent(orgId, userId, fromDate, toDate, timezone));
  dispatch(getIncomingEmailActivity(orgId, userId, fromDate, toDate, timezone));
  dispatch(getOutgoingEmailActivity(orgId, userId, fromDate, toDate, timezone));
  dispatch(getTalkTimeRatio(orgId, userId, fromDate, toDate, timezone));
  dispatch(getMeetingCount(orgId, userId, fromDate, toDate, timezone));
  dispatch(getMeetingDuration(orgId, userId, fromDate, toDate, timezone));
};
