import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../../store/hooks';
import { User } from '../../../../../types';

export interface NavigationTab {
  label: string;
  path: string;
  isActive: boolean;
  onClick: () => void;
}

const getRules = (user: User) => [
  {
    path: `/orgs/${user.orgId}/users/${user.userId}/team-analytics`,
    tabs: [
      {
        label: 'Overview',
        path: `/orgs/${user.orgId}/users/${user.userId}/team-analytics/overview`,
      },
      {
        label: 'Activity',
        path: `/orgs/${user.orgId}/users/${user.userId}/team-analytics/activity`,
      },
      {
        label: 'Analytics',
        path: `/orgs/${user.orgId}/users/${user.userId}/team-analytics/analytics`,
      },
    ],
  },
];

export const useNavigationTabs = (): NavigationTab[] => {
  const user = useAppSelector((state) => state.auth.user);

  const history = useHistory();

  const { pathname } = useLocation();

  const navigationTabs = useMemo(() => {
    const currentTabs = user
      ? getRules(user).find((rule) => pathname.includes(rule.path))?.tabs || []
      : [];

    return currentTabs.map(({ label, path }) => ({
      label,
      path,
      isActive: pathname === path,
      onClick: () => {
        history.push(path);
      },
    }));
  }, [history, pathname, user]);

  return navigationTabs;
};
