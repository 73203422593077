import { TooltipPosition } from '../Tooltip';

export const getPositionOffsetStyles = (
  position: TooltipPosition,
  offset: number
): {
  paddingTop?: number;
  paddingRight?: number;
  paddingBottom?: number;
  paddingLeft?: number;
} => {
  switch (position) {
    case TooltipPosition.TOP:
    case TooltipPosition.TOP_START:
    case TooltipPosition.TOP_END: {
      return {
        paddingBottom: offset,
      };
    }
    case TooltipPosition.RIGHT:
    case TooltipPosition.RIGHT_START:
    case TooltipPosition.RIGHT_END: {
      return {
        paddingLeft: offset,
      };
    }
    case TooltipPosition.BOTTOM:
    case TooltipPosition.BOTTOM_START:
    case TooltipPosition.BOTTOM_END: {
      return {
        paddingTop: offset,
      };
    }
    case TooltipPosition.LEFT:
    case TooltipPosition.LEFT_START:
    case TooltipPosition.LEFT_END: {
      return {
        paddingRight: offset,
      };
    }
    default: {
      return {};
    }
  }
};
