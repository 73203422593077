export enum RelationshipMemberSide {
  USER_SIDE = 'userSide',
  PROSPECT_SIDE = 'prospectSide',
  UNASSIGNED = 'unassigned',
}

export enum RelationshipMemberAssociationType {
  TEAM_MEMBER = 'teamMember',
  THIRD_PARTY = 'thirdParty',
}

export enum RelationshipMemberLinkingType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic',
  ASSIGNMENT_RULE = 'assignmentRule',
}

export interface RelationshipMemberLink {
  associationType?: RelationshipMemberAssociationType;
  contactSide?: RelationshipMemberSide;
  isActive?: boolean;
}

export interface RelationshipMember {
  orgId: string;
  accountId: string | null;
  contactId: string;
  contactSide: RelationshipMemberSide;
  associationType: RelationshipMemberAssociationType;
  linkingType: RelationshipMemberLinkingType;
  note: string | null;
  isActive: boolean;
  linkedAt: string;
  unlinkedAt: string | null;
}
