import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  RSSortBy,
  RSSortOrder,
  RelationshipsFiltersQueryParams,
  RelationshipsState,
  RelatioshipCheckboxFilters,
} from './relationships.types';
import {
  ADMIN_FILTERS_GROUP_ID,
  DEAL_SIZE_FILTER_DEFAULT_VALUE,
  DEAL_STAGE_FILTER_DEFAULT_VALUE,
  DEAL_STAGE_FILTER_OPTIONS,
  LAST_INTERACTION_DATE_FILTER_DEFAULT_VALUE,
  LAST_INTERACTION_DATE_FILTER_OPTIONS,
  RELATIONSHIP_AGE_FILTER_DEFAULT_VALUE,
  RELATIONSHIP_AGE_FILTER_OPTIONS,
  RELATIONSHIP_EVENTS_FILTERS_GROUP_ID,
  RELATIONSHIP_OWNER_FILTER_DEFAULT_VALUE,
  RELATIONSHIP_OWNER_FILTER_OPTIONS,
} from './relationships.constants';
import {
  FilterType,
  FilterValue,
  RangeFilterMaskType,
} from '../../types/relationshipFilters.types';
import {
  getRelationships,
  getAccountsForSearch,
  getContactsForSearch,
} from './relationships.thunks';
import {
  isDateRangeFilterValue,
  isRangeFilterValue,
} from './relationships.utils';

const initialState: RelationshipsState = {
  relationships: {
    count: 0,
    p: 1,
    ipp: 10,
    sortBy: null,
    sortOrder: null,
    nextPage: null,
    results: [],
  },
  relationshipsForTable: [],
  search: {
    accountsData: {
      accounts: [],
      isLoading: false,
      isError: false,
      noResults: false,
    },
    contactsData: {
      contacts: [],
      isLoading: false,
      isError: false,
      noResults: false,
    },
  },
  relationshipsDataStatus: {
    isLoading: false,
    isError: false,
  },
  filters: [
    {
      id: uuidv4(),
      value: LAST_INTERACTION_DATE_FILTER_DEFAULT_VALUE,
      previousValue: LAST_INTERACTION_DATE_FILTER_DEFAULT_VALUE,
      defaultValue: LAST_INTERACTION_DATE_FILTER_DEFAULT_VALUE,
      label: 'Last Event Date',
      type: FilterType.DATE_RANGE,
      selectOptions: LAST_INTERACTION_DATE_FILTER_OPTIONS,
      isActive: false,
    },
    {
      id: uuidv4(),
      value: RELATIONSHIP_AGE_FILTER_DEFAULT_VALUE,
      previousValue: RELATIONSHIP_AGE_FILTER_DEFAULT_VALUE,
      defaultValue: RELATIONSHIP_AGE_FILTER_DEFAULT_VALUE,
      label: 'Relationship Age',
      type: FilterType.SELECT,
      selectOptions: RELATIONSHIP_AGE_FILTER_OPTIONS,
      isActive: false,
    },
    {
      id: uuidv4(),
      value: RELATIONSHIP_OWNER_FILTER_DEFAULT_VALUE,
      previousValue: RELATIONSHIP_OWNER_FILTER_DEFAULT_VALUE,
      defaultValue: RELATIONSHIP_OWNER_FILTER_DEFAULT_VALUE,
      label: 'Relationship Owner',
      type: FilterType.SELECT,
      selectOptions: RELATIONSHIP_OWNER_FILTER_OPTIONS,
      isActive: false,
      isDisabled: true,
    },
    {
      id: uuidv4(),
      value: DEAL_STAGE_FILTER_DEFAULT_VALUE,
      previousValue: DEAL_STAGE_FILTER_DEFAULT_VALUE,
      defaultValue: DEAL_STAGE_FILTER_DEFAULT_VALUE,
      label: 'Deal Stage',
      type: FilterType.SELECT,
      selectOptions: DEAL_STAGE_FILTER_OPTIONS,
      isDisabled: true,
      isActive: false,
    },
    {
      id: uuidv4(),
      value: DEAL_SIZE_FILTER_DEFAULT_VALUE,
      previousValue: DEAL_SIZE_FILTER_DEFAULT_VALUE,
      defaultValue: DEAL_SIZE_FILTER_DEFAULT_VALUE,
      label: 'Est. Deal Size',
      type: FilterType.RANGE,
      maskType: RangeFilterMaskType.CURRENCY,
      isActive: false,
      isDisabled: true,
    },
    {
      id: uuidv4(),
      value: false,
      previousValue: false,
      defaultValue: false,
      label: 'Hide Relationships with events',
      type: FilterType.CHECKBOX,
      isActive: false,
      name: RelatioshipCheckboxFilters.HIDE_RELATIONSHIPS_WITH_EVENTS,
      groupId: RELATIONSHIP_EVENTS_FILTERS_GROUP_ID,
    },
    {
      id: uuidv4(),
      value: false,
      previousValue: false,
      defaultValue: false,
      label: 'Hide Relationships without events',
      type: FilterType.CHECKBOX,
      isActive: false,
      name: RelatioshipCheckboxFilters.HIDE_RELATIONSHIPS_WITHOUT_EVENTS,
      groupId: RELATIONSHIP_EVENTS_FILTERS_GROUP_ID,
    },
    {
      id: uuidv4(),
      value: false,
      previousValue: false,
      defaultValue: false,
      label: 'Show only My Relationships',
      type: FilterType.CHECKBOX,
      isActive: false,
      name: RelatioshipCheckboxFilters.SHOW_MY_RELATIONSHIPS,
      restrictedFor: ['dealmaker'],
      groupId: ADMIN_FILTERS_GROUP_ID,
    },
  ],
  filtersQueryParams: {},
};

const relationshipsSlice = createSlice({
  name: 'relationships',
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
    applyFilters: (state) => {
      state.filters = state.filters.map((filter) => {
        filter.previousValue = filter.value;
        return filter;
      });
    },
    changeFilterValue: (
      state,
      action: PayloadAction<{ id: string; value: FilterValue }>
    ) => {
      state.filters = state.filters.map((filter) => {
        if (filter.id === action.payload.id) {
          filter.value = action.payload.value;
        }
        return filter;
      });
    },
    toggleFilterActiveState: (state, action: PayloadAction<string>) => {
      state.filters = state.filters.map((filter) => {
        if (filter.id === action.payload) {
          filter.isActive = !filter.isActive;
          filter.value = filter.defaultValue;
        }

        return filter;
      });
    },
    updateFiltersQueryParams: (
      state,
      action: PayloadAction<RelationshipsFiltersQueryParams>
    ) => {
      state.filtersQueryParams = action.payload;
    },
    resetUnsavedFilters: (state) => {
      state.filters = state.filters.map((filter) => {
        filter.value = filter.previousValue;

        if (
          isDateRangeFilterValue(filter.value) &&
          isDateRangeFilterValue(filter.defaultValue)
        ) {
          filter.isActive =
            filter.value.selectValue !== filter.defaultValue.selectValue;

          return filter;
        }

        if (
          isRangeFilterValue(filter.value) &&
          isRangeFilterValue(filter.defaultValue)
        ) {
          filter.isActive =
            filter.value.min !== filter.defaultValue.min ||
            filter.value.max !== filter.defaultValue.max;

          return filter;
        }

        filter.isActive = filter.value !== filter.defaultValue;
        return filter;
      });
    },
    updateSorting: (
      state,
      action: PayloadAction<{
        sortBy: RSSortBy | null;
        sortOrder: RSSortOrder | null;
      }>
    ) => {
      state.relationships.sortOrder = action.payload.sortOrder;
      state.relationships.sortBy = action.payload.sortBy;
    },
    clearRelationships: (state) => {
      state.relationships = {
        ...initialState.relationships,
        sortBy: state.relationships.sortBy,
        sortOrder: state.relationships.sortOrder,
      };
      state.relationshipsForTable = initialState.relationshipsForTable;
      state.relationshipsDataStatus = initialState.relationshipsDataStatus;
    },
    clearSearch: (state) => {
      state.search = initialState.search;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRelationships.pending, (state) => {
      state.relationshipsDataStatus.isLoading = true;
      state.relationshipsDataStatus.isError = false;
    });
    builder.addCase(getRelationships.fulfilled, (state, action) => {
      state.relationshipsDataStatus.isLoading = false;
      state.relationships = {
        ...action.payload,
        sortBy: state.relationships.sortBy,
        sortOrder: state.relationships.sortOrder,
      };
      state.relationshipsForTable = [
        ...state.relationshipsForTable,
        ...action.payload?.results,
      ];
    });
    builder.addCase(getRelationships.rejected, (state, action) => {
      state.relationshipsDataStatus.isLoading = false;
      state.relationshipsDataStatus.isError = !action.meta.aborted;
    });
    builder.addCase(getAccountsForSearch.pending, (state) => {
      state.search.accountsData.isLoading = true;
      state.search.accountsData.isError = false;
      state.search.accountsData.noResults = false;
    });
    builder.addCase(getAccountsForSearch.fulfilled, (state, action) => {
      state.search.accountsData.isLoading = false;
      state.search.accountsData.accounts = action?.payload?.results;
      state.search.accountsData.noResults = !action?.payload?.results?.length;
    });
    builder.addCase(getAccountsForSearch.rejected, (state, action) => {
      state.search.accountsData.isLoading = false;
      state.search.accountsData.isError = !action.meta.aborted;
    });
    builder.addCase(getContactsForSearch.pending, (state) => {
      state.search.contactsData.noResults = false;
      state.search.contactsData.isLoading = true;
      state.search.contactsData.contacts =
        initialState.search.contactsData.contacts;
    });
    builder.addCase(getContactsForSearch.fulfilled, (state, action) => {
      state.search.contactsData.isLoading = false;
      state.search.contactsData.contacts = action?.payload?.results;
      state.search.contactsData.noResults = !action?.payload?.results?.length;
    });
    builder.addCase(getContactsForSearch.rejected, (state, action) => {
      state.search.contactsData.isLoading = false;
      state.search.contactsData.isError = !action.meta.aborted;
    });
  },
});

export const {
  clearFilters,
  applyFilters,
  changeFilterValue,
  toggleFilterActiveState,
  clearRelationships,
  updateFiltersQueryParams,
  resetUnsavedFilters,
  clearSearch,
  updateSorting,
} = relationshipsSlice.actions;

export default relationshipsSlice.reducer;
