import React from 'react';

export const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="176"
    height="40"
    viewBox="0 0 176 40"
    fill="none"
    {...props}
  >
    <path
      d="M90.0519 11V3L86 4V22C86 28 91.026 31 94.9221 31H97.8442V27H95.8961C92.974 27 90.0519 25 90.0519 22V15L95.9468 15.04L96.8701 11H90.0519Z"
      fill="#070B12"
    />
    <path
      d="M146.052 11V3L142 4V22C142 28 147.026 31 150.922 31H153.844V27H151.896C148.974 27 146.052 25 146.052 22V15L151.947 15.04L152.87 11H146.052Z"
      fill="#070B12"
    />
    <path
      d="M111.379 10C109.29 10 107.426 10.6386 105.891 11.8622V11H102.044L102.029 20.0919C102.011 20.4135 102 20.7389 102 21.072V31H106.339V21.072C106.339 19.5886 106.595 18.3379 107.107 17.3201C107.192 17.1516 107.284 16.9878 107.385 16.8292C107.484 16.6729 107.591 16.5221 107.705 16.3775C108.616 15.2244 109.84 14.6475 111.379 14.647C112.482 14.647 113.546 15.0179 114.573 15.7596L117 11.9096C115.249 10.6416 113.357 10 111.379 10Z"
      fill="#070B12"
    />
    <path
      d="M15.0486 20.4171C13.7487 19.4177 11.6696 18.7555 8.81122 18.4304C7.4079 18.2687 6.40069 18.0051 5.78961 17.6399C5.17852 17.2746 4.87322 16.7679 4.8737 16.1197C4.8737 15.3892 5.23746 14.8282 5.96496 14.4368C6.69247 14.0454 7.74508 13.8494 9.1228 13.8489C11.0976 13.8489 12.9429 14.4165 14.6588 15.5515L16.5689 12.3107C15.9655 11.8746 15.3288 11.5001 14.6588 11.1875C12.9624 10.3958 11.052 10 8.92788 10C7.29027 10 5.84105 10.2567 4.58024 10.7702C3.31942 11.2837 2.33831 12 1.63689 12.9189C0.935467 13.8384 0.584758 14.9063 0.584758 16.1227C0.584758 17.8521 1.26686 19.2509 2.63105 20.3191C3.99524 21.3873 5.93888 22.0417 8.46196 22.2824C9.94354 22.4442 11.0157 22.7145 11.6785 23.0933C12.3413 23.4722 12.6726 24.0128 12.6726 24.7152C12.6726 25.4458 12.3345 26.0067 11.6582 26.3981C10.9819 26.7895 10.0334 26.9855 8.81267 26.986C7.5565 26.9888 6.30739 26.7905 5.1092 26.3981C3.91312 26.0062 2.88635 25.4588 2.0289 24.7559L0 28.0404C2.54627 30.0135 5.44469 31 8.69528 31C10.3329 31 11.7821 30.7365 13.0429 30.2094C14.3037 29.6824 15.2783 28.9526 15.9667 28.02C16.6551 27.088 16.9995 26 17 24.7559C16.9986 22.8642 16.3481 21.418 15.0486 20.4171Z"
      fill="#070B12"
    />
    <path
      d="M81.0502 20.4156C79.7503 19.4164 77.6712 18.7543 74.813 18.4292C73.4122 18.2675 72.4052 18.004 71.7923 17.6388C71.1793 17.2735 70.8737 16.7669 70.8757 16.1188C70.8757 15.3884 71.2396 14.8275 71.9676 14.4362C72.6956 14.0448 73.7481 13.8489 75.1253 13.8484C77.1 13.8484 78.945 14.4158 80.6603 15.5507L82.571 12.3104C80.4393 10.7701 77.8925 10 74.9304 10C73.2929 10 71.8437 10.2567 70.5829 10.7701C69.3222 11.2835 68.3411 11.9997 67.6397 12.9185C66.9383 13.8378 66.5876 14.9056 66.5876 16.1219C66.5876 17.851 67.2697 19.2496 68.6338 20.3176C69.998 21.3857 71.9406 22.041 74.4616 22.2837C75.9431 22.4454 77.0152 22.7157 77.678 23.0945C78.3407 23.4733 78.6721 24.0138 78.6721 24.7161C78.6721 25.4466 78.334 26.0075 77.6577 26.3988C76.9814 26.7901 76.0327 26.9861 74.8116 26.9866C73.5554 26.9894 72.3064 26.7911 71.1083 26.3988C69.9127 26.007 68.8862 25.4596 68.0288 24.7568L66 28.0408C68.5462 30.0136 71.4445 31 74.6949 31C76.3325 31 77.7816 30.7365 79.0424 30.2095C80.3031 29.6825 81.2782 28.9518 81.9675 28.0174C82.6558 27.0855 83 25.9977 83 24.7538C82.9995 22.8624 82.3496 21.4163 81.0502 20.4156Z"
      fill="#070B12"
    />
    <path
      d="M136.069 13.0453C134.109 11.0536 131.492 10 128.5 10C125.509 10 122.891 11.0536 120.932 13.0453C119.014 14.9949 118 17.5727 118 20.5004C118 23.4281 119.014 26.0058 120.931 27.9554C122.891 29.9471 125.509 31 128.5 31C130.963 31 133.172 30.2848 134.97 28.9204V31H138.969V21.3325C138.987 21.0573 139 20.7806 139 20.4989C139 17.5727 137.986 14.9949 136.069 13.0453ZM132.839 24.8948C131.725 26.0285 130.279 26.5951 128.5 26.5947C126.722 26.5942 125.277 26.0276 124.165 24.8948C123.049 23.7621 122.492 22.2973 122.493 20.5004C122.493 18.7035 123.05 17.2382 124.165 16.1045C125.279 14.9717 126.725 14.4053 128.503 14.4053C130.281 14.4053 131.728 14.9717 132.842 16.1045C133.956 17.2377 134.514 18.7027 134.514 20.4996C134.515 22.2965 133.956 23.7616 132.839 24.8948Z"
      fill="#070B12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.069 13.0453C174.986 14.9949 176 17.5727 176 20.5004C176 20.7814 175.987 21.0587 175.969 21.3325V31H171.97L171.969 28.9204C170.172 30.2848 167.963 31 165.5 31C162.508 31 159.891 29.9471 157.931 27.9554C156.014 26.0058 155 23.4281 155 20.5004C155 17.5727 156.014 14.9949 157.931 13.0453C159.891 11.0536 162.509 10 165.5 10C168.492 10 171.109 11.0536 173.069 13.0453ZM165.5 26.5947C167.278 26.5951 168.724 26.0285 169.839 24.8948C170.953 23.7621 171.51 22.2973 171.51 20.5004C171.51 18.7035 170.953 17.2384 169.839 16.1052C168.724 14.9724 167.278 14.4061 165.5 14.4061C163.723 14.4061 162.276 14.9724 161.161 16.1052C160.047 17.2384 159.49 18.7035 159.49 20.5004C159.49 22.2973 160.047 23.7621 161.161 24.8948C162.276 26.0276 163.723 26.5942 165.5 26.5947Z"
      fill="#070B12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 11V21.6061C24 24.9708 26.6554 27.3636 29.5 27.3636C32.3446 27.3636 35 24.9708 35 21.6061V11H39V21.6061C39 26.6093 34.9397 31 29.5 31C24.0603 31 20 26.6093 20 21.6061V11H24Z"
      fill="#070B12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.022 11.9676C48.8209 10.5902 51.0336 9.86678 53.5015 9.86678C56.4934 9.86678 59.1107 10.9271 61.0681 12.9314C62.9862 14.8934 64 17.4875 64 20.4338C64 23.38 62.9862 25.9741 61.0681 27.9361C59.1078 29.9404 56.4904 31 53.4985 31C50.5067 31 47.8885 29.9404 45.929 27.9361C44.0419 26.0062 43.0326 23.4641 43 20.5777V20.5726L43.0002 3.97978L47.0202 3L47.022 11.9676ZM53.5 27C57.0899 27 60 24.0899 60 20.5C60 16.9101 57.0899 14 53.5 14C49.9101 14 47 16.9101 47 20.5C47 24.0899 49.9101 27 53.5 27Z"
      fill="#070B12"
    />
  </svg>
);
