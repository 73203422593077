export default function GetColorAndMessage(
  measurements: number,
  title: string
): { triangeColor: string; message: string } {
  let triangeColor = '#c5c5cc';
  let message = '';

  const changemeasurements = (color: string, text: string) => {
    triangeColor = color;
    message = text;
  };

  if (title === 'empathy') {
    if (measurements < -1.29 && measurements >= -1.75)
      changemeasurements('#F0972E', 'Your empathy is too low.');
    if (measurements < -0.82 && measurements >= -1.29)
      changemeasurements('#F0972E', 'Your empathy is low.');
    if (measurements < -0.59 && measurements >= -0.82)
      changemeasurements(
        '#DCCF5C',
        'Your empathy level is within the recommended range.'
      );
    if (measurements < -0.36 && measurements >= -0.59)
      changemeasurements('#96C25E', 'Your empathy level is close to optimal.');
    if (measurements < 0.11 && measurements >= -0.36)
      changemeasurements(
        '#32B76C',
        'Well done! Your empathy level is in the optimal range.'
      );
    if (measurements < 0.34 && measurements >= 0.11)
      changemeasurements('#96C25E', 'Your empathy level is close to optimal.');
    if (measurements < 0.57 && measurements >= 0.34)
      changemeasurements(
        '#DCCF5C',
        'Your empathy level is within the recommended range.'
      );
    if (measurements < 1.04 && measurements >= 0.57)
      changemeasurements('#F0972E', 'Your empathy is high.');
    if (measurements <= 1.5 && measurements >= 1.04)
      changemeasurements('#F0972E', 'Your empathy is too high.');
  } else if (title === 'responsiveness') {
    if (measurements < -0.97 && measurements >= -1.3)
      changemeasurements(
        '#F0972E',
        'Your response time is slower than average.'
      );
    if (measurements < -0.64 && measurements >= -0.97)
      changemeasurements(
        '#F0972E',
        'Your response time is slightly slower than average.'
      );
    if (measurements < -0.48 && measurements >= -0.64)
      changemeasurements(
        '#DCCF5C',
        'Your response time is within the recommended range.'
      );
    if (measurements < -0.31 && measurements >= -0.48)
      changemeasurements(
        '#96C25E',
        'Your average response time is close to optimal.'
      );
    if (measurements < 0.01 && measurements >= -0.31)
      changemeasurements(
        '#32B76C',
        'Well done! Your average response time is in the optimal range.'
      );
    if (measurements < 0.18 && measurements >= 0.01)
      changemeasurements(
        '#96C25E',
        'Your average response time is close to optimal.'
      );
    if (measurements < 0.34 && measurements >= 0.18)
      changemeasurements(
        '#DCCF5C',
        'Your response time is within the recommended range.'
      );
    if (measurements < 0.67 && measurements >= 0.34)
      changemeasurements(
        '#F0972E',
        'Your response time is slightly faster than average.'
      );
    if (measurements <= 1.0 && measurements >= 0.67)
      changemeasurements(
        '#F0972E',
        'Your response time is faster than average.'
      );
  } else if (title === 'resilience') {
    if (measurements < 0.29 && measurements >= 0.0)
      changemeasurements('#F0972E', 'Your resilience level is too low.');
    if (measurements < 0.57 && measurements >= 0.29)
      changemeasurements('#F0972E', 'Your resilience level is low.');
    if (measurements < 0.71 && measurements >= 0.57)
      changemeasurements(
        '#DCCF5C',
        'Your resilience level is within the recommended range.'
      );
    if (measurements < 0.86 && measurements >= 0.71)
      changemeasurements(
        '#96C25E',
        'Your resilience level is close to optimal.'
      );
    if (measurements < 1.14 && measurements >= 0.86)
      changemeasurements(
        '#32B76C',
        'Well done! Your resilience level is in the optimal range.'
      );
    if (measurements < 1.29 && measurements >= 1.14)
      changemeasurements(
        '#96C25E',
        'Your resilience level is close to optimal.'
      );
    if (measurements < 1.43 && measurements >= 1.29)
      changemeasurements(
        '#DCCF5C',
        'Your resilience level is within the recommended range.'
      );
    if (measurements < 1.71 && measurements >= 1.43)
      changemeasurements('#F0972E', 'Your resilience level is high.');
    if (measurements <= 2.0 && measurements >= 1.71)
      changemeasurements('#F0972E', 'Your resilience level is too high.');
  } else if (title === 'intuition') {
    if (measurements < 0.29 && measurements >= 0.0)
      changemeasurements('#F0972E', 'Your intuition level is too low.');
    if (measurements < 0.57 && measurements >= 0.29)
      changemeasurements('#F0972E', 'Your intuition level is low.');
    if (measurements < 0.71 && measurements >= 0.57)
      changemeasurements(
        '#DCCF5C',
        'Your intuition level is within the recommended range.'
      );
    if (measurements < 0.86 && measurements >= 0.71)
      changemeasurements(
        '#96C25E',
        'Your intuition level is close to optimal.'
      );
    if (measurements < 1.14 && measurements >= 0.86)
      changemeasurements(
        '#32B76C',
        'Well done! Your intuition level is in the optimal range.'
      );
    if (measurements < 1.29 && measurements >= 1.14)
      changemeasurements(
        '#96C25E',
        'Your intuition level is close to optimal.'
      );
    if (measurements < 1.43 && measurements >= 1.29)
      changemeasurements(
        '#DCCF5C',
        'Your intuition level is within the recommended range.'
      );
    if (measurements < 1.71 && measurements >= 1.43)
      changemeasurements('#F0972E', 'Your intuition level is high.');
    if (measurements <= 2.0 && measurements >= 1.71)
      changemeasurements('#F0972E', 'Your intuition level is too high.');
  } else if (title === 'overallCompetence') {
    if (measurements >= 0 && measurements < 0.06)
      changemeasurements(
        '#EF6863',
        'Your Overall Competence needs improvement. Most of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.06 && measurements < 0.13)
      changemeasurements(
        '#EF6863',
        'Your Overall Competence needs improvement. Most of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.13 && measurements < 0.19)
      changemeasurements(
        '#EF6863',
        'Your Overall Competence needs improvement. Most of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.19 && measurements < 0.25)
      changemeasurements(
        '#EF6863',
        'Your Overall Competence needs improvement. Over 75% of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.25 && measurements < 0.31)
      changemeasurements(
        '#EE9153',
        'Your Overall Competence has areas to improve. Over 50% of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.31 && measurements < 0.38)
      changemeasurements(
        '#EE9153',
        'Your Overall Competence has areas to improve. Over 50% of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.38 && measurements < 0.44)
      changemeasurements(
        '#F3BE42',
        'Your Overall Competence has areas to improve. Over 50% of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.44 && measurements < 0.5)
      changemeasurements(
        '#F3BE42',
        'Your Overall Competence has areas to improve. Over 50% of your emails gave an advantage to the other side.'
      );
    if (measurements >= 0.5 && measurements < 0.56)
      changemeasurements(
        '#F3BE42',
        'Your Overall Competence has room to improve. Just about 50% of your emails ended with you having the upper hand.'
      );
    if (measurements >= 0.56 && measurements < 0.63)
      changemeasurements(
        '#90C155',
        'Your Overall Competence has room to improve. Just about 50% of your emails ended with you having the upper hand.'
      );
    if (measurements >= 0.63 && measurements < 0.69)
      changemeasurements(
        '#90C155',
        'Your Overall Competence has room to improve.  Over 50% of your emails ended with you having the upper hand.'
      );
    if (measurements >= 0.69 && measurements < 0.75)
      changemeasurements(
        '#90C155',
        'Well done! Your Overall Competence is in the recommended range. You usually gain the upper hand in your email messages.'
      );
    if (measurements >= 0.75 && measurements < 0.81)
      changemeasurements(
        '#80BA5B',
        'Well done! Your Overall Competence is in the recommended range. You usually gain the upper hand in your email messages.'
      );
    if (measurements >= 0.81 && measurements < 0.88)
      changemeasurements(
        '#80BA5B',
        'Well done! Your Overall Competence is in the recommended range. You usually gain the upper hand in your email messages.'
      );
    if (measurements >= 0.88 && measurements < 0.94)
      changemeasurements(
        '#80BA5B',
        'Congratulations! Your overall Competence is at the highest level. You almost always gain the upper hand in your email messages.'
      );
    if (measurements >= 0.94 && measurements <= 1)
      changemeasurements(
        '#80BA5B',
        'Congratulations! Your overall Competence is at the highest level. You almost always gain the upper hand in your email messages.'
      );
  }

  return { triangeColor, message };
}
