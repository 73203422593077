export enum ChromeMessageType {
  UPDATE_IDENTITY = 'UPDATE_IDENTITY',
}

export interface ChromeMessagePayloadMap {
  [ChromeMessageType.UPDATE_IDENTITY]: null;
}

export interface ChromeMessage<T extends ChromeMessageType> {
  type: T;
  payload: ChromeMessagePayloadMap[T];
}
