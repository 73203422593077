import { useToggle } from './useToggle';

type Items = {
  open: boolean;
  handleToggleTooltip: () => void;
};

export const useTooltip = (): Items => {
  const { open, toggle } = useToggle(false);

  const handleToggleTooltip = (): void => {
    toggle();
  };

  return { open, handleToggleTooltip };
};
