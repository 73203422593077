import React from 'react';

interface Props {
  active: boolean;
}

export const AppsIcon: React.FC<Props> = ({ active }: Props): JSX.Element => {
  return (
    <div className="submenu__body__link-icon">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {active ? (
          <>
            <path
              d="M8.83301 2.66663C8.46482 2.66663 8.16634 2.9651 8.16634 3.33329V7.16936C8.16634 7.53755 8.46482 7.83602 8.83301 7.83602H12.667C13.0352 7.83602 13.3337 7.53754 13.3337 7.16935V3.33329C13.3337 2.9651 13.0352 2.66663 12.667 2.66663H8.83301Z"
              fill={active ? 'white' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              d="M3.33366 2.66663C2.96547 2.66663 2.66699 2.96511 2.66699 3.3333V7.16936C2.66699 7.53755 2.96547 7.83602 3.33366 7.83602H7.16738C7.53557 7.83602 7.83404 7.53755 7.83404 7.16936V3.3333C7.83404 2.96511 7.53557 2.66663 7.16738 2.66663H3.33366Z"
              fill={active ? 'white' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              d="M3.38045 8.16285C3.01226 8.16285 2.71379 8.46132 2.71379 8.82951V12.6666C2.71379 13.0348 3.01226 13.3333 3.38045 13.3333H7.21409C7.58228 13.3333 7.88076 13.0348 7.88076 12.6666V8.82951C7.88076 8.46132 7.58228 8.16285 7.21409 8.16285H3.38045Z"
              fill={active ? 'white' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              d="M8.16634 8.8295C8.16634 8.46131 8.46482 8.16283 8.83301 8.16283H12.667C13.0352 8.16283 13.3337 8.46131 13.3337 8.8295V12.6666C13.3337 13.0348 13.0352 13.3333 12.667 13.3333H8.83301C8.46482 13.3333 8.16634 13.0348 8.16634 12.6666V8.8295Z"
              fill={active ? 'white' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
          </>
        ) : (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.66699 3.33329C2.66699 2.9651 2.96547 2.66663 3.33366 2.66663H7.16738C7.53557 2.66663 7.83404 2.9651 7.83404 3.33329V7.16935C7.83404 7.53754 7.53557 7.83602 7.16738 7.83602H3.33366C2.96547 7.83602 2.66699 7.53754 2.66699 7.16935V3.33329ZM3.33366 3.33329H7.16738L7.16738 7.16935H3.33366L3.33366 3.33329Z"
              fill={active ? '#646c8391' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.71379 8.82951C2.71379 8.46132 3.01226 8.16284 3.38045 8.16284H7.21409C7.58228 8.16284 7.88076 8.46132 7.88076 8.82951V12.6666C7.88076 13.0348 7.58228 13.3333 7.21409 13.3333H3.38045C3.01226 13.3333 2.71379 13.0348 2.71379 12.6666V8.82951ZM3.38045 8.82951H7.21409V12.6666H3.38045V8.82951Z"
              fill={active ? '#646c8391' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.83301 8.16284C8.46482 8.16284 8.16634 8.46132 8.16634 8.82951V12.6666C8.16634 13.0348 8.46482 13.3333 8.83301 13.3333H12.667C13.0352 13.3333 13.3337 13.0348 13.3337 12.6666V8.82951C13.3337 8.46132 13.0352 8.16284 12.667 8.16284H8.83301ZM12.667 8.82951H8.83301V12.6666H12.667V8.82951Z"
              fill={active ? '#646c8391' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.16634 3.33329C8.16634 2.9651 8.46482 2.66663 8.83301 2.66663H12.667C13.0352 2.66663 13.3337 2.9651 13.3337 3.33329V7.16935C13.3337 7.53754 13.0352 7.83602 12.667 7.83602H8.83301C8.46482 7.83602 8.16634 7.53754 8.16634 7.16935V3.33329ZM8.83301 3.33329H12.667V7.16935L8.83301 7.16935V3.33329Z"
              fill={active ? '#646c8391' : '#44536A'}
              style={{ transition: 'all 0.2s linear' }}
            />
          </>
        )}
      </svg>
    </div>
  );
};
