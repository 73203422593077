import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from '../../axios';

export const sendContactRequest = createAsyncThunk<
  boolean,
  Record<string, unknown>
>(
  'global/sendContactRequest',
  async (requestBody, { fulfillWithValue, getState, rejectWithValue }) => {
    try {
      const {
        auth: { user },
      } = getState() as RootState;

      const { orgId = '', userId = '' } = user || {};

      await axios.post(
        `services/orgs/${orgId}/users/${userId}/support-contact-us`,
        requestBody
      );

      return fulfillWithValue(true);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        auth: { user },
      } = getState() as RootState;
      return !!user;
    },
  }
);
