import { Dispatch } from 'redux';
import axios from '../../axios';
import { AddedTeam, Row, TeamMember } from '../../types';
import { Team } from './teams.reducer';
import {
  ClearTeamsErrors,
  CreateTeam,
  CreateTeamFailure,
  CreateTeamSuccess,
  GetTeamsAction,
  InvokeCreateTeamModal,
  InvokeDeleteMembersModal,
  InvokeNewLeaderModal,
  SetCurrentMember,
  SetCurrentTeam,
  SetTeamsLoading,
  UpdateTeamDetails,
} from './teams.types';
import { getTeamRow, getTeamsUsersRow } from './teams.utils';

export const SET_TEAMS_LOADING = 'SET_TEAMS_LOADING';
export const GET_TEAMS = 'GET_TEAMS';
export const SET_CURRENT_TEAM = 'SET_CURRENT_TEAM';
export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER';
export const UPDATE_TEAM_DETAILS = 'UPDATE_TEAM_DETAILS';
export const UPDATE_TEAM_MEMBERS = 'UPDATE_TEAM_MEMBERS';
export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';
export const INVOKE_DELETE_MEMBERS_MODAL = 'INVOKE_DELETE_MEMBERS_MODAL';
export const INVOKE_NEW_LEADER_MODAL = 'INVOKE_NEW_LEADER_MODAL';
export const INVOKE_CREATE_TEAM_MODAL = 'INVOKE_CREATE_TEAM_MODAL';
export const CLEAR_TEAMS_ERRORS = 'CLEAR_TEAMS_ERRORS';

export const getTeams = (orgId: string) => async (
  dispatch: Dispatch<GetTeamsAction>
): Promise<void> => {
  try {
    const teamsResponse = await axios.get(`/services/orgs/${orgId}/teams`);
    dispatch({
      type: GET_TEAMS,
      payload: getTeamsUsersRow(teamsResponse.data.payload),
    });
  } catch (e) {
    // handle error
  }
};

export const getTeamsForDealmaker = (orgId: string, userId: string) => async (
  dispatch: Dispatch<GetTeamsAction>
): Promise<void> => {
  try {
    const teamsResponse = await axios.get(
      `/services/orgs/${orgId}/user/${userId}/teams`
    );
    dispatch({
      type: GET_TEAMS,
      payload: getTeamsUsersRow(teamsResponse.data.payload),
    });
  } catch (e) {
    // handle error
  }
};

export const createTeam = (
  orgId: string,
  requesterUserId: string,
  team: AddedTeam
) => async (
  dispatch: Dispatch<
    | CreateTeam
    | GetTeamsAction
    | CreateTeamSuccess
    | CreateTeamFailure
    | SetTeamsLoading
  >
): Promise<void> => {
  try {
    dispatch({ type: SET_TEAMS_LOADING, flag: true });
    const teamToAdd = {
      ...team,
      newAvatar: team.avatar
        ? { contentType: 'image/png', b64content: team.avatar.split(',')[1] }
        : null,
    };
    const response = await axios.post(
      `services/orgs/${orgId}/teams?requesterId=${requesterUserId}`,
      teamToAdd
    );
    if (response?.data?.payload) {
      dispatch({
        type: CREATE_TEAM,
        payload: getTeamRow(response.data.payload),
      });
      dispatch({ type: CREATE_TEAM_SUCCESS });
      dispatch({ type: SET_TEAMS_LOADING, flag: false });
    }
  } catch (e) {
    dispatch({ type: CREATE_TEAM_FAILURE });
    dispatch({ type: SET_TEAMS_LOADING, flag: false });
  }
};

export const setCurrentTeam = (team: Team): SetCurrentTeam => ({
  type: SET_CURRENT_TEAM,
  payload: team,
});

export const setCurrentTeamMember = (member: Row | null): SetCurrentMember => ({
  type: SET_CURRENT_MEMBER,
  payload: member,
});

export const updateTeamDetails = (
  orgId: string,
  requesterId: string,
  teamId: string,
  teamDetails: Partial<AddedTeam>
) => async (
  dispatch: Dispatch<UpdateTeamDetails | SetTeamsLoading>
): Promise<void> => {
  dispatch({ type: SET_TEAMS_LOADING, flag: true });
  const teamDetailsToUpdate = {
    newAvatar: teamDetails.avatar
      ? {
          contentType: 'image/png',
          b64content: teamDetails.avatar.split(',')[1],
        }
      : null,
    name: teamDetails.name || null,
  };
  try {
    const updatedTeam = await axios.patch(
      `services/orgs/${orgId}/teams/${teamId}?requesterId=${requesterId}`,
      teamDetailsToUpdate
    );
    if (updatedTeam?.data?.code === 200) {
      dispatch({
        type: UPDATE_TEAM_DETAILS,
        payload: getTeamRow(updatedTeam.data.payload),
      });
    }
    dispatch({ type: SET_TEAMS_LOADING, flag: false });
  } catch (e) {
    dispatch({ type: SET_TEAMS_LOADING, flag: false });
  }
};

export const updateTeamMembers = (
  orgId: string,
  requesterId: string,
  teamId: string,
  members: TeamMember[],
  action: 'addMembers' | 'deleteMembers',
  noNeedToUpdate?: boolean
) => async (
  dispatch: Dispatch<UpdateTeamDetails | GetTeamsAction>
): Promise<void> => {
  try {
    const membersToUpdate = { op: action, members };
    const response = await axios.post(
      `services/orgs/${orgId}/teams/${teamId}/members?requesterId=${requesterId}`,
      membersToUpdate
    );
    if (response?.data?.code === 200 && !noNeedToUpdate) {
      dispatch({
        type: UPDATE_TEAM_DETAILS,
        payload: getTeamRow(response?.data?.payload),
      });
    }
    if (!response?.data?.payload) {
      const updatedTeams = await axios.get(`/services/orgs/${orgId}/teams`);
      dispatch({
        type: GET_TEAMS,
        payload: getTeamsUsersRow(updatedTeams.data.payload),
      });
    }
  } catch (e) {
    // handle error
  }
};

export const deleteTeam = (orgId: string, teamId: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  try {
    await axios.delete(`services/orgs/${orgId}/teams/${teamId}`);
    dispatch({
      type: DELETE_TEAM_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: DELETE_TEAM_FAILURE,
    });
  }
};

export const invokeDeleteMebersModal = (
  flag: boolean
): InvokeDeleteMembersModal => ({
  type: INVOKE_DELETE_MEMBERS_MODAL,
  flag,
});

export const invokeNewLeaderModal = (flag: boolean): InvokeNewLeaderModal => ({
  type: INVOKE_NEW_LEADER_MODAL,
  flag,
});

export const invokeCreateTeamModal = (
  flag: boolean
): InvokeCreateTeamModal => ({
  type: INVOKE_CREATE_TEAM_MODAL,
  flag,
});

export const resetTeamsErrors = (): ClearTeamsErrors => ({
  type: CLEAR_TEAMS_ERRORS,
});
