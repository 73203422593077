export const getInitials = (name: string): string => {
  const [firstName, lastName] = name.split(' ');

  if (!lastName) {
    return firstName.slice(0, 2).toUpperCase();
  }

  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  return '';
};
