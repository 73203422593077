import React, { useEffect, useRef, useState } from 'react';
import { useIMask } from 'react-imask';
import { useClickOutside, useId } from '../../../../hooks';
import { Select, SelectOption } from './components/Select/Select';
import { ImageFileUpload } from './components/ImageFileUpload/ImageFileUpload';
import { useCountryPhoneCodeSelectOptions } from './hooks/useCountryPhoneCodeSelectOptions';
import { getInputMaskOptions } from './utils/getInputMaskOptions';
import { Tooltip } from '../Tooltip/Tooltip';

export enum FormFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  TEXTAREA = 'textarea',
  SELECT = 'select',
  IMAGE = 'image',
  PHONE = 'phone',
}

export interface FormFieldProps {
  name?: string;
  selectName?: string;
  value?: string;
  selectValue?: string;
  placeholder?: string;
  selectPlaceholder?: React.ReactNode;
  disabled?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  touched?: boolean;
  type?: FormFieldType;
  options?: SelectOption[];
  placeholderIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  rounded?: boolean;
  withAutocomplete?: boolean;
  className?: string;
  customTitle?: string;
  tooltip?: string;
  onAutocomplete?: (name: string, value: string) => void;
  onChange?: (name: string, value: string) => void;
  onInputChange?: (value: string) => void;
  onBlur?: (e: React.FocusEvent<any, Element>) => void;
  onFocus?: (e: React.FocusEvent<any, Element>) => void;
  renderDropdownContent?: () => React.ReactNode;
}

export const FormField: React.FC<FormFieldProps> = ({
  name,
  selectName,
  value,
  selectValue,
  label,
  error,
  placeholder,
  selectPlaceholder,
  options,
  placeholderIcon,
  className,
  type = FormFieldType.TEXT,
  required = false,
  touched = false,
  disabled = false,
  rounded = false,
  withAutocomplete = false,
  customTitle,
  tooltip,
  onAutocomplete,
  onChange,
  onInputChange,
  onBlur,
  onFocus,
  renderDropdownContent,
}) => {
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const countryPhoneCodeSelectOptions = useCountryPhoneCodeSelectOptions();

  const id = useId();
  const errorId = useId();

  const {
    ref: inputRef,
    unmaskedValue,
    setUnmaskedValue,
  } = useIMask<HTMLInputElement>(getInputMaskOptions(type, selectValue), {
    defaultUnmaskedValue: value,
  });

  const showErrorMessage = touched && error && !isAutocompleteVisible;

  const attrs = {
    container: {
      ref: containerRef,
      className: `form-field${disabled ? ' form-field--disabled' : ''}${
        showErrorMessage ? ' form-field--invalid' : ''
      }${type === FormFieldType.TEXTAREA ? ' form-field--textarea' : ''}${
        type === FormFieldType.IMAGE ? ' form-field--image' : ''
      }${withAutocomplete ? ' form-field--autocomplete' : ''}${
        className ? ` ${className}` : ''
      }`,
    },
    label: {
      htmlFor: id,
      className: 'form-field__label',
    },
    fieldsGroup: {
      className: 'sound-wave-form__fields-group',
    },
    select: {
      disabled,
      onInputChange,
      renderDropdownContent,
      options:
        type === FormFieldType.PHONE ? countryPhoneCodeSelectOptions : options,
      placeholder: selectPlaceholder || placeholder,
      value: selectValue || value,
      autocomplete: withAutocomplete,
      onChange: (selectedValue: string) => {
        onChange?.(selectName || name || '', selectedValue);
      },
      ...(type === FormFieldType.PHONE
        ? {
            width: 74,
            dropdownWidth: 264,
            className: 'form-field__country-code-select',
          }
        : {}),
    },
    input: {
      id,
      name,
      required,
      disabled,
      placeholder,
      onFocus,
      type: type === 'email' || type === 'text' ? type : 'text',
      'aria-required': required,
      'aria-invalid': !!error,
      className: 'form-field__input',
      ...(showErrorMessage ? { 'aria-errormessage': errorId } : {}),
      ...(type === FormFieldType.PHONE
        ? {
            ref: inputRef,
            onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
              onBlur?.(e);
              onChange?.(name || '', unmaskedValue);
            },
          }
        : {
            value,
            onBlur,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              onChange?.(name || '', e.target.value);
            },
          }),
    },
    textarea: {
      id,
      name,
      required,
      disabled,
      placeholder,
      value,
      onBlur,
      onFocus,
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(name || '', e.target.value);
      },
      'aria-required': required,
      'aria-invalid': !!error,
      className: 'form-field__input',
      ...(showErrorMessage ? { 'aria-errormessage': errorId } : {}),
    },
    imageFileUpload: {
      id,
      name,
      rounded,
      disabled,
      placeholderIcon,
      customTitle,
      onChange: (image: string) => {
        onChange?.(name || '', image);
      },
      image: value,
    },
    autocompleteDropdown: {
      className: 'form-field__autocomplete-dropdown',
    },
    error: {
      id: errorId,
      className: 'form-field__error',
    },
    tooltip: {
      width: 192,
      text: tooltip,
    },
  };

  const renderLabel = () =>
    label ? (
      <label {...attrs.label}>
        {label}
        {required ? '*' : ''}
      </label>
    ) : null;

  const renderError = () =>
    showErrorMessage ? <span {...attrs.error}>{error}</span> : null;

  const renderAutocompleteOptions = () =>
    options?.map((option, index) => {
      const props = {
        key: index,
        type: 'button' as const,
        className: 'form-field__autocomplete-option',
        onClick: () => {
          onAutocomplete?.(name || '', option.value);
          setIsAutocompleteVisible(false);
        },
      };

      return <button {...props}>{option.label}</button>;
    });

  useEffect(() => {
    setIsAutocompleteVisible(
      !disabled && !!options?.length && withAutocomplete
    );
  }, [disabled, options, withAutocomplete]);

  useEffect(() => {
    setUnmaskedValue(value || '');
  }, [value, setUnmaskedValue]);

  useClickOutside(containerRef, () => {
    setIsAutocompleteVisible(false);
  });

  if (type === FormFieldType.SELECT) {
    return (
      <div {...attrs.container}>
        {renderLabel()}
        <Select {...attrs.select} />
        {renderError()}
      </div>
    );
  }

  if (type === FormFieldType.TEXTAREA) {
    return (
      <div {...attrs.container}>
        {renderLabel()}
        <textarea {...attrs.textarea} />
        {renderError()}
      </div>
    );
  }

  if (type === FormFieldType.IMAGE) {
    return (
      <div {...attrs.container}>
        {renderLabel()}
        <ImageFileUpload {...attrs.imageFileUpload} />
        {renderError()}
      </div>
    );
  }

  if (type === FormFieldType.PHONE) {
    return (
      <div {...attrs.container}>
        {renderLabel()}
        <div {...attrs.fieldsGroup}>
          <Select {...attrs.select} />
          <input {...attrs.input} />
        </div>
        {renderError()}
      </div>
    );
  }

  return (
    <div {...attrs.container}>
      {renderLabel()}
      {tooltip ? (
        <Tooltip {...attrs.tooltip}>
          <input {...attrs.input} />
        </Tooltip>
      ) : (
        <input {...attrs.input} />
      )}
      {isAutocompleteVisible ? (
        <div {...attrs.autocompleteDropdown}>{renderAutocompleteOptions()}</div>
      ) : null}
      {renderError()}
    </div>
  );
};
