import {
  MeetingRecord,
  UpcomingMeeting,
} from '../../types/meetingDetails.types';
import { Filter, FilterValue } from '../../types/meetingRecordsFilters.types';
import { Pagination } from '../../types/pagination.types';

export enum MRSortBy {
  ACCOUNT_NAME = 'name',
  CREATED_AT = 'createdAt',
  LAST_ACTIVITY = 'lastActivity',
  EVENTS_NUMBER = 'touchPointsCount',
}

export enum MRSortOrder {
  FROM_HIGH = 'desc',
  FLOM_LOW = 'asc',
}

export interface MeetingRecordsResponse {
  count: number;
  p: number;
  ipp: number;
  sortBy: MRSortBy | null;
  sortOrder: MRSortOrder | null;
  nextPage: string | null;
  results: UpcomingMeeting[];
}

export enum MeetingRecordsCheckboxFilters {
  SHOW_MEETING_RECORDS_WITH_NA_ACCOUNT = 'showNoAccountOnly',
  // HIDE_MEETING_RECORDS_WITHOUT_NA_ACCOUNT = 'hideMeetingWithoutNAAccount',
  // SHOW_MY_MEETING_RECORDS = 'showMyMeetins',
}

export interface MeetingRecordsFiltersQueryParams {
  meetingDateMin?: string;
  meetingDateMax?: string;
  meetingType?: string;
  showNoAccountOnly?: string;
}
export interface MeetingRecordsQueryParams
  extends MeetingRecordsFiltersQueryParams {
  ipp: number;
  p: number;
  sortBy: string;
  sortOrder: string;
}

export interface MeetingRecordsState {
  isLoading: {
    meetingRecords: boolean;
  };
  isError: {
    meetingRecords: boolean;
  };
  pagination: Required<Pagination>;
  data: MeetingRecord[];
  upcoming: {
    data: UpcomingMeeting[];
    nextPage: string | null;
    status: {
      isLoading: boolean;
      isError: boolean;
      noData: boolean;
    };
  };
  filters: Filter<FilterValue>[];
  filtersQueryParams: MeetingRecordsFiltersQueryParams;
}
