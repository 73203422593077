import React from 'react';

export const GoogleSmallIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <path
      d="M10.6655 8.43652V11.6408H15.1185C14.9229 12.6713 14.3361 13.5439 13.4561 14.1306L16.1414 16.2142C17.7059 14.77 18.6086 12.6488 18.6086 10.129C18.6086 9.54233 18.5559 8.97814 18.4581 8.43661L10.6655 8.43652Z"
      fill="#4285F4"
    />
    <path
      d="M6.02869 11.5156L5.42306 11.9792L3.2793 13.6491C4.64075 16.3494 7.43115 18.2148 10.6655 18.2148C12.8995 18.2148 14.7724 17.4777 16.1414 16.214L13.4561 14.1305C12.7189 14.6269 11.7787 14.9278 10.6655 14.9278C8.51428 14.9278 6.68653 13.4761 6.03208 11.5204L6.02869 11.5156Z"
      fill="#34A853"
    />
    <path
      d="M3.27912 6.23242C2.71501 7.34561 2.3916 8.60178 2.3916 9.94064C2.3916 11.2795 2.71501 12.5357 3.27912 13.6489C3.27912 13.6563 6.03216 11.5127 6.03216 11.5127C5.86668 11.0162 5.76887 10.4897 5.76887 9.94056C5.76887 9.39139 5.86668 8.86489 6.03216 8.36845L3.27912 6.23242Z"
      fill="#FBBC05"
    />
    <path
      d="M10.6657 4.96132C11.8842 4.96132 12.9674 5.38253 13.8324 6.1949L16.2017 3.82555C14.7651 2.48668 12.8997 1.66675 10.6657 1.66675C7.43132 1.66675 4.64075 3.52464 3.2793 6.23252L6.03226 8.36873C6.68662 6.41302 8.51445 4.96132 10.6657 4.96132Z"
      fill="#EA4335"
    />
  </svg>
);
