import React from 'react';

export const ClearIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_13475_50280)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2426 3.75729C12.503 4.01764 12.503 4.43975 12.2426 4.7001L8.94281 7.99993L12.2426 11.2998C12.503 11.5601 12.503 11.9822 12.2426 12.2426C11.9823 12.5029 11.5602 12.5029 11.2998 12.2426L8 8.94274L4.70017 12.2426C4.43982 12.5029 4.01771 12.5029 3.75736 12.2426C3.49701 11.9822 3.49701 11.5601 3.75736 11.2998L7.05719 7.99993L3.75736 4.7001C3.49701 4.43975 3.49701 4.01764 3.75736 3.75729C4.01771 3.49694 4.43982 3.49694 4.70017 3.75729L8 7.05712L11.2998 3.75729C11.5602 3.49694 11.9823 3.49694 12.2426 3.75729Z"
        fill="#646F87"
      />
    </g>
    <defs>
      <clipPath id="clip0_13475_50280">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
