import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RelationshipDetailsState } from './relationshipDetails.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../types/relationshipMember.types';
import {
  applyEditModeChanges,
  createAccountNote,
  deleteAccountNote,
  getAccountNotes,
  getRelationshipAccountCompanyDetails,
  getRelationshipAccountDetails,
  getRelationshipContacts,
  getRelationshipEvents,
  updateAccountNote,
} from './relationshipDetails.thunks';
import {
  assignEventToAccount,
  linkContactToAccount,
  updateAccount,
} from '../relationshipFlows/relationshipFlows.thunks';
import { Contact } from '../../types/contact.types';

const initialState: RelationshipDetailsState = {
  isLoading: {
    account: false,
    accountCompanyDetails: false,
    contacts: false,
    notes: false,
    updateNotes: false,
    events: false,
    editModeChanges: false,
  },
  isError: {
    account: false,
    accountCompanyDetails: false,
    contacts: false,
    notes: false,
    updateNotes: false,
    events: false,
    editModeChanges: false,
  },
  eventsPagination: {
    count: 0,
    p: 0,
    ipp: 20,
    nextPage: null,
  },
  isEditMode: false,
  account: null,
  accountCompanyDetails: null,
  contacts: [],
  contactsSnapshot: null,
  selectedContactIds: [],
  notes: [],
  events: [],
};

export const relationshipDetailsSlice = createSlice({
  name: 'relationshipDetails',
  initialState,
  reducers: {
    switchToEditMode: (state) => {
      state.isEditMode = true;
      state.contactsSnapshot = state.contacts;
    },
    switchToViewMode: (state) => {
      state.isEditMode = false;
      state.contacts = state.contactsSnapshot || [];
      state.contactsSnapshot = null;
      state.selectedContactIds = [];
    },
    cancelEditModeChanges: (state) => {
      state.contacts = state.contactsSnapshot || [];
      state.selectedContactIds = [];
    },
    changeMembersAssignment: (
      state,
      action: PayloadAction<{
        contactIds: string[];
        associationType: RelationshipMemberAssociationType;
        contactSide: RelationshipMemberSide;
      }>
    ) => {
      const { contactIds, associationType, contactSide } = action.payload;
      const contactIdsSet = new Set(contactIds);

      state.contacts = state.contacts.map((contact) => {
        if (
          contactIdsSet.has(contact.contactId) &&
          contact.extra?.currentAccountLink
        ) {
          contact.extra.currentAccountLink.associationType = associationType;
          contact.extra.currentAccountLink.contactSide = contactSide;
        }

        return contact;
      });
    },
    changeMembersActiveState: (
      state,
      action: PayloadAction<{ contactIds: string[]; isActive: boolean }>
    ) => {
      const { contactIds, isActive } = action.payload;
      const contactIdsSet = new Set(contactIds);

      state.contacts = state.contacts.map((contact) => {
        if (
          contactIdsSet.has(contact.contactId) &&
          contact.extra?.currentAccountLink
        ) {
          contact.extra.currentAccountLink.isActive = isActive;
        }

        return contact;
      });
    },
    selectMember: (state, action: PayloadAction<string>) => {
      const cotactIdsSet = new Set(state.selectedContactIds);
      cotactIdsSet.add(action.payload);
      state.selectedContactIds = Array.from(cotactIdsSet);
    },
    unselectMember: (state, action: PayloadAction<string>) => {
      state.selectedContactIds = state.selectedContactIds.filter(
        (contactId) => contactId !== action.payload
      );
    },
    discardMemberChanges: (state, action: PayloadAction<string>) => {
      const contactInSnapshot = state.contactsSnapshot?.find(
        ({ contactId }) => contactId === action.payload
      );

      state.contacts = state.contacts.map((contact) => {
        if (contact.contactId === action.payload && contactInSnapshot) {
          return contactInSnapshot;
        }
        return contact;
      });

      state.selectedContactIds = state.selectedContactIds.filter(
        (contactId) => contactId !== action.payload
      );
    },
    clearSelectedMembers: (state) => {
      state.selectedContactIds = [];
    },
    resetRelationshipDetails: (state) => {
      state.eventsPagination = initialState.eventsPagination;
      state.isEditMode = initialState.isEditMode;
      state.accountCompanyDetails = initialState.accountCompanyDetails;
      state.contacts = initialState.contacts;
      state.contactsSnapshot = initialState.contactsSnapshot;
      state.selectedContactIds = initialState.selectedContactIds;
      state.notes = initialState.notes;
      state.events = initialState.events;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRelationshipAccountDetails.pending, (state) => {
      state.isLoading.account = true;
      state.isError.account = false;
    });
    builder.addCase(
      getRelationshipAccountDetails.fulfilled,
      (state, action) => {
        state.isLoading.account = false;
        state.account = action.payload;
      }
    );
    builder.addCase(getRelationshipAccountDetails.rejected, (state, action) => {
      state.isLoading.account = false;
      state.isError.account = !action.meta.aborted;
    });
    builder.addCase(getRelationshipAccountCompanyDetails.pending, (state) => {
      state.isLoading.accountCompanyDetails = true;
      state.isError.accountCompanyDetails = false;
    });
    builder.addCase(
      getRelationshipAccountCompanyDetails.fulfilled,
      (state, action) => {
        state.isLoading.accountCompanyDetails = false;
        state.accountCompanyDetails = action.payload;
      }
    );
    builder.addCase(
      getRelationshipAccountCompanyDetails.rejected,
      (state, action) => {
        state.isLoading.accountCompanyDetails = false;
        state.isError.accountCompanyDetails = !action.meta.aborted;
      }
    );
    builder.addCase(getAccountNotes.pending, (state) => {
      state.isLoading.notes = true;
      state.isError.notes = false;
    });
    builder.addCase(getAccountNotes.fulfilled, (state, action) => {
      state.isLoading.notes = false;
      state.notes = action.payload;
    });
    builder.addCase(getAccountNotes.rejected, (state, action) => {
      state.isLoading.notes = false;
      state.isError.notes = !action.meta.aborted;
    });
    builder.addCase(createAccountNote.pending, (state) => {
      state.isLoading.updateNotes = true;
      state.isError.updateNotes = false;
    });
    builder.addCase(createAccountNote.fulfilled, (state, action) => {
      state.isLoading.updateNotes = false;
      state.notes = [action.payload, ...state.notes];
    });
    builder.addCase(createAccountNote.rejected, (state, action) => {
      state.isLoading.updateNotes = false;
      state.isError.updateNotes = !action.meta.aborted;
    });
    builder.addCase(updateAccountNote.pending, (state) => {
      state.isLoading.updateNotes = true;
      state.isError.updateNotes = false;
    });
    builder.addCase(updateAccountNote.fulfilled, (state, action) => {
      state.isLoading.updateNotes = false;
      state.notes = state.notes.map((note) => {
        if (note.noteId === action.payload.noteId) {
          return action.payload;
        }

        return note;
      });
    });
    builder.addCase(updateAccountNote.rejected, (state, action) => {
      state.isLoading.updateNotes = false;
      state.isError.updateNotes = !action.meta.aborted;
    });
    builder.addCase(deleteAccountNote.pending, (state) => {
      state.isLoading.updateNotes = true;
      state.isError.updateNotes = false;
    });
    builder.addCase(deleteAccountNote.fulfilled, (state, action) => {
      state.isLoading.updateNotes = false;
      state.notes = state.notes.filter(
        (note) => note.noteId !== action.payload
      );
    });
    builder.addCase(deleteAccountNote.rejected, (state, action) => {
      state.isLoading.updateNotes = false;
      state.isError.updateNotes = !action.meta.aborted;
    });
    builder.addCase(getRelationshipContacts.pending, (state) => {
      state.isLoading.contacts = true;
      state.isError.contacts = false;
    });
    builder.addCase(getRelationshipContacts.fulfilled, (state, action) => {
      state.isLoading.contacts = false;
      state.contacts = action.payload;
    });
    builder.addCase(getRelationshipContacts.rejected, (state, action) => {
      state.isLoading.contacts = false;
      state.isError.contacts = !action.meta.aborted;
    });
    builder.addCase(applyEditModeChanges.pending, (state) => {
      state.isLoading.editModeChanges = true;
      state.isError.editModeChanges = false;
    });
    builder.addCase(applyEditModeChanges.fulfilled, (state) => {
      state.isLoading.editModeChanges = false;
      state.contactsSnapshot = state.contacts;
    });
    builder.addCase(applyEditModeChanges.rejected, (state, action) => {
      state.isLoading.editModeChanges = false;
      state.isError.editModeChanges = !action.meta.aborted;
    });
    builder.addCase(getRelationshipEvents.pending, (state) => {
      state.isLoading.events = true;
      state.isError.events = false;
    });
    builder.addCase(getRelationshipEvents.fulfilled, (state, action) => {
      state.isLoading.events = false;
      state.events = [...state.events, ...action.payload.results];
      state.eventsPagination.ipp = action.payload.ipp;
      state.eventsPagination.p = action.payload.p;
      state.eventsPagination.nextPage = action.payload.nextPage;
    });
    builder.addCase(getRelationshipEvents.rejected, (state, action) => {
      state.isLoading.events = false;
      state.isError.events = !action.meta.aborted;
    });
    builder.addCase(linkContactToAccount.fulfilled, (state, action) => {
      const isNewContact = !state.contacts.some(
        (contact) => contact.contactId === action.payload.contactId
      );

      const contactWithExtra: Contact = {
        ...action.payload,
        extra: {
          ...action.payload.extra,
          currentAccountLink: {
            accountId: action.meta.arg.accountId,
            associationType: action.meta.arg
              .contactType as RelationshipMemberAssociationType,
            contactSide: action.meta.arg.assignedTo as RelationshipMemberSide,
            isActive: true,
            note: action.meta.arg.note,
          },
        },
      };

      const updatedContacts = isNewContact
        ? [...state.contacts, contactWithExtra]
        : state.contacts.map((contact) =>
            contact.contactId === action.meta.arg.contactId
              ? contactWithExtra
              : contact
          );

      state.contacts =
        state.account?.accountId === action.meta.arg.accountId
          ? updatedContacts
          : state.contacts;
    });
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      if (state.account?.accountId === action.payload.accountId) {
        state.account = action.payload;

        if (state.accountCompanyDetails) {
          state.accountCompanyDetails.avatarSrc = action.payload.avatarSrc;
          state.accountCompanyDetails.accountName = action.payload.name;
          state.accountCompanyDetails.companyWebsite =
            typeof action.meta.arg.website === 'string'
              ? action.meta.arg.website
              : state.accountCompanyDetails.companyWebsite;
        }
      }
    });
    builder.addCase(assignEventToAccount.fulfilled, (state, action) => {
      state.events = state.events.filter((event) => {
        const isUpdatedEvent =
          event.touchPointId === action.payload.touchPoint?.touchPointId &&
          event.accountId !== action.payload.account?.accountId;

        return !isUpdatedEvent;
      });
    });
  },
});

export const {
  switchToEditMode,
  switchToViewMode,
  cancelEditModeChanges,
  changeMembersAssignment,
  changeMembersActiveState,
  selectMember,
  unselectMember,
  discardMemberChanges,
  clearSelectedMembers,
  resetRelationshipDetails,
} = relationshipDetailsSlice.actions;

export default relationshipDetailsSlice.reducer;
